import React, { forwardRef } from 'react';

import { t } from '../../../../../features/i18next/t';

import styles from '../styles.css';

export const EmptyNotification = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref} className={styles.empty}>
      <div className={styles.emptyTitle}>
        {t('multi_product__notifications__dropdown__none')}
      </div>
    </div>
  );
});

EmptyNotification.displayName = 'EmptyNotification';
