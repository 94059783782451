import { Record, List, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  title: z.any(),
  duration: z.any(),
  learnings: z.any(),
  imageSrc: z.any(),
  length: z.any(),
  suggestedActionTexts: z.any(),
  locale: z.any(),
});
type Schema = z.infer<typeof schema>;

class LessonRecord
  extends Record({
    id: undefined,
    completed: false,
    description: undefined,
    duration: undefined,
    imageSrc: undefined,
    learnings: List(),
    length: undefined,
    path: undefined,
    slideNumber: undefined,
    title: undefined,
    suggestedActionTexts: List(),
  })
  implements Schema
{
  id!: Schema['id'];
  completed?: boolean;
  duration?: number;
  length?: number;
  slideNumber?: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'LessonRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'LessonRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get progress() {
    return this.completed
      ? 100
      : ((this.slideNumber || 0) / (this.length || 0)) * 100;
  }

  static createFromApi(data: $TSFixMe) {
    const { id, attributes } = data;

    return new LessonRecord(
      fromJS({
        id,
        ...attributes,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default LessonRecord;
