import React from 'react';

import { LogosLogoWdayIcon as WorkdayLogo } from '@peakon/bedrock/icons/graphic';
import { Grid } from '@peakon/components';
import getResizedImageUrl from '@peakon/shared/utils/getResizedImageUrl';

import styles from './styles.css';

type Props = {
  companyLogo?: string;
  companyName?: string;
};

export function LogoContainer({ companyLogo, companyName }: Props) {
  return (
    <div className={styles.logoContainer}>
      <Grid>
        <Grid.Row>
          <Grid.Col sm={12}>
            <div className={styles.logoList}>
              <div />
              <div className={styles.centerLogo}>
                {companyLogo && (
                  <img
                    className={styles.centerLogoImage}
                    src={getResizedImageUrl({ src: companyLogo, height: 40 })}
                    alt={companyName}
                  />
                )}
              </div>
              <div className={styles.endLogo}>
                <WorkdayLogo />
              </div>
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
