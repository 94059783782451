import { List } from 'immutable';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';

import escapeAttrName from '@peakon/shared/utils/escapeAttrName';

export const getParams = (url: $TSFixMe) => qs.parse(url);

const getFilterQuery = ({
  filters = List(),
  fields = List(),
  sort = 'name',
  order = 'asc',
  perPage = '25',
  query,
}: $TSFixMe) => {
  const filter = filters
    // @ts-expect-error TS(7006): Parameter 'f' implicitly has an 'any' type.
    .map((f) => f.toQueryParam())
    // @ts-expect-error TS(7006): Parameter 'curr' implicitly has an 'any' type.
    .reduce((curr, prev) => merge(curr, prev), {});

  if (!filter.hasOwnProperty('employmentStatus')) {
    if (filter.hasOwnProperty('Separation date')) {
      filter.employmentStatus = '"hired","employed","left"$in';
    } else {
      filter.employmentStatus = '"hired","employed"$in';
    }
  }

  const labels = fields
    // @ts-expect-error TS(7006): Parameter 'f' implicitly has an 'any' type.
    .filter((f) => !f.default)
    // @ts-expect-error TS(7006): Parameter 'f' implicitly has an 'any' type.
    .map((f) => {
      return f.type === 'employee'
        ? f.label.concat(`,${f.label}.account`)
        : f.label;
    })
    .map(escapeAttrName)
    // @ts-expect-error TS(7006): Parameter 'f' implicitly has an 'any' type.
    .map((f) => {
      if (f.match(/\\.account/)) {
        return f.replace(/\\/, '');
      }
      return f;
    })
    .toArray();
  return pickBy(
    {
      q: query,
      filter,
      simple: false,
      sort,
      order: order.toLowerCase(),
      per_page: perPage,
      fields: {
        employees: [
          '+',
          'createdAt',
          'phones',
          'employmentStatus',
          'employmentEnd',
        ].join(','),
      },
      // Relacing \ in order to remove bug from issue: SUP-375
      include: ['account', 'phones'].concat(labels).join(','),
    },
    identity,
  );
};

// eslint-disable-next-line import/no-default-export
export default getFilterQuery;
