import { Map, List } from 'immutable';
import flatten from 'lodash/flatten';

const getInitialState = () => {
  return Map({
    priorities: List(),
    isLoading: true,
    drivers: List(),
    driver: Map(),
  });
};

const priorities = (state = getInitialState(), action: $TSFixMe) => {
  switch (action.type) {
    case 'PRIORITIES_AND_STRENGTHS_READ_LOADING':
    case 'PRIORITIES_MANAGER_READ_LOADING':
    case 'PRIORITIES_DRIVER_READ_LOADING':
    case 'PRIORITIES_READ_LOADING:': {
      return state.merge({
        isLoading: true,
      });
    }
    case 'PRIORITIES_READ_SUCCESS':
    case 'PRIORITIES_MANAGER_READ_SUCCESS': {
      return state.merge({
        priorities: List(action.data.data),
        isLoading: false,
      });
    }
    case 'PRIORITIES_AND_STRENGTHS_READ_SUCCESS': {
      return state.merge({
        // @ts-expect-error TS(7006): Parameter 'd' implicitly has an 'any' type.
        priorities: List(flatten(action.data.map((d) => d.data))),
        isLoading: false,
      });
    }
    case 'PRIORITIES_DRIVER_RESET':
    case 'PRIORITIES_RESET': {
      return getInitialState();
    }
    case 'GROUP_CATEGORIES_SCORES_SUCCESS':
    case 'PRIORITIES_MANAGER_SUGGESTED_READ_SUCCESS': {
      return state.merge({
        drivers: List(action.data.data),
        isLoading: false,
      });
    }
    case 'PRIORITIES_DRIVER_READ_SUCCESS': {
      return state.merge({
        driver: action.data.data,
        isLoading: false,
      });
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default priorities;
