import { fromJS, List, OrderedMap } from 'immutable';

import { ActionsGroup, ActionsTeam } from '@peakon/records';
import Action from '@peakon/records/ActionRecord';

type State = OrderedMap<string, ActionsTeam>;

const teams = (
  state = OrderedMap<string, ActionsTeam>(),
  action: $TSFixMe,
): State => {
  switch (action.type) {
    case 'ACTION_TEAMS_GROUPS_LIST_SUCCESS':
    case 'ACTION_TEAMS_ACTIONS_LIST_SUCCESS': {
      const { data } = action.data;

      return OrderedMap(
        // @ts-expect-error TS(7006): Parameter 'team' implicitly has an 'any' type.
        data.map((team) => [team.id, ActionsTeam.createFromApi(team)]),
      );
    }

    case 'CONTEXT_ACTION_GROUPS_LIST_LOADING':
    case 'CONTEXT_ACTIONS_LIST_LOADING': {
      const { contextId } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn([contextId], (team) =>
        team.set('isLoading', true),
      ) as State;
    }

    case 'CONTEXT_ACTION_GROUPS_LIST_SUCCESS': {
      const { contextId, data } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn([contextId], (team) =>
        team.merge({
          isLoading: false,
          groups: OrderedMap(
            // @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
            data.map((group) => [
              group.attributes.group,
              ActionsGroup.createFromApi(group, contextId),
            ]),
          ),
        }),
      ) as State;
    }

    case 'CONTEXT_ACTIONS_LIST_SUCCESS': {
      const { contextId, data } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn([contextId], (team) =>
        team.merge({
          isLoading: false,
          actions: List(
            fromJS(
              // @ts-expect-error TS(7006): Parameter 'dataAction' implicitly has an 'any' typ... Remove this comment to see the full error message
              data.map((dataAction) =>
                Action.createFromApi(dataAction, team.id),
              ),
            ),
          ),
        }),
      ) as State;
    }

    case 'ACTION_REMOVE_TEAM': {
      const { id, group, sort, team } = action.data;

      let selector;
      if (sort === 'group') {
        selector = [team, 'groups', group, 'actions'];
      } else {
        selector = [team, 'actions'];
      }

      if (!state.hasIn(selector)) {
        return state;
      }

      const newState = state.updateIn(selector, (actions) =>
        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        actions.filter((a) => a.id !== id),
      );

      // remove empty groups
      if (sort === 'group') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return newState.updateIn([team, 'groups'], (groups) =>
          // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
          groups.filter((item) => item.actions.size),
        ) as State;
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return newState as State;
    }

    case 'ACTION_UPDATE_TEAM_SUCCESS': {
      const { id, attributes, group, team, sort, data } = action.data;

      let selector;
      if (sort === 'group') {
        selector = [team, 'groups', group, 'actions'];
      } else {
        selector = [team, 'actions'];
      }

      if (!state.hasIn(selector)) {
        return state;
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.updateIn(selector, (actions) => {
        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        const index = actions.findIndex((a) => a.id === id);

        if (data) {
          return actions.updateIn([index], () =>
            Action.createFromApi(data, team),
          );
        }

        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        return actions.updateIn([index], (a) => a.merge(attributes));
      }) as State;
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default teams;
