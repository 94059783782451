import React from 'react';

import classnames from 'classnames';

import { UtilityCheckmarkIcon as CheckmarkIcon } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

type StepProps = {
  active?: boolean;
  children?: React.ReactNode;
  completed?: boolean;
  onClick?: $TSFixMeFunction;
  step?: number;
};

const Step = ({ active, children, completed, onClick, step }: StepProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className={classnames(styles.step, {
      [styles.active]: active,
      [styles.completed]: completed,
      [styles.link]: Boolean(onClick),
    })}
    onClick={onClick}
  >
    <div className={styles.number}>
      {completed ? <CheckmarkIcon className={styles.checkmark} /> : `${step}.`}
    </div>
    <div className={styles.title}>{children}</div>
  </div>
);

type StepsBreadcrumbProps = {
  children?: React.ReactNode;
};

const StepsBreadcrumb = ({ children }: StepsBreadcrumbProps) => (
  <div className={styles.root}>{children}</div>
);

StepsBreadcrumb.Step = Step;

// eslint-disable-next-line import/no-default-export
export default StepsBreadcrumb;
