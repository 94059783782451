import React, { forwardRef } from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';

type LinkComponentProps = {
  children?: React.ReactNode;
  to?: string;
};

type ReactLinkComponentProps = {
  children?: React.ReactNode;
  to: string;
};

const ALink = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  ({ children, to, ...props }, ref) => {
    return (
      <a ref={ref} href={to} {...props}>
        {children}
      </a>
    );
  },
);

ALink.displayName = 'ALink';

const ReactLink = forwardRef<HTMLAnchorElement, ReactLinkComponentProps>(
  ({ children, to, ...props }, ref) => {
    return (
      <ReactRouterLink ref={ref} to={to} {...props}>
        {children}
      </ReactRouterLink>
    );
  },
);

ReactLink.displayName = 'ReactLink';

type LinkProps = {
  type: 'react' | 'a';
  to?: string;
  children: React.ReactNode;
  className: string;
  onClick?: () => void;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, type, ...props }, ref) => {
    if (type === 'react' && props.to !== undefined) {
      return (
        <ReactLink ref={ref} to={props.to} {...props}>
          {children}
        </ReactLink>
      );
    } else {
      return (
        <ALink ref={ref} {...props}>
          {children}
        </ALink>
      );
    }
  },
);

Link.displayName = 'Link';

// eslint-disable-next-line import/no-default-export
export default Link;
