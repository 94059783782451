import { combineReducers } from '@reduxjs/toolkit';

import phases from './phases';
import questions from './questions';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  questions,
  phases,
});
