import { abbreviation } from '@peakon/engage-helpers';

// eslint-disable-next-line import/no-default-export
export default function getAbbreviation(firstName?: string, lastName?: string) {
  const words = [firstName ?? ''];

  if (lastName) {
    words.push(lastName);
  }

  return abbreviation.abbreviateWords(words);
}

export function getAbbreviationFromName(name: string) {
  return abbreviation.abbreviate(name);
}
