import React from 'react';

import { NotificationsSensitiveCommentsIcon as SensitiveCommentIcon } from '@peakon/bedrock/icons/graphic';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  matches?: Array<string>;
};

export const SensitiveBanner = ({ matches = [] }: Props) => (
  <div className={styles.root} data-test-id="comment-sensitive-banner">
    <SensitiveCommentIcon />
    <span>
      {matches.length === 0
        ? t('comments__item__sensitive__marked_by_company')
        : t('comments__item__sensitive__detected')}
    </span>
    {matches?.map((match) => (
      <span key={match} className={styles.word}>
        {match}
      </span>
    ))}
  </div>
);
