import React from 'react';

import classnames from 'classnames';

import { EditCloseIcon as Cross } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

import ContextLabel from '../ContextLabel';

import styles from './styles.css';

type Props = {
  compact?: boolean;
  excluded?: boolean;
  included?: boolean;
  onClear?: $TSFixMeFunction;
  segment?: $TSFixMe; // TODO: PropTypes.instanceOf(Segment) | PropTypes.instanceOf(Context) | PropTypes.object
};

export const SegmentBadge = ({
  compact,
  excluded,
  included,
  onClear,
  segment,
}: Props) => (
  <div
    className={classnames(styles.root, {
      [styles.included]: included,
      [styles.excluded]: excluded,
      [styles.compact]: compact,
    })}
    data-test-id="segment-badge"
  >
    <ContextLabel dashboardContext={segment} size="tiny" />
    {onClear && (
      <UnstyledButton
        accessibleName={t('segments_picker__clear_picked_segment', {
          replace: {
            segmentName: segment.nameTranslated ?? segment.name,
          },
        })}
        className={styles.clear}
        onClick={(e) => {
          e.stopPropagation();
          onClear(segment.id);
        }}
      >
        <Cross aria-hidden />
      </UnstyledButton>
    )}
  </div>
);
