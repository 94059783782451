import React from 'react';

import moment from 'moment';

import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  date?: string;
  format?: string;
};

const CreatedAt = ({ date, format = 'L' }: Props) => {
  // Prevent moment.utc(undefined) which would return today's date
  if (!date) {
    return null;
  }

  const utcDate = moment.utc(date);

  return (
    <span className={styles.createdAt} title={utcDate.format('YYYY-MM-DD')}>
      {t('created_at', { replace: { date: utcDate.format(format) } })}
    </span>
  );
};

// eslint-disable-next-line import/no-default-export
export default CreatedAt;
