import { useSyncExternalStore } from 'react';

import store2 from 'store2';

import { hasProxyViewRight } from '@peakon/app-manager/utils/decodeToken';

const _TOKEN_KEYS = ['auth_token', 'initial_auth_token'] as const;
type AuthTokenKey = (typeof _TOKEN_KEYS)[number];

export const setToken = (key: AuthTokenKey, token: string | null) => {
  if (token) {
    store2.set(key, token);
  } else {
    store2.remove(key);
  }
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: token || '',
    }),
  );
};

export const getSnapshot: (key: AuthTokenKey) => string | null = (key) =>
  store2.get(key);

const subscribe = (listener: () => void) => {
  window.addEventListener('storage', listener);
  return () => window.removeEventListener('storage', listener);
};

type HookFn = (
  key: AuthTokenKey,
) => [string | null, (token: string | null) => void];

const useTokenFromStorage: HookFn = (key: AuthTokenKey) => {
  const getFn = () => getSnapshot(key);
  const store = useSyncExternalStore(subscribe, getFn);
  const setInitialTokenFn = (token: string | null) => {
    setToken(key, token);
  };

  return [store, setInitialTokenFn];
};

export const useInitialAuthToken = () =>
  useTokenFromStorage('initial_auth_token');

export const useAuthToken = () => useTokenFromStorage('auth_token');

export const proxyViewSnapshot = () => {
  return (
    getSnapshot('initial_auth_token') ||
    hasProxyViewRight(getSnapshot('auth_token'))
  );
};
