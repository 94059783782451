import { useQuery } from '@tanstack/react-query';

import { isHttpError } from '@peakon/shared/utils';
import { ErrorReporterType } from '@peakon/shared/utils/errorReporter';

import { getContextById } from './fetchContext';
import { NullableSession } from '../../../types/Session';

export type AuthOptions = { remember: boolean };

export const useContextByIdQuery = ({
  contextId,
  enabled,
}: {
  contextId: string | null;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: ['context', contextId],
    enabled: enabled && Boolean(contextId),
    retry: false,
    queryFn: async () => {
      if (!contextId) {
        throw new Error('Missing contextId');
      }
      return await getContextById(contextId);
    },
  });
};

export const useSessionQuery = ({
  authenticate,
  authOptions,
  onAuthenticated,
  withAuthentication,
  errorReporter,
  app,
}: {
  authenticate: (authOptions?: AuthOptions) => Promise<NullableSession>;
  authOptions?: AuthOptions;
  onAuthenticated: (session: NullableSession) => void;
  withAuthentication: boolean;
  errorReporter: ErrorReporterType;
  app: 'shared-dashboard' | undefined;
}) => {
  return useQuery({
    queryKey: ['session', authOptions],
    queryFn: () => authenticate(authOptions),
    enabled: withAuthentication,
    cacheTime: 0,
    retry: false,
    onSuccess: async (sessionData: NullableSession) => {
      onAuthenticated(sessionData);
    },
    onError: (err: Error) => {
      if (
        app === 'shared-dashboard' &&
        isHttpError(err) &&
        err.code >= 400 &&
        err.code < 500
      ) {
        // shared dashboard does not want to redirect to login
        // instead for these ranges we want to show the NotFound screen
        return;
      }
      onAuthenticated(null);

      errorReporter.error(err);
    },
  });
};
