import { List } from 'immutable';

import CustomActionRecord from '@peakon/records/improve/CustomActionRecord';
import { Driver } from '@peakon/shared/types/Drivers';
import { jsonapiparser } from '@peakon/shared/utils';

const getInitialState = () => ({
  isLoading: true,
  isSaving: false,
  items: List<CustomActionRecord>(),
  error: null,
  drivers: List(),
  suggested: List(),
});

type State = {
  isLoading: boolean;
  isSaving: boolean;
  items: List<CustomActionRecord>;
  error: null;
  drivers: List<unknown>;
  suggested: List<unknown>;
};

const customActions = (state = getInitialState(), action: $TSFixMe): State => {
  switch (action.type) {
    case 'CUSTOM_ACTION_LIST_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isLoading: false,
        items: List(data.map(CustomActionRecord.createFromApi)),
      };
    }
    case 'CUSTOM_ACTION_CREATE_LOADING':
    case 'CUSTOM_ACTION_UPDATE_LOADING': {
      return {
        ...state,
        isSaving: true,
      };
    }
    case 'CUSTOM_ACTION_CREATE_FAILED':
    case 'CUSTOM_ACTION_UPDATE_FAILED': {
      return {
        ...state,
        isSaving: false,
      };
    }
    case 'CUSTOM_ACTION_CREATE_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isSaving: false,
        items: state.items.push(CustomActionRecord.createFromApi(data)),
      };
    }
    case 'CUSTOM_ACTION_UPDATE_SUCCESS': {
      const { customAction, data } = action.data;
      const { items } = state;

      return {
        ...state,
        isSaving: false,
        items: items.updateIn(
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          [items.findIndex((i) => i.id === customAction.id)],
          (item) => item.merge(CustomActionRecord.createFromApi(data)),
        ),
      };
    }
    case 'CUSTOM_ACTION_DELETE_SUCCESS': {
      const { customAction } = action.data;
      const { items } = state;

      return {
        ...state,
        isSaving: false,
        items: items.removeIn([
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          items.findIndex((i) => i.id === customAction.id),
        ]),
      };
    }
    // FIXME: when company resources are also Platoised
    case 'CUSTOM_ACTION_DRIVERS_LIST_SUCCESS': {
      const { data } = jsonapiparser(action.data);

      return {
        ...state,
        drivers: List(data.filter((driver: Driver) => driver.id !== 'null')),
      };
    }
    case 'CUSTOM_ACTION_SUGGESTED_READ_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        suggested: List(data.map(CustomActionRecord.createFromApi)),
      };
    }
    case 'IMPROVE_OVERVIEW_V2_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default customActions;
