import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { jsonapiparser } from '@peakon/shared/utils';
import api from '@peakon/shared/utils/api';

import { Links, Message } from './types/message';
import { conversationMessagesKeys } from './useMessages';
import { ConversationKind } from '../../context/ConversationsContext';

type MessageProps = {
  commentId: string;
  contextId?: string;
  anonymous?: boolean;
  kind: ConversationKind;
  message: string;
};

export type QueryData = {
  pages: {
    data: Partial<Message>[];
    links?: Links;
  }[];
};

const createMessage = async ({
  commentId,
  contextId,
  kind,
  message,
  anonymous,
}: MessageProps): Promise<Message> => {
  if (!commentId) {
    throw new Error('`commentId` is required');
  }

  let url = `/conversations/comments/${commentId}/messages`;

  if (contextId) {
    url += `/contexts/${contextId}`;
  }

  const response = await api.post(
    url,
    {
      'fields[employees]': 'firstName,lastName,name,avatar',
      include: 'employee',
    },
    {
      data: {
        attributes: {
          anonymous,
          kind,
          text: message,
        },
        type: 'conversation_messages',
      },
    },
  );

  return jsonapiparser(response).data;
};

type MutationOptions = UseMutationOptions<Message, Error, MessageProps>;

type CreateMessageParams = {
  commentId: MessageProps['commentId'];
  onSuccess: MutationOptions['onSuccess'];
  onError: MutationOptions['onError'];
};

const useCreateMessage = ({
  onSuccess,
  onError,
  commentId,
}: CreateMessageParams) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation({
    mutationFn: ({ contextId, kind, message, anonymous = false }) =>
      createMessage({
        commentId,
        contextId,
        kind,
        message,
        anonymous,
      }),
    onError,
    onSuccess: (newMessage, _variables, _context) => {
      queryClient.setQueryData<QueryData>(
        conversationMessagesKeys.list(commentId),
        (query) => insertMessageIntoQueryCache(query, newMessage),
      );

      onSuccess?.(newMessage, _variables, _context);
    },
  });

  return {
    mutate: (args: Omit<MessageProps, 'commentId'>) =>
      mutate({ commentId, ...args }),
    ...rest,
  };
};

export const insertMessageIntoQueryCache = (
  queryCache?: QueryData,
  message?: Message,
) => {
  const lastPage = queryCache?.pages[queryCache.pages.length - 1];

  return {
    ...queryCache,
    pages: [
      ...(queryCache?.pages || []),
      { data: [{ isNew: true, ...message }], links: lastPage?.links },
    ],
  };
};

// eslint-disable-next-line import/no-default-export
export default useCreateMessage;
