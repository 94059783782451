import { useQuery } from '@tanstack/react-query';

import api from '../../../utils/api';
import { Params, commentsKeys } from '../commentKeys';

const getUnreadCommentsCount = async ({
  contextId,
  params,
}: {
  contextId?: string;
  params: Params;
}) => {
  return await api.get(`/comments/contexts/${contextId}/unread`, params);
};

type UnreadCommentsData = {
  unreadCommentsCount: number;
  unreadSensitiveCommentsCount: number;
};

type Args = {
  contextId?: string;
  isTest?: boolean;
  hasCommentRights?: boolean;
};

export const getUnreadCommentsCountParams = (isTest?: boolean): Params => {
  if (isTest) {
    return { filter: { 'round.type': 'test' } };
  }

  return {};
};

export const useUnreadCommentsCount = ({
  contextId,
  isTest,
  hasCommentRights,
}: Args) => {
  const params = getUnreadCommentsCountParams(isTest);

  const { data: unreadCounts, ...rest } = useQuery({
    queryKey: commentsKeys.unreadCommentsCount(contextId, params),
    queryFn: () => getUnreadCommentsCount({ contextId, params }),
    select: (data): UnreadCommentsData => {
      return {
        // @ts-expect-error @peakon/peakon-js TS18046: 'data' is of type 'unknown'.
        unreadCommentsCount: data.unread,
        // @ts-expect-error @peakon/peakon-js TS18046: 'data' is of type 'unknown'.
        unreadSensitiveCommentsCount: data.sensitive,
      };
    },
    enabled: Boolean(contextId) && hasCommentRights,
  });

  return { ...unreadCounts, ...rest };
};
