import React from 'react';

import get from 'lodash/get';

import { TopNavigationWrapper as TopNavigation } from '@peakon/shared/components/Navigation/Top';

import { useShellContext } from '../../../context/ShellContext';

type Props = {
  isTest: boolean;
  welcomeTourItem?: React.ReactNode;
};

export function Header({ isTest, welcomeTourItem }: Props) {
  const { session, isAuthenticated } = useShellContext();

  if (!isAuthenticated || !session) {
    return null;
  }

  const employee = get(session, 'data.relationships.employee.attributes', {});

  return (
    <TopNavigation
      employee={employee}
      isTest={isTest}
      welcomeTourItem={welcomeTourItem}
    />
  );
}
