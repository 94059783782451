import React from 'react';

import { GuidanceWhatDoesThisMeanOutlineIcon as HelpIcon } from '@peakon/bedrock/icons/system';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';

import styles from './styles.css';

type Props = {
  label?: React.ReactNode;
};

const HelpBadge = ({ label }: Props) => (
  <div className={styles.badge}>
    <HelpIcon aria-hidden="true" />
    {label && <VisuallyHidden>{label}</VisuallyHidden>}
  </div>
);

// eslint-disable-next-line import/no-default-export
export default HelpBadge;
