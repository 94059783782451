import { Record, fromJS, List } from 'immutable';
import { z } from 'zod';

import {
  ContextCategoryGroupResponse,
  ContextResponse,
} from '@peakon/shared/schemas/api/contexts';
import hasRight from '@peakon/shared/utils/hasRight';
import { Right, RightLevels } from '@peakon/shared/utils/rights/types';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { Attribute, CategoryGroup } from './';
import { CategoryGroup as CategoryGroupType } from './constants/questionSets';
import { validateTestingSchema } from './utils';

export const DEFAULT_FIELDS = {
  id: undefined,
  level: undefined,
  name: undefined,
  logo: undefined,
  abbreviation: undefined,
  direct: undefined,
  employeeId: undefined,
  employeeCount: {
    hired: undefined,
    employed: undefined,
    left: undefined,
  },
  rights: List(),
  attribute: undefined,
  isEmployeeDashboardTeam: undefined,
};

const schema = z.object({});
const testingSchema = schema.extend({
  id: z.string().optional(), // think it's only `optional` within shareable-dashboard. failed here: "As logged out user.when accessing private shared dashboard.it logs in"
  employeeid: z.string().optional(),
  attribute: z.any(),
  meta: z.any(),
  rawAttributes: z.any(),
  categoryGroups: z.any(),
  employeeCount: z.any(),
  level: z.any(),
  direct: z.any(),
  logo: z.any(),
  name: z.any(),
  abbreviation: z.any(),
  rights: z.any(),
  isEmployeeDashboardTeam: z.any(),
  employeeId: z.any().optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Context
  extends Record({
    ...DEFAULT_FIELDS,
    categoryGroups: List(),
    loading: false,
    group: undefined,
    rawAttributes: undefined,
  })
  implements Schema
{
  id!: string;
  abbreviation?: string;
  attribute?: Attribute;
  categoryGroups!: List<CategoryGroup>;
  direct?: boolean;
  employeeId?: string;
  group?: CategoryGroupType;
  level?: 'company' | 'segment';
  loading!: boolean;
  logo?: string;
  name?: string;
  rawAttributes: $TSFixMe;
  rights?: List<Right>;
  isEmployeeDashboardTeam?: boolean;
  employeeCount?: {
    employed?: number;
    left?: number;
    hired?: number;
  };

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ContextRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ContextRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isCompany() {
    return this.level === 'company';
  }

  hasRight(right: string | string[] | Right | Right[]) {
    return hasRight(this.rights?.toArray(), right);
  }

  hasGradient() {
    return this.level === 'company' || this.attribute?.type !== 'employee';
  }

  isEmployee(employeeId: string) {
    return Boolean(employeeId) && this.employeeId === employeeId;
  }

  isCurrentEmployee(session: $TSFixMe) {
    return (
      this.employeeId &&
      parseInt(this.employeeId, 10) === parseInt(session.employeeId, 10)
    );
  }

  hasGroupAccess(group?: (typeof RightLevels)[number]) {
    if (!group) {
      return false;
    }

    return this.hasRight(`score:categoryGroup:read:${group}`);
  }

  get totalEmployeeCount(): number | undefined {
    return (
      this.getIn(['employeeCount', 'employed']) ?? this.employeeCount?.employed
    );
  }

  get icon() {
    return this.level !== 'company' &&
      (this.attribute?.type === 'date' || this.attribute?.type === 'number')
      ? this.attribute.type
      : undefined;
  }

  get raw() {
    return this.rawAttributes.toJS();
  }

  static revive(data: $TSFixMe) {
    return new Context(fromJS({ ...data, loading: false }));
  }

  static createFromApi(
    data: ContextResponse,
    options?: {
      keepRawAttributes?: boolean;
    },
  ) {
    const keepRawAttributes = options?.keepRawAttributes;
    const { type, id } = data;

    const attribute = data.relationships?.attribute;
    const employeeId = data.relationships?.employee?.id;
    const contextCategoryGroups =
      data.relationships?.contextCategoryGroups || [];

    return new Context(
      fromJS({
        id,
        ...data.attributes,
        employeeId,
        attribute: attribute ? Attribute.createFromApi(attribute) : undefined,
        meta: {
          id,
          type,
        },
        rawAttributes: keepRawAttributes ? data : undefined,
        categoryGroups: contextCategoryGroups.map(
          (contextCategoryGroup: ContextCategoryGroupResponse) =>
            CategoryGroup.createFromApi(
              contextCategoryGroup.relationships.categoryGroup,
              {
                keepStandard: true,
                access: contextCategoryGroup.attributes.access,
                hasOutcome: contextCategoryGroup.attributes.hasOutcome,
                hasOtherDrivers:
                  contextCategoryGroup.attributes.hasOtherDrivers,
                categories: contextCategoryGroup.relationships.categories,
                resource: contextCategoryGroup.attributes.resource,
              },
            ),
        ),
      }),
    );
  }
}
