import { useQuery } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';

import api from '../../../utils/api';

export type FeatureFlipRaw = {
  [key: string]: {
    current: boolean | null;
    default: boolean;
    description?: string;
  };
};

export type FeatureFlip = {
  name: string;
  userValue: boolean | null;
  defaultValue: boolean;
  description?: string;
  id: string;
};

const parseFeatureFlips = (data: FeatureFlipRaw) => {
  const features: FeatureFlip[] = [];

  Object.keys(data).forEach((featureName) => {
    if (featureName !== 'data') {
      features.push({
        id: featureName,
        name: featureName,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        defaultValue: data[featureName].default,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        description: data[featureName].description,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        userValue: data[featureName].current,
      });
    }
  });

  features.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return features;
};

const getFeatureFlips = async () => {
  try {
    const response = await api.get(
      `/test_companies/self_service/feature_flips`,
    );

    return response ? jsonapiparser(response) : undefined;
  } catch {
    return undefined;
  }
};

const useFeatureFlips = () => {
  return useQuery({
    queryKey: ['get_feature_flips'],
    queryFn: () => getFeatureFlips(),
    // @ts-expect-error return from jsonapiparser is unknown
    select: (data) => parseFeatureFlips(data),
  });
};

// eslint-disable-next-line import/no-default-export
export default useFeatureFlips;
