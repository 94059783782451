import React from 'react';

import { AcknowledgementIcon } from '@peakon/shared/components/AcknowledgementIcon';
import {
  ACKNOWLEDGEMENT_TYPES,
  AcknowledgementType,
} from '@peakon/shared/constants/acknowledgementTypes';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';

import Tooltip from '../../../dashboard/Tooltip';

import styles from './styles.css';

type Props = {
  counts: Partial<Record<AcknowledgementType, number>>;
};

const AcknowledgementCountsTooltip = ({ counts }: Props) => {
  const acknowledgementTranslations = createTranslationMap({
    thanks_for_sharing: t('acknowledgement__thanks_for_sharing'),
    great_idea: t('acknowledgement__great_idea'),
    working_on_it: t('acknowledgement__working_on_it'),
    would_love_to_talk_in_person: t(
      'acknowledgement__would_love_to_talk_in_person',
    ),
    i_agree: t('acknowledgement__i_agree'),
  });

  return (
    <div className={styles.root}>
      <Tooltip
        content={
          <Tooltip.Section>
            <Tooltip.Content>
              {ACKNOWLEDGEMENT_TYPES.filter(
                (acknowledgmentType) => acknowledgmentType in counts,
              ).map((acknowledgmentType) => (
                <div key={acknowledgmentType} className={styles.item}>
                  <div className={styles.count}>
                    <div className={styles.icon}>
                      <AcknowledgementIcon type={acknowledgmentType} />
                    </div>
                    {counts[acknowledgmentType]}
                  </div>
                  <span>
                    {acknowledgementTranslations.get(acknowledgmentType)}
                  </span>
                </div>
              ))}
            </Tooltip.Content>
          </Tooltip.Section>
        }
        title={t('acknowledgement__counts__tooltip_title')}
      >
        <span>
          {t('acknowledgement__counts', {
            replace: {
              count: Object.values(counts).reduce(
                (result, value) => result + value,
              ),
            },
          })}
        </span>
      </Tooltip>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementCountsTooltip;
