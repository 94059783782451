import { t } from '@peakon/shared/features/i18next/t';

type MemberStatusType = 'employee' | 'tree';

const getMembersStatus = (type: MemberStatusType, direct?: boolean) => {
  if (type === 'tree') {
    if (direct) {
      return t('tree_attribute__direct_members');
    } else {
      return t('tree_attribute__all_members');
    }
  } else {
    if (direct) {
      return t('levels__direct_reports');
    } else {
      return t('levels__all_reports');
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default getMembersStatus;
