import { Map } from 'immutable';

const courseProgress = (state = Map(), action: $TSFixMe) => {
  switch (action.type) {
    case 'COURSE_PROGRESS_SUCCESS': {
      const { data } = action.data;

      return Map(
        // @ts-expect-error TS(7006): Parameter 'lesson' implicitly has an 'any' type.
        data.map((lesson) => [
          lesson.id,
          Map({
            ...lesson.attributes,
          }),
        ]),
      );
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default courseProgress;
