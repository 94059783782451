export const IN = 'in';
export const NOT_IN = 'notIn';
export const GTE = 'gte';
export const LTE = 'lte';
export const LT = 'lt';

export type OptionFilterCondition = typeof IN | typeof NOT_IN;

export type Condition =
  | typeof NOT_IN
  | typeof IN
  | typeof GTE
  | typeof LTE
  | typeof LT;
