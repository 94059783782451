import React from 'react';

import { Card } from '@peakon/bedrock/react/card';
import { Inline, Stack, Box } from '@peakon/bedrock/react/layout';
import { Placeholder } from '@peakon/components';

import { Layout } from './Layout';

export function LoadingPlaceholder() {
  return (
    <Layout>
      <Card>
        <Box padding={16}>
          <Stack spacing={16}>
            <Inline spacing={8}>
              <Placeholder width={32} height={32} />
              <Placeholder width={200} height={32} />
            </Inline>
            <Stack spacing={8}>
              <Placeholder width="100%" height={20} />
              <Placeholder width="100%" height={20} />
              <Placeholder width="25%" height={20} />
            </Stack>
            <Stack spacing={8}>
              <Placeholder width={120} height={16} />
              <Placeholder width="100%" height={60} />
              <Placeholder width={140} height={40} />
            </Stack>
          </Stack>
        </Box>
      </Card>
    </Layout>
  );
}
