import React, { Fragment } from 'react';

import { v4 as uuidv4 } from 'uuid';

export const DEFAULT_EMPHASIS_KEY = uuidv4();

/**
 * Utility function for emphasizing translated strings.
 * Takes an already translated string and emphasizes (wraps) it with the
 * provided `Component` (`<strong />` by default).
 * @deprecated Create a new translation string and use the `t` function to inject components directly into the string.
 *
 * for example:
 *
 * old string: "hierarchy_form__searching": "Searching for relationships, {{emphasis_start}}please wait...{{emphasis_end}}"
 * new string: "hierarchy_form__searching_v2": "Searching for relationships, <bold>please wait...</bold>"
 *
 * t('hierarchy_form__searching_v2', { components: { bold: <strong /> });
 *
 * @param str Translated string.
 * @param emphasis The emphasis placeholder key used in the translation.
 * @default DEFAULT_EMPHASIS_KEY
 * @param Component Component used for wrapping the emphasized portion of the string.
 * @default strong
 * @returns An emphasized translation.
 */
// eslint-disable-next-line import/no-default-export
export default function emphasize(
  str: string,
  emphasis: string = DEFAULT_EMPHASIS_KEY,
  Component: React.ElementType = 'strong',
) {
  return (
    str
      .split(emphasis)
      // Every other string in the array is emphasized when split
      .map((s, i) => (
        <Fragment key={i}>{i % 2 ? <Component>{s}</Component> : s}</Fragment>
      ))
  );
}
