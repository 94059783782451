/**
 * FULL CONTEXT: https://workday-dev.slack.com/archives/C04HVDL40NA/p1721805402237919
 *
 * This is a temporary file used to store translations that have nested variables because it is not supported in Phrase
 * The intention is to replace all of these strings in our codebase with simpler versions and once that is done we can remove this file
 */

export const temporaryNamespace = 'temporary';

export const temporaryTranslations = {
  tr: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Genel puan, {total, plural, one {anketi alan {bold}#{bold} {link}çalışan{link}} other {anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {answered, plural, one {{emphasis}#{emphasis} çalışanın} other {{emphasis}#{emphasis} çalışanın}} verdiği yanıtları baz alır',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu soruyu cevapladı',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu değerle ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu sonuçla ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {Anketi alan {bold}#{bold} {link}çalışan{link}} other {Anketi alan {bold}#{bold} {link}çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu alt etkenle ilgili soruları cevapladı',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{link}Anketi alan {bold}#{bold} çalışan{link}} other {{link}Anketi alan {bold}#{bold} çalışan{link}}} arasından {emphasis}{answered}{emphasis} tanesi bu etkenle ilgili soruları cevapladı',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} tarihinden bu yana değişiklik yok} 1{{date} tarihinden bu yana {emphasis}+{difference}{emphasis} puan artış oldu} other{{date} tarihinden bu yana {emphasis}{difference}{emphasis} puan düşüş oldu}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, one{Bir çalışana} other{{count} çalışana}} hatırlatma gönderilsin mi?',
      },
    },
  },
  hu: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Az összesített pontszám {answered, plural, one {{emphasis}#{emphasis} munkavállaló} other {{emphasis}#{emphasis} munkavállaló}} válaszán alapul (összesen {total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt erre a kérdésre',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel az értékkel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel az eredménnyel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel a másodlagos hajtóerővel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}megkérdezett munkavállalóból{link}} other {{bold}#{bold} {link}megkérdezett munkavállalóból{link}}} {emphasis}{answered}{emphasis} válaszolt az ezzel a hajtóerővel kapcsolatos kérdésekre',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# megkérdezett munkavállaló{link}} other {{link}# megkérdezett munkavállaló{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nincs változás {date} óta} 1{{emphasis}+{difference}{emphasis} növekedés {date} óta} other{{emphasis}{difference}{emphasis} csökkenés {date} óta}}',
      },
    },
  },
  lt: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Bendras įvertis pagal atsakymus iš {answered, plural, one {{emphasis}#{emphasis} darbuotojo} few {{emphasis}#{emphasis} darbuotojų} many {{emphasis}#{emphasis} darbuotojų} other {{emphasis}#{emphasis} darbuotojų}} (iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}} atsakė į šį klausimą',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šią vertybę',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šį rezultatą',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojas, kuris gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojai, kurie gavo apklausą{link}}} atsakė į klausimus apie šį antrinį veiksnį',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} iš {total, plural, one {{bold}#{bold} {link}darbuotojo, kuris gavo apklausą{link}} few {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} many {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}} other {{bold}#{bold} {link}darbuotojų, kurie gavo apklausą{link}}} atsakė į klausimus apie šį veiksnį',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# darbuotojas, kuris gavo apklausą {link}} few {{link}# darbuotojai, kurie gavo apklausą{link}} many {{link}# darbuotojai, kurie gavo apklausą{link}} other {{link}# darbuotojai, kurie gavo apklausą{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nėra pakeitimų nuo {date}} 1{{emphasis}+{difference}{emphasis} patobulinimų nuo {date}} other{{emphasis}{difference}{emphasis} sumažėjimų nuo {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Išsiųsti priminimą {count, plural, one{vienam darbuotojui} other{{count} employees}}?',
      },
    },
  },
  nl: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'De totaalscore is gebaseerd op antwoorden van {answered, plural, one {{emphasis}#{emphasis} werknemer} other {{emphasis}#{emphasis} werknemers}} (van {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben deze vraag beantwoord',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze waarde',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze resultaten',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze subdrijfveer',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} van de {total, plural, one {{bold}#{bold} {link}werknemer die de enquête heeft ontvangen{link}} other {{bold}#{bold} {link}werknemers die de enquête hebben ontvangen{link}}} hebben vragen beantwoord over deze drijfveer',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# werknemer die de enquête heeft ontvangen{link}} other {{link}# werknemers die de enquête hebben ontvangen{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Geen wijziging sinds {date}} 1{{emphasis}+{difference}{emphasis} verbetering sinds {date}} other{{emphasis}{difference}{emphasis} afname sinds {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Herinnering versturen naar {count, plural, one{één werknemer} other{{count} werknemers}}?',
      },
    },
  },
  ja: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '総合スコアは、{answered, plural, other {{emphasis}#{emphasis}人の社員}}の回答に基づいています(アンケートを受け取ったのは{total, plural, other {{bold}#{bold} {link}人の社員{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの価値に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち{emphasis}{answered}{emphasis}人がこの成果に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち {emphasis}{answered}{emphasis} 人がこのサブ推進要因に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, other {{bold}#{bold} {link}人の社員がアンケートを受け取り{link}}}、そのうち {emphasis}{answered}{emphasis} 人がこの推進要因に関する質問に答えました',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, other {{link}#人の社員がアンケートを受け取りました{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date}以降変化なし} 1{{emphasis}+{difference}{emphasis} {date}以降改善} other{{emphasis}{difference}{emphasis} {date}以降減少}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, other{{count}人の社員}}にリマインダーを送信しますか?',
      },
    },
  },
  de: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Der Gesamt-Score ergibt sich aus den Antworten von {answered, plural, one {{emphasis}#{emphasis} Mitarbeiter} other {{emphasis}#{emphasis} Mitarbeitern}} (von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben diese Frage beantwortet',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Unternehmenswert beantwortet',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu dieser Auswirkung des Mitarbeiter-Engagements beantwortet',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Teilfaktor beantwortet',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} von {total, plural, one {{bold}#{bold} {link}Mitarbeiter, der die Befragung erhalten hat{link}} other {{bold}#{bold} {link}Mitarbeitern, die die Befragung erhalten haben{link}}}, hat/haben Fragen zu diesem Engagement-Faktor beantwortet',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# befragter Mitarbeiter{link}} other {{link}# befragte Mitarbeiter{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Keine Änderung seit {date}} 1{{emphasis}+{difference}{emphasis} Verbesserung seit {date}} other{{emphasis}{difference}{emphasis} Verringerung seit {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Erinnerung an {count, plural, one{einen Mitarbeiter} other{{count} Mitarbeiter}} senden?',
      },
    },
  },
  ru: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Средний показатель основан на ответах {answered, plural, one {{emphasis}#{emphasis} сотрудника} few {{emphasis}#{emphasis} сотрудников} many {{emphasis}#{emphasis} сотрудников} other {{emphasis}#{emphasis} сотрудника}} (из {total, plural, one {{bold}#{bold} {link}, получившего  опрос{link}} few {{bold}#{bold} {link}, получивших опрос{link}} many {{bold}#{bold} {link}, получивших опрос{link}} other {{bold}#{bold} {link}, получивших опрос{link}}})',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# сотрудник получил опрос{link}} other {{link}несколько сотрудников получили опрос: #{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Нет изменений с {date}} 1{{emphasis}+{difference}{emphasis} улучшение с {date}} other{{emphasis}{difference}{emphasis} ухудшение с {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Отправить напоминание {count, plural, one{одному сотруднику} other{{count} сотрудникам}}?',
      },
    },
  },
  pl: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Łączny wynik obliczany na podstawie odpowiedzi udzielonych przez {answered, plural, one {{emphasis}#{emphasis} pracownika} few{{emphasis}#{emphasis} pracowników} many{{emphasis}#{emphasis} pracowników} other{{emphasis}#{emphasis} pracownika}} (spośród {total, plural, one {{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na to pytanie: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tej wartości: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego wyniku: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego podczynnika: {emphasis}{answered}{emphasis} spośród {total, plural, one{{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'Liczba pracowników, którzy odpowiedzieli na pytania dotyczące tego czynnika: {emphasis}{answered}{emphasis} spośród {total, plural, one {{bold}#{bold} {link}pracownika, który otrzymał ankietę{link}} few{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} many{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}} other{{bold}#{bold} {link}pracowników, którzy otrzymali ankietę{link}}}',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one{{link}# pracownik, który otrzymał ankietę{link}} few{{link}# pracowników, którzy otrzymali ankietę{link}} many{{link}# pracowników, którzy otrzymali ankietę{link}} other{{link}# pracownika, który otrzymał ankietę{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Bez zmian od {date}} 1{{emphasis}+{difference}{emphasis} – poprawa od {date}} other{{emphasis}{difference}{emphasis} – pogorszenie od {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Wysłać przypomnienie do {count, plural, one{jednego pracownika} few{{count} pracowników} many{{count} pracowników} other{{count} pracownika}}?',
      },
    },
  },
  fi: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Kokonaispisteet perustuvat vastauksiin {answered, plural,one {{emphasis}#{emphasis} työntekijältä} other {{emphasis}#{emphasis} työntekijältä}} (/{total, plural,one {{bold}#{bold} {link}kyselyn saanut työntekijä{link}} other {{bold}#{bold} {link}kyselyn saanutta työntekijää{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän kysymykseen',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän arvoon liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän tulokseen liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän osatekijään liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis}/{total, plural, one {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}} other {{bold}#{bold} {link}kyselyn saaneesta työntekijästä{link}}} vastasi tähän tekijään liittyviin kysymyksiin',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural,one {{link}# työntekijä on saanut kyselyn{link}}other {{link}# työntekijää on saanut kyselyn{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ei muutosta alkaen {date}} 1{{emphasis}+{difference}{emphasis} parannusta alkaen {date}} other{{emphasis}{difference}{emphasis} vähennys alkaen {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Lähetä muistutus {count, plural, one{yhdelle työntekijälle} other{{count} työntekijälle}}?',
      },
    },
  },
  'zh-CN': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '整体评分基于 {answered, plural, other {{emphasis}#{emphasis} 名员工}}的回复而得出（共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}）',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了此问题',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此价值观的问题',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此结果的问题',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '共有 {total, plural, other {{bold}#{bold} {link}名员工收到调查{link}}}，其中 {emphasis}{answered}{emphasis} 名员工回答了有关此次级驱动因素的问题',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message: '共有 {total, plural, other {{link}# 名员工收到调查{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{从 {date}起无变更} 1{{emphasis}+{difference}{emphasis} 从 {date}起改进} other{{emphasis}{difference}{emphasis} 从 {date}起减少}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message: '是否向 {count, plural, other{{count} 名员工}}发送提醒？',
      },
    },
  },
  pt: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação global baseia-se nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a esta pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este subfator',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este fator',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sem alterações desde {date}} 1{{emphasis}+{difference}{emphasis} de melhoria desde {date}} other{{emphasis}{difference}{emphasis} de diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
    },
  },
  en: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
    },
  },
  'pt-PT': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação global baseia-se nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a esta pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este subfator',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} respondeu/responderam a perguntas sobre este fator',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sem alterações desde {date}} 1{{emphasis}+{difference}{emphasis} de melhoria desde {date}} other{{emphasis}{difference}{emphasis} de diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
    },
  },
  it: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Il punteggio complessivo si basa sulle risposte di {answered, plural, one {{emphasis}#{emphasis} dipendente} other {{emphasis}#{emphasis} dipendenti}} (su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} a questa domanda',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo valore',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo risultato',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo fattore secondario',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} su {total, plural, one {{bold}#{bold} {link}dipendente che ha ricevuto il sondaggio ha risposto{link}} other {{bold}#{bold} {link}dipendenti che hanno ricevuto il sondaggio hanno risposto{link}}} alle domande su questo fattore',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# dipendente che ha ricevuto il sondaggio{link}} other {{link}# dipendenti che hanno ricevuto il sondaggio{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nessun cambiamento dal {date}} 1{{emphasis}+{difference}{emphasis} di miglioramento dal {date}} other{{emphasis}{difference}{emphasis} di diminuzione dal {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Inviare un promemoria a {count, plural, one{# dipendente} other{{count} dipendenti}}?',
      },
    },
  },
  et: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Üldskoor põhineb {answered, plural, one {{emphasis}#{emphasis} töötaja} other {{emphasis}#{emphasis} töötaja}} vastustel (uuringulink saadeti {total, plural, one {{bold}#{bold}{link} töötajale{link}} other {{bold}#{bold}{link} töötajale{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on sellele küsimusele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle väärtuse küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle tulemuse küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle alamteguri küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{total, plural, one {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}} other {{bold}#{bold} {link}töötajast, kellele saadeti uuringulink{link}}}, on selle teguri küsimustele vastanud {emphasis}{answered}{emphasis}',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# töötaja, kes sai uuringulingi{link}} other {{link}# töötajat, kes said uuringulingi{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Muutusi pole alates {date}} 1{{emphasis}+{difference}{emphasis} parem alates {date}} other{{emphasis}{difference}{emphasis} kehvem alates {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Kas saata meeldetuletus {count, plural, one{ühele töötajale} other{{count} töötajale}}?',
      },
    },
  },
  'fr-CA': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Le score général est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} employé} other {{emphasis}#{emphasis} employés}} (sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage{link}} other{{bold}#{bold} {link}employés qui ont reçu le sondage{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} à cette question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} aux questions à propos de cette valeur',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce résultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce sous-moteur',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} sur {total, plural, one {{bold}#{bold} {link}employé qui a reçu le sondage a répondu{link}} other {{bold}#{bold} {link}employés qui ont reçu le sondage ont répondu{link}}} aux questions sur ce moteur',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employé qui a reçu le sondage{link}} other {{link}# employés qui ont reçu le sondage{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Pas de changement depuis le {date}} 1{{emphasis}+{difference}{emphasis} d’augmentation depuis le {date}} other{{emphasis}{difference}{emphasis} de diminution depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un employé} other{{count} employés}}?',
      },
    },
  },
  fr: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          "Le score global est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} collaborateur} other {{emphasis}#{emphasis} collaborateurs}} (sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}})",
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu à cette question.",
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur cette valeur.",
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce résultat.",
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce sous-moteur.",
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce moteur.",
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          "{total, plural, one {{link}# collaborateur ayant reçu l'enquête{link}} other {{link}# collaborateurs ayant reçu l'enquête{link}}}",
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Aucun changement depuis le {date}}1{Augmentation de {emphasis}{difference}{emphasis} depuis le {date}}other{Baisse de {emphasis}{difference}{emphasis} depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un collaborateur} other{{count} collaborateurs}} ?',
      },
    },
  },
  ro: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Punctajul global este bazat pe răspunsurile de la {answered, plural, one {{emphasis}#{emphasis} angajat} few {{emphasis}#{emphasis} angajați} other {{emphasis}#{emphasis} de angajați}} (din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la această întrebare',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre această valoare',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest rezultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest factor secundar',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} din {total, plural, one {{bold}#{bold} {link}angajat care a primit sondajul{link}} few {{bold}#{bold} {link}angajați care au primit sondajul{link}} other {{bold}#{bold} {link}de angajați care au primit sondajul{link}}} a(u) răspuns la întrebări despre acest factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# angajat care a primit sondajul {link}} few {{link}# angajați care au primit sondajul{link}} other {{link}# de angajați care au primit sondajul{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nicio schimbare de la {date}} 1{Îmbunătățire de {emphasis}{difference}{emphasis} de la {date}} other{Scădere de {emphasis}{difference}{emphasis} de la {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vrei să trimiți un memento către {count, plural, one{un angajat} few {{count} angajați} other{{count} de angajați}}?',
      },
    },
  },
  hi: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'कुल स्कोर सर्वेक्षण पाने वाले {total, plural,one {{bold}#{bold}{link} कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {answered, plural,one {{emphasis}#{emphasis} कर्मचारी} other {{emphasis}#{emphasis} कर्मचारियों}} के जवाबों पर आधारित है',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस प्रश्न का उत्तर दिया',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस मूल्य के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस उप-ड्राइवर के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold}{link} कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से{emphasis}{answered}{emphasis} ने इस उप-ड्राइवर के सवालों के जवाब दिए',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'सर्वेक्षण पाने वाले कुल {total, plural,one {{bold}#{bold} {link}कर्मचारी{link}} other {{bold}#{bold}{link} कर्मचारियों{link}}} में से {emphasis}{answered}{emphasis} ने इस ड्राइवर के प्रश्नों के उत्तर दिए',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link} सर्वेक्षण प्राप्त करने वाला # कर्मचारी{link}} other {{link}सर्वेक्षण प्राप्त करने वाले # कर्मचारी{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} के बाद से कोई बदलाव नहीं} 1{{emphasis}+{difference}{emphasis} {date} के बाद से सुधार} other{{emphasis}{difference}{emphasis} {date} के बाद से ह्रास}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, one{कर्मचारी} other{{count} कर्मचारियों}} को रीमाइंडर भेजें?',
      },
    },
  },
  'pt-BR': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'A pontuação geral é baseada nas respostas de {answered, plural, one {{emphasis}#{emphasis} colaborador} other {{emphasis}#{emphasis} colaboradores}} (de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} a essa pergunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse submotivador',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}colaborador que recebeu a pesquisa respondeu{link}} other {{bold}#{bold} {link}colaboradores que receberam a pesquisa responderam{link}}} às perguntas sobre esse motivador',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# colaborador recebeu a pesquisa{link}} other {{link}# colaboradores receberam a pesquisa{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nenhuma alteração desde {date}} 1{{emphasis}+{difference}{emphasis} melhoria desde {date}} other{{emphasis}{difference}{emphasis} diminuição desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Enviar lembrete para {count, plural, one{um colaborador} other{{count} colaboradores}}?',
      },
    },
  },
  ko: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '총점은 ({total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중) {answered, plural, other {직원 {emphasis}#{emphasis}명}}의 답변을 근거로 계산합니다.',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '이 가치체계에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '이 결과에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '이 하위 Driver에서 {total, plural, other {{bold}#{bold}명의 {link}설문 수신 직원{link}}} 중 {emphasis}{answered}{emphasis}명이 질문에 답변함',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message: '{total, plural, other {{link}#명의 설문 수신 직원{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{{date} 이후 변화 없음} 1{{date} 이후 {emphasis}+{difference}{emphasis} 개선} other{{date} 이후 {emphasis}{difference}{emphasis} 감소}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '{count, plural, other{{count}명의 직원}}에게 알림을 전송하시겠습니까?',
      },
    },
  },
  'fr-FR': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          "Le score global est basé sur les réponses de {answered, plural, one {{emphasis}#{emphasis} collaborateur} other {{emphasis}#{emphasis} collaborateurs}} (sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}})",
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu à cette question.",
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur cette valeur.",
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce résultat.",
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce sous-moteur.",
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          "Sur {total, plural, one {{bold}#{bold} {link}collaborateur ayant reçu l'enquête{link}} other {{bold}#{bold} {link}collaborateurs ayant reçu l'enquête{link}}}, {emphasis}{answered}{emphasis} ont répondu aux questions sur ce moteur.",
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          "{total, plural, one {{link}# collaborateur ayant reçu l'enquête{link}} other {{link}# collaborateurs ayant reçu l'enquête{link}}}",
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Aucun changement depuis le {date}}1{Augmentation de {emphasis}{difference}{emphasis} depuis le {date}}other{Baisse de {emphasis}{difference}{emphasis} depuis le {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Envoyer un rappel à {count, plural, one{un collaborateur} other{{count} collaborateurs}} ?',
      },
    },
  },
  'zh-TW': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          '整體分數取決於 {answered, plural, one {{emphasis}#{emphasis} 名員工} other {{emphasis}#{emphasis} 名員工}}的回應 (共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此問題',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此價值觀的相關問題',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此結果的相關問題',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此次驅動因素的相關問題',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '共 {total, plural, one {{bold}#{bold} {link}名員工收到問卷調查{link}} other {{bold}#{bold} {link}名員工收到問卷調查{link}}}，其中 {emphasis}{answered}{emphasis} 名員工已回答此驅動因素的相關問題',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}收到問券調查的 # 名員工{link}} other {{link}收到問卷調查的 # 名員工{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{自 {date} 起沒有變化} 1{自 {date} 起提高 {emphasis}+{difference}{emphasis}} other{自 {date} 起降低 {emphasis}{difference}{emphasis}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message: '要傳送提醒給 {count, plural, other{{count} 名員工}} 嗎？',
      },
    },
  },
  'es-LA': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'La puntuación general se basa en las respuestas de {answered, plural, one {{emphasis}#{emphasis} empleado} other {{emphasis}#{emphasis} empleados}} (de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} esta pregunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este resultado',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este subfactor',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta respondió{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta respondieron{link}}} preguntas sobre este factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# empleado que recibió la encuesta{link}} other {{link}# empleados que recibieron la encuesta{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sin cambios desde el {date}} 1{Mejoró {emphasis}{difference}{emphasis} desde el {date}} other{Disminuyó {emphasis}{difference}{emphasis} desde el {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '¿Quiere enviar un recordatorio a {count, plural, one{un empleado} other{{count} empleados}}?',
      },
    },
  },
  cs: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Celkové skóre je založeno na odpovědích od {answered, plural, one{{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (z celkem {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'Odpovědi na tuto otázku: {emphasis}{answered}{emphasis} z {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'Zodpovězené otázky ohledně této hodnoty: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'Zodpovězené otázky ohledně tohoto výsledku: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'Zodpovězené otázky ohledně tohoto dílčího faktoru: {emphasis}{answered}{emphasis} z celkem {total, plural, one {{bold}#{bold} {link}dotazovaného zaměstnance{link}} few {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} many {{bold}#{bold} {link}dotazovaných zaměstnanců{link}} other {{bold}#{bold} {link}dotazovaných zaměstnanců{link}}}',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'Zodpovězené otázky týkající se tohoto faktoru: {emphasis}{answered}{emphasis} z {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Žádná změna od {date}} 1{{emphasis}+{difference}{emphasis} zlepšení od {date}} other{{emphasis}{difference}{emphasis} zhoršení od {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Odeslat připomenutí {count, plural, one{jednomu zaměstnanci} other{{count} employees}}?',
      },
    },
  },
  lv: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Kopvērtējuma pamatā ir atbildes no {answered, plural, one {{emphasis}#{emphasis} darbinieka} other {{emphasis}#{emphasis} darbiniekiem}} (no {total, plural, one {{bold}#{bold} {link}darbinieka, kurš saņēma aptauju{link}} other {{bold}#{bold} {link}darbiniekiem, kurš saņēma aptauju{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz šo jautājumu',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo vērtību',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo iznākumu',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par šo blakustimulu',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} no {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} atbildēja uz jautājumiem par stimulu',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# darbinieks, kurš saņēma aptauju{link}} other {{link}# darbinieki, kuri saņēma aptauju{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Nav izmaiņu kopš {date}} 1{{emphasis}+{difference}{emphasis} uzlabojuma kopš {date}} other{{emphasis}{difference}{emphasis} samazinājuma kopš {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vai sūtīt atgādinājumu {count, plural, one{vienam darbiniekam} other{{count} darbiniekiem}}?',
      },
    },
  },
  no: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den samlede verdien er basert på svarene fra {answered, plural,one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (av {total, plural,one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på dette spørsmålet.',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne verdien.',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om dette utfallet.',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne underfaktoren.',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}, svarte på spørsmål om denne faktoren.',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen endring siden {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Vil du sende påminnelse til {count, plural, one{én ansatt} other{{count} employees}}?',
      },
    },
  },
  da: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den samlede score er baseret på svar fra {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede dette spørgsmål',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne værdi',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om dette resultat',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne underliggende drivkraft',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} ud af {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} besvarede spørgsmål om denne drivkraft',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen ændring side {date}} 1{{emphasis}+{difference}{emphasis} forbedring siden {date}} other{{emphasis}{difference}{emphasis} falder siden {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send påmindelse til {count, plural, one{en medarbejder} other{{count} employees}}?',
      },
    },
  },
  'en-US': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
    },
  },
  th: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'คะแนนรวมได้มาจากคำตอบของพนักงาน {answered, plural, other {{emphasis}#{emphasis} คน}} (จากทั้งหมด {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามนี้',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับค่านิยมนี้',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับผลลัพธ์นี้',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, other {{bold}#{bold} {link}คนที่ได้รับแบบสำรวจ{link}}}ตอบคำถามเกี่ยวกับปัจจัยขับเคลื่อนย่อยนี้',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'พนักงาน {emphasis}{answered}{emphasis} คนจาก {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}}ตอบคำถามเกี่ยวกับปัจจัยผลักดันนี้',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, other {{link}พนักงาน # คนที่ได้รับแบบสำรวจ{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{ไม่มีการเปลี่ยนแปลงตั้งแต่ {date}} 1{{emphasis}+{difference}{emphasis} มีการปรับปรุงตั้งแต่ {date}} other{{emphasis}{difference}{emphasis} ลดลงตั้งแต่ {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'ส่งการเตือนไปยังพนักงาน {count, plural, other{{count} คน}}ใช่หรือไม่',
      },
    },
  },
  'en-GB': {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'The overall score is based on responses from {answered, plural, one {{emphasis}#{emphasis} employee} other {{emphasis}#{emphasis} employees}} (out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered this question',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this value',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this outcome',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this sub-driver',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} out of {total, plural, one {{bold}#{bold} {link}employee who received the survey{link}} other {{bold}#{bold} {link}employees who received the survey{link}}} answered questions on this driver',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# employee who received the survey{link}} other {{link}# employees who received the survey{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{No change since {date}} 1{{emphasis}+{difference}{emphasis} improvement since {date}} other{{emphasis}{difference}{emphasis} decrease since {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Send reminder to {count, plural, one{one employee} other{{count} employees}}?',
      },
    },
  },
  sv: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'Den totala poängen baserar sig på svar från {answered, plural, one {{emphasis}#{emphasis} medarbetare} other {{emphasis}#{emphasis} medarbetare}} (av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade den här frågan',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om den här värderingen',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om det här resultatet',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} svarade på frågor om den här del-drivkraften',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} av {total, plural, one {{bold}#{bold} {link}medarbetare som fick undersökningen{link}} other {{bold}#{bold} {link}medarbetare som fick undersökningen{link}}} besvarade frågor om den här drivkraften',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# medarbetare som fick undersökningen{link}} other {{link}# medarbetare som fick undersökningen{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Ingen förändring sedan {date}} 1{{emphasis}+{difference}{emphasis} förbättring sedan {date}} other{{emphasis}{difference}{emphasis} minskning sedan {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'Skicka påminnelse till {count, plural, one{en medarbetare} other{{count} medarbetare}}?',
      },
    },
  },
  es: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'La puntuación general se basa en las respuestas de {answered, plural, one {{emphasis}#{emphasis} empleado} other {{emphasis}#{emphasis} empleados}} (de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a esta pregunta',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este valor',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre estos resultados',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este subfactor',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          '{emphasis}{answered}{emphasis} de {total, plural, one {{bold}#{bold} {link}empleado que recibió la encuesta ha{link}} other {{bold}#{bold} {link}empleados que recibieron la encuesta han{link}}} respondido a las preguntas sobre este factor',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}# empleado que recibió la encuesta{link}} other {{link}# empleados que recibieron la encuesta{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{Sin cambios desde {date}} 1{{emphasis}+{difference}{emphasis} mejora desde {date}} other{{emphasis}{difference}{emphasis} descenso desde {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          '¿Quieres enviar un recordatorio a {count, plural, one{un empleado} other{{count} empleados}}?',
      },
    },
  },
  ar: {
    [temporaryNamespace]: {
      dashboard__overview_card__participation_surveyed__group: {
        message:
          'تعتمد الدرجة الإجمالية على استجابات {answered, plural, zero {} one {{emphasis}#{emphasis} موظف} two {{emphasis}#{emphasis} من الموظفين} few {{emphasis}#{emphasis} من الموظفين} many {{emphasis}#{emphasis} من الموظفين} other {{emphasis}#{emphasis} من الموظفين}} (من إجمالي {total, plural, one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}من الموظفين الذين تلقوا الاستطلاع{link}}})',
      },
      dashboard__overview_card__participation_surveyed__question: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن هذا السؤال',
      },
      dashboard__overview_card__participation_surveyed__value: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذه القيمة',
      },
      dashboard__overview_card__participation_surveyed__outcome: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذه النتيجة',
      },
      dashboard__overview_card__participation_surveyed__subdriver: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذا الدافع الفرعي',
      },
      dashboard__overview_card__participation_surveyed__driver: {
        message:
          'أَجاب {emphasis}{answered}{emphasis} من إجمالي {total, plural, zero {} one {{bold}#{bold} {link}موظف تلقى الاستطلاع{link}} two {{bold}#{bold} {link}موظفيْن ممن تلقوا الاستطلاع{link}} few {{bold}#{bold} {link}موظفين ممن تلقوا الاستطلاع{link}} many {{bold}#{bold} {link}موظفًا ممن تلقوا الاستطلاع{link}} other {{bold}#{bold} {link}موظف ممن تلقوا الاستطلاع{link}}} عن أسئلة هذا الدافع',
      },
      dashboard__overview_card__participation_surveyed__total: {
        message:
          '{total, plural, one {{link}موظف واحد تلقى الاستطلاع{link}} other {{link}# من الموظفين تلقوا الاستطلاع{link}}}',
      },
      dashboard__overview_card__changes: {
        message:
          '{sign, select, 0{لا تغيير منذ {date}} 1{{emphasis}+{difference}{emphasis} تحسُّ منذ {date}} other{{emphasis}{difference}{emphasis} انخفاض منذ {date}}}',
      },
      'sendouts__overview__current-round__send-reminder__confirm': {
        message:
          'هل تريد إرسال تذكير إلى {count, plural, one{موظف واحد} other{{count} من الموظفين}}؟',
      },
    },
  },
};
