import z from 'zod';

import { AccountResponse } from '@peakon/shared/schemas/api/accounts';
import { EmployeeResponse } from '@peakon/shared/schemas/api/employees';
import {
  sessionDataSchema,
  sharedDashboardSessionSchema,
} from '@peakon/shared/schemas/api/sessions';
import { CompanyResponse } from '@peakon/shared/types/Company';

type DashboardSessionData = z.infer<typeof sessionDataSchema>;

export type DashboardSession = {
  data: DashboardSessionData;
  included?: (AccountResponse | CompanyResponse | EmployeeResponse)[];
};

type SharedDashboardSessionData = z.infer<
  typeof sharedDashboardSessionSchema.shape.data
>;

export type SharedDashboardSession = z.infer<
  typeof sharedDashboardSessionSchema
>;

export type SessionData = DashboardSessionData | SharedDashboardSessionData;

export type Session = DashboardSession | SharedDashboardSession;

export type NullableSession = Session | null | undefined;

export type NullableDashboardSession = DashboardSession | null | undefined;

export type NullableSharedDashboardSession =
  | SharedDashboardSession
  | null
  | undefined;

export const isSharedDashboardSession = (
  session: DashboardSession | SharedDashboardSession,
  // eslint-disable-next-line no-restricted-syntax
): session is SharedDashboardSession => {
  return 'type' in session && session.type === 'bearer_tokens';
};
