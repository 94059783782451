import { combineReducers } from '@reduxjs/toolkit';
import { OrderedSet } from 'immutable';
import sortBy from 'lodash/sortBy';

import { fromJsonApi } from '../../utils/parsers/drivers';

export const scores = (state = OrderedSet(), action: $TSFixMe) => {
  switch (action.type) {
    case `GROUP_CATEGORIES_SCORES_RESET`: {
      return OrderedSet();
    }
    case `GROUP_CATEGORIES_SCORES_SUCCESS`: {
      const { group, data: drivers } = action.data;

      return OrderedSet(
        sortBy(
          // @ts-expect-error TS(7006): Parameter 'driver' implicitly has an 'any' type.
          drivers.map((driver) => ({
            ...fromJsonApi(driver, { strict: false }),
            group,
          })),
          (d) => d.rank,
        ),
      );
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  scores,
});
