export const CORE_QUESTION_SETS = [
  'engagement',
  'diversity_inclusion',
  'health_wellbeing',
  'transformation_change',
] as const;

export const MOMENTS_THAT_MATTER_QUESTION_SETS = [
  'onboarding',
  'exit',
] as const;

export const ALL_QUESTION_SETS = [
  ...CORE_QUESTION_SETS,
  'other',
  'covid19',
  ...MOMENTS_THAT_MATTER_QUESTION_SETS,
] as const;

export const QUESTION_SETS_TEXT_VALUES = [
  ...ALL_QUESTION_SETS,
  'values',
  'open_ended',
] as const;

export const EXPORT_CONSTANTS = Object.fromEntries(
  ALL_QUESTION_SETS.map((set) => [set, 'driver']).concat([
    ['values', 'value'],
    ['text', 'text'],
  ]),
);

export const STANDARD_QUESTION_SETS = [
  ...ALL_QUESTION_SETS.filter((set) => set !== 'other'),
  'text',
];

export type CategoryGroup = (typeof ALL_QUESTION_SETS)[number];
export type CategoryGroupWithTextAndValues =
  (typeof QUESTION_SETS_TEXT_VALUES)[number];
export type CoreCategoryGroup = (typeof CORE_QUESTION_SETS)[number];
export type ExportableCategoryGroup = Extract<
  CategoryGroup,
  'engagement' | 'diversity_inclusion' | 'health_wellbeing'
>;

export type StandardCategoryGroup = Exclude<CategoryGroup, 'other'> | 'text';

export const onboardingRulesDefaultValues = {
  orientation: {
    ruleValue: 2,
  },
  alignment: {
    ruleValue: 4,
  },
  integrating: {
    ruleValue: 8,
  },
  contributing: {
    ruleValue: 12,
  },
  thriving: {
    ruleValue: 20,
  },
};

export const exitRulesDefaultValues = {
  culture_values: {
    ruleValue: 2,
  },
  development: {
    ruleValue: 2,
  },
  experience: {
    ruleValue: 2,
  },
  leadership: {
    ruleValue: 2,
  },
  recognition_reward: {
    ruleValue: 2,
  },
  role: {
    ruleValue: 2,
  },
  wellbeing: {
    ruleValue: 2,
  },
};

export const QUESTION_STATUS = ['all', 'active', 'inactive'] as const;
export type QuestionStatus = (typeof QUESTION_STATUS)[number];
