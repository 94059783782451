import { Record, List, Map, StaticMap } from 'immutable';
import { z } from 'zod';

import { BenchmarkTypeEnum } from '@peakon/shared/types/Benchmark';
import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { COMPANY_SIZES } from './constants/companies';
import { Override } from './types/Override';
import { validateTestingSchema } from './utils';

const dataSchema = z.object({
  id: z.string(),
  attributes: z.any(),
  links: z.any(),
  type: z.literal('companies'),
  relationships: z
    .object({
      companySubdomains: z
        .array(
          z.object({
            type: z.literal('company_subdomains'),
            id: z.string(),
            attributes: z.object({
              subdomain: z.string(),
              primary: z.boolean(),
            }),
          }),
        )
        .optional(),
    })
    .optional(),
});

const schema = z.object({
  id: z.string().optional(),
});

/*
 * Note: some of the properties here shouldn't be optional
 * But this is needed because we use the constructor without props new Company()
 */
const settingsSchema = z.object({
  anonymityLevel: z.number().optional(),
  commentDateVisibility: z.enum(['date', 'round']).optional(),
  commentLevel: z.number().optional(),
  commentMachineTranslations: z.enum(['off', 'on', 'one-by-one']).optional(),
  commentRoundLevel: z.number().optional(),
  conversationEmail: z.enum(['full', 'limited']).optional(),
  customSenderDomainDkimMode: z.enum(['byodkim', 'easydkim']).optional(),
  dataRetentionPeriod: z.number().optional(),
  differenceLevel: z.number().optional(),
  driverMode: z.enum(['aggregated', 'mixed', 'split']).optional(),
  driversOrder: z.enum(['difference', 'priority', 'score']).optional(),
  engagementScore: z.enum(['average', 'enps']).optional(),
  leaverAutoDeletionPeriod: z.number().optional(),
  semanticSearchTerms: z.enum(['accepted', 'not-accepted']).optional(),
  sensitiveComments: z.boolean().optional(),
  significanceLevel: z.number().optional(),
  smimeEnabled: z.boolean().optional(),
  validityDriver: z.number().optional(),
  validityEnded: z.number().optional(),
  validityOverall: z.number().optional(),
});

const testingSchema = schema.extend({
  abbreviation: z.string().optional(),
  accessAllowed: z.boolean(),
  addOns: z.array(z.string()),
  allowAllDomains: z.boolean().optional(),
  benchmarkId: z.string().nullable().optional(),
  benchmarkSelector: z
    .object({
      percentile: z.number().optional(),
      sector: z.string().optional(),
    })
    .nullable()
    .optional(),
  benchmarkType: BenchmarkTypeEnum.nullable().optional(),
  companySize: z.string().optional(),
  createdAt: z.date().optional(),
  domains: z.array(z.string()),
  employeeCount: z.number().optional(),
  employeeFeatures: z.array(z.string()),
  featureFlips: z.array(z.string()).optional(),
  features: z.array(z.string()),
  location: z.string().nullable().optional(),
  logo: z.string().nullable().optional(),
  meta: z
    .object({
      id: z.string(),
      links: z.object({
        self: z.string(),
      }),
      type: z.literal('companies'),
    })
    .optional(),
  name: z.string().optional(),
  primarySubdomain: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
  sector: z.string().nullable().optional(),
  settings: settingsSchema.optional(),
  testCompany: z.boolean().optional(),
  timezone: z.string().nullable().optional(),
});
type Schema = Override<
  // TODO (Dana): This will be replaced with the actual schema once we are sure of the types
  z.infer<typeof testingSchema>,
  {
    addOns: List<string>;
    domains: List<string>;
    employeeFeatures: List<string>;
    features: List<string>;
    settings: StaticMap<z.infer<typeof settingsSchema>>;
  }
>;

const defaultValues = {
  accessAllowed: false,
  addOns: List<string>(),
  employeeFeatures: List<string>(),
  features: List<string>(),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  settings: Map() as unknown as StaticMap<z.infer<typeof settingsSchema>>,
  domains: List<string>(),
};

// eslint-disable-next-line import/no-default-export
export default class Company extends Record<Schema>({
  id: undefined,
  abbreviation: undefined,
  allowAllDomains: undefined,
  benchmarkSelector: undefined,
  benchmarkType: undefined,
  companySize: undefined,
  createdAt: undefined,
  employeeCount: undefined,
  location: undefined,
  logo: undefined,
  meta: undefined,
  name: undefined,
  sector: undefined,
  timezone: undefined,
  testCompany: undefined,
  primarySubdomain: undefined,
  ...defaultValues,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CompanyRecord',
    });

    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CompanyRecord',
      log: [
        { environment: 'local', logLevel: 'error' },
        { environment: 'staging', logLevel: 'info' },
        { environment: 'production', logLevel: 'info' },
      ],
      defaultValues,
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasAddOn(addOn: string): boolean {
    return this.addOns.includes(addOn);
  }

  hasFeature(feature: string): boolean {
    return this.features.includes(feature);
  }

  get scoreMode() {
    const engagementScore = this.settings.get('engagementScore');

    return engagementScore === 'enps' ? 'nps' : 'mean';
  }

  get driversOrder() {
    return this.settings.get('driversOrder');
  }

  get commentDateVisibility() {
    return this.settings.get('commentDateVisibility');
  }

  get anonymityLevel() {
    return this.settings.get('anonymityLevel');
  }

  get driverMode() {
    return this.settings.get('driverMode');
  }

  static createFromApi(data: unknown) {
    const parsedData = validateData(data, dataSchema, {
      errorMessagePrefix: 'CompanyRecord dataSchema',
    });
    const { id, attributes, links, type, relationships } = parsedData;

    const primarySubdomain = relationships?.companySubdomains?.find(
      (subdomain) => subdomain.attributes.primary,
    );

    const {
      size: companySize,
      employeeFeatures,
      addOns,
      domains,
      features,
      createdAt,
      name,
      settings,
      ...other
    } = attributes;

    return new Company({
      id,
      companySize: companySize || COMPANY_SIZES[0],
      createdAt: createdAt ? new Date(createdAt) : undefined,
      features: List(features),
      addOns: List(addOns),
      domains: List(domains),
      name: name === 'My company' ? undefined : name,
      employeeFeatures: List(employeeFeatures),
      settings: Map(settings),
      meta: {
        id,
        type,
        links,
      },
      primarySubdomain: primarySubdomain
        ? {
            name: primarySubdomain.attributes.subdomain,
            id: primarySubdomain.id,
          }
        : undefined,
      ...other,
    });
  }

  toJsonApi() {
    const {
      meta,
      id: _id,
      companySize,
      settings: _settings,
      employeeCount: _employeeCount,
      ...attributes
      // @ts-expect-error Property 'toJSON' does not exist on type 'Company'. Did you mean 'toJS'?ts(2551)
    } = this.toJSON();

    return {
      ...meta,
      attributes: {
        ...attributes,
        size: companySize,
      },
    };
  }
}
