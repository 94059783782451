import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import jsonapiparser from '@peakon/jsonapiparser';

import { FeatureFlip } from './useFeatureFlips';
import api from '../../../utils/api';

export type FeatureFlipsUpdated = {
  [key: string]: { current: boolean | null };
};

const updateFeatureFlips = async (updatedFeatureFlips: FeatureFlip[]) => {
  const updated: FeatureFlipsUpdated = {};

  updatedFeatureFlips.forEach((item) => {
    updated[item.id] = { current: item.userValue };
  });

  const response = await api.post(
    `/test_companies/self_service/feature_flips`,
    null,
    updated,
  );
  return jsonapiparser(response);
};

export const useUpdateFeatureFlips = ({
  onError,
}: UseMutationOptions<unknown, Error, FeatureFlip[], unknown>) => {
  return useMutation({ mutationFn: updateFeatureFlips, onError });
};
