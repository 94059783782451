import { OrderedMap } from 'immutable';
import get from 'lodash/get';

import { CategoryGroup } from '@peakon/records';

const OPEN_ENDED_ID = 'open_ended';

const categoryGroups = (state = OrderedMap(), action: $TSFixMe) => {
  switch (action.type) {
    case 'CONTEXT_LIST_SUCCESS': {
      const contextCategoryGroups = get(
        action.data,
        'data[0].relationships.contextCategoryGroups',
        [],
      );

      return OrderedMap(
        contextCategoryGroups
          // @ts-expect-error TS(7006): Parameter 'contextGroup' implicitly has an 'any' t... Remove this comment to see the full error message
          .map((contextGroup) =>
            CategoryGroup.createFromApi(
              contextGroup.relationships.categoryGroup,
              {
                access: contextGroup.attributes.access,
                hasOutcome: contextGroup.attributes.hasOutcome,
                hasOtherDrivers: contextGroup.attributes.hasOtherDrivers,
                resource: contextGroup.attributes.resource,
                categories: contextGroup.relationships.categories,
              },
            ),
          )
          // @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
          .map((group) => [group.id, group]),
      );
    }

    case 'CONTEXT_CHANGED': {
      return OrderedMap(
        // @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
        action.data.categoryGroups.map((group) => {
          if (group.id === 'text') {
            return [
              OPEN_ENDED_ID,
              group.merge({ id: OPEN_ENDED_ID, standard: OPEN_ENDED_ID }),
            ];
          }

          return [group.id, group];
        }),
      );
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default categoryGroups;
