import { Record } from 'immutable';
import trim from 'lodash/trim';
import { z } from 'zod';

import diff from '@peakon/shared/utils/diff';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from './AttributeRecord';
import { validateTestingSchema } from './utils';

const EDITABLE_FIELDS = [
  'aliases',
  'comparisonAccess',
  'description',
  'descriptionTranslations',
  'employeeAccess',
  'name',
  'nameTranslations',
  'open',
  'primary',
  'rejectExternal',
  'standard',
  'status',
];

const segmentsToApi = (segments: $TSFixMe) => {
  if (!segments || segments.isEmpty()) {
    return null;
  }

  return segments.map(
    (
      // @ts-expect-error no implicit any
      segment,
    ) => ({
      type: 'segments',
      id: segment.id,
    }),
  );
};

const schema = z.object({});
const testingSchema = schema.extend({
  original: z.object({}),
});

type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class AttributeEditor
  extends Record({
    original: new Attribute(),
    current: new Attribute(),
  })
  implements Schema
{
  current: $TSFixMe;
  original: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'AttributeEditor',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'AttributeEditor',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasChangedName() {
    return this.original.name !== this.current.name;
  }

  hasChanges() {
    return Boolean(this.hasChangedName() || this.diff().length);
  }

  isNewAttribute() {
    return this.original.name === undefined;
  }

  diff() {
    return diff(this.current.toJS(), this.original.toJS());
  }

  invalidLinks() {
    return (
      !this.current.links.first() ||
      !this.current.links.last() ||
      this.current.links.first()?.id === this.current.links.last()?.id
    );
  }

  isInvalid({ skipRangeCheck = false } = {}) {
    return (
      !this.current.name ||
      !this.current.type ||
      (this.current.type === 'link' && this.invalidLinks()) ||
      (!skipRangeCheck &&
        this.current.ranges &&
        (this.current.ranges.some(
          (
            // @ts-expect-error no implicit any
            range,
          ) => range.invalid,
        ) ||
          (this.current.ranges.size === 1 &&
            (this.current.ranges.last().to === null ||
              this.current.ranges.last().to === undefined)))) ||
      (this.current.nameTranslations &&
        this.current.nameTranslations.some(
          (item: $TSFixMe) => !item.translation,
        )) ||
      (this.current.nameTranslations &&
        this.current.descriptionTranslations.some(
          (item: $TSFixMe) => !item.translation,
        ))
    );
  }

  hasRanges() {
    return this.current.type === 'date' || this.current.type === 'number';
  }

  isSeparation() {
    return (
      this.current.standard === 'separation_date' ||
      this.current.standard === 'separation_reason'
    );
  }

  toJsonApi(type = 'PATCH') {
    const editorDiff = this.diff();

    const data = {
      type: 'attributes',
      attributes: {},
      relationships: {},
    };

    editorDiff.forEach((change) => {
      const field = change[0];
      if (type === 'POST' || EDITABLE_FIELDS.includes(field)) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data.attributes[field] = this.current.get(field);

        if (field === 'aliases' || field === 'links') {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          data.attributes[field] = data.attributes[field].toJS();
        } else if (
          field === 'nameTranslations' ||
          field === 'descriptionTranslations'
        ) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          data.attributes[field] = data.attributes[field].reduce(
            (acc: $TSFixMe, cur: $TSFixMe) => {
              // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
              acc[cur.locale] = cur.translation;
              return acc;
            },
            {},
          );
        } else if (
          field === 'description' &&
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          trim(data.attributes[field]) === ''
        ) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          data.attributes[field] = null;
        }
      }
    });

    if (this.current.includedSegments) {
      // @ts-expect-error TS(2339): Property 'includedSegments' does not exist on type... Remove this comment to see the full error message
      data.relationships.includedSegments = {
        data: segmentsToApi(this.current.includedSegments),
      };
    }

    if (this.current.excludedSegments) {
      // @ts-expect-error TS(2339): Property 'excludedSegments' does not exist on type... Remove this comment to see the full error message
      data.relationships.excludedSegments = {
        data: segmentsToApi(this.current.excludedSegments),
      };
    }

    if (
      this.hasRanges() &&
      editorDiff.some((change) => change[0] === 'ranges')
    ) {
      const ranges = this.rangesToJsonApi();

      if (ranges) {
        // @ts-expect-error TS(2339): Property 'ranges' does not exist on type '{}'.
        data.relationships.ranges = {
          data: ranges,
        };
      }
    }

    if (type === 'POST' && editorDiff.some((change) => change[0] === 'links')) {
      const links = this.current.links.toJS();

      // @ts-expect-error TS(2339): Property 'links' does not exist on type '{}'.
      data.relationships.links = {
        data: [
          {
            type: 'attributes',
            id: links.child.id,
          },
          {
            type: 'attributes',
            id: links.parent.id,
          },
        ],
      };
    }

    return data;
  }

  rangesToJsonApi() {
    let ranges = this.current.ranges.toJS().map(
      (
        // @ts-expect-error no implicit any
        range,
      ) => ({
        type: 'attribute_ranges',
        attributes: {
          from: range.from,
          to: range.to,
        },
      }),
    );

    // find range IDs to avoid re-creating relationships on the API
    if (!this.original.ranges.isEmpty()) {
      ranges.forEach(
        (
          // @ts-expect-error no implicit any
          range,
        ) => {
          const storedRange = this.original.ranges.find(
            (r: $TSFixMe) =>
              (r.from === null && range.attributes.from === null) ||
              parseFloat(r.from) === range.attributes.from,
          );

          if (storedRange) {
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            range.id = storedRange.id;
          }
        },
      );
    } else {
      const lastIndexTo = ranges[ranges.length - 1].attributes.to;
      if (lastIndexTo !== null && !isNaN(lastIndexTo)) {
        ranges.push({
          type: 'attribute_ranges',
          attributes: {
            from: lastIndexTo,
            to: null,
          },
        });
      }
    }

    if (this.original.standard === 'separation_date') {
      ranges = ranges.reverse().map(
        (
          // @ts-expect-error no implicit any
          range,
        ) => {
          const {
            id,
            attributes: { from: prevFrom, to: prevTo },
          } = range;

          return {
            id,
            type: 'attribute_ranges',
            attributes: {
              from: prevTo === null ? null : -prevTo,
              to: prevFrom === null ? null : -prevFrom,
            },
          };
        },
      );
    }

    return ranges;
  }
}
