export const assignWindowLocation = (
  ...args: Parameters<typeof window.location.assign>
) => {
  window.location.assign(...args);
};

export const replaceWindowLocation = (
  ...args: Parameters<typeof window.location.replace>
) => {
  window.location.replace(...args);
};

export const reloadWindowLocation = () => window.location.reload();
