import { analytics } from './analytics';

/**
 * Track navigation events
 * @param properties
 * @param properties.level - The level of the navigation event. One of 'first', 'second', 'third'.
 *
 * 'first' should be used for navigation events that happen from the top navigation bar.
 *
 * 'second' should be used for navigation events that happen from the left navigation bar.
 *
 * 'third' should be used for navigation events that happen from tabs within the pages.
 * @param properties.name_first_level - The name of the first level navigation item.
 * @param properties.name_second_level - The name of the second level navigation item.
 * @param properties.name_third_level - The name of the third level navigation item.
 **/
export const trackNavigation = (
  properties:
    | {
        level: 'first';
        name_first_level: string;
      }
    | {
        level: 'second';
        name_first_level: string;
        name_second_level: string;
      }
    | {
        level: 'third';
        name_first_level: string;
        name_second_level: string;
        name_third_level: string;
      },
) => {
  analytics.track('navigation_clicked', properties);
};

export const trackSegmentRecalculationRateLimit = (
  recalculationType: 'topics' | 'priorities',
) => {
  if (recalculationType === 'priorities') {
    analytics.track('recalculating_segments__focus_area_threshold_exceeded');
  } else {
    analytics.track('recalculating_segments__topic_threshold_exceeded');
  }
};

export const customAnalyticsEvents = {
  trackNavigation,
  trackSegmentRecalculationRateLimit,
};
