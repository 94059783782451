class Base {
  requirement: $TSFixMe;
  requirements: $TSFixMe;
  constructor(requirements: $TSFixMe) {
    if (Array.isArray(requirements)) {
      this.requirements = requirements.map((requirement) =>
        Base.normalize(requirement),
      );
    } else {
      this.requirement = Base.normalize(requirements);
    }
  }

  evaluate(_evaluator: $TSFixMe) {
    return false;
  }

  static normalize(requirement: $TSFixMe) {
    if (typeof requirement === 'string') {
      if (requirement.startsWith('!')) {
        return new Not(requirement.substring(1));
      } else {
        return requirement;
      }
    } else if (typeof requirement.evaluate === 'function') {
      return requirement;
    }
  }
}

class And extends Base {
  requirements: $TSFixMe;
  // @ts-expect-error TS(2416) FIXME: Property 'evaluate' in type 'And' is not assignabl... Remove this comment to see the full error message
  evaluate(evaluator: $TSFixMe, requirements: $TSFixMe) {
    for (const requirement of this.requirements) {
      if (!evaluator.evaluate(requirement, requirements)) {
        return false;
      }
    }

    return true;
  }
}

class Not extends Base {
  requirement: $TSFixMe;
  evaluate(evaluator: $TSFixMe) {
    return !evaluator.evaluate(this.requirement);
  }
}

class Or extends Base {
  requirements: $TSFixMe;
  // @ts-expect-error TS(2416) FIXME: Property 'evaluate' in type 'Or' is not assignable... Remove this comment to see the full error message
  evaluate(evaluator: $TSFixMe, requirements: $TSFixMe) {
    for (const requirement of this.requirements) {
      if (evaluator.evaluate(requirement, requirements)) {
        return true;
      }
    }

    return false;
  }
}

export const and = (...requirements: $TSFixMe[]) => new And(requirements);
export const or = (...requirements: $TSFixMe[]) => new Or(requirements);
export const not = (requirement: $TSFixMe) => new Not(requirement);
