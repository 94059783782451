import { fromJS, List, Record } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ActionItemRecord from './ActionItemRecord';
import Category from './CategoryRecord';
import Employee from './EmployeeRecord';
import Course from './improve/CourseRecord';
import Segment from './SegmentRecord';
import { validateTestingSchema } from './utils';
import Value from './ValueRecord';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  team: z.any(),
  category: z.any(),
  group: z.any(),
  access: z.any(),
  dueAt: z.any(),
  archivedAt: z.any(),
  createdAt: z.any(),
  completedAt: z.any(),
  items: z.any(),
  categoryGroup: z.any(),
  notes: z.any(),
  driver: z.any(),
  subdriver: z.any(),
  text: z.any(),
  createdByEmployee: z.any(),
  value: z.any(),
  segment: z.any(),
  course: z.any(),

  // probably a typo somewhere
  lessonid: z.string().optional(),
  lessonId: z.any().nullable(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ActionRecord
  extends Record({
    id: undefined,
    access: undefined,
    createdAt: undefined,
    archivedAt: undefined,
    completedAt: undefined,
    driver: undefined,
    dueAt: undefined,
    group: undefined,
    lessonId: undefined,
    notes: undefined,
    subdriver: undefined,
    team: undefined,
    text: undefined,
    category: undefined,
    categoryId: undefined, // only used when creating actions
    categoryGroup: undefined,

    segment: undefined,
    value: undefined,
    createdByEmployee: undefined,
    course: undefined,

    items: undefined,
    itemProgress: undefined,

    suggested: undefined,
    referenceType: undefined,
    referenceId: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  createdAt!: string;
  completedAt!: string;
  dueAt!: string;
  items: $TSFixMe;
  text: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get deadline() {
    const now = moment.utc();

    if (this.dueAt === null) {
      return 'not_set';
    }

    if (this.dueAt < now.format('YYYY-MM-DD')) {
      return 'overdue';
    }

    if (
      this.dueAt >= now.format('YYYY-MM-DD') &&
      this.dueAt <= now.endOf('week').format('YYYY-MM-DD')
    ) {
      return 'due_this_week';
    }

    if (
      this.dueAt >= now.format('YYYY-MM-DD') &&
      this.dueAt > now.endOf('week').format('YYYY-MM-DD')
    ) {
      return 'upcoming';
    }
  }

  totalIncompleteItems() {
    if (!this.items) {
      return 0;
    }

    return this.items.filter(
      (
        // @ts-expect-error no implicit any
        item,
      ) => !item.completed,
    ).size;
  }

  hasIncompleteItems() {
    return this.totalIncompleteItems() > 0;
  }

  static createFromApi(action: $TSFixMe, team?: $TSFixMe) {
    const {
      id,
      attributes,
      relationships: {
        segment,
        value,
        createdByEmployee,
        lesson,
        items,
        category,
      } = {},
    } = action;

    const course = get(lesson, 'relationships.course');

    return new ActionRecord(
      fromJS({
        id,
        team,
        segment: segment ? Segment.createFromApi(segment) : undefined,
        value: value ? Value.createFromApi(value) : undefined,
        createdByEmployee: createdByEmployee
          ? Employee.createFromApi(createdByEmployee)
          : undefined,
        course: course ? Course.createFromApi(course) : undefined,
        items: items
          ? List(items.map(ActionItemRecord.createFromApi))
          : undefined,
        ...attributes,
        category: category ? Category.createFromApi(category) : undefined,
      }),
    );
  }

  static createApiAction(action: $TSFixMe) {
    const attributes = {
      text: action.text,
      notes: action.notes,
      categoryGroup: action.categoryGroup,
      subdriver: action.subdriver,
      dueAt: action.dueAt,
      access: action.access,
    };

    const categoryId = action.categoryId || get(action, 'category.id');

    const data = {
      type: 'actions',
      attributes,
      relationships: {},
    };

    if (action.segment) {
      Object.assign(data.relationships, {
        segment: {
          id: action.segment.id,
          type: 'segments',
        },
      });
    }

    if (action.value) {
      Object.assign(data.relationships, {
        value: {
          id: action.value.id,
          type: 'values',
        },
      });
    }

    if (categoryId) {
      Object.assign(data.relationships, {
        category: {
          id: categoryId,
          type: 'categories',
        },
      });
    } else {
      // @ts-expect-error TS(2339): Property 'driver' does not exist on type '{ text: ... Remove this comment to see the full error message
      attributes.driver = action.driver;
    }

    if (action.items) {
      const items = action.items
        .filter(
          (
            // @ts-expect-error no implicit any
            item,
          ) => item.text.length > 0,
        )
        // @ts-expect-error no implicit any
        .map((item) => {
          const actionItem = {
            type: 'action_items',
            attributes: {
              text: item.text,
              completed: item.completed,
            },
          };

          if (item.id && !item.isNew) {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ type: stri... Remove this comment to see the full error message
            actionItem.id = item.id;
          }

          return actionItem;
        });

      Object.assign(data.relationships, {
        items: {
          data: items.toJS(),
        },
      });
    }

    if (isEmpty(data.relationships)) {
      // @ts-expect-error TS(2790): The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
      delete data.relationships;
    }

    return data;
  }
}
