import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { SlideIn } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { jsonapiparser } from '@peakon/shared/utils';

import { ConversationComment } from './ConversationComment/ConversationComment';
import { ConversationForm } from './ConversationForm/ConversationForm';
import { ConversationMessages } from './ConversationMessages/ConversationMessages';
import { conversationMessageAdded } from '../../../actions/CommentActions';
import { catchHandler } from '../../../actions/NotificationActions';
import { ConversationsContext } from '../../../context/ConversationsContext';
import { useDashboardContext } from '../../../hooks/useDashboardContext';
import { useRightsChecker } from '../../../hooks/useRightsChecker';
import { Message } from '../../../queries/conversations/types/message';
import useCreateMessage, {
  QueryData,
  insertMessageIntoQueryCache,
} from '../../../queries/conversations/useCreateMessage';
import { conversationMessagesKeys } from '../../../queries/conversations/useMessages';
import { isConsultant } from '../../../selectors/SessionSelectors';
import { useAppDispatch, useAppSelector } from '../../../utils/reduxHooks';

import styles from './styles.css';

export function ConversationSlideIn() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    comment: conversationComment,
    isPermalink,
    kind,
    onConversationToggle,
    realtimeChannel,
  } = useContext(ConversationsContext);

  const [pauseFocus, setPauseFocus] = useState(false);

  const comments = useAppSelector((state) => state.comments);
  const dashboardContext = useDashboardContext();
  const currentEmployeeId = useAppSelector((state) => state.session.employeeId);
  const isConsultantAccess = useAppSelector(isConsultant);

  const [hasWriteRights] = useRightsChecker(['conversation:write']);

  const show = Boolean(conversationComment);

  const handleError = (error: Error) => {
    dispatch(catchHandler(error));
  };

  const { mutate: createMessage, isLoading: isSaving } = useCreateMessage({
    onError: handleError,
    onSuccess: () => {
      // @ts-expect-error - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      dispatch(conversationMessageAdded(conversationComment?.id));

      scrollToBottom();
    },
    commentId: conversationComment?.id ?? '',
  });

  const handleRealtimeMessage = useCallback(
    // @ts-expect-error React-18 Parameter 'response' implicitly has an 'any' type.ts(7006)
    (response) => {
      if (!conversationComment) {
        return;
      }

      const newMessage: Message = jsonapiparser(response).data;

      const isCurrentUser = newMessage?.employee?.id === currentEmployeeId;

      // we already handle it via form submit
      if (isCurrentUser) {
        return;
      }

      queryClient.setQueryData<QueryData>(
        conversationMessagesKeys.list(conversationComment.id),
        (query) => insertMessageIntoQueryCache(query, newMessage),
      );

      dispatch(conversationMessageAdded(conversationComment.id));

      scrollToBottom();
    },
    [conversationComment, currentEmployeeId, dispatch, queryClient],
  );

  useEffect(() => {
    if (show) {
      scrollToBottom();
    }
  }, [show]);

  useEffect(() => {
    if (realtimeChannel) {
      realtimeChannel.on('message', handleRealtimeMessage);
    }
  }, [handleRealtimeMessage, realtimeChannel]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleClose = () => {
    onConversationToggle(null);
  };

  const handleLoadNext = (isVisible?: boolean) => {
    if (isVisible && containerRef.current) {
      const previousScrollPosition =
        containerRef.current.scrollHeight - containerRef.current.scrollTop;

      // the component might have been unmounted by the time we finish loading the data
      if (containerRef.current) {
        containerRef.current.scrollTop =
          containerRef.current.scrollHeight - previousScrollPosition;
      }
    }
  };

  const handleSubmit = (message: string) => {
    if (isConsultantAccess || !conversationComment) {
      return;
    }

    return createMessage({
      contextId: isPermalink ? undefined : dashboardContext.id,
      kind,
      message,
    });
  };

  const reduxComment = useMemo(() => {
    if (!conversationComment) {
      return null;
    }

    const storedComment =
      comments.list.find(
        (comment) =>
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          comment.id === conversationComment.id,
      ) ||
      comments.overview.items.find(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        (comment) => comment.id === conversationComment.id,
      );

    return storedComment || conversationComment;
  }, [comments, conversationComment]);

  return (
    <SlideIn
      closeButtonLabel={t('common__close')}
      focusTrapProps={{
        paused: pauseFocus,
      }}
      show={show}
      testId="conversation-slide-in"
      title={t('conversation__title')}
      onClose={handleClose}
      withOverlay
    >
      <SlideIn.Panel>
        <SlideIn.Content className={styles.slideInContent}>
          <div
            ref={containerRef}
            aria-live="polite"
            className={styles.main}
            role="log"
            data-test-id="slide-in-conversation-history"
          >
            <div className={styles.container}>
              {reduxComment && (
                <ConversationComment
                  comment={reduxComment}
                  isPermalink={isPermalink}
                />
              )}
              {conversationComment && (
                <ConversationMessages
                  comment={conversationComment}
                  kind={kind}
                  onLoad={scrollToBottom}
                  onLoadNext={handleLoadNext}
                />
              )}
            </div>
          </div>
          {hasWriteRights && (
            <div className={styles.form}>
              <ConversationForm
                isConsultantAccess={isConsultantAccess}
                isSaving={isSaving}
                onConfirm={setPauseFocus}
                onSubmit={handleSubmit}
              />
            </div>
          )}
        </SlideIn.Content>
      </SlideIn.Panel>
    </SlideIn>
  );
}
