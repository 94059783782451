import get from 'lodash/get';

import jsonapiparser from '@peakon/jsonapiparser';
import PeakonApi from '@peakon/peakon-js';
import { CONTEXT_RIGHTS } from '@peakon/shared/constants/auth';
import {
  ContextResponse,
  contextSchema,
} from '@peakon/shared/schemas/api/contexts';
import api from '@peakon/shared/utils/api';
import { Right } from '@peakon/shared/utils/rights/types';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { Session } from '../../../types/Session';

const PARAMS = {
  fields: {
    contexts:
      'rights,level,logo,name,employee,direct,attribute,abbreviation,contextCategoryGroups,isEmployeeDashboardTeam,employeeCount',
    attributes: 'name,nameTranslated,type',
  },

  include:
    'attribute,contextCategoryGroups,contextCategoryGroups.categoryGroup',
};

export const rightsRequiredForContext: Right[] = [
  'score:driver:read:manager',
  'score:driver:read:all',
  'score:driver:read:context',
  'employee:read:manager',
  'employee:read:all',
  'question:admin',
  'schedule:admin',
  'schedule:read:manager',
  'schedule:read:manager',
  'schedule:preview:manager',
  'question:read:manager',
  'question:admin:manager',
  'action:read:private',
  'usageMetrics:read',
  'usageMetrics:read:manager',
  'externalMetric:read:all',
  'branding:read',
];

type FetchContextProps = {
  session: Session;
  client: PeakonApi;
};

export const fetchContext = async ({
  session,
}: FetchContextProps): Promise<{
  defaultContext: ContextResponse | null;
  contexts: ContextResponse[];
}> => {
  const sessionRights: Right[] = get(session, 'data.attributes.rights', []);
  const ACCESS_BY_SEGMENT_REGEX =
    /^(?:score:driver|schedule|question|usageMetrics):(?:read|preview|admin):context:segment_[0-9]+$/;

  let contexts: ContextResponse[] = [];

  if (
    sessionRights.some(
      (right: Right) =>
        CONTEXT_RIGHTS.includes(right) || ACCESS_BY_SEGMENT_REGEX.test(right),
    )
  ) {
    const response = jsonapiparser(await api.get('/contexts', PARAMS));
    // @ts-expect-error return from jsonapiparser is unknown
    contexts = [...response.data];
    const currentParameters = new URLSearchParams(window.location.search);
    const contextId = currentParameters.get('contextId');
    // @ts-expect-error return from jsonapiparser is unknown
    const currentContext = response.data.find(
      (context: ContextResponse) => context.id === contextId,
    );

    if (currentContext) {
      return {
        defaultContext: currentContext,
        contexts,
      };
    }

    // @ts-expect-error return from jsonapiparser is unknown
    const defaultContext = response.data.find((context: ContextResponse) =>
      context.attributes?.rights?.includes('dataset:read'),
    );

    if (defaultContext) {
      return {
        defaultContext,
        contexts,
      };
    }

    // @ts-expect-error return from jsonapiparser is unknown
    const firstContext = response.data[0];

    if (firstContext) {
      return {
        defaultContext: firstContext,
        contexts,
      };
    }
  }

  return {
    defaultContext: null,
    contexts,
  };
};

export const getContextById = async (id: string): Promise<ContextResponse> => {
  const response = await api.get(`/contexts/${id}`, PARAMS);
  // @ts-expect-error return from jsonapiparser is unknown
  return validateData(jsonapiparser(response).data, contextSchema, {
    errorMessagePrefix: 'contextSchema',
  });
};
