import { assignWindowLocation } from '../../../utils/window';
import { getSubdomainHref } from '../getSubdomainHref';

type Props = {
  newSubdomain: string;
  currentSubdomain: string;
};

/**
 *
 * Redirects the user to the subdomain directly, without trying to preserve their sessions. If the users were logged in on the current subdomain, they will be asked to log in again in the new subdomain.
 */
const RedirectToSubdomain = ({ newSubdomain, currentSubdomain }: Props) => {
  const redirectURL = getSubdomainHref({
    href: window.location.href,
    currentSubdomain,
    newSubdomain,
  });

  assignWindowLocation(redirectURL);
  return null;
};

// eslint-disable-next-line import/no-default-export
export default RedirectToSubdomain;
