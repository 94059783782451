import React, { Fragment } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';

import { Avatar } from '@peakon/components';
import { Segment } from '@peakon/records';

import AttributeBadge from '../AttributeBadge';

import styles from './styles.css';

const getTitle = (context: $TSFixMe) => {
  const contextName = context.nameTranslated || context.name;
  const attrName =
    get(context, 'attribute.nameTranslated') || get(context, 'attribute.name');

  return attrName ? `${contextName} (${attrName}) ` : contextName;
};

type ContextLabelProps = {
  dashboardContext?: $TSFixMe; // TODO  PropTypes.oneOfType([PropTypes.instanceOf(ActionsTeam), PropTypes.instanceOf(Context), PropTypes.instanceOf(Segment),PropTypes.object]),
  className?: string;
  modifier?:
    | 'action'
    | 'actionTitle'
    | 'list'
    | 'notification'
    | 'slideIn'
    | 'tooltip';
  size?: 'tiny' | 'small';
  fontSize?: 'default' | 'large';
  type?: string;
  badgeType?: 'light' | 'noBorder';
  avatarSize?: 'tiny' | 'small' | 'medium-small' | 'medium' | 'large';
  count?: React.ReactNode;
  date?: React.ReactNode;
  ellipsis?: boolean;
  testId?: string;
};

const ContextLabel = ({
  dashboardContext,
  modifier,
  size = 'small',
  avatarSize,
  fontSize,
  type,
  badgeType = 'light',
  className,
  count,
  date,
  ellipsis = false,
  testId,
}: ContextLabelProps) => (
  <div
    className={classnames(
      styles.root,
      {
        [styles[size]]: size,
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        [styles[fontSize]]: fontSize,
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        [styles[modifier]]: modifier,
      },

      className,
    )}
    title={getTitle(dashboardContext)}
    data-test-id={testId}
  >
    <div
      className={classnames(
        styles.avatar,
        {
          // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
          [styles[modifier]]: modifier,
          [styles.notificationNoBadge]:
            modifier === 'notification' && dashboardContext.level !== 'company',
        },

        className,
      )}
    >
      <Avatar
        abbreviation={dashboardContext.abbreviation}
        gradient={
          dashboardContext.level === 'company' ||
          dashboardContext.attribute.type !== 'employee'
        }
        icon={
          dashboardContext.level !== 'company' &&
          (dashboardContext.attribute.type === 'date' ||
            dashboardContext.attribute.type === 'number')
            ? dashboardContext.attribute.type
            : undefined
        }
        rounded={
          dashboardContext instanceof Segment
            ? false
            : get(dashboardContext, 'attribute.type') === 'employee'
        }
        src={dashboardContext.logo}
        size={avatarSize || size}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        type={type}
      />
    </div>
    {modifier === 'actionTitle' ? (
      <div className={styles.actionTitleV2}>
        <span
          className={classnames(styles.name, { [styles.ellipsis]: ellipsis })}
        >
          {dashboardContext.nameTranslated || dashboardContext.name}
        </span>
        {count && <span className={styles.count}>{count}</span>}
        {dashboardContext.level !== 'company' && (
          <div
            className={classnames(styles.badge, {
              [styles[modifier]]: modifier,
            })}
          >
            <AttributeBadge
              attribute={dashboardContext.attribute}
              direct={dashboardContext.direct}
              type="noBorder"
            />
          </div>
        )}
      </div>
    ) : modifier === 'notification' ? (
      <div className={styles.notificationLabel}>
        <span
          className={classnames(styles.notificationName, {
            [styles.ellipsis]: ellipsis,
          })}
        >
          {dashboardContext.nameTranslated || dashboardContext.name}
          {date}
        </span>
        {count && <span className={styles.count}>{count}</span>}
        {dashboardContext.level !== 'company' && (
          <div
            className={classnames(styles.badge, {
              [styles[modifier]]: modifier,
            })}
          >
            <AttributeBadge
              attribute={dashboardContext.attribute}
              direct={dashboardContext.direct}
              type={badgeType}
            />
          </div>
        )}
      </div>
    ) : (
      <Fragment>
        <span
          className={classnames(styles.name, { [styles.ellipsis]: ellipsis })}
        >
          {dashboardContext.nameTranslated || dashboardContext.name}
        </span>
        {count && <span className={styles.count}>{count}</span>}
        {dashboardContext.level !== 'company' && (
          <div
            className={classnames(styles.badge, {
              // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
              [styles[modifier]]: modifier,
            })}
          >
            <AttributeBadge
              attribute={
                dashboardContext.badgeAttributes ?? dashboardContext.attribute
              }
              direct={dashboardContext.direct}
              type={badgeType}
            />
          </div>
        )}
      </Fragment>
    )}
  </div>
);

// eslint-disable-next-line import/no-default-export
export default ContextLabel;
