import {
  ZodDiscriminatedUnion,
  ZodEffects,
  ZodObject,
  ZodSchema,
  ZodType,
  ZodUnion,
} from 'zod';

/**
 * Gets the "root" zod object, if for example a type
 * has `refine` or `superRefine` attached to it.
 * Multiple `ZodEffect`s can be chained together, so
 * it needs to be recursive.
 */
function unwrapZodObject(zodObject: ZodType): ZodType {
  if (zodObject instanceof ZodEffects) {
    return unwrapZodObject(zodObject.sourceType());
  }

  return zodObject;
}

/**
 * Recursively goes through a zod schema and returns a list of
 * all required fields in that schema.
 */
function recursiveRequiredFields(
  zodObject: ZodType,
  fieldPath = '',
  requiredFields: string[] = [],
): string[] {
  const unwrappedZodObject = unwrapZodObject(zodObject);

  if (unwrappedZodObject instanceof ZodObject) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const shape = unwrappedZodObject._def.shape() as Record<string, ZodType>;

    return Object.entries(shape)
      .map(([key, childZodObject]) =>
        recursiveRequiredFields(
          childZodObject,
          fieldPath ? `${fieldPath}.${key}` : key,
          requiredFields,
        ),
      )
      .flat();
  }

  if (
    unwrappedZodObject instanceof ZodDiscriminatedUnion ||
    unwrappedZodObject instanceof ZodUnion
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const options = unwrappedZodObject._def.options as ZodType[];

    return options
      .map((zodOption) =>
        recursiveRequiredFields(zodOption, fieldPath, requiredFields),
      )
      .flat();
  }

  if (!unwrappedZodObject.isOptional()) {
    return [...requiredFields, fieldPath];
  }

  return requiredFields;
}

/**
 * Recursively goes through a zod schema and returns a list of
 * all required fields in that schema.
 */
export function getRequiredFields(schema: ZodSchema) {
  // Objects like `z.discriminatedUnion` can generate
  // duplicate keys, so we need to filter them out
  return [...new Set(recursiveRequiredFields(schema))];
}
