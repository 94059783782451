import React from 'react';

import { Transition } from 'react-transition-group';

const defaultStyles = {
  transition: 'opacity 300ms ease-in-out, height 400ms ease-in-out',
  opacity: 1,
  height: '100%',
};

const transitionStyles = {
  exiting: { opacity: 1, height: '100%' },
  exited: { opacity: 0, height: 0 },
};

type Props = {
  in?: boolean;
  className?: string;
  children?: React.ReactNode;
  timeout?: number;
};

const FadeOut = ({
  in: inProp = true,
  className,
  children,
  timeout = 300,
}: Props) => (
  <Transition in={inProp} timeout={timeout}>
    {(state) => (
      <div
        className={className}
        style={{
          ...defaultStyles,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

// eslint-disable-next-line import/no-default-export
export default FadeOut;
