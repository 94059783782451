import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import CategoryRecord from '../CategoryRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  screenshot: z.any(),
  driver: z.any(),
  resourceType: z.any(),
  author: z.any(),
  url: z.any(),
  title: z.any(),
  source: z.any(),
  description: z.any(),
  category: z.any(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ResourceRecord
  extends Record({
    id: undefined,
    driver: undefined,
    screenshot: undefined,
    title: undefined,
    url: undefined,
    contentType: 'resource',
    source: undefined,
    resourceType: undefined,
    author: undefined,
    description: undefined,
    category: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  driver!: string;
  title!: string;
  description!: string;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ResourceRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ResourceRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe, { category }: $TSFixMe = {}) {
    const { id, attributes } = data;

    return new ResourceRecord(
      fromJS({
        id,
        ...attributes,
        category: category ? CategoryRecord.createFromApi(category) : undefined,
      }),
    );
  }
}
