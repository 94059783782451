import React, { useEffect, useState } from 'react';

import { matchPath, useHistory, useLocation } from 'react-router-dom';

import api from '@peakon/shared/utils/api';
import RealtimeChannel from '@peakon/shared/utils/realtime/RealtimeChannel';

import { toggleActive } from '../actions/CommentActions';
import {
  ConversationsContext,
  ConversationContent,
  ConversationKind,
} from '../context/ConversationsContext';
import { useAppDispatch } from '../utils/reduxHooks';

type Props = {
  children: React.ReactNode;
};

const ConversationsProvider = ({ children }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [kind, setKind] = useState<ConversationKind>(ConversationKind.reply);
  const [comment, setComment] = useState<ConversationContent | null>(null);
  const [realtimeChannel, setRealtimeChannel] =
    useState<RealtimeChannel | null>(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let unlisten;

    if (history && comment) {
      unlisten = history.listen(() => {
        setComment(null);
        dispatch(toggleActive(null));

        if (realtimeChannel) {
          realtimeChannel.leave(true);
          setRealtimeChannel(null);
        }
      });

      return unlisten;
    }
  }, [comment, dispatch, history, realtimeChannel]);

  const onConversationToggle = (
    newComment: ConversationContent | null,
    newKind = ConversationKind.reply,
  ) => {
    setComment(newComment);
    dispatch(toggleActive(newComment));

    if (newComment) {
      const channel = new RealtimeChannel(
        `conversations/comments/${newComment.id}`,
        { getToken: () => api.auth.restoreToken() },
      );

      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      channel.join();

      setRealtimeChannel(channel);
      setKind(newKind);
    } else if (realtimeChannel) {
      realtimeChannel.leave(true);
      setRealtimeChannel(null);
    }
  };

  const onKindChange = (newKind: ConversationKind) => {
    setKind(newKind);
  };

  const isPermalink =
    matchPath(location.pathname, {
      path: '/dashboard/comments/:id',
    }) !== null;

  return (
    <ConversationsContext.Provider
      value={{
        comment,
        kind,
        isPermalink,
        onConversationToggle,
        onKindChange,
        realtimeChannel,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConversationsProvider;
