import { List } from 'immutable';

import CategoryRecord from '@peakon/records/CategoryRecord';
import CustomResourceRecord from '@peakon/records/improve/CustomResourceRecord';
import { jsonapiparser } from '@peakon/shared/utils';

type CustomResourceState = {
  isLoading: boolean;
  isSaving: boolean;
  items: List<CustomResourceRecord>;
  error: null;
  drivers: List<CategoryRecord>;
  file: {
    value?: File;
    progress?: number;
    error?: unknown;
  } | null;
};

const getInitialState: () => CustomResourceState = () => ({
  isLoading: false,
  isSaving: false,
  items: List<CustomResourceRecord>(),
  error: null,
  drivers: List<CategoryRecord>(),
  file: null,
});

const customResources = (
  state = getInitialState(),
  action: $TSFixMe,
): CustomResourceState => {
  switch (action.type) {
    case 'CUSTOM_RESOURCE_LIST_LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'CUSTOM_RESOURCE_LIST_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isLoading: false,
        items: List(data.map(CustomResourceRecord.createFromApi)),
      };
    }
    case 'CUSTOM_RESOURCE_CREATE_LOADING':
    case 'CUSTOM_RESOURCE_UPDATE_LOADING': {
      return {
        ...state,
        isSaving: true,
      };
    }
    case 'CUSTOM_RESOURCE_CREATE_FAILED':
    case 'CUSTOM_RESOURCE_UPDATE_FAILED': {
      return {
        ...state,
        isSaving: false,
      };
    }
    case 'CUSTOM_RESOURCE_CREATE_SUCCESS': {
      const { data } = action.data;

      return {
        ...state,
        isSaving: false,
        items: state.items.push(CustomResourceRecord.createFromApi(data)),
      };
    }
    case 'CUSTOM_RESOURCE_UPDATE_SUCCESS': {
      const { resource, data } = action.data;
      const { items } = state;

      const category = data.relationships?.category;

      return {
        ...state,
        isSaving: false,
        items: items.updateIn(
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          [items.findIndex((i) => i.id === resource.id)],
          (item) =>
            item.merge(
              category && category.id !== item.category.id
                ? resource.set(
                    'category',
                    CategoryRecord.createFromApi(category),
                  )
                : resource,
            ),
        ),
      };
    }
    case 'CUSTOM_RESOURCE_DELETE_SUCCESS': {
      const { resource } = action.data;
      const { items } = state;

      return {
        ...state,
        isSaving: false,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        items: items.removeIn([items.findIndex((i) => i.id === resource.id)]),
      };
    }
    case 'CUSTOM_RESOURCE_UPLOAD_PROGRESS': {
      const { progress } = action.data;

      return {
        ...state,
        isSaving: true,
        file: {
          progress,
        },
      };
    }
    case 'CUSTOM_RESOURCE_UPLOAD_SUCCESS': {
      const { value } = action.data;

      return {
        ...state,
        isSaving: false,
        file: {
          value,
        },
      };
    }
    case 'CUSTOM_RESOURCE_UPLOAD_FAILED': {
      const { error } = action.data;

      return {
        ...state,
        isSaving: false,
        file: {
          ...state.file,
          error,
        },
      };
    }
    case 'CUSTOM_RESOURCE_CANCEL': {
      return {
        ...state,
        file: null,
      };
    }
    case 'CUSTOM_RESOURCE_DRIVERS_LIST_SUCCESS': {
      const { data } = jsonapiparser(action.data);

      return {
        ...state,
        drivers: List(data),
      };
    }
    case 'CUSTOM_RESOURCE_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default customResources;
