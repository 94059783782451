import React, { Component } from 'react';

import find from 'lodash/find';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { MentionsInput, Mention } from 'react-mentions';

import { Avatar } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { getResizedImageUrl } from '@peakon/shared/utils';

import styles from './styles.css';

type EllipsisProps = {
  children?: React.ReactNode;
};

const Ellipsis = ({ children }: EllipsisProps) => (
  <span className={styles.ellipsis}>{children}</span>
);

type MentionsTextareaProps = {
  employees: $TSFixMe[];
  getMentionable: $TSFixMeFunction;
  placeholder?: string;
  onChange: $TSFixMeFunction;
  value: string;
  id?: string;
  ariaDescribedby?: string;
};

type MentionsTextareaState = { value: string };

class MentionsTextarea extends Component<
  MentionsTextareaProps,
  MentionsTextareaState
> {
  portalNode: $TSFixMe;

  constructor(props: MentionsTextareaProps) {
    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
    super();

    this.displayTransform = this.displayTransform.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value: props.value,
    };
  }

  componentDidMount() {
    this.portalNode = window.document.getElementById(
      'conversation-message-form',
    );
  }

  componentDidUpdate(prevProps: MentionsTextareaProps) {
    const { value } = this.props;
    const { value: stateValue } = this.state;
    if (prevProps.value !== value && value !== stateValue) {
      this.setState({ value });
    }
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;

    this.setState(
      {
        value: e.target.value,
      },
      () => {
        onChange(this.state.value);
      },
    );
  }

  displayTransform(id: $TSFixMe) {
    const { employees } = this.props;

    const employee = find(employees, (e) => e.id === id.replace(/^\D+/g, ''));

    return employee ? employee.attributes.name : '';
  }

  render() {
    const { getMentionable, placeholder, id, ariaDescribedby } = this.props;
    const { value } = this.state;

    return (
      <MentionsInput
        aria-required
        classNames={styles}
        name="conversationMessage"
        onChange={this.handleChange}
        placeholder={placeholder}
        style={
          this.portalNode
            ? {
                suggestions: {
                  list: { bottom: this.portalNode.offsetHeight },
                },
              }
            : undefined
        }
        suggestionsPortalHost={this.portalNode}
        value={value}
        aria-describedby={ariaDescribedby}
        id={id}
      >
        <Mention
          appendSpaceOnAdd
          displayTransform={this.displayTransform}
          markup="<@E__id__>"
          trigger={/(@(\S+ \S+|\S*))$/}
          data={getMentionable}
          className={styles.mention}
          renderSuggestion={(suggestion: $TSFixMe) => (
            <div
              className={styles.user}
              aria-label={t('conversations__mention__label', {
                replace: { name: suggestion.display },
              })}
            >
              <div className={styles.avatar}>
                <Avatar
                  size="tiny"
                  abbreviation={suggestion.abbreviation}
                  alt={suggestion.display}
                  rounded
                  src={getResizedImageUrl({ src: suggestion.avatar })}
                />
              </div>
              <Ellipsis>{suggestion.display}</Ellipsis>
            </div>
          )}
        />
      </MentionsInput>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default MentionsTextarea;
