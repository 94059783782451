import { Record } from 'immutable';
import { z } from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

const systemNotificationSchema = z.object({
  id: z.string(),
  title: translatedStringSchema,
  message: translatedStringSchema.optional(),
  type: z.enum(['success', 'error']),
  code: z.number().optional(),
  link: z
    .object({
      href: z.string(),
      label: z.string(),
    })
    .optional(),
  duration: z.number().optional(),
  dismissable: z.boolean().optional(),
});

export type SystemNotificationSchema = z.infer<typeof systemNotificationSchema>;

// eslint-disable-next-line import/no-default-export
export default class SystemNotification extends Record<SystemNotificationSchema>(
  {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: undefined!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    title: undefined!,
    message: undefined,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    type: undefined!,
    code: undefined,
    link: undefined,
    duration: undefined,
    dismissable: undefined,
  },
) {
  constructor(props: unknown = {}) {
    validateData(props, systemNotificationSchema, {
      errorMessagePrefix: 'systemNotificationSchema',
    });

    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
