import isPlainObject from 'lodash/isPlainObject';

const errorMiddleware =
  (errorHandler: $TSFixMe) => () => (next: $TSFixMe) => (action: $TSFixMe) => {
    if (isPlainObject(action)) {
      try {
        return next(action);
      } catch (error) {
        errorHandler(error);

        throw error;
      }
    } else {
      return Promise.resolve(next(action)).catch((error) => {
        errorHandler(error);

        throw error;
      });
    }
  };

// eslint-disable-next-line import/no-default-export
export default errorMiddleware;
