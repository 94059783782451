import React from 'react';

// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Tooltip as TippyTooltip } from 'react-tippy';

type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
};

const Tooltip = ({
  className,
  children,
  disabled,
  title,
  position = 'right',
  ...rest
}: Props) => (
  <TippyTooltip
    className={className}
    animateFill={false}
    animation="shift"
    arrow
    delay={[100, 200]}
    disabled={disabled}
    inertia
    popperOptions={{
      modifiers: {
        keepTogether: {
          enabled: true,
        },
        preventOverflow: { boundariesElement: 'viewport' },
      },
    }}
    position={position}
    title={title}
    {...rest}
  >
    {children}
  </TippyTooltip>
);

// eslint-disable-next-line import/no-default-export
export default Tooltip;
