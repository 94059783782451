// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'xss/... Remove this comment to see the full error message
import xss from 'xss/lib/index';

const _xss = new xss.FilterXSS({
  whiteList: {
    p: [],
    strong: [],
    h6: [],
    i: [],
    em: [],
    span: ['class'],
    ul: [],
    li: [],
    ol: [],
    br: [],
  },
});

// eslint-disable-next-line import/no-default-export
export default _xss;
