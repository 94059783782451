export type Params = {
  filter?: { 'round.type': 'test' };
};

export const commentsKeys = {
  comments: (contextId: string) => ['comments', contextId] as const,
  unreadComments: (contextId: string) =>
    [...commentsKeys.comments(contextId), 'unread'] as const,
  unreadCommentsCount: (contextId = '', params: Params) =>
    [...commentsKeys.unreadComments(contextId), 'count', params] as const,
};
