import { useEffect, useState } from 'react';

import moment from 'moment';

const getRelativeTime = (datetime: NonNullable<moment.MomentInput>) => {
  const relativeTime = moment.utc(datetime);

  if (relativeTime.isSameOrBefore(moment())) {
    return relativeTime.fromNow();
  }

  return relativeTime.format('ll');
};

export function useRelativeTime(datetime: NonNullable<moment.MomentInput>) {
  const [relativeTime, setRelativeTime] = useState(getRelativeTime(datetime));

  useEffect(() => {
    const timer = setInterval(() => {
      setRelativeTime(getRelativeTime(datetime));
    }, 60 * 1000);

    return () => clearInterval(timer);
  }, [datetime]);

  return relativeTime;
}
