import React, { useEffect } from 'react';

import { Button } from '@peakon/bedrock/react/button';
import { Stack, Inline, Box } from '@peakon/bedrock/react/layout';
import { Link } from '@peakon/bedrock/react/link';
import { BodyText } from '@peakon/bedrock/react/typography';
import { analytics } from '@peakon/shared/features/analytics/analytics';
import { t } from '@peakon/shared/features/i18next/t';

import { COOKIE_POLICY_URL } from './constants';
import { useCookieConsentContext } from './CookieConsentContext';
import { useCookieConsentService } from './CookieConsentService';

import styles from './styles.css';

export function CookieConsentDialog() {
  useEffect(() => {
    analytics.track('cookie_consent_dialog__viewed');
  }, []);

  const { acceptAllCookieCategories, declineAllCookieCategories } =
    useCookieConsentService();

  const { setIsCookieConsentModalOpened } = useCookieConsentContext();

  function handleAcceptAll() {
    analytics.track('cookie_consent_dialog_accept_all__clicked');
    acceptAllCookieCategories();
  }

  function handleDeclineAll() {
    analytics.track('cookie_consent_dialog_decline_all__clicked');
    declineAllCookieCategories();
  }

  function handleSettings() {
    analytics.track('cookie_consent_dialog_settings__clicked');
    setIsCookieConsentModalOpened(true);
  }

  return (
    <div className={styles.dialogWrapper}>
      <div
        className={styles.dialog}
        role="dialog"
        aria-modal="false"
        aria-label={t('user_consent__dialog__title')}
      >
        <Box padding={16}>
          <Stack spacing={8}>
            <BodyText>
              {t('user_consent__dialog__description', {
                components: {
                  link: (
                    <Link
                      variant="primary"
                      href={COOKIE_POLICY_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                },
              })}
            </BodyText>
            <Inline spacing={8} alignInline="space-between">
              <Inline spacing={8}>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleAcceptAll}
                >
                  {t('user_consent__dialog__accept_all_btn')}
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleDeclineAll}
                >
                  {t('user_consent__dialog__decline_all_btn')}
                </Button>
              </Inline>

              <Button variant="secondary" size="small" onClick={handleSettings}>
                {t('user_consent__dialog__settings_btn')}
              </Button>
            </Inline>
          </Stack>
        </Box>
      </div>
    </div>
  );
}
