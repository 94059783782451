export type Log = {
  message: string;
  position: string;
  severity: 'warning' | 'info';
};

export type LogResult = {
  created: number;
  skipped: number;
  updated: number;
};

type ImportResult = {
  backgroundTaskId?: string;
  logs?: Log[];
  result?: LogResult;
  type?: 'externalMetricImportFile';
  progress: number | null;
  error?: string | null;
};

type Action = {
  type:
    | 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_FLUSH'
    | 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_PROGRESS'
    | 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_ERROR'
    | 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_RESULT';
  data?: ImportResult;
};

const getInitialState = () => ({
  progress: null,
});

const importResults = (
  state: ImportResult = getInitialState(),
  action: Action,
): ImportResult => {
  switch (action.type) {
    case 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_FLUSH': {
      return getInitialState();
    }

    case 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_PROGRESS':
    case 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_ERROR':
    case 'EXTERNAL_METRICS_OBSERVATIONS_IMPORT_RESULT': {
      return {
        ...state,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default importResults;
