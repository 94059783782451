const SECTORS = [
  'Consumer',
  'Consumer Durables & Apparel',
  'Consumer Services',
  'Food, Beverage & Tobacco',
  'Media',
  'Retailing',
  'Education',
  'Energy & Utilities',
  'Independent Power and Renewable Electricity Producers',
  'Finance',
  'Diversified Financials',
  'Insurance',
  'Government',
  'Healthcare',
  'Health Care Equipment & Services',
  'Pharmaceuticals, Biotechnology & Life Sciences',
  'Materials',
  'Manufacturing',
  'Capital Goods',
  'Non-profit',
  'Professional Services',
  'Commercial & Professional Services',
  'Technology',
  'Software & Services',
  'Technology Hardware & Equipment',
  'Transportation',
  'Hotels, Restaurants & Leisure',
];

export const NEW_SECTORS = [...SECTORS, 'Consumer Discretionary'].sort();

export const COMPANY_SIZES = [
  '1-25',
  '26-50',
  '51-150',
  '151-500',
  '501-1000',
  '1,001-5,000',
  '5,001-10,000',
  '>10,000',
];
