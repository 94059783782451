/**
 * Returns the href for the primary subdomain, without any transfer token or preserving the session.
 * @example
 * getSubdomainHref({
 *   href: 'https://app.peakon.com/dashboard?foo=bar',
 *   primarySubdomain: 'kinetar',
 *   currentSubdomain: 'app',
 * }) // 'https://kinetar.peakon.com/dashboard?foo=bar'
 **/
export const getSubdomainHref = ({
  href,
  newSubdomain,
  currentSubdomain,
}: {
  href: string;
  newSubdomain: string;
  currentSubdomain: string;
}) => {
  return href.replace(currentSubdomain, newSubdomain);
};
