import React from 'react';

import { Avatar } from '@peakon/components';
import { getResizedImageUrl } from '@peakon/shared/utils';
import { getAbbreviationFromName } from '@peakon/shared/utils/getAbbreviation';

const avatarURLOpts = {
  width: 24,
  height: 24,
  fit: 'crop',
  dpr: 2,
};

type Props = {
  name: string;
  src?: string | null;
};

export function ManagerAvatar({ name, src }: Props) {
  return (
    <Avatar
      size="medium-small"
      src={src ? getResizedImageUrl({ ...avatarURLOpts, src }) : undefined}
      abbreviation={getAbbreviationFromName(name)}
      rounded
    />
  );
}
