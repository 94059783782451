import { List } from 'immutable';

import { Resource } from '@peakon/records';

import { getResourceName, getResourceState } from '../utils';

const initialSubscriptions = List(
  ['session_read', 'context_list'].map((name) => new Resource({ name })),
);

const loaders = (
  state = initialSubscriptions,
  action: {
    data: string[];
    type: 'LOADER_SUBSCRIBE' | 'LOADER_UNSUBSCRIBE';
  },
) => {
  if (action.type === 'LOADER_SUBSCRIBE') {
    const resources = action.data;

    return state.concat(
      resources.map((resource) => new Resource({ name: resource })),
    );
  } else if (action.type === 'LOADER_UNSUBSCRIBE') {
    const resources = action.data;

    return state.filter((r) => (r ? !resources.includes(r.name) : false));
  }

  const resourceName = getResourceName(action.type);
  const index = state.findIndex((r) => r?.name === resourceName);

  return index > -1
    ? state.update(index, (resource) =>
        resource.set(
          'state',
          // @ts-expect-error Type 'string' is not assignable to type '"NOT_INITIALIZED" | "SUCCESS" | "LOADING" | null'.
          getResourceState(action.type),
        ),
      )
    : state;
};

// eslint-disable-next-line import/no-default-export
export default loaders;
