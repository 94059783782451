import React from 'react';

type MaybeProps = {
  test: boolean;
  children: React.ReactElement;
  elseRender?: React.ReactElement | null;
};

const Maybe = ({ children, test = true, elseRender = null }: MaybeProps) =>
  test ? children : elseRender;

// eslint-disable-next-line import/no-default-export
export default Maybe;
