import React, { Component } from 'react';

import classnames from 'classnames';
import trim from 'lodash/trim';
import { connect } from 'react-redux';

import { UtilitySegmentListIcon as SegmentSelectorIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { LegacyPopover } from '@peakon/components';
import { BaseAttributeAttributes } from '@peakon/shared/schemas/api/attributes';
import api from '@peakon/shared/utils/api';

import { currentContext } from '../../../selectors/ContextSelectors';
import { RootState } from '../../../types/redux';
import { SegmentBadge } from '../../SegmentBadge';
import { SegmentsPicker } from '../SegmentsPicker';

import styles from './styles.css';

type SegmentGroupResponse = {
  attributes: {
    segmentCount: number;
  };
  id: string;
  relationships: {
    attribute: {
      type: 'attributes';
      id: string;
      attributes: BaseAttributeAttributes;
    };
  };
};

const modifiers = [
  {
    name: 'preventOverflow',
    options: { tether: true, rootBoundary: 'viewport' },
  },

  {
    name: 'flip',
    options: {
      fallbackPlacements: ['auto'],
    },
  },
];

const SEGMENT_PARAMS = {
  fields: {
    segments: [
      'abbreviation',
      'attribute',
      'contextId',
      'direct',
      'employee',
      'employeeCount',
      'employeeCounts',
      'identifier',
      'name',
      'nameTranslated',
      'type',
    ].join(','),
    employees: 'identifier,account',
    accounts: 'email',
  },

  include: ['attribute', 'segment', 'employee', 'employee.account'].join(','),
};

type SegmentsPickerInputProps = {
  excluded?: boolean;
  excludedAttributeIds?: string[];
  included?: boolean;
  onRemove?: $TSFixMeFunction;
  onSelect?: $TSFixMeFunction;
  onToggle?: $TSFixMeFunction;
  optionOnly?: boolean;
  placeholder?: string;
  placement?: string;
  readOnly?: boolean;
  selected: $TSFixMe[];
  showAddAll?: boolean;
  single?: boolean;
  testId?: string;
  lockedAttributeId?: string;
  popperNode?: $TSFixMe;
  withoutTreeAttribute?: boolean;
} & ReturnType<
  // eslint-disable-next-line no-use-before-define
  typeof mapStateToProps
>;

type SegmentsPickerInputState = { open: boolean };

export class SegmentsPickerInput extends Component<
  SegmentsPickerInputProps,
  SegmentsPickerInputState
> {
  static defaultProps: Partial<SegmentsPickerInputProps> = {
    placement: 'bottom-start',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    selected: [] as Array<$TSFixMe>,
    testId: 'segments-picker-input',
  };

  constructor(props: SegmentsPickerInputProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const {
      excluded,
      excludedAttributeIds,
      included,
      onRemove,
      placeholder,
      placement,
      readOnly,
      selected,
      showAddAll,
      single,
      testId,
      lockedAttributeId,
      popperNode,
    } = this.props;

    const { open } = this.state;

    if (readOnly) {
      return (
        <div className={styles.segments}>
          {selected.map((segment) => (
            <div key={segment.id} className={styles.segment}>
              <SegmentBadge
                excluded={excluded}
                included={included}
                // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onClear={readOnly ? undefined : () => onRemove(segment.id)}
                segment={segment}
              />
            </div>
          ))}
        </div>
      );
    }

    return (
      <LegacyPopover
        arrow
        arrowClassName={styles.arrow}
        className={styles.popper}
        onClose={this.onClose}
        modifiers={modifiers}
        open={open}
        placement={placement}
        popperNode={popperNode ?? window.document.body}
        testId="segment-picker-popover"
        target={({ ref }) => (
          <UnstyledButton
            accessibleName={placeholder || ''}
            className={styles.root}
            data-test-id={testId}
            onClick={this.onToggle}
          >
            <div ref={ref} className={styles.button}>
              <SegmentSelectorIcon />
            </div>
            <div className={classnames('peak-form-text', styles.input)}>
              {selected.length === 0 ? (
                <div className={styles.placeholder}>{placeholder}</div>
              ) : (
                <div className={styles.segments}>
                  {selected.map((segment) => (
                    <div key={segment.id} className={styles.segment}>
                      <SegmentBadge
                        excluded={excluded}
                        included={included}
                        onClear={
                          onRemove ? () => onRemove(segment.id) : undefined
                        }
                        segment={segment}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </UnstyledButton>
        )}
        targetClassName={styles.target}
        tipSize={6}
        width={562}
      >
        <SegmentsPicker
          excludedAttributeIds={excludedAttributeIds}
          loadSegmentGroups={this.loadAttributes}
          loadSegments={this.loadSegments}
          onClose={this.onClose}
          // @ts-expect-error TS(2322): Type '(query: string) => Promise<any> | undefined'... Remove this comment to see the full error message
          onSearch={this.onSearch}
          onSelect={this.onSelect}
          selected={selected}
          single={single}
          showAddAll={showAddAll}
          hasSearch={!lockedAttributeId}
        />
      </LegacyPopover>
    );
  }

  onClose = () => {
    const { onToggle } = this.props;

    this.setState(
      {
        open: false,
      },
      () => {
        onToggle?.(false);
      },
    );
  };

  onToggle = () => {
    const { onToggle } = this.props;
    const { open } = this.state;
    const nextOpen = !open;

    this.setState(
      {
        open: nextOpen,
      },
      () => {
        onToggle?.(nextOpen);
      },
    );
  };

  loadAttributes = async () => {
    const { optionOnly, lockedAttributeId, withoutTreeAttribute } = this.props;

    const params: { include: string; filter?: { [k: string]: string } } = {
      include: 'attribute',
    };

    if (optionOnly) {
      params.filter = {
        type: 'option',
      };
    }

    if (withoutTreeAttribute) {
      params.filter = {
        type: 'tree$notIn',
      };
    }

    const response = await api.segment.getGroups(params);

    // FIXME: find a better way in the API to do this
    if (lockedAttributeId) {
      response.data = response.data.filter((a: SegmentGroupResponse) => {
        return a.id.replace('attributes/', '') === lockedAttributeId;
      });
    }

    return response;
  };

  loadSegments = (id: string, { perPage = 10 } = {}) => {
    if (id === 'link') {
      return this.loadChildSegments();
    }

    return api.segment.listByAttribute(id, {
      ...SEGMENT_PARAMS,
      per_page: perPage,
    });
  };

  loadChildSegments = () => {
    const { contextId } = this.props;

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return api.engagement.getLinks(contextId, {
      include: 'critical,segment,segment.attribute',
    });
  };

  onSearch = (query: string) => {
    const q = trim(query);

    if (!q) {
      return;
    }

    const { optionOnly, withoutTreeAttribute } = this.props;

    const params: { [k: string]: unknown } = {
      ...SEGMENT_PARAMS,
      q,
      sort: 'relevance',
      order: 'desc',
      per_page: 10,
      translated: true,
    };

    if (optionOnly) {
      params['filter[type]'] = 'option';
    }

    if (withoutTreeAttribute) {
      params.filter = {
        type: 'tree$notIn',
      };
    }

    return api.segment.list(params);
  };

  onSelect = (selected: $TSFixMe, { all }: { all?: boolean } = {}) => {
    const { onSelect, onToggle, single } = this.props;

    const nextOpen = !single && !all;

    this.setState(
      {
        open: nextOpen,
      },

      () => {
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onSelect(selected);
        onToggle?.(nextOpen);
      },
    );
  };
}

const mapStateToProps = (state: RootState) => {
  const dashboardContext = currentContext(state);

  return {
    contextId: dashboardContext ? dashboardContext.id : undefined,
  };
};

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(SegmentsPickerInput);
