import React, { forwardRef } from 'react';

import { Menu } from '@peakon/components';
import {
  AcknowledgementType,
  ACKNOWLEDGEMENT_TYPES,
} from '@peakon/shared/constants/acknowledgementTypes';

import Button from './Button';

import styles from './styles.css';

type ItemProps = {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  type: AcknowledgementType;
};

const Item = forwardRef<$TSFixMe, ItemProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ disabled, isLoading, type, className, isSelected, ...props }, ref) => {
    return (
      <li
        className={styles.item}
        {...props}
        ref={ref}
        data-test-id="ack-button"
      >
        <Button disabled={disabled} isLoading={isLoading} type={type} />
      </li>
    );
  },
);

Item.displayName = 'AcknowledgementMenuItem';

type AcknowledgementMenuProps = {
  activeType: AcknowledgementType | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const AcknowledgementMenu = ({
  activeType,
  onMouseEnter,
  onMouseLeave,
}: AcknowledgementMenuProps) => (
  <Menu.Popover
    compact
    placement="top-start"
    className={styles.root}
    testId="acknowledge-menu"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {ACKNOWLEDGEMENT_TYPES.map((type) => (
      <Menu.Item
        key={type}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<ItemProps & RefAtt... Remove this comment to see the full error message
        component={Item}
        type={type}
        value={type}
        disabled={Boolean(activeType) && activeType !== type}
        isLoading={activeType === type}
      />
    ))}
  </Menu.Popover>
);

// eslint-disable-next-line import/no-default-export
export default AcknowledgementMenu;
