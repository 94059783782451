import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Button } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

type ButtonProps = React.ComponentPropsWithoutRef<typeof Button>;

type Props = Omit<ButtonProps, 'variant' | 'children'> & {
  variant?: ButtonProps['variant'];
  children?: ButtonProps['children'];
};

/**
 * Thin wrapper around Bedrock's `Button` component.
 * Sets `type="submit"`, `variant="primary"`, and busy state by default.
 */
export function SubmitButton({
  children,
  variant = 'primary',
  ...rest
}: Props) {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const label = children ?? t('form__submit_button_label');

  return (
    <Button busy={isSubmitting} type="submit" variant={variant} {...rest}>
      {label}
    </Button>
  );
}
