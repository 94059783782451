// eslint-disable-next-line no-restricted-imports -- we need this in order to provide the helpers below
import i18n from 'i18next';
import moment from 'moment';

import * as localization from '@peakon/localization';
import { DEFAULT_LOCALE_ID } from '@peakon/localization';

import { queryClient } from '../../queries/queryClient';
import api from '../../utils/api';

const changeMomentLocale = async (localeId: string) => {
  if (localeId !== 'en') {
    await import(`moment/locale/${localeId}`);
  }

  moment.locale(localeId);
};

/**
 * Updates the language dependent services (api, moment,...) to the given locale.
 * NOTE: this does not change the language of the app.
 */
export const updateLanguageDependentServices = async (locale: {
  id: string;
  rtl: boolean;
  language: string;
  moment: string;
  messageFormat: string;
}) => {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  window.document.getElementsByTagName('html')[0].dir = locale.rtl
    ? 'rtl'
    : 'ltr';
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  window.document.getElementsByTagName('html')[0].lang = locale.language;

  await changeMomentLocale(locale.moment);

  api.setLocale(locale.id);
};

/**
 * Changes the language of the app and all language dependent services (api, moment,...).
 */
export const changeLanguage = async (localeId: string) => {
  const locale = getManagerScopedLocaleInfo(localeId);
  if (locale.id === getCurrentLocaleInfo().id) {
    return;
  }
  if (!locale) {
    throw new Error(`Locale ${localeId} is not supported`);
  }
  // updating the dependant services first as `i18n.changeLanguage` will cause a re-render of the app, and we want to make sure that the API and other dependant services have the updated language set before any data is refetched.
  await updateLanguageDependentServices(locale);
  await queryClient.invalidateQueries();
  // eslint-disable-next-line @peakon/peakon/no-i18next-change-language -- this is the one source of truth where we want to allow this call
  await i18n.changeLanguage(locale.id);
};

export const detectBrowserLocaleId = () => {
  const detectedLanguage = i18n.services.languageDetector.detect(['navigator']);
  const detectedLocaleId = Array.isArray(detectedLanguage)
    ? detectedLanguage[0]
    : detectedLanguage;

  return getManagerScopedLocaleInfo(detectedLocaleId).id; // run the detected locale through the localization library to ensure we only return supported locales
};

/**
 * Returns the locale info for manager languages, as defined in [@peakon/localization](https://github.com/peakon/localization)
 */
export function getManagerScopedLocaleInfo(locale: string) {
  return localization.resolve({ employee: true, full: true }, locale);
}

/**
 * Returns the locale info for employee, as defined in [@peakon/localization](https://github.com/peakon/localization)
 *
 * Warning: most of the dashboard should NOT use this function, since the dashboard is only translated in manager languages. Exceptions to this rule that might make sense: email preview, survey language dropdown, etc.
 */
export function getEmployeeScopedLocaleInfo(locale: string) {
  return localization.resolve({ employee: true }, locale);
}

/**
 * Returns the current locale info, as defined in [@peakon/localization](https://github.com/peakon/localization)
 */
export function getCurrentLocaleInfo() {
  return getManagerScopedLocaleInfo(i18n.resolvedLanguage ?? i18n.language);
}

/**
 * Returns the default locale info, as defined in [@peakon/localization](https://github.com/peakon/localization)
 */
export function getDefaultLocaleInfo() {
  return localization.resolve({}, DEFAULT_LOCALE_ID);
}
