import { assignWindowLocation } from '@peakon/shared/utils/window';

import getRedirectUrl from './getRedirectUrl';

// eslint-disable-next-line import/no-default-export
export default function redirectTo(url: string) {
  const redirectUrl = getRedirectUrl();
  assignWindowLocation(
    `${url}${
      redirectUrl ? `?redirectTo=${encodeURIComponent(redirectUrl)}` : ''
    }`,
  );
}
