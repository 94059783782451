import { createTranslationMap } from './createTranslationMap';
import { ValidLocaleIds } from './localesValidation';
import { TranslatedString, t } from './t';

export const getTranslatedLocaleNamesMap = () => {
  return createTranslationMap({
    en: t('locale__en'),
    'en-GB': t('locale__en'),
    'en-US': t('locale__en-US'),
    de: t('locale__de'),
    'zh-CN': t('locale__zh'),
    da: t('locale__da'),
    dv: t('locale__dv'),
    fr: t('locale__fr'),
    'fr-FR': t('locale__fr'),
    'fr-CA': t('locale__fr-CA'),
    es: t('locale__es'),
    is: t('locale__is'),
    it: t('locale__it'),
    pt: t('locale__pt'),
    'pt-PT': t('locale__pt'),
    'pt-BR': t('locale__pt-BR'),
    sv: t('locale__sv'),
    tr: t('locale__tr'),
    th: t('locale__th'),
    ko: t('locale__ko'),
    ru: t('locale__ru'),
    ar: t('locale__ar'),
    pl: t('locale__pl'),
    ca: t('locale__ca'),
    cs: t('locale__cs'),
    bg: t('locale__bg'),
    et: t('locale__et'),
    fi: t('locale__fi'),
    hr: t('locale__hr'),
    lt: t('locale__lt'),
    lv: t('locale__lv'),
    nl: t('locale__nl'),
    uk: t('locale__uk'),
    no: t('locale__no'),
    ro: t('locale__ro'),
    ur: t('locale__ur'),
    ms: t('locale__ms'),
    sk: t('locale__sk'),
    sl: t('locale__sl'),
    'sr-CS': t('locale__sr-CS'),
    lo: t('locale__lo'),
    kn: t('locale__kn'),
    hi: t('locale__hi'),
    tl: t('locale__tl'),
    zu: t('locale__zu'),
    ja: t('locale__ja'),
    'zh-TW': t('locale__zh_tw'),
    hu: t('locale__hu'),
    el: t('locale__el'),
    id: t('locale__id'),
    he: t('locale__he'),
    mn: t('locale__mn'),
    ml: t('locale__ml'),
    bn: t('locale__bn'),
    vi: t('locale__vi'),
    'es-LA': t('locale__es-LA'),
    ta: t('locale__ta'),
    te: t('locale__te'),
    az: t('locale__az'),
    xh: t('locale__xh'),
    'vls-BE': t('locale__vls_be'),
    mi: t('locale__mi'),
    my: t('locale__my'),
    mk: t('locale__mk'),
    fo: t('locale__fo'),
    'gu-IN': t('locale__gu-IN'),
    'pa-IN': t('locale__pa-IN'),
    mr: t('locale__mr'),
  } as const satisfies Record<ValidLocaleIds, TranslatedString>);
};
