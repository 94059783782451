import { List, OrderedSet } from 'immutable';

import createPagination from '../utils/createPagination';

const commentList = (
  state = List<string>(),
  action: $TSFixMe,
): List<string> => {
  switch (action.type) {
    case 'TOPIC_SEMANTIC_COMMENT_LIST_SUCCESS':
    case 'TOPIC_COMMENT_LIST_SUCCESS': {
      const { data } = action.data;

      // @ts-expect-error TS(7009): 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
      return new List(data.map((comment) => comment.id));
    }

    case 'TOPIC_COMMENT_APPEND_SUCCESS': {
      const { data } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return OrderedSet(
        // @ts-expect-error TS(7006): Parameter 'comment' implicitly has an 'any' type.
        state.concat(data.map((comment) => comment.id)),
      ).toList() as List<string>;
    }
    case 'COMMENT_DELETE_SUCCESS': {
      const { id } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.filter((comment) => comment !== id) as List<string>;
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default createPagination(commentList, {
  startTypes: ['TOPIC_COMMENT_LIST', 'TOPIC_SEMANTIC_COMMENT_LIST'],
  appendTypes: ['TOPIC_COMMENT_APPEND'],
  removeTypes: ['COMMENT_DELETE'],
  resetTypes: ['COMMENT_CLEAR_ALL'],
});
