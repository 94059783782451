import React, { Suspense, lazy } from 'react';

import { Spinner } from '@peakon/bedrock/react/spinner';

import ErrorBoundary from '../ErrorBoundary';

import styles from './styles.css';

const Loader = (props: $TSFixMe) => {
  return (
    <div className={styles.spinner}>
      <Spinner {...props} size="48" />
    </div>
  );
};

type LazyLoadProps = {
  component?: $TSFixMe;
  fallback?: $TSFixMe;
  loading?: $TSFixMe;
};

const LazyLoad = ({
  component: Component,
  fallback,
  loading,
  ...rest
}: LazyLoadProps) => {
  return (
    <ErrorBoundary fallback={fallback}>
      <Suspense fallback={loading}>
        <Component {...rest} />
      </Suspense>
    </ErrorBoundary>
  );
};

export const withLazy = (
  fn: $TSFixMe,
  { loading: LoaderComponent = Loader } = {},
) => {
  const LazyComponent = lazy(fn);

  // eslint-disable-next-line react/display-name
  return (props: $TSFixMe) => {
    return (
      <LazyLoad
        component={LazyComponent}
        loading={LoaderComponent && <LoaderComponent />}
        {...props}
      />
    );
  };
};
