import React from 'react';

import { Avatar } from '@peakon/components';
import { AnonymousAvatar } from '@peakon/shared/components/avatars/AnonymousAvatar/AnonymousAvatar';
import { getResizedImageUrl } from '@peakon/shared/utils';
import { getAbbreviationFromName } from '@peakon/shared/utils/getAbbreviation';

import { ConversationMessageProps } from './ConversationMessage';

type EmployeeAvatarProps = {
  anonymous: boolean;
  employee: ConversationMessageProps['message']['employee'];
};

export function EmployeeAvatar({ employee, anonymous }: EmployeeAvatarProps) {
  if (anonymous) {
    return <AnonymousAvatar size="conversation" />;
  }

  if (employee) {
    const employeeAvatarSrc =
      employee.avatar &&
      getResizedImageUrl({
        src: employee.avatar,
        fit: 'crop',
        height: 70,
        width: 70,
      });

    return (
      <div>
        <Avatar
          abbreviation={getAbbreviationFromName(employee.name)}
          alt={employee.name}
          src={employeeAvatarSrc || undefined}
          rounded
          aria-hidden
        />
      </div>
    );
  }

  return null;
}
