import React from 'react';

import { Button } from '@peakon/bedrock/react/button';
import { SlideIn } from '@peakon/bedrock/react/dialog';
import { Divider as BRDivider } from '@peakon/bedrock/react/divider';
import { Box, Inline, Stack } from '@peakon/bedrock/react/layout';
import { BodyText } from '@peakon/bedrock/react/typography';
import { Avatar, Placeholder } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { getAbbreviation } from '@peakon/shared/utils';

import styles from './ManagerSlideIn.css';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  managers?: Array<{
    id?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    avatar?: string;
    email?: string;
    identifier?: string;
  }>;
};

export function ManagerSlideIn({
  isOpen,
  isLoading,
  onDismiss,
  managers,
}: Props) {
  const showLoadingState = isLoading && !managers;
  const showNoResults = !isLoading && !managers?.length;
  const hierarchyListItemTranslations = [
    t('comment_manager_list__faq_access_criteria_settings'),
    t('comment_manager_list__faq_access_criteria_relation'),
    t('comment_manager_list__faq_access_criteria_visibility'),
    t('comment_manager_list__faq_access_criteria_survey_round'),
  ];

  return (
    <SlideIn
      open={isOpen}
      closeLabel={t('common__close')}
      onDismiss={onDismiss}
      heading={t('comment_manager_list__header')}
    >
      <SlideIn.TabList>
        <SlideIn.Tab name="managers">
          {t('comment_manager_list__header')}
        </SlideIn.Tab>
        <SlideIn.Tab name="manager-hierarchy">
          {t('comment_manager_list__faq_header')}
        </SlideIn.Tab>
      </SlideIn.TabList>

      <SlideIn.Content>
        <SlideIn.TabPanel controlledBy="managers">
          <Stack spacing={16}>
            <BodyText>{t('comment_manager_list__description')}</BodyText>
            <Divider />
            {showLoadingState && <EmployeeListLoadingPlaceholder />}
            {showNoResults && <BodyText>{t('common__no_result')}</BodyText>}
            {managers?.map((employee, index) => (
              <Stack key={employee.id ?? index} spacing={16}>
                <EmployeeListItem
                  name={employee.name}
                  avatar={employee.avatar}
                  abbreviation={getAbbreviation(
                    employee.firstName,
                    employee.lastName,
                  )}
                  identifier={
                    employee.email ??
                    (employee.identifier ? `(${employee.identifier})` : '')
                  }
                />
                <Divider />
              </Stack>
            ))}
          </Stack>
        </SlideIn.TabPanel>
        <SlideIn.TabPanel controlledBy="manager-hierarchy">
          <BodyText>{t('comment_manager_list__faq_description')}</BodyText>
          <ul>
            {hierarchyListItemTranslations.map((translation, index) => (
              <li key={index}>
                <Box paddingBlockStart={16}>
                  <BodyText>{translation}</BodyText>
                </Box>
              </li>
            ))}
          </ul>
        </SlideIn.TabPanel>
      </SlideIn.Content>

      <SlideIn.Actions>
        <Button variant="secondary" onClick={onDismiss}>
          {t('common__close')}
        </Button>
      </SlideIn.Actions>
    </SlideIn>
  );
}

const Divider = () => (
  <div className={styles.divider}>
    <BRDivider />
  </div>
);

type EmployeeListItemProps = {
  name?: string;
  abbreviation?: string;
  avatar?: string;
  identifier?: string;
};

function EmployeeListItem(props: EmployeeListItemProps) {
  const { name, avatar, identifier, abbreviation } = props;

  return (
    <Inline spacing={16} alignBlock="center">
      <Avatar rounded abbreviation={abbreviation} src={avatar} />
      <Stack>
        <BodyText bold>{name}</BodyText>
        <BodyText variant="hint" size="small">
          {identifier}
        </BodyText>
      </Stack>
    </Inline>
  );
}

function EmployeeListLoadingPlaceholder() {
  return (
    <Stack spacing={16}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Stack key={index} spacing={16}>
          <Inline spacing={16} alignBlock="center">
            <Placeholder type="round" width={30} height={30} />
            <Stack spacing={4}>
              <Placeholder width={150} height={14} />
              <Placeholder width={30} height={13} />
            </Stack>
          </Inline>
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
}
