import { Record, List } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  list: z.any(),
  isLoading: z.any(),
  items: z.any(),
  self: z.any(),
  nextUrl: z.any(),
  total: z.any(),
});
type Schema = z.infer<typeof schema>;

class Pagination<TEntity = $TSFixMe>
  extends Record({
    id: undefined,
    self: 0,
    items: List(),
    isLoading: true,
    nextUrl: undefined,
    total: 0,
  })
  implements Schema
{
  id!: Schema['id'];
  isLoading?: boolean;
  items!: List<TEntity>;
  nextUrl: $TSFixMe;
  self: $TSFixMe;
  total: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Pagination',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'Pagination',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get loadedCount() {
    return this.items.size;
  }

  isInitialLoad() {
    return this.isLoading && this.self === 0;
  }

  isEmpty() {
    return this.items.isEmpty();
  }

  isLoadingMore() {
    return this.isLoading && this.self > 0;
  }

  hasNext() {
    return typeof this.nextUrl !== 'undefined';
  }
}

// eslint-disable-next-line import/no-default-export
export default Pagination;
