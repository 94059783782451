import { List } from 'immutable';
import isFinite from 'lodash/isFinite';
import { v4 as uuidv4 } from 'uuid';

import { AttributeEditor, AttributeRange, Translation } from '@peakon/records';

const id = uuidv4();

const attributeEditor = (
  state = new AttributeEditor(),
  action: $TSFixMe,
): AttributeEditor => {
  switch (action.type) {
    case 'ATTRIBUTE_EDITOR_START': {
      const { attribute } = action.data;

      return new AttributeEditor({
        original: attribute,
        current: attribute,
      });
    }

    case 'ATTRIBUTE_EDITOR_CREATE_START': {
      return new AttributeEditor();
    }

    case 'ATTRIBUTE_EDITOR_STOP': {
      return new AttributeEditor();
    }

    case 'ATTRIBUTE_EDITOR_UPDATE_FIELD': {
      const { field, value } = action.data;

      const currentEmployeeAccess = state.getIn(['current', 'employeeAccess']);
      const currentComparisonAccess = state.getIn([
        'current',
        'comparisonAccess',
      ]);

      // unrestricted access is not possible when requiring consent
      if (field === 'sensitive' && value) {
        return state
          .setIn(
            ['current', 'employeeAccess'],
            currentEmployeeAccess === 'unrestricted'
              ? 'restricted'
              : currentEmployeeAccess,
          )
          .setIn(
            ['current', 'comparisonAccess'],
            currentComparisonAccess === 'unrestricted'
              ? 'restricted'
              : currentComparisonAccess,
          )
          .setIn(['current', field], value);
      }

      return state.setIn(['current', field], value);
    }

    case 'ATTRIBUTE_EDITOR_UPDATE_LINKS': {
      const { level, value } = action.data;

      return state.setIn(['current', 'links', level], value);
    }

    case 'ATTRIBUTE_EDITOR_CLEAR_LINKS': {
      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.deleteIn(['current', 'links']);
    }

    case 'ATTRIBUTE_EDITOR_UPDATE_TRANSLATION': {
      const { autoFocus, locale, text, type } = action.data;

      const selector = ['current', `${type}Translations`, locale];

      if (state.hasIn(selector)) {
        // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
        return state.updateIn(selector, (translation) =>
          translation.set('translation', text),
        );
      }

      return state.setIn(
        selector,
        new Translation({
          locale,
          translation: text,
          autoFocus,
        }),
      );
    }

    case 'ATTRIBUTE_EDITOR_REMOVE_TRANSLATION': {
      const { locale, type } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.removeIn(['current', `${type}Translations`, locale]);
    }

    case 'ATTRIBUTE_EDITOR_ADD_ALIAS': {
      const { alias } = action.data;

      if (state.getIn(['current', 'aliases']).includes(alias)) {
        return state;
      }

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'aliases'], (aliases) =>
        aliases.push(alias),
      );
    }

    case 'ATTRIBUTE_EDITOR_REMOVE_ALIAS': {
      const { alias } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'aliases'], (aliases) =>
        // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
        aliases.filter((item) => item !== alias),
      );
    }

    case 'ATTRIBUTE_EDITOR_START_RANGE': {
      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'ranges'], (ranges) =>
        ranges
          // @ts-expect-error TS(7006): Parameter 'range' implicitly has an 'any' type.
          .map((range) => range.set('autoFocus', false))
          .splice(
            0,
            0,
            new AttributeRange({ id, autoFocus: true, from: null }),
          ),
      );
    }

    case 'ATTRIBUTE_EDITOR_CLEAR_RANGES': {
      return state.setIn(['current', 'ranges'], List());
    }

    case 'ATTRIBUTE_EDITOR_ADD_RANGE': {
      const { index, range } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'ranges'], (ranges) =>
        ranges
          // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
          .map((item) => item.set('autoFocus', false))
          .splice(index, 0, new AttributeRange(range)),
      );
    }

    case 'ATTRIBUTE_EDITOR_UPDATE_RANGE': {
      const { index, to } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'ranges', index], (range) =>
        range.set('to', to),
      );
    }

    case 'ATTRIBUTE_EDITOR_REMOVE_RANGE': {
      const { index } = action.data;

      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'ranges'], (ranges) =>
        ranges.delete(index),
      );
    }

    case 'ATTRIBUTE_EDITOR_RANGES_CHANGED': {
      // @ts-expect-error TS(2322): Type 'Map<string, any>' is not assignable to type ... Remove this comment to see the full error message
      return state.updateIn(['current', 'ranges'], (ranges) => {
        // cascade range to changes, next range 'from' should equal previous range 'to'
        // @ts-expect-error TS(7006): Parameter 'range' implicitly has an 'any' type.
        ranges.forEach((range, index) => {
          const nextIndex = index + 1;

          if (ranges.get(nextIndex)) {
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            ranges = ranges.setIn([nextIndex, 'from'], range.to);
          }
        });

        // @ts-expect-error TS(7006): Parameter 'range' implicitly has an 'any' type.
        return ranges.map((range, index) => {
          let isInvalid;

          if (index === 0) {
            isInvalid = range.from !== null || !isFinite(range.to);
          } else if (index === ranges.size - 1) {
            isInvalid = !isFinite(range.from);
          } else {
            isInvalid =
              !isFinite(range.from) ||
              !isFinite(range.to) ||
              ranges.some(
                (r: $TSFixMe, i: $TSFixMe) =>
                  i < index && (r.to > range.from || r.to >= range.to),
              );
          }

          return range.set('invalid', isInvalid);
        });
      });
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default attributeEditor;
