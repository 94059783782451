import { Record, fromJS, List } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ActionItemRecord from '../ActionItemRecord';
import CategoryRecord from '../CategoryRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  items: z.any(),
  categoryGroup: z.any(),
  notes: z.string(),
  driver: z.any(),
  text: z.string(),
  category: z.any(),
  group: z.any(),
  // not on current types
  subdriver: z.any(),

  // probably a typo somewhere
  categoryid: z.string().optional(),
  categoryId: z.string().optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class StandardActionRecord
  extends Record({
    id: undefined,
    driver: undefined,
    group: undefined,
    category: undefined,
    categoryId: undefined,
    categoryGroup: undefined,
    title: undefined,
    text: undefined,
    notes: undefined,
    contentType: 'action',

    items: undefined,
  })
  implements Schema
{
  id!: Schema['id'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'StandardActionRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'StandardActionRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const { id, attributes } = data;

    const category = get(data, 'relationships.category');
    const items = get(data, 'relationships.items');

    return new StandardActionRecord(
      fromJS({
        id,
        items: items
          ? List(items.map(ActionItemRecord.createFromApi))
          : undefined,
        ...attributes,
        categoryId: category ? category.id : undefined,
        category: category ? CategoryRecord.createFromApi(category) : undefined,
      }),
    );
  }
}
