import React, { useState } from 'react';

import { List as ImmutableList } from 'immutable';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { Spinner } from '@peakon/bedrock/react/spinner';
import Employee from '@peakon/records/EmployeeRecord';
import { t } from '@peakon/shared/features/i18next/t';

import { ManagerInfo } from './ManagerInfo';
import { EmployeeInfo } from '../employees/EmployeeInfo';

import styles from './styles.css';

export type EmployeeListProps = {
  isLoading?: boolean;
  totalEmployees?: number;
  employees?: ImmutableList<Employee>;
  noResultsMessage?: string;
  isSegmentManager?: boolean;
  loadMore?: () => void;
  onManagerRemove?: (id: string) => Promise<void>;
  hasEmployeeAccess?: boolean;
  customScrollParentRef?: HTMLDivElement | null;
};

export const EmployeeList = ({
  isLoading,
  totalEmployees,
  employees = ImmutableList(),
  noResultsMessage,
  isSegmentManager,
  loadMore,
  onManagerRemove,
  hasEmployeeAccess,
  customScrollParentRef,
}: EmployeeListProps) => {
  // this is trick to force rendering of extra items at the beginning to please tests which expects dom items being rendered already
  // we set this to 0 once we start scrolling to render only visible items
  const [increasedViewportInPx, setIncreasedViewportInPx] = useState(8000);
  const [isEndOfList, setIsEndOfList] = useState(false);

  const isRowLoaded = (index: number) => {
    if (index === totalEmployees) {
      setIsEndOfList(true);
    }
    // Every page is loaded except the loading indicator
    return index < employees.size;
  };

  const handleLoadMore = () => {
    // return early if we're already in a loading state
    if (isLoading) {
      return;
    }

    if (employees.size === totalEmployees) {
      return;
    }

    loadMore?.();
  };

  const rowRenderer = (index: number, employeeData: $TSFixMe) => {
    const employee = isSegmentManager ? employeeData.employee : employeeData;

    const employeeInfo = isSegmentManager ? (
      <ManagerInfo
        automatic={employeeData.automatic}
        employee={employee}
        level={employeeData.level}
        onRemove={onManagerRemove}
      />
    ) : (
      <EmployeeInfo
        key={index}
        employee={employee}
        modifier="slideIn"
        size="medium"
      />
    );

    if (!isRowLoaded(index)) {
      return (
        <div key={index} className={styles.loader}>
          <Spinner />
        </div>
      );
    }

    if (isSegmentManager || !hasEmployeeAccess || employee.isAnonymized) {
      return (
        <div key={index} className={styles.row} role="row">
          {employeeInfo}
        </div>
      );
    }

    const to = `/admin/employees/${employee.id}`;

    return (
      <Link key={index} to={to} className={styles.row}>
        {employeeInfo}
      </Link>
    );
  };

  if (employees.isEmpty() && isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  if (totalEmployees === 0) {
    return (
      <div className={styles.empty}>
        {noResultsMessage || t('employees__search__no_result')}
      </div>
    );
  }

  return (
    <Virtuoso
      customScrollParent={customScrollParentRef ?? undefined}
      context={{ isEndOfList }}
      data={employees.toArray()}
      increaseViewportBy={{ top: 0, bottom: increasedViewportInPx }}
      itemContent={(index, data) => {
        return rowRenderer(index, data);
      }}
      endReached={(index) => {
        if (!isRowLoaded(index + 1)) {
          handleLoadMore();
        }
      }}
      isScrolling={(isScrolling) => {
        if (isScrolling && increasedViewportInPx !== 0) {
          setIncreasedViewportInPx(0);
        }
      }}
      // @ts-expect-error TS(2322): Type '({ context }: FooterProps) => false | React.... Remove this comment to see the full error message
      components={{ Footer }}
    />
  );
};

type FooterProps = {
  context: {
    isEndOfList: boolean;
  };
};

const Footer = ({ context }: FooterProps) => {
  return (
    !context.isEndOfList && (
      <div className={styles.listLoader}>
        <Spinner />
      </div>
    )
  );
};
