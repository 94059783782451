import io from 'socket.io-client';

// eslint-disable-next-line import/no-default-export
export default class RealtimeSocket {
  socket;

  constructor() {
    this.socket = io({
      path: '/realtime/socket.io/',
      autoConnect: false,
      // Try WebSocket first, if available. This bypasses socket.io's conservative default of
      // first doing a "handshake" with the polling transport, then creating the WebSocket-based
      // connection separately.
      // This way we'll only open one WebSocket connection to the realtime service
      // in the happy case where WebSockets are supported by both the client and intermediate
      // proxies, and avoid 4-5 extra requests for establishing the polling transport and
      // the initial joinChannel commands the application emits before the WebSocket is available.
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionDelay: 30000,
      randomizationFactor: 0.6,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: Infinity,
      withCredentials: true,
    });

    this.socket.on('connect_error', () => {
      if (this.socket.io.opts.transports?.[0] === 'websocket') {
        // Based on snippet from: https://socket.io/docs/v4/client-options/#transports
        // We failed to connect with the WebSocket transport, revert to polling mode with upgrade.
        this.socket.io.opts.transports = ['polling', 'websocket'];
        // We need to explicitly connect again because we've disabled autoConnect:
        this.socket.connect();
      }
    });

    this.socket.on('connect', () => {
      this.socket.connected = true;
    });

    this.socket.on('disconnect', (reason) => {
      this.socket.connected = false;
      // eslint-disable-next-line no-console
      console.log(reason);
    });
  }

  getSocket() {
    return this.socket;
  }
}
