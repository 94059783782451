import { combineReducers } from '@reduxjs/toolkit';

import blacklist from './blacklist';
import commentPagination from './comments';
import groups from './groups';
import locales from './locales';
import observations from './observations';
import topics from './topics';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  comments: commentPagination,
  blacklist,
  groups,
  locales,
  observations,
  topics,
});
