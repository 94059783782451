import React from 'react';

import { Switch, Route, useLocation } from 'react-router-dom';

import '@peakon/bedrock/css/grid/index.css';
import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';
import { validatedEnvConfig } from '@peakon/shared/utils/env';

import { withLazy } from '../../components/LazyLoaded';
import { RestrictedRoute } from '../../components/RestrictedRoute';
import { INTEGRATION_FACTORY } from '../../data/integrations';
import Dashboard from '../DashboardContainer';
import { OAuthComplete } from '../Integrations/OAuthComplete/OAuthComplete';

const AccountRoutes = withLazy(() =>
  import('../account/AccountRoutes').then((m) => ({
    default: m.AccountRoutes,
  })),
);
const AttributesRoute = withLazy(() =>
  import('../administration/routes/AttributesRoute/AttributesRoute').then(
    (m) => ({
      default: m.AttributesRoute,
    }),
  ),
);
const Actions = withLazy(() => import('../Actions'));
const AccessControl = withLazy(() =>
  import('../administration/routes/accessControl/AccessControlRoutes').then(
    (m) => ({
      default: m.AccessControlRoutes,
    }),
  ),
);
const OverviewRoute = withLazy(() =>
  import('../administration/routes/OverviewRoute/OverviewRoute').then((m) => ({
    default: m.OverviewRoute,
  })),
);
const CustomSenderDomain = withLazy(() => import('../CustomSenderDomain'));
const EmployeesRoutes = withLazy(() =>
  import('../administration/routes/employees/EmployeesRoutes').then((m) => ({
    default: m.EmployeesRoutes,
  })),
);
const Improve = withLazy(() =>
  import('../improve/ImproveRoutes').then((m) => ({
    default: m.ImproveRoutes,
  })),
);
const AdminImprove = withLazy(() =>
  import('../administration/routes/improve/ImproveRoutes').then((m) => ({
    default: m.AdminImproveRoutes,
  })),
);
const CompanyRoutes = withLazy(() =>
  import('../administration/routes/company/CompanyRoutes').then((m) => ({
    default: m.CompanyRoutes,
  })),
);
const Integrations = withLazy(() => import('../Integrations/Integrations'));
const Integration = withLazy(() => import('../Integration/Integration'));
const Questions = withLazy(() =>
  import('../administration/routes/questions/QuestionsRoutes').then((m) => ({
    default: m.QuestionsRoutes,
  })),
);
const ExternalMetricsAdmin = withLazy(() =>
  import('../administration/routes/impact/ImpactRoutes').then((m) => ({
    default: m.ImpactRoutes,
  })),
);
const Branding = withLazy(() =>
  import('../administration/routes/branding/BrandingRoutes').then((m) => ({
    default: m.BrandingRoutes,
  })),
);
const SchedulesRoutes = withLazy(() =>
  import('../administration/routes/schedules/SchedulesRoutes').then((m) => ({
    default: m.SchedulesRoutes,
  })),
);
const ValuesRoute = withLazy(() =>
  import('../administration/routes/ValuesRoute/ValuesRoute').then((m) => ({
    default: m.ValuesRoute,
  })),
);
const DataSettingsRoutes = withLazy(() =>
  import('../administration/routes/dataSettings/DataSettingsRoutes').then(
    (m) => ({ default: m.DataSettingsRoutes }),
  ),
);

export const Routes = () => {
  const location = useLocation();

  return (
    <Switch>
      <RestrictedRoute
        path="/oauth/slack/complete"
        render={(props) => <OAuthComplete {...props} service="slack" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/microsoft-teams/start"
        component={() => {
          if (validatedEnvConfig.microsoftTeams.clientId) {
            window.location.href = INTEGRATION_FACTORY[
              'microsoft-teams'
            ].getConnectUrl(
              { clientId: validatedEnvConfig.microsoftTeams.clientId },
              location,
            );
          }
          return null;
        }}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/oauth/microsoft-teams/complete"
        render={(props) => (
          <OAuthComplete {...props} service="microsoft-teams" />
        )}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/oauth/bamboohr/complete"
        render={(props) => <OAuthComplete {...props} service="bamboohr" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/workday/complete"
        render={(props) => <OAuthComplete {...props} service="workday" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/company/custom-domain"
        component={CustomSenderDomain}
        rights={['email:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/company"
        component={CompanyRoutes}
        rights={['company:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/:integration/start"
        component={Integration}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/apps"
        component={Integrations}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/:integration"
        component={Integration}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations"
        component={Integrations}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/attributes*"
        rights={['attribute:admin', 'segment:admin']}
        sessionRight={true}
        component={AttributesRoute}
      />

      <RestrictedRoute
        path="/admin/employees*"
        rights={[
          'employee:read:all',
          'employee:read:manager',
          'employee:read:context',
        ]}
        sessionRight={true}
        component={EmployeesRoutes}
      />

      <RestrictedRoute
        addOn={['actions', 'engagement']}
        path="/actions*"
        rights={['action:admin', 'action:read']}
        component={Actions}
      />

      <RestrictedRoute
        exact
        path="/admin"
        rights={[
          'employee:read',
          'attribute:admin',
          'segment:admin',
          'group:admin',
          'application:admin',
          'company:admin',
          'schedule:admin',
          'question:admin',
          'value:admin',
          'sensitive:admin',
        ]}
        sessionRight={true}
        component={OverviewRoute}
      />

      <Route path="/admin/external_metrics*" component={ExternalMetricsAdmin} />

      <RestrictedRoute
        path="/admin/branding*"
        rights={['branding:admin']}
        addOn="survey_multi_brand"
        component={Branding}
      />

      <RestrictedRoute
        path="/admin/data-settings/*"
        rights={['company:admin', 'sensitive:admin']}
        sessionRight={true}
        component={DataSettingsRoutes}
      />

      <RestrictedRoute
        path="/admin/questions*"
        rights={['question:admin']}
        component={Questions}
      />

      <RestrictedRoute
        path="/admin/values"
        rights={['value:admin']}
        sessionRight={true}
        component={ValuesRoute}
      />

      <RestrictedRoute
        path={[
          '/admin/access/(groups|specialist)',
          '/admin/access/(groups|specialist)/:id',
        ]}
        rights={['group:admin']}
        sessionRight={true}
        component={AccessControl}
      />

      <RestrictedRoute
        path="/admin/schedules*"
        rights={['schedule:read']}
        sessionRight={true}
        component={SchedulesRoutes}
      />

      <Route path="/account*" component={AccountRoutes} />
      <Route path="/admin/improve*" component={AdminImprove} />
      <Route path={['/improve*', '/courses*']} component={Improve} />
      <Route path="/dashboard*" component={Dashboard} />
      <Route component={NotFoundScreen} />
    </Switch>
  );
};
