import React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';

import { Avatar } from '@peakon/components';

import { AttributeBadge } from '../../attribute/AttributeBadge';

import styles from './styles.css';

type Props = {
  context: $TSFixMe;
  className?: string;
  modifier?:
    | 'action'
    | 'actionTitle'
    | 'list'
    | 'notification'
    | 'slideIn'
    | 'tooltip';
  size?: 'tiny' | 'small';
  fontSize?: 'default' | 'large';
  type?: string;
  badgeType?: 'light' | 'noBorder';
  avatarSize?: 'tiny' | 'small' | 'medium-small' | 'medium' | 'large';
  count?: React.ReactNode;
};

const ContextLabel = ({
  avatarSize,
  badgeType = 'light',
  className,
  context,
  count,
  fontSize,
  modifier,
  size = 'small',
  type,
}: Props) => {
  const name = context.nameTranslated || context.name;
  const attribute = get(context, 'attribute') || {};
  const attributeName = attribute.nameTranslated || attribute.name;

  const title = attributeName ? `${name} (${attributeName}) ` : name;

  const isCompany = context.level === 'company';
  const isEmployeeAttribute = attribute.type === 'employee';

  return (
    <div
      className={classnames(styles.root, {
        // @ts-expect-error - Type 'undefined' cannot be used as an index type.
        [styles[fontSize]]: fontSize,
        // @ts-expect-error - Type 'undefined' cannot be used as an index type.
        [styles[modifier]]: modifier,
        [styles[size]]: size,
      })}
      title={title}
    >
      <div
        className={classnames(styles.avatar, className, {
          [styles.badge]: modifier === 'notification' && !isCompany,
        })}
      >
        <Avatar
          abbreviation={context.abbreviation}
          gradient={isCompany || !isEmployeeAttribute}
          icon={
            !isCompany &&
            (attribute.type === 'date' || attribute.type === 'number')
              ? context.attribute.type
              : undefined
          }
          rounded={isEmployeeAttribute}
          src={context.logo}
          size={avatarSize || size}
          // @ts-expect-error - Type 'string | undefined' is not assignable to type 'AvatarType | undefined'.
          type={type}
        />
      </div>
      <div className={styles.footer}>
        <span className={styles.name}>{name}</span>
        {count && <span className={styles.count}>{count}</span>}
        {!isCompany && (
          <div className={styles.badge}>
            <AttributeBadge
              attribute={context.attribute}
              direct={context.direct}
              type={badgeType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ContextLabel;
