import { Map } from 'immutable';
import isEmpty from 'lodash/isEmpty';

import CourseRecord from '@peakon/records/improve/CourseRecord';

type InitialState = {
  isLoading?: boolean;
  featuredCourse?: CourseRecord;
  course?: CourseRecord;
  directToSummary?: boolean;
};

const initialState: InitialState = {
  isLoading: true,
  featuredCourse: undefined,
  course: undefined,
  directToSummary: false,
};

const getInitialState = () => Map(initialState);

const courseState = (state = getInitialState(), action: $TSFixMe) => {
  switch (action.type) {
    case 'COURSE_FEATURED_READ_LOADING': {
      return state.merge({
        isLoading: true,
      });
    }
    case 'COURSE_READ_SUCCESS': {
      return state.merge({
        isLoading: false,
        course: CourseRecord.createFromApi(action.data.data),
      });
    }
    case 'COURSE_FEATURED_READ_FAILED':
    case 'COURSE_READ_FAILED': {
      return state.merge({
        isLoading: false,
      });
    }
    case 'COURSE_PROGRESS_SUMMARY': {
      const { status } = action.data;

      return state.merge({
        directToSummary: status,
      });
    }
    case 'COURSE_FEATURED_READ_SUCCESS': {
      const nextState: InitialState = {
        isLoading: false,
      };

      if (isEmpty(action.data.data)) {
        nextState.featuredCourse = undefined;
      } else {
        const [course] = action.data.data;
        nextState.featuredCourse = CourseRecord.createFromApi(course);
      }

      return state.merge(nextState);
    }
    case 'COURSE_FEATURED_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default courseState;
