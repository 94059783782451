import React from 'react';

import { AcknowledgementIcon } from '@peakon/shared/components/AcknowledgementIcon';
import {
  ACKNOWLEDGEMENT_TYPES,
  AcknowledgementType,
} from '@peakon/shared/constants/acknowledgementTypes';

import Tooltip from './Tooltip';

import styles from './styles.css';

type Props = {
  counts: Partial<Record<AcknowledgementType, number>>;
};

const AcknowledgementCounts = ({ counts }: Props) => (
  <div className={styles.root}>
    <div className={styles.icons}>
      {ACKNOWLEDGEMENT_TYPES.filter(
        (acknowledgmentType) => acknowledgmentType in counts,
      ).map((type) => (
        <div key={type} className={styles.icon}>
          <AcknowledgementIcon size="tiny" type={type} />
        </div>
      ))}
    </div>
    <Tooltip counts={counts} />
  </div>
);

// eslint-disable-next-line import/no-default-export
export default AcknowledgementCounts;
