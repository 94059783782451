import React, { useState } from 'react';

import { NavigationBasicNavigationMoreVerticalIcon as MoreVertIcon } from '@peakon/bedrock/icons/system';
import { Spinner, Menu } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  isSensitive?: boolean;
  onDelete?: () => void;
  onMarkAsSensitive?: () => void;
};

export const CommentDropdown = ({
  isSensitive,
  onDelete,
  onMarkAsSensitive,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMarkAsSensitive = async (
    e: React.MouseEvent<React.ReactNode>,
  ) => {
    e.preventDefault();

    setLoading(true);

    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    await onMarkAsSensitive();

    setLoading(false);
    setOpen(false);
  };

  if (!onDelete && !onMarkAsSensitive) {
    return null;
  }

  return (
    <div>
      <Menu
        isOpen={open}
        onStateChange={(change) => {
          if (change.hasOwnProperty('isOpen')) {
            // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
            setOpen(change.isOpen);
          }
        }}
      >
        <Menu.Target>
          <div
            className={styles.target}
            aria-label={
              open ? t('a11y_appmenu__expanded') : t('a11y_appmenu__collapsed')
            }
          >
            <MoreVertIcon data-test-id="comment-dropdown-target" />
          </div>
        </Menu.Target>
        <Menu.Popover placement="bottom-end">
          {onMarkAsSensitive && (
            <Menu.Item
              testId="comment-mark-sensitive"
              className={styles.option}
              // @ts-expect-error React-18 Type 'DetailedHTMLFactory<WebViewHTMLAttributes<HTMLWebViewElement>, HTMLWebViewElement>' is not assignable to type 'ReactNode'.ts(2322)
              onClick={handleMarkAsSensitive}
              value="sensitive"
            >
              {loading ? (
                <Spinner color="primary" size="small" />
              ) : isSensitive ? (
                t('sensitive_comments_mark_not_sensitive')
              ) : (
                t('sensitive_comments_mark_sensitive')
              )}
            </Menu.Item>
          )}
          {onDelete && (
            <Menu.Item
              // @ts-expect-error TS(2322): Type '{ children: string & BRAND<"TranslatedString... Remove this comment to see the full error message
              testId="comment-delete"
              className={styles.option}
              onClick={onDelete}
              value="delete"
            >
              {t('delete')}
            </Menu.Item>
          )}
        </Menu.Popover>
      </Menu>
    </div>
  );
};
