import { combineReducers } from '@reduxjs/toolkit';
import { List, Map, OrderedMap } from 'immutable';

import { Comment, Employee } from '@peakon/records';
import Acknowledgement from '@peakon/records/AcknowledgementRecord';

import overview from './overview';
import questions from './questions';
import unread from './unread';

const getInitialState = () => OrderedMap<string, Comment>();

const comments = (
  state = getInitialState(),
  action: $TSFixMe,
): OrderedMap<string, Comment> => {
  switch (action.type) {
    case 'COMMENT_CLEAR_ALL': {
      return OrderedMap<string, Comment>();
    }

    case 'COMMENTS_OVERVIEW_LIST_SUCCESS':
    case 'TOPIC_SEMANTIC_COMMENT_LIST_SUCCESS':
    case 'TOPIC_COMMENT_LIST_SUCCESS': {
      const { data } = action.data;

      return OrderedMap(
        // @ts-expect-error TS(7006): Parameter 'comment' implicitly has an 'any' type.
        data.map(Comment.createFromApi).map((comment) => [comment.id, comment]),
      );
    }

    case 'COMMENTS_OVERVIEW_LIST_APPEND_SUCCESS':
    case 'TOPIC_COMMENT_APPEND_SUCCESS':
    case 'COMMENT_LIST_SUCCESS': {
      const { data } = action.data;

      return state.merge(
        // @ts-expect-error TS(7006): Parameter 'comment' implicitly has an 'any' type.
        data.map(Comment.createFromApi).map((comment) => [comment.id, comment]),
      );
    }

    case 'COMMENT_MANAGERS_LIST_LOADING': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.set('isLoadingManagers', true),
      );
    }

    case 'COMMENT_MANAGERS_LIST_SUCCESS': {
      const { id, data } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.merge({
          isLoadingManagers: false,
          managers: List(data.map(Employee.createFromApi)),
        }),
      );
    }

    case 'COMMENT_DELETE_SUCCESS': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.delete(id);
    }

    case 'COMMENT_GET_SUCCESS': {
      const { data } = action.data;

      const comment = Comment.createFromApi(data);

      return OrderedMap([[comment.id, comment]]);
    }

    case 'COMMENT_ACKNOWLEDGE_SUCCESS': {
      const { id, data } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.merge({
          acknowledgement: Acknowledgement.createFromApi(
            data.relationships.acknowledgement,
          ),
          acknowledgementCounts: Map(data.attributes.acknowledgementCounts),
        }),
      );
    }

    case 'COMMENT_TRANSLATE_SUCCESS': {
      const { id, translation } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.merge({
          translation,
          translated: true,
        }),
      );
    }

    case 'COMMENT_REVERT_TRANSLATE': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.set('translated', false),
      );
    }

    case 'COMMENT_MARK_SENSITIVE_SUCCESS': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) => comment.set('sensitive', true));
    }

    case 'COMMENT_MARK_NOT_SENSITIVE_SUCCESS': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) => comment.set('sensitive', false));
    }

    case 'COMMENTS_CONVERSATION_MESSAGE_ADDED': {
      const { id } = action.data;

      if (!state.has(id)) {
        return state;
      }

      return state.updateIn([id], (comment) =>
        comment.set('messageCount', comment.messageCount + 1),
      );
    }

    case 'COMMENT_SET_ACTIVE': {
      const { id } = action.data;

      return state.update((stateComments) =>
        stateComments.map((comment) => {
          if (comment.id === id) {
            return comment.set('active', true);
          }

          return comment.active ? comment.set('active', false) : comment;
        }),
      );
    }

    case 'COMMENTS_MARK_AS_READ': {
      return state.update((stateComments) =>
        stateComments.map((comment) => {
          return comment.set('new', false);
        }),
      );
    }

    case 'COMMENT_SEND_SEMANTIC_TOPICS_FEEDBACK_SUCCESS':
    case 'COMMENT_SEND_SEMANTIC_SEARCH_FEEDBACK_SUCCESS': {
      const { commentId, isBiased, isBiasedComment, isRelevant } = action.data;

      if (!state.hasIn([commentId])) {
        return state;
      }

      return state.updateIn([commentId], (comment) =>
        comment.merge({
          commentFeedback: {
            isRelevant,
            isBiased,
            isBiasedComment,
          },
        }),
      );
    }

    case 'COMMENTS_FLUSH': {
      return getInitialState();
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  questions,
  list: comments,
  overview,
  unread,
});
