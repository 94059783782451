import { REHYDRATE } from 'redux-persist';

import { FilterSort } from '@peakon/records';

const filterOptionsState = (state = new FilterSort(), action: $TSFixMe) => {
  switch (action.type) {
    case REHYDRATE: {
      const { filterOptions } = action.payload ?? {};
      return filterOptions ? new FilterSort(filterOptions) : state;
    }
    case 'FILTER_CLEAR_ALL': {
      return state.set('sortBy', 'name');
    }
    case 'FILTER_QUERY': {
      const { query } = action.data;

      if (query !== '') {
        return state.merge({
          query,
          direction: 'DESC',
          sortBy: 'score',
        });
      }

      // preserve current sort & direction if it's not score
      if (state.sortBy !== 'score') {
        return state.set('query', query);
      }

      return state.merge({
        query,
        direction: 'ASC',
        sortBy: 'name',
      });
    }
    case 'FILTER_SORT_BY': {
      const { sortBy } = action.data;

      return state.set('sortBy', sortBy);
    }
    case 'FILTER_SORT_ORDER': {
      const { direction } = action.data;

      return state.set('direction', direction);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default filterOptionsState;
