import React, { createContext, useContext } from 'react';

/**
 * NOTE:
 * This code is copied from @peakon/component and as much
 * and the original logic has been preserved expect for using
 * integers instead of ElementType
 * */

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export const LevelContext = createContext<HeadingLevel>(1);

export const useHierarchyLevel = (headingLevel: HeadingLevel): HeadingLevel => {
  const level = useLevel();

  if (level > headingLevel) {
    return level;
  }

  return headingLevel;
};

export const useLevel = () => {
  return useContext(LevelContext);
};

type HierarchySectionProps = {
  initialLevel?: number;
  children?: React.ReactNode;
};

/**
 * @deprecated This component only exists for legacy reasons
 */
export const HierarchySection = ({
  initialLevel = 0,
  children,
}: HierarchySectionProps) => {
  const level = useLevel();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const nextLevel = Math.min(
    !level || initialLevel > level ? initialLevel + 1 : level + 1,
    6,
  ) as HeadingLevel;

  return (
    <LevelContext.Provider value={nextLevel}>{children}</LevelContext.Provider>
  );
};
