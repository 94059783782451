import React, { useEffect } from 'react';

import z from 'zod';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { Stack, Inline } from '@peakon/bedrock/react/layout';
import { Link } from '@peakon/bedrock/react/link';
import { BodyText, LabelText } from '@peakon/bedrock/react/typography';
import {
  Form,
  Checkbox,
  SubmitButton,
  ZodFormProvider,
  useZodForm,
} from '@peakon/shared/components/Form';
import { analytics } from '@peakon/shared/features/analytics/analytics';
import { t } from '@peakon/shared/features/i18next/t';

import { COOKIE_POLICY_URL } from './constants';
import { useCookieConsentContext } from './CookieConsentContext';
import {
  type CookieConsentRecord,
  cookieConsentCategories,
  useCookieConsentService,
} from './CookieConsentService';

const formSchema = z.object({
  analytics: z
    .boolean()
    .optional()
    .transform((value) => value ?? false), // default to false instead of undefined
});

type FormSchema = z.infer<typeof formSchema>;

const getConsentCategoryTranslations = () => {
  return {
    analytics: {
      label: t('user_consent__modal__analytics_cookies_label'),
      description: t('user_consent__modal__analytics_cookies_description'),
    },
  } satisfies CookieConsentRecord<{ label: string; description: string }>;
};

export function CookieConsentSettingsModal() {
  const { isCookieConsentModalOpened, setIsCookieConsentModalOpened } =
    useCookieConsentContext();
  const { setCookieConsent, cookieConsentAnswers } = useCookieConsentService();

  const consentTranslations = getConsentCategoryTranslations();

  const { reset, formState, ...restOfMethods } = useZodForm({
    schema: formSchema,
    defaultValues: Object.fromEntries(
      cookieConsentCategories.map((category) => [
        category,
        cookieConsentAnswers[category]?.answer === 'yes',
      ]),
    ),
  });

  function handleSubmit(data: FormSchema) {
    setCookieConsent(data);

    analytics.track('cookie_consent_modal__submitted', { data });

    setIsCookieConsentModalOpened(false);
  }

  useEffect(() => {
    if (isCookieConsentModalOpened) {
      analytics.track('cookie_consent_modal__viewed');
    }
  }, [isCookieConsentModalOpened]);

  return (
    <Modal
      heading={t('user_consent__modal__title')}
      closeLabel={t('common__close')}
      open={isCookieConsentModalOpened}
      onDismiss={() => setIsCookieConsentModalOpened(false)}
    >
      <ZodFormProvider
        schema={formSchema}
        reset={reset}
        formState={formState}
        {...restOfMethods}
      >
        <Modal.Content>
          <Form id="cookie_consent_form" onSubmit={handleSubmit}>
            <Stack spacing={16}>
              <BodyText>
                {t('user_consent__modal__description', {
                  components: {
                    link: (
                      <Link
                        variant="primary"
                        href={COOKIE_POLICY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  },
                })}
              </BodyText>

              <div>
                <LabelText as="span" size="large">
                  {t('user_consent__modal__essential_cookies_label')}
                </LabelText>
                <BodyText size="small">
                  {t('user_consent__modal__essential_cookies_description')}
                </BodyText>
              </div>
              {cookieConsentCategories.map((consentKey) => (
                <Checkbox
                  key={consentKey}
                  name={consentKey}
                  label={consentTranslations[consentKey].label}
                  description={consentTranslations[consentKey].description}
                />
              ))}
            </Stack>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Inline spacing={8}>
            <SubmitButton variant="primary" form="cookie_consent_form">
              {t('user_consent__modal__save_btn')}
            </SubmitButton>
            <Button
              variant="secondary"
              onClick={() => setIsCookieConsentModalOpened(false)}
            >
              {t('common__cancel')}
            </Button>
          </Inline>
        </Modal.Actions>
      </ZodFormProvider>
    </Modal>
  );
}
