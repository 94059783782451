export const popperModifiers = [
  {
    name: 'preventOverflow',
    options: {
      rootBoundary: 'viewport',
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, 28],
    },
  },
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top', 'bottom', 'right'],
    },
  },
];
