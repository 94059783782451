import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  name: z.string(),
  description: z.string(),
});
type Schema = z.infer<typeof testingSchema>;

// eslint-disable-next-line import/no-default-export
export default class Value extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  name: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  description: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ValueRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ValueRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { name, description },
    } = data;

    return new Value(
      fromJS({
        id,
        name,
        description,
      }),
    );
  }
}
