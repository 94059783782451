import { Record, fromJS, List, Map } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import { localeIdsSchema } from '@peakon/shared/features/i18next/localesValidation';
import { AccessEnum, TypeEnum } from '@peakon/shared/schemas/api/attributes';
import { RawScheduleSegment } from '@peakon/shared/schemas/api/schedules';
import { SegmentResponse } from '@peakon/shared/schemas/api/segments';
import { AttributeType } from '@peakon/shared/types/Attribute';
import { BenchmarkTypeEnum } from '@peakon/shared/types/Benchmark';
import hasRight from '@peakon/shared/utils/hasRight';
import { Right } from '@peakon/shared/utils/rights/types';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from './AttributeRecord';
import DashboardContext from './ContextRecord';
import SegmentManager from './SegmentManagerRecord';
import Translation from './TranslationRecord';
import { getRelationships, sortTranslations } from './utils';

/*
  FIXME / Note:

  Some of the properties here shouldn't really be optionals, like id, attribute and name for example.
  Using this record to create new segments in the attributes admin page is preventing us from doing that.
  We should try to use a separate record there.
*/
const schema = z.object({
  id: z.string().optional(),
  abbreviation: z.string().optional(),
  attribute: z.object({}).optional(),
  benchmark: z.object({}).nullable().optional(),
  benchmarkId: z.string().nullable().optional(),
  benchmarkPropagate: z.boolean().optional(),
  benchmarkSelector: z.object({}).nullable().optional(),
  benchmarkType: BenchmarkTypeEnum.optional(),
  comparisonAccess: AccessEnum.optional(),
  contextId: z.string().optional(),
  direct: z.boolean().optional(),
  email: z.string().nullable().optional(),
  employeeCount: z.number().optional(),
  employeeCounts: z
    .record(z.enum(['employed', 'hired', 'left']), z.number())
    .optional(),
  externalId: z.string().nullable().optional(),
  identifier: z.string().optional(),
  links: z.number().optional(),
  loading: z.boolean().optional(),
  logo: z.string().nullable().optional(),
  managers: z.array(z.object({})).nullable().optional(),
  name: z.string().optional(),
  nameTranslated: z.string().optional(),
  nameTranslations: z
    .record(localeIdsSchema, z.object({}))
    .nullable()
    .optional(), // z.object is TranslationRecord
  rights: z.array(z.string()).optional(),
  standard: z.string().nullable().optional(),
  standardLabel: z.string().nullable().optional(),
  type: TypeEnum.optional(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Segment
  extends Record({
    id: undefined,
    abbreviation: undefined,
    contextId: undefined,
    direct: undefined,
    email: undefined,
    employeeCount: undefined,
    employeeCounts: undefined,
    externalId: undefined,
    identifier: undefined,
    links: undefined,
    logo: undefined,
    name: undefined,
    nameTranslated: undefined,
    nameTranslations: Map(),
    standard: undefined,
    comparisonAccess: undefined,
    rights: List(),

    benchmark: undefined,
    benchmarkPropagate: undefined,
    benchmarkId: undefined,
    benchmarkSelector: undefined,
    benchmarkType: undefined,

    loading: false,

    // relationships
    attribute: undefined,
    managers: List(),
    standardLabel: undefined,
  })
  implements
    Omit<
      Schema,
      | 'benchmarkSelector'
      | 'employeeCounts'
      | 'managers'
      | 'nameTranslations'
      | 'rights'
    >
{
  id: Schema['id'];
  abbreviation: Schema['abbreviation'];
  comparisonAccess: Schema['comparisonAccess'];
  contextId: Schema['contextId'];
  direct: Schema['direct'];
  email: Schema['email'];
  employeeCount: Schema['employeeCount'];
  employeeCounts?: Map<'employed' | 'hired' | 'left', number>;
  externalId: Schema['externalId'];
  identifier: Schema['identifier'];
  links: Schema['links'];
  loading: Schema['loading'];
  logo: Schema['logo'];
  name: Schema['name'];
  nameTranslated: Schema['nameTranslated'];
  nameTranslations?: Map<string, Translation>;
  rights!: List<string>;
  standard: Schema['standard'];
  // eslint-disable-next-line
  benchmark?: Segment;
  benchmarkPropagate: Schema['benchmarkPropagate'];
  benchmarkId: Schema['benchmarkId'];
  benchmarkSelector?: Map<string, unknown>;
  benchmarkType: Schema['benchmarkType'];
  attribute?: Attribute;
  managers!: List<SegmentManager>;
  standardLabel: Schema['standardLabel'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Segment',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  canBeViewedAs(currentContext: DashboardContext) {
    return (
      currentContext &&
      !this.isContext(currentContext.id) &&
      this.hasRight('dataset:read')
    );
  }

  isContext(contextId?: string) {
    return this.contextId && this.contextId === contextId;
  }

  isType(type: AttributeType) {
    return this.attribute?.type === type;
  }

  hasRight(right: Right) {
    return hasRight(this.rights?.toArray(), right);
  }

  hasLinks() {
    return typeof this.links !== 'undefined' && this.links > 0;
  }

  static createFromApi(data: SegmentResponse | RawScheduleSegment): Segment {
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    const { id, attributes, relationships: { employee, managers } = {} } = data;

    // @ts-expect-error TS(2339): Property 'nameTranslations' does not exist on type 'RawScheduleSegment'.
    const nameTranslations = sortTranslations(attributes?.nameTranslations);
    // @ts-expect-error TS(2339): Property 'attribute' does not exist on type '{}'.
    const { attribute, benchmark } = getRelationships(data.relationships);

    return new Segment(
      fromJS({
        id,
        email: get(employee, 'relationships.account.attributes.email'),
        identifier: get(employee, 'attributes.identifier'),
        attribute,
        ...attributes,
        nameTranslations,
        managers: managers
          ? managers.map(SegmentManager.createFromApi)
          : List(),
        benchmark,
      }),
    );
  }

  toOption() {
    return {
      value: this.id,
      label: this.name,
      attribute: this.attribute,
      direct: this.direct,
    };
  }
}
