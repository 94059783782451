// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';
import { matchPath } from 'react-router-dom';
import { z } from 'zod';

const shareTokenSchema = z
  .object({
    token: z.string().optional(),
  })
  .catch({
    token: undefined,
  });

const categoryGroupIdSchema = z
  .object({
    categoryGroupId: z.string().optional(),
  })
  .catch({
    categoryGroupId: undefined,
  });

export const getShareBaseUrl = (pathname: $TSFixMe) => {
  const shareMatch = matchPath(pathname, {
    path: `/share/:token`,
    exact: false,
    strict: true,
  });

  const { token } = shareTokenSchema.parse(shareMatch?.params);

  if (token) {
    const categoryPathMatch = matchPath(pathname, {
      path: `/share/:token/:categoryGroupId(engagement|diversity_inclusion|health_wellbeing|custom_questions|covid|transformation_change)`,
      exact: false,
      strict: true,
    });

    const { categoryGroupId } = categoryGroupIdSchema.parse(
      categoryPathMatch?.params,
    );

    if (categoryGroupId) {
      return `/share/${token}/${categoryGroupId}`;
    }

    return `/share/${token}`;
  }

  return pathname;
};

export const getUrl = (
  path: string,
  params: Record<string, string | undefined>,
) => {
  return `${path}${qs.stringify(params, { addQueryPrefix: true })}`;
};
