import { List } from 'immutable';

type Observation = {
  mean: number;
  size: number;
  closesAt: string;
  nps: {
    promoters: number;
    passives: number;
    detractors: number;
    score: number;
  };
};

type Action = {
  type?: `TOPIC_OBSERVATIONS_LIST_${
    | 'SUCCESS'
    | 'LOADING'
    | 'FAILED'
    | 'CANCELLED'}`;
  data?: Observation[];
};

const observations = (state = List(), action: Action) => {
  switch (action.type) {
    case 'TOPIC_OBSERVATIONS_LIST_SUCCESS': {
      const { data } = action;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return List(data as Observation[]);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default observations;
