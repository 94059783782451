import React from 'react';

// eslint-disable-next-line no-restricted-imports -- could probably be replaced by `getCurrentLocaleInfo` but needs testing
import i18n from 'i18next';

import { getCurrentLocaleInfo } from '@peakon/shared/features/i18next/helpers';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type EmailPreviewFields = {
  from: string;
  subject: string;
  html: string;
};

type EmailPreviewBodyProps = {
  locale?: string;
  preview: EmailPreviewFields;
};

const EmailPreviewBody = ({ locale, preview }: EmailPreviewBodyProps) => {
  const localeInfo = locale
    ? {
        id: locale,
        dir: i18n.dir(locale),
      }
    : {
        id: getCurrentLocaleInfo().id,
        dir: getCurrentLocaleInfo().rtl ? 'rtl' : 'ltr',
      };

  const { from, subject, html } = preview;

  return (
    <div className={styles.email} lang={localeInfo.id} dir={localeInfo.dir}>
      <div className={styles.emailHeader}>
        <span aria-hidden="true">●●●</span>
      </div>
      <div className={styles.subject}>{subject}</div>
      <div className={styles.from}>
        {t('surveys__round__form__email-preview-from', { replace: { from } })}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
        className={styles.body}
      />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default EmailPreviewBody;
