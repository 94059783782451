// https://github.com/github/fetch/issues/89#issuecomment-256610849
export function futch(
  url: string,
  options = {},
  onProgress?: (event: ProgressEvent<EventTarget>) => void,
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    // @ts-expect-error TS(2339): Property 'method' does not exist on type '{}'.
    xhr.open(options.method || 'GET', url);

    // @ts-expect-error TS(2339): Property 'headers' does not exist on type '{}'.
    for (const k in options.headers || {}) {
      // @ts-expect-error TS(2339): Property 'headers' does not exist on type '{}'.
      xhr.setRequestHeader(k, options.headers[k]);
    }

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    xhr.onload = (e) => resolve(e.target.responseText);
    xhr.onerror = reject;

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    // @ts-expect-error TS(2339): Property 'body' does not exist on type '{}'.
    xhr.send(options.body);
  });
}
