export const defaultState = {
  isOpen: false,
  newNotifications: [],
  unread: 0,
};

function reducer(state: $TSFixMe, action: $TSFixMe) {
  switch (action.type) {
    case 'RESET': {
      return defaultState;
    }
    case 'TOGGLE': {
      return {
        ...state,
        isOpen: action.data,
      };
    }
    case 'NOTIFICATION_UNREAD_COUNT': {
      return {
        ...state,
        unread: action.data,
      };
    }
    case 'NEW_NOTIFICATION': {
      return {
        ...state,
        newNotifications: state.isOpen
          ? state.newNotifications.concat(action.data)
          : [],
        unread: state.unread + 1,
      };
    }
    default:
      return state;
  }
}

// eslint-disable-next-line import/no-default-export
export default reducer;
