import React from 'react';

import classnames from 'classnames';

import { Typography } from '@peakon/components';

import styles from './styles.css';

type TitleProps = {
  children?: React.ReactNode | string;
};

const Title = ({ children }: TitleProps) => (
  <Typography.H2 className={styles.title}>{children}</Typography.H2>
);

type SectionHeaderProps = {
  children?: React.ReactNode;
  className?: string;
};

const SectionHeader = ({ className, children }: SectionHeaderProps) => (
  <div className={classnames(styles.root, className)}>{children}</div>
);

SectionHeader.Title = Title;

// eslint-disable-next-line import/no-default-export
export default SectionHeader;
