import { Map, OrderedMap, List } from 'immutable';
import get from 'lodash/get';

import { Employee, CacheQuery } from '@peakon/records';

import createPagination from './utils/createPagination';
import { EmployeeSearchActions } from '../actions/EmployeeActions';

export const employeeSearch = (state = OrderedMap(), action: $TSFixMe) => {
  switch (action.type) {
    case 'EMPLOYEE_FORCE_REFRESH': {
      return OrderedMap();
    }
    case 'EMPLOYEE_APPEND_SUCCESS':
    case 'EMPLOYEE_LOAD_MORE_SUCCESS': {
      const { data: employees } = action.data;

      return state.merge(
        employees
          .map(Employee.createFromApi)
          // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
          .map((employee) => [employee.id, employee]),
      );
    }
    case 'EMPLOYEE_LIST_SUCCESS':
    case 'EMPLOYEE_FILTER_SUCCESS': {
      const { data: employees } = action.data;

      return OrderedMap(
        employees
          .map(Employee.createFromApi)
          // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
          .map((employee) => [employee.id, employee]),
      );
    }
    case 'EMPLOYEE_SEARCH_CLEAR': {
      return OrderedMap();
    }

    case 'EMPLOYEE_BULK_DELETE_SUCCESS': {
      const { currentEmployeeId, range, type } = action.data;

      return state.filter((employee) => {
        if (type === 'ALL') {
          return (
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            employee.id === currentEmployeeId || range.includes(employee.id)
          );
        }

        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        return !range.includes(employee.id);
      });
    }
    case 'ACCESS_GROUP_NOTIFY_SUCCESS': {
      const { employeeId } = action.data;

      return state.has(employeeId)
        ? state.update(employeeId, (employee) =>
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            employee.set('invitedToAdministerAt', new Date()),
          )
        : state;
    }
    default:
      return state;
  }
};

const employeesState = (state = Map(), action: $TSFixMe) => {
  switch (action.type) {
    case 'SESSION_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const employee = get(action.data, 'data.relationships.employee');

      return employee
        ? state.merge({
            [employee.id]: Employee.createFromApi(employee),
          })
        : state;
    }
    case 'EMPLOYEE_UPDATE_SUCCESS':
    case 'EMPLOYEE_READ_SUCCESS': {
      const { data } = action.data;

      const employee = Employee.createFromApi(data);

      return state.set(employee.id, employee);
    }
    case 'EMPLOYEE_SEARCH_SUCCESS':
    case 'EMPLOYEE_SEARCH_LOAD_MORE_SUCCESS': {
      const { data: employees } = action.data;

      return state.merge(
        employees
          .map(Employee.createFromApi)
          // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
          .map((employee) => [employee.id, employee]),
      );
    }
    default:
      return state;
  }
};

export const employeeQueries = (
  state = Map<string, CacheQuery>(),
  action: EmployeeSearchActions,
) => {
  switch (action.type) {
    case 'EMPLOYEE_SEARCH_SUCCESS': {
      const { query, data: employees, links } = action.data;

      return state.set(
        query,
        new CacheQuery({
          query,
          results: employees.map((employee) => employee.id),
          nextUrl: links?.next,
        }),
      );
    }
    case 'EMPLOYEE_SEARCH_LOAD_MORE_SUCCESS': {
      const { data: employees, links, query } = action.data;

      // handle initial load by id
      if (!state.has(query)) {
        return state.set(
          query,
          new CacheQuery({
            query,
            results: employees.map((employee) => employee.id),
            nextUrl: links?.next,
          }),
        );
      }

      return state.update(query, (cache) =>
        cache.merge({
          results: cache.results.concat(
            // @ts-expect-error employee.id is optional
            employees.map((employee) => employee.id),
          ),
          nextUrl: links?.next,
        }),
      );
    }
    default:
      return state;
  }
};

const pagination = (state = List(), action: $TSFixMe): List<unknown> => {
  switch (action.type) {
    case 'EMPLOYEE_SEARCH_SUCCESS': {
      const { data: employees } = action.data;

      // @ts-expect-error TS(7006): Parameter 'employee' implicitly has an 'any' type.
      return List(employees.map((employee) => employee.id));
    }

    case 'EMPLOYEE_SEARCH_LOAD_MORE_SUCCESS': {
      const { data: employees } = action.data;

      // @ts-expect-error TS(2740): Type 'Iterable<number, unknown>' is missing the fo... Remove this comment to see the full error message
      return state.concat(List(employees.map((employee) => employee.id)));
    }

    case 'EMPLOYEE_SEARCH_CACHE_SUCCESS': {
      return List(action.data.results);
    }

    default:
      return state;
  }
};

export const employeePagination = createPagination(pagination, {
  startTypes: ['EMPLOYEE_SEARCH', 'EMPLOYEE_SEARCH_CACHE'],
  appendTypes: ['EMPLOYEE_SEARCH_LOAD_MORE'],
  removeTypes: [],
  resetTypes: [],
});

// eslint-disable-next-line import/no-default-export
export default employeesState;
