import React from 'react';

import { t } from '../../../../../features/i18next/t';
import { useUnreadCommentsCount } from '../../../../../queries/comments/useUnreadCommentsCount/useUnreadCommentsCount';
import { SummaryMenuLabel } from '../../../components/SummaryMenuLabel/SummaryMenuLabel';
import { Badge } from '../../../Side/Menu';
import { ProductName } from '../types';
import { isActive, getPath, getDefaultQueryParams } from '../utils';

const insights: ProductName = 'insights';

type InsightsMenuProps = {
  rights: { [x: string]: boolean };
  contextId?: string;
  contextLevel?: string;
  contextIsDirect?: boolean;
  isTest?: boolean;
  addOns: string[];
};

export const getInsightsMenu = ({
  rights,
  contextId,
  contextLevel,
  contextIsDirect,
  isTest,
  addOns,
}: InsightsMenuProps) => {
  return {
    name: insights,
    dataTestId: 'mpn-insight',
    menuLabel: t('multi_product__insights'),
    children: [
      {
        menuLabel: t('multi_product__insights'),
        name: insights,
        children: [
          {
            name: 'engagement',
            menuLabel: t('multi_product__insights__menu_item__engagement'),
            getPath: getPath({
              path: '/dashboard/group/engagement',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.datasetEngagement);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/',
                  '/dashboard',
                  '/dashboard/group/engagement',
                ],
              });
            },
          },
          {
            name: 'diversityAndInclusion',
            menuLabel: t(
              'multi_product__insights__menu_item__diversity_and_inclusion',
            ),
            getPath: getPath({
              path: '/dashboard/group/diversity_inclusion',
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.datasetDiversity);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'healthAndWellbeing',
            menuLabel: t(
              'multi_product__insights__menu_item__health_and_wellbeing',
            ),
            getPath: getPath({
              path: '/dashboard/group/health_wellbeing',
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.datasetWellbeing);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'transformationAndChange',
            menuLabel: t(
              'multi_product__insights__menu_item__transformation_and_change',
            ),
            getPath: getPath({
              path: '/dashboard/group/transformation_change',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.datasetTransformation);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'curatedInsights',
            menuLabel: t(
              'multi_product__insights__menu_item__curated_insights',
            ),
            getPath: getPath({
              path: '/dashboard/curated_insights',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.curatedInsights);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__insights__menu_header__comments'),
        name: 'comments',
        children: [
          {
            menuLabel: (
              <ManagerDashboardAllCommentsMenuLabel
                contextId={contextId}
                rights={rights}
                isTest={isTest}
              />
            ),
            dataTestId: 'nav-comments-link',
            name: 'allComments',
            getPath: getPath({
              path: '/dashboard/comments',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.comment);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/comments/:commentId',
                ],
              });
            },
          },
          {
            name: 'npsSummary',
            menuLabel: <SummaryMenuLabel contextId={contextId} />,
            getPath: getPath({
              path: '/dashboard/nps-summary',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            dataTestId: 'nav-summary-link',
            get shouldRender() {
              return Boolean(
                addOns?.includes('ai_summaries') &&
                  rights.insight &&
                  rights.comment,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/nps-summary',
                ],
              });
            },
          },
          {
            menuLabel: t('multi_product__insights__menu_item__topics'),
            name: 'topics',
            getPath: getPath({
              path: '/dashboard/topics/simple',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.insight && rights.topic);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/dashboard/topics/semantic',
                  '/dashboard/topics/semantic/overview/:id',
                  '/dashboard/topics/simple/overview/:id',
                  '/dashboard/topics/simple/category/:categoryId',
                  '/dashboard/topics/simple/segment/:segmentId',
                ],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__insights__menu_header__actions'),
        name: 'actions',
        children: [
          {
            menuLabel: t('multi_product__insights__menu_item__action_plan'),
            name: 'actionPlan',
            getPath: getPath({
              path: '/actions',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(rights.action);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            menuLabel: t('multi_product__insights__menu_item__team_actions'),
            name: 'myTeamActions',
            getPath: getPath({
              path: '/actions/my-team',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return Boolean(
                rights.action && contextLevel === 'segment' && !contextIsDirect,
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
    ],
  };
};

const ManagerDashboardAllCommentsMenuLabel = ({
  contextId,
  rights,
  isTest = false,
}: Pick<InsightsMenuProps, 'contextId' | 'rights' | 'isTest'>) => {
  const { unreadCommentsCount = 0 } = useUnreadCommentsCount({
    contextId,
    isTest,
    hasCommentRights: Boolean(rights?.comment),
  });

  return (
    <React.Fragment>
      {t('multi_product__insights__menu_item__all_comments')}
      {unreadCommentsCount > 0 && <Badge>{unreadCommentsCount}</Badge>}
    </React.Fragment>
  );
};
