import React from 'react';

// eslint-disable-next-line no-restricted-imports -- we intentionally want to use this here
import { useTranslation } from 'react-i18next';

/**
 * This component is used to ensure that the app gets re-rendered when the language changes.
 *
 * This is necessary in order to ensure translations are updated when we the `t` function is imported directly from'@peakon/shared/features/i18next/t', since that doesn't trigger components to rerender when the language changes, as opposed to using the `t` function from the `useTranslation` hook.
 **/
const TranslatedApp = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  return (
    // ensure the app gets re-rendered when the language changes
    <React.Fragment key={i18n.language}>{children}</React.Fragment>
  );
};

// eslint-disable-next-line import/no-default-export
export default TranslatedApp;
