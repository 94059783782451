import React, { useEffect, useState, useCallback } from 'react';

import get from 'lodash/get';
import { CSSTransition } from 'react-transition-group';

import { Overlay, Portal } from '@peakon/components';

import { GodModeModal } from './GodModeModal';

import styles from './styles.css';

export type ModalGodModeProps = {
  session: $TSFixMe;
};

const GodModeTriggerSequence = 'edomdog';

export const GodModeMain = ({ session }: ModalGodModeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isTestCompany, setIsTestCompany] = useState(false);
  const [isMonitoringKeyPresses, setIsMonitoringKeyPresses] = useState(false);

  const [pressedSequence, _setPressedSequence] = useState('');
  const pressedSequenceRef = React.useRef(pressedSequence);
  // need to define special function that sets state through reference, because native event ('keypress') cannot access local state of the component, only the initial state of it
  const setPressedSequence = (data: string) => {
    pressedSequenceRef.current = data;
    _setPressedSequence(data);
  };

  useEffect(() => {
    if (session) {
      const companyAttributes = get(session, [
        'data',
        'relationships',
        'company',
        'attributes',
      ]);

      setIsTestCompany(companyAttributes?.testCompany ?? false);
      setCompanyName(companyAttributes?.name ?? '');
    }
  }, [session]);

  useEffect(() => {
    if (pressedSequence === GodModeTriggerSequence) {
      setPressedSequence('');
      setIsOpen(true);
    }
  }, [pressedSequence]);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
      return;
    }

    const newSequence = e.key + pressedSequenceRef.current;
    setPressedSequence(newSequence.substring(0, 7));
  }, []);

  useEffect(() => {
    if (isTestCompany && !isMonitoringKeyPresses) {
      window.addEventListener('keypress', handleKeyPress);
      setIsMonitoringKeyPresses(true);
    }
    return () => {
      if (isMonitoringKeyPresses) {
        window.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [isTestCompany, isMonitoringKeyPresses, handleKeyPress]);

  const handleOnModalClose = () => {
    setIsOpen(false);
  };

  const handleOnError = (error: Error) => {
    // internal component only
    // eslint-disable-next-line no-console
    console.log(`God mode error: ${error.message}`);
  };

  return (
    <React.Fragment>
      <Overlay
        zIndex={1000}
        show={isOpen}
        onClick={handleOnModalClose}
        preventBodyScroll={false}
      />
      {isOpen && (
        <Portal>
          <CSSTransition
            appear
            in={isOpen}
            timeout={300}
            style={{ zIndex: 1001 }}
            unmountOnExit
          >
            <div className={styles.modalRoot}>
              <div className={styles.rootGodMode}>
                <GodModeModal
                  onClose={handleOnModalClose}
                  companyName={companyName}
                  onError={handleOnError}
                />
              </div>
            </div>
          </CSSTransition>
        </Portal>
      )}
    </React.Fragment>
  );
};
