import get from 'lodash/get';
// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';

export const getTotalFromMeta = (meta: $TSFixMe) => get(meta, 'page.total', 0);

const getParams = (url: $TSFixMe) => {
  if (typeof url !== 'string') {
    return {};
  }

  // @ts-expect-error TS(2769): No overload matches this call. Overload 1 of 2, '(str: st...
  return qs.parse(url.split('?')[1]);
};

export const getPagesFromLinks = (props = {}) => {
  if (!props) {
    return {};
  }

  return Object.keys(props).reduce((acc, current) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const url = props[current];
    const params = getParams(url) || {};
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    acc[current] = Number(params.page) || 1;

    return acc;
  }, {});
};

// eslint-disable-next-line import/no-default-export
export default getParams;
