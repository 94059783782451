import { Record } from 'immutable';
import { z } from 'zod';

import { ContextResponse } from '@peakon/shared/schemas/api/contexts';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from '../ContextRecord';
import { validateTestingSchema } from '../utils';

type ExternalMetricRegression = {
  id: string;
  attributes: {
    driverValue: number;
    externalValue: number;
  };
  relationships: {
    context: ContextResponse;
  };
};

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

class RegressionObservationRecord
  extends Record({
    id: undefined,
    driverValue: undefined,
    externalValue: undefined,

    context: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  driverValue?: number;
  externalValue?: number;

  context?: Context;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'RegressionObservationRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'RegressionObservationRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: ExternalMetricRegression) {
    const {
      id,
      attributes,
      relationships: { context },
    } = data;

    return new RegressionObservationRecord({
      id,
      ...attributes,
      context: Context.createFromApi(context),
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default RegressionObservationRecord;
