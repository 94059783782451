import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { NOT_INITIALIZED, SUCCESS } from './constants/states';

const schema = z.object({
  name: z.string(),
  state: z.enum([NOT_INITIALIZED, SUCCESS, 'LOADING']).optional().nullable(),
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Resource extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  name: undefined!,
  state: NOT_INITIALIZED,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Resource',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
