import { fromJS, Record } from 'immutable';
import uniqueId from 'lodash/uniqueId';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  completed: z.boolean(),
  text: z.string(),
  isNew: z.boolean(),
});
type Schema = z.infer<typeof testingSchema>;

class ActionItemRecord extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  completed: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  text: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  isNew: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionItemRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionItemRecord',
      log: [
        {
          environment: 'local',
          logLevel: 'error',
        },
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(actionItem: $TSFixMe) {
    const {
      id,
      attributes: { completed, text },
    } = actionItem;

    // set as new when dealing with suggested checklist items
    const isNew = id.startsWith('action_');

    return new ActionItemRecord(
      fromJS({
        id,
        completed,
        text,
        isNew,
      }),
    );
  }

  static createNew() {
    return new ActionItemRecord({
      id: uniqueId('action_items_'),
      text: '',
      completed: false,
      isNew: true,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default ActionItemRecord;
