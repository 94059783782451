import { List } from 'immutable';

import { Attribute } from '@peakon/records';
import { FilterOption } from '@peakon/records/FilterRecord';

const attributes = (state = List(), action: $TSFixMe) => {
  switch (action.type) {
    case 'ATTRIBUTE_READ_SUCCESS': {
      const { data } = action.data;

      return List(data.map(Attribute.createFromApi));
    }

    case 'ATTRIBUTE_GET_OPTIONS_SUCCESS': {
      const { id, data } = action.data;
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const index = state.findIndex((attribute) => attribute.id === id);

      if (index === -1) {
        return state;
      }

      return state.update(index, (attribute) =>
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        attribute.set(
          'options',
          data.map(
            (option: $TSFixMe) =>
              new FilterOption({
                id: option.id,
                label: option.attributes.name,
                labelTranslated: option.attributes.nameTranslated,
              }),
          ),
        ),
      );
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default attributes;
