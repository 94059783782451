import { OrderedSet, Record } from 'immutable';

import jsonapiparser from '@peakon/jsonapiparser';
import { Employee, LegacyPagination } from '@peakon/records';

const NotInOpen = Record({
  employees: new LegacyPagination(),
  responses: 0,
  total: 0,
});

const employeesNotInOpen = (state = new NotInOpen(), action: $TSFixMe) => {
  switch (action.type) {
    case 'LIST_EMPLOYEES_NOT_IN_OPEN_SUCCESS': {
      const {
        // @ts-expect-error return from jsonapiparser is unknown
        data,
        // @ts-expect-error return from jsonapiparser is unknown
        links,
        // @ts-expect-error return from jsonapiparser is unknown
        meta: { page },
      } = jsonapiparser(action.data);

      const total = page?.total || 0;

      return state.set('total', total).set(
        'employees',
        new LegacyPagination({
          list: OrderedSet(data.map(Employee.createFromApi)),
          nextUrl: links && links.next,
        }),
      );
    }

    case 'LIST_EMPLOYEES_NOT_IN_OPEN_LOADING':
    case 'LIST_EMPLOYEES_NOT_IN_OPEN_LOAD_NEXT_LOADING':
      return state.updateIn(['employees'], (employees) =>
        employees.set('isLoading', true),
      );

    case 'LIST_EMPLOYEES_NOT_IN_OPEN_LOAD_NEXT_SUCCESS': {
      const { data: response } = action;
      // @ts-expect-error return from jsonapiparser is unknown
      const { links, data } = jsonapiparser(response);

      return state.updateIn(['employees'], (employees) =>
        employees.merge({
          isLoading: false,
          list: OrderedSet(
            employees.list.concat(data.map(Employee.createFromApi)),
          ),
          nextUrl: links && links.next,
        }),
      );
    }

    case 'ADD_EMPLOYEES_NOT_IN_OPEN_SUCCESS': {
      return new NotInOpen();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default employeesNotInOpen;
