import React from 'react';

import { Grid } from '@peakon/components';

import { PageHeader } from './components/PageHeader/PageHeader';

import styles from './styles.css';

type Props = {
  children: React.ReactNode;
  companyLogo?: string;
  companyName?: string;
  showDisclaimer?: boolean;
};

export function Layout({
  children,
  companyLogo,
  companyName,
  showDisclaimer = false,
}: Props) {
  return (
    <div className={styles.layout}>
      <PageHeader
        showDisclaimer={showDisclaimer}
        companyLogo={companyLogo}
        companyName={companyName}
      />
      {children && (
        <Grid>
          <Grid.Row>
            <Grid.Col sm={12}>{children}</Grid.Col>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
}
