import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ContextRecord from './ContextRecord';
import EmployeeRecord from './EmployeeRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class PriorityDriverRecord
  extends Record({
    id: undefined,
    driver: undefined,
    source: undefined,
    subdriver: undefined,

    context: undefined,
    setByEmployee: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'PriorityDriverRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'PriorityDriverRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { driver, source, subdriver },
      relationships: { context, setByEmployee },
    } = data;

    return new PriorityDriverRecord(
      fromJS({
        id,
        driver,
        source,
        subdriver,
        context: context && ContextRecord.createFromApi(context),
        setByEmployee:
          setByEmployee && EmployeeRecord.createFromApi(setByEmployee),
      }),
    );
  }
}
