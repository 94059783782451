import { Map } from 'immutable';

const form = (state = Map(), action: $TSFixMe) => {
  switch (action.type) {
    case 'ACTION_FORM_START': {
      const { team } = action.data;

      return state.merge({
        team,
        show: true,
      });
    }

    case 'ACTION_FORM_END': {
      return state.merge({
        team: null,
        show: false,
      });
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default form;
