import React from 'react';

import classNames from 'classnames';

import { AccessAnonymousProfileIcon as AnonymousIcon } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

type Props = {
  size?: 'medium' | 'conversation';
  variant?: 'default' | 'light';
};

export function AnonymousAvatar({
  size = 'medium',
  variant = 'default',
}: Props) {
  return (
    <div
      className={classNames(styles.anonymousAvatar, styles[size], {
        [styles.light]: variant === 'light',
      })}
      aria-hidden
    >
      <AnonymousIcon />
    </div>
  );
}
