import React from 'react';

import classnames from 'classnames';
import isNumber from 'lodash/isNumber';

import styles from './styles.css';

type UnreadCountProps = {
  count?: number;
  limited?: boolean;
  modifier?: 'right';
  renderZero?: boolean;
  large?: boolean;
  type?: 'primary' | 'danger';
};

const UnreadCount = ({
  count,
  limited,
  modifier,
  renderZero = false,
  large = false,
  type = 'primary',
}: UnreadCountProps) =>
  isNumber(count) && (count > 0 || renderZero) ? (
    <span
      data-test-id="unread-count"
      className={classnames(styles.count, {
        ...(modifier && { [styles[modifier]]: modifier }),
        [styles[type]]: type,
        [styles.large]: large,
      })}
    >
      {limited && count > 99 ? '99+' : count}
    </span>
  ) : null;

// eslint-disable-next-line import/no-default-export
export default UnreadCount;
