import featureFactory from '@peakon/shared/utils/featureFactory';

import { sessionSelector } from '../selectors/SessionSelectors';
import { GetState, Dispatch } from '../types/redux';

export const featureAdded = (name: string) => ({
  type: 'FEATURE_ADDED',
  data: { name },
});

export const featureRemoved = (name: string) => ({
  type: 'FEATURE_REMOVED',
  data: { name },
});

export const featuresRead = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const session = sessionSelector(state);

  const features = featureFactory(session.features.toJS())
    .filter((feature) => feature.active)
    .map((feature) => feature.key);

  return dispatch({
    type: 'FEATURE_READ',
    data: { features },
  });
};
