import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';

/**
 *
 * @returns an array of change arrays,
 * ie: [[key1, newValue], [key2, newValue]]
 *
 */
const diff = (
  current: Record<string, unknown>,
  original: Record<string, unknown>,
) => {
  return differenceWith(toPairs(current), toPairs(original), isEqual);
};

// eslint-disable-next-line import/no-default-export
export default diff;
