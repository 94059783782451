import { fromJS, List } from 'immutable';

import jsonapiparser from '@peakon/jsonapiparser';
import {
  Attribute,
  ScheduleAttribute,
  ScheduleAttributes,
} from '@peakon/records';

const scheduleAttributes = (
  state = new ScheduleAttributes(),
  action: $TSFixMe,
): ScheduleAttributes => {
  switch (action.type) {
    case 'SCHEDULE_ATTRIBUTES_LIST_SUCCESS': {
      const { data } = action.data;

      return state.set(
        'options',
        List(fromJS(data.map(Attribute.createFromApi))),
      );
    }

    case 'SCHEDULE_ATTRIBUTES_EDITABLE_LIST_SUCCESS': {
      const {
        // @ts-expect-error return from jsonapiparser is unknown
        data: {
          attributes: { attributeMessage, attributeMessageTranslations },
          relationships: { attributes },
        },
      } = jsonapiparser(action.data);

      return state.merge({
        editableAttributes: List(
          fromJS(attributes.map(ScheduleAttribute.createFromApi)),
        ),
        message: attributeMessage,
        messageTranslations: fromJS(attributeMessageTranslations),
      });
    }

    case 'SCHEDULE_ATTRIBUTES_ADD_SUCCESS': {
      const {
        // @ts-expect-error return from jsonapiparser is unknown
        data: {
          relationships: { attributes },
        },
      } = jsonapiparser(action.data);

      return state.merge({
        editableAttributes: List(
          fromJS(attributes.map(ScheduleAttribute.createFromApi)),
        ),
      });
    }

    case 'SCHEDULE_ATTRIBUTES_REMOVE_SUCCESS': {
      const { attributeId } = action.data;

      return state.set(
        'editableAttributes',
        // @ts-expect-error TS(2339): Property 'editableAttributes' does not exist on ty... Remove this comment to see the full error message
        state.editableAttributes.filter(
          (editable: $TSFixMe) => editable.attribute.id !== attributeId,
        ),
      );
    }

    case 'SCHEDULE_ATTRIBUTES_UPDATE_MESSAGE_SUCCESS': {
      const {
        // @ts-expect-error return from jsonapiparser is unknown
        data: {
          attributes: { attributeMessage, attributeMessageTranslations },
        },
      } = jsonapiparser(action.data);

      return state.merge({
        message: attributeMessage,
        messageTranslations: fromJS(attributeMessageTranslations),
      });
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default scheduleAttributes;
