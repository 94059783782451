import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  autoFocus: z.boolean(),
  from: z.number().nullable(),
  invalid: z.boolean(),
  to: z.number().nullable().optional(),
});
type Schema = z.infer<typeof testingSchema>;

// eslint-disable-next-line import/no-default-export
export default class AttributeRange extends Record<Schema>({
  id: undefined,
  autoFocus: false,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  from: undefined!,
  invalid: false,
  to: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'AttributeRange',
      defaultValues: {
        autoFocus: false,
        invalid: false,
      },
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'AttributeRange',
      defaultValues: {
        autoFocus: false,
        invalid: false,
      },
      log: [
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
