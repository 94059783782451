import { combineReducers } from '@reduxjs/toolkit';

import { ScheduleFormEditorRecord } from '@peakon/records/FormEditorRecord';

import frequencies from './frequencies';
import schedule from './schedule';

const createForm = (
  reducer: $TSFixMe,
  { persistAndReset = [], reset, persist = [] }: $TSFixMe = {},
) => {
  const initialState = new ScheduleFormEditorRecord();

  return (state = initialState, action: $TSFixMe): ScheduleFormEditorRecord => {
    if (persistAndReset.includes(action.type)) {
      const current = reducer(state.current, action);

      return ScheduleFormEditorRecord.startEditing(current);
    } else if (reset === action.type) {
      return ScheduleFormEditorRecord.startEditing(state.original);
    } else if (persist.includes(action.type)) {
      return state
        .set('current', reducer(state.current, action))
        .set('original', reducer(state.original, action));
    }

    return state.set('current', reducer(state.current, action));
  };
};

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  form: createForm(schedule, {
    persistAndReset: ['SCHEDULE_READ_SUCCESS', 'SCHEDULE_UPDATE_SUCCESS'],
    reset: 'SCHEDULE_FORM_RESET',
    persist: ['SCHEDULE_QUESTION_LIMIT_REMOVED'],
  }),
  frequencies: createForm(frequencies, {
    persistAndReset: [
      'SCHEDULE_FREQUENCIES_READ_SUCCESS',
      'SCHEDULE_FREQUENCIES_UPDATE_SUCCESS',
    ],
    reset: 'SCHEDULE_FREQUENCY_FLUSH',
  }),
});
