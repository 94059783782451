import React from 'react';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type ProgressBarProps = {
  currentStep: number;
  totalSteps: number;
};

const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {
  const percentage = Math.round((currentStep / totalSteps) * 100);

  // If we only have one step, skip the progress bar
  if (totalSteps <= 1) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div aria-hidden="true" className={styles.label}>
        {window.document.dir === 'rtl'
          ? `${totalSteps}/${currentStep}`
          : `${currentStep}/${totalSteps}`}
      </div>
      <VisuallyHidden>
        {t('pagination__showing', {
          replace: { totalShowing: currentStep, total: totalSteps },
        })}
      </VisuallyHidden>
      <div className={styles.wrapper} aria-hidden="true">
        <div className={styles.progress} style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProgressBar;
