import React from 'react';

import classnames from 'classnames';

import { UtilityReplyIcon as ReplyIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

import UnreadCount from '../comments/UnreadCount';

import styles from './styles.css';

type Props = {
  active?: boolean;
  count?: number;
  onClick?: $TSFixMeFunction;
};

const ConversationToggler = ({ active, count = 0, onClick }: Props) => {
  return (
    <UnstyledButton
      className={classnames(styles.root, {
        [styles.active]: active || count > 0,
      })}
      data-test-id="conversation-toggler"
      onClick={onClick}
      accessibleName={
        count
          ? t('comment_item__conversation_replies', {
              replace: { count },
            })
          : t('comment_item__conversation')
      }
    >
      {count ? (
        <UnreadCount count={count} modifier="right" large />
      ) : (
        <ReplyIcon className={styles.icon} />
      )}
      <span className={styles.label}>{t('comment_item__conversation')}</span>
    </UnstyledButton>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConversationToggler;
