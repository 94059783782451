import { Record } from 'immutable';
import { z } from 'zod';

import { errorReporter } from '@peakon/shared/utils/errorReporter';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const entitySchema = z.enum(['account', 'employee', 'company']);
export type UxFlowEntity = z.infer<typeof entitySchema>;

const schema = z.object({
  entity: entitySchema,
  name: z.string(),
  completed: z.boolean(),
});
type Schema = z.infer<typeof schema>;

class UxFlowRecord
  extends Record({
    id: undefined,
    entity: undefined,
    name: undefined,
    completed: false,
  })
  implements Schema
{
  entity!: Schema['entity'];
  name!: Schema['name'];
  completed!: Schema['completed'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'UxFlowRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get id() {
    if (!this.entity || !this.name) {
      errorReporter.warning(
        `Invalid uxflow ${!this.entity ? 'entity' : 'name'}`,
        { entity: this.entity, name: this.name },
      );
    }

    return UxFlowRecord.id(this.entity, this.name);
  }

  static id(entity: UxFlowEntity, name: string) {
    return `${entity}-${name}`;
  }
}

// eslint-disable-next-line import/no-default-export
export default UxFlowRecord;
