import React, { Fragment, useState } from 'react';

import classnames from 'classnames';

import {
  EditCloseIcon as CloseIcon,
  NavigationBasicNavigationChevronLeftIcon as ChevronLeft,
  NavigationBasicNavigationChevronRightIcon as ChevronRight,
} from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { t } from '@peakon/shared/features/i18next/t';

import SideNavigation from '..';
import ProfileSummary from '../../components/ProfileSummary';
import { ProductName, RawNavigation } from '../../hooks/useNavigation/types';
import Menu, { List, Item, Spacer } from '../Menu';
import MenuSlideIn, {
  TopLevelMenu,
  ProductMenu,
  MenuSlideInHeader,
} from '../MenuSlideIn';

import styles from './styles.css';

type Props = {
  isLoading?: boolean;
  navigation: RawNavigation;
  isShown?: boolean;
  onClose: () => void;
  companyName?: string;
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
  isTest: boolean;
};

function CollapsibleSideNav({
  isLoading,
  navigation,
  isShown,
  onClose,
  companyName,
  employee,
  isTest,
}: Props) {
  const menuItems = [
    navigation.profile,
    navigation.insights,
    navigation.analysis,
    navigation.improve,
    navigation.employeeDashboard,
    navigation.administration,
  ].filter(Boolean);

  const [maybeValidProductMenu, setProductMenu] = useState<ProductName | null>(
    null,
  );

  /**
   *
   * NOTE:
   *
   * Since resizing and state updates can remove a key from the navigation object
   * we have to guard against a "stale" key potentially missing from the navigation object.
   *
   * This should only ever be an issue when the state changes or resizing happens WHILE the
   * navigation is open and a submenu is selected.
   *
   */

  const activeSubnavigation =
    maybeValidProductMenu && navigation[maybeValidProductMenu];

  if (maybeValidProductMenu && !activeSubnavigation) {
    /**
     *
     * If we have an invalid stale value in `maybeValidProductMenu` we reset the state and short circuit.
     * This will trigger an immediate rerender of the component and state will no longer be stale
     *
     */

    setProductMenu(null);
    return null;
  }

  function handleOverlayClick() {
    if (activeSubnavigation) {
      setProductMenu(null);
    } else {
      onClose();
    }
  }

  function handleMenuItemClick() {
    if (activeSubnavigation) {
      setProductMenu(null);
    }
    onClose();
  }

  return (
    <MenuSlideIn isShown={isShown} handleOverlayClick={handleOverlayClick}>
      <nav data-test-id="collapsible-side-nav" id="collapsible-side-nav">
        <TopLevelMenu isShown={!activeSubnavigation}>
          <MenuSlideInHeader
            onClick={onClose}
            icon={CloseIcon}
            label={t('common__close')}
          />
          <Menu isCollapsible>
            {!isLoading && (
              <List>
                {menuItems.map((menuGroup) => (
                  <Fragment key={menuGroup.name}>
                    <Item key={menuGroup.name}>
                      <UnstyledButton
                        accessibleName={menuGroup.menuLabel}
                        className={classnames(styles.topLevelNavItem, {
                          [styles.active]: menuGroup.isActive,
                        })}
                        onClick={() => setProductMenu(menuGroup.name ?? null)}
                      >
                        {menuGroup.name === 'profile' ? (
                          <ProfileSummary
                            company={{ name: companyName }}
                            employee={employee}
                          />
                        ) : (
                          <span>{menuGroup.menuLabel}</span>
                        )}

                        <ChevronRight aria-hidden />
                      </UnstyledButton>
                    </Item>
                    {menuGroup.name === 'profile' && <Spacer />}
                  </Fragment>
                ))}
              </List>
            )}
          </Menu>
        </TopLevelMenu>
        <ProductMenu isShown={Boolean(activeSubnavigation)}>
          <MenuSlideInHeader
            onClick={() => setProductMenu(null)}
            icon={ChevronLeft}
            label={t('common__back')}
            title={activeSubnavigation?.menuLabel}
          />
          <SideNavigation
            location="top"
            productToShow={activeSubnavigation?.name}
            onMenuItemClick={handleMenuItemClick}
            isTest={isTest}
          />
        </ProductMenu>
      </nav>
    </MenuSlideIn>
  );
}

// eslint-disable-next-line import/no-default-export
export default CollapsibleSideNav;
