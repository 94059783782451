import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.css';

type Props = {
  icon: ReactNode;
  name: string;
  locale?: string | null;
  to: string;
};

export const TopicBadge = ({ icon: Icon, name, locale, to }: Props) => (
  <Link
    className={styles.root}
    to={to}
    data-test-id="comment-topic"
    lang={locale || undefined}
  >
    {Icon}
    <span>{name}</span>
  </Link>
);
