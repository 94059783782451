import { combineReducers } from '@reduxjs/toolkit';

import importResults from './importResults';
import metrics from './metrics';
import observations from './observations';
import regressions from './regressions';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  importResults,
  metrics,
  observations,
  regressions,
});
