import React, { useRef, useEffect } from 'react';

import classnames from 'classnames';

import { EditCloseIcon as CrossIcon } from '@peakon/bedrock/icons/system';
import { IconButton } from '@peakon/bedrock/react/button';
import { Typography } from '@peakon/components';
import { TranslatedString, t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export const TaskNotification = ({
  taskId,
  heading,
  onClose,
  children,
  status,
}: {
  taskId: string;
  status: 'completed' | 'inProgress' | 'error';
  heading: TranslatedString;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (status === 'completed') {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [status]);

  return (
    <div
      ref={ref}
      tabIndex={-1}
      id={`notification-${taskId}`}
      data-test-id="notification"
      className={classnames(styles.notification, {
        [styles.success]: status === 'completed',
        [styles.error]: status === 'error',
      })}
    >
      <div className={styles.header}>
        <Typography.H2 className={styles.headerText}>{heading}</Typography.H2>
        <div>
          <IconButton
            variant="tertiary"
            icon={<CrossIcon />}
            onClick={onClose}
            accessibleName={t('common__close')}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

type ContentProps = {
  children: React.ReactNode;
} & (
  | {
      icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
      iconSize?: 'large';
    }
  | { icon?: undefined; iconSize?: undefined }
);

const Content = ({ icon: Icon, iconSize, children }: ContentProps) => {
  return (
    <div
      className={classnames(styles.contentContainer, {
        [styles.withIcon]: Icon,
        [styles.largeIcon]: iconSize === 'large',
      })}
    >
      {Icon && (
        <div>
          <Icon />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

TaskNotification.Content = Content;
