import z, { ZodEffects, ZodNumber, ZodTypeAny } from 'zod';

// https://github.com/airjp73/remix-validated-form/blob/main/packages/zod-form-data/src/helpers.ts
type InputType<TDefaultType extends ZodTypeAny> = {
  (): ZodEffects<TDefaultType>;
  <TProvidedType extends ZodTypeAny>(
    schema: TProvidedType,
  ): ZodEffects<TProvidedType>;
};

const stripEmpty = z.literal('').transform(() => undefined);
const stringToNumeric = z
  .string()
  .transform((val) => Number(val))
  .refine((val) => !Number.isNaN(val));

const preprocessIfValid = (schema: ZodTypeAny) => (val: unknown) => {
  const result = schema.safeParse(val);
  if (result.success) {
    return result.data;
  }

  return val;
};

/**
 * Coerces numerical strings to numbers transforms empty strings to `undefined` before validating.
 * If you call `zfd.number` with no arguments,
 * it will assume the field is a required number by default.
 * If you want to customize the schema, you can pass that as an argument.
 */
export const numeric: InputType<ZodNumber> = (schema = z.number()) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  z.preprocess(
    preprocessIfValid(z.union([stripEmpty, stringToNumeric])),
    schema,
  ) as unknown as z.ZodEffects<z.ZodNumber, number, number>;
