import { Record, List, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Lesson from './LessonRecord';
import Resource from './ResourceRecord';
import CategoryRecord from '../CategoryRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  resources: z.any(),
  driver: z.any(),
  imageSrc: z.any(),
  copyrights: z.any(),
  totalDuration: z.any(),
  title: z.any(),
  locale: z.any(),
  publicationDate: z.any(),
  imageBackgroundColor: z.any(),
  lessons: z.any(),
  description: z.any(),
  category: z.any(),
});
type Schema = z.infer<typeof schema>;

class CourseRecord
  extends Record({
    id: undefined,
    description: undefined,
    driver: undefined,
    imageSrc: undefined,
    category: undefined,
    lessons: List(),
    resources: List(),
    title: undefined,
    contentType: 'course',
    imageBackgroundColor: undefined,
    whatYouWillLearn: undefined,
    copyrights: List(),
    totalDuration: undefined,
    publicationDate: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  description!: string;
  driver!: string;
  imageSrc!: string;
  title!: string;
  imageBackgroundColor!: string;
  category!: CategoryRecord;
  lessons!: List<Lesson>;
  resources!: List<Resource>;
  whatYouWillLearn?: string;
  totalDuration?: number;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CourseRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CourseRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get minutes() {
    return this.totalDuration;
  }

  hasProgress() {
    return this.lessons.some(
      (lesson) => (lesson?.progress ?? 0) > 0 || lesson?.completed === true,
    );
  }

  isCompleted() {
    return (
      this.lessons.size > 0 &&
      this.lessons.every((lesson) => lesson?.completed === true)
    );
  }

  currentLesson() {
    return this.lessons.find(
      (lesson) => (lesson?.progress ?? 0) > 0 && !lesson?.completed,
    );
  }

  static createFromApi(data = {}) {
    const {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      id,
      // @ts-expect-error TS(2339): Property 'attributes' does not exist on type '{}'.
      attributes,
      // @ts-expect-error TS(2339): Property 'relationships' does not exist on type '{... Remove this comment to see the full error message
      relationships: { lessons = [], resources = [], category = {} } = {},
    } = data;

    return new CourseRecord(
      fromJS({
        id,
        ...attributes,
        lessons: List(lessons.map(Lesson.createFromApi)),
        resources: List(resources.map(Resource.createFromApi)),
        category: category ? CategoryRecord.createFromApi(category) : undefined,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default CourseRecord;
