import { UxFlow } from '@peakon/records';
import { UxFlowEntity } from '@peakon/records/UxFlowRecord';
import api from '@peakon/shared/utils/api';
import { proxyViewSnapshot } from '@peakon/shell/hooks/useTokenFromStorage';

import { Dispatch, GetState } from '../types/redux';
import { asyncDispatch } from '../utils';

export const onboarded = () => (dispatch: Dispatch) =>
  dispatch(complete('company', 'onboarding'));

export const isOnboarded = () => (dispatch: Dispatch) =>
  dispatch(check('company', 'onboarding'));

export const hideInfo = (key: string) => (dispatch: Dispatch) =>
  dispatch(complete('account', key));

export const showInfo = (key: string) => (dispatch: Dispatch) =>
  dispatch(remove('account', key));

export const isHidingInfo = (key: string) => (dispatch: Dispatch) =>
  dispatch(check('account', key));

export const check =
  (entity: UxFlowEntity, name: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    // don't check when impersonating managers
    if (proxyViewSnapshot()) {
      return;
    }

    const { flows } = getState();
    const uxFlow = flows.get(UxFlow.id(entity, name));

    return (
      uxFlow ||
      asyncDispatch({
        dispatch,
        resource: 'FLOW_CHECK',
        data: {
          entity,
          name,
        },

        action: api.uxflow.get(entity, name),
      })
    );
  };

export const complete =
  (entity: string, name: string) => (dispatch: Dispatch) =>
    asyncDispatch({
      dispatch,
      resource: 'FLOW_COMPLETE',
      data: {
        completed: true,
        entity,
        name,
      },

      action: api.uxflow.complete(entity, name),
    });

const remove = (entity: string, name: string) => (dispatch: Dispatch) =>
  asyncDispatch({
    dispatch,
    resource: 'FLOW_REMOVE',
    data: {
      completed: false,
      entity,
      name,
    },

    action: api.uxflow.remove(entity, name),
  });
