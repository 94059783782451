import z from 'zod';

import { translatedStringSchema } from '../../features/i18next/t';

const ATTRIBUTE_STANDARDS = [
  'manager',
  'division',
  'department',
  'office',
  'level',
  'tenure',
  'separation_date',
  'separation_reason',
  'age',
  'gender',
  'salary',
  'type',
  'title',
] as const;
export const StandardEnum = z.enum(ATTRIBUTE_STANDARDS);

export const ATTRIBUTE_TYPES = [
  'option',
  'date',
  'employee',
  'number',
  'tree',
  'link',
] as const;
export const TypeEnum = z.enum(ATTRIBUTE_TYPES);

export const ACCESS_TYPES = ['unrestricted', 'restricted', 'disabled'] as const;
export const AccessEnum = z.enum(ACCESS_TYPES);

export const ACCESS_TYPE_UNRESTRICTED = ACCESS_TYPES[0];
export const ACCESS_TYPE_RESTRICTED = ACCESS_TYPES[1];
export const ACCESS_TYPE_DISABLED = ACCESS_TYPES[2];

const ATTRIBUTE_STATUS = ['active', 'inactive'] as const;
export const StatusEnum = z.enum(ATTRIBUTE_STATUS);

const attributeOptionSchema = z.object({
  id: z.string(),
  type: z.literal('attribute_options'),
  attributes: z.object({
    name: z.string(),
    nameTranslated: translatedStringSchema,
  }),
});

const baseAttributeAttributes = z.object({
  aliases: z.array(z.string()),
  comparisonAccess: AccessEnum,
  comparisonAccessLocked: z.boolean(),
  description: z.string().nullable(),
  descriptionTranslated: translatedStringSchema.nullable(),
  descriptionTranslations: z.record(z.string(), z.string()).optional(),
  direct: z.boolean().optional(),
  employeeAccess: AccessEnum,
  employeeAccessLocked: z.boolean(),
  name: z.string(),
  nameTranslated: translatedStringSchema,
  nameTranslations: z.record(z.string(), z.string()).optional(),
  open: z.boolean(),
  primary: z.boolean(),
  rejectExternal: z.boolean().nullable(),
  sensitive: z.boolean(),
  sort: z.number().optional(),
  standard: StandardEnum.nullable(),
  status: StatusEnum,
  type: TypeEnum,
  valid: z.boolean().optional(),
});

const baseAttributeSchema = z.object({
  id: z.string(),
  type: z.literal('attributes'),
  attributes: baseAttributeAttributes,
  links: z
    .object({
      self: z.string(),
    })
    .optional(),
});

export const attributeSchema = baseAttributeSchema.extend({
  relationships: z
    .object({
      links: z.array(baseAttributeSchema).optional(),
      options: z.array(attributeOptionSchema).optional(),
      ranges: z
        .array(
          z.object({
            id: z.string(),
            attributes: z.object({
              name: z.string(),
              from: z.string().nullish(),
              to: z.string().nullish(),
              filter: z.record(
                z.enum(['$gte', '$lte']),
                z.string().or(z.number()),
              ),
            }),
          }),
        )
        .optional(),
    })
    .optional(),
});

const contextAttributesSchema = z.object({
  name: z.string(),
  nameTranslated: translatedStringSchema,
  type: TypeEnum,
  standard: StandardEnum.nullish(),
});

export const contextAttributeSchema = z.object({
  type: z.literal('attributes'),
  id: z.string(),
  attributes: contextAttributesSchema,
});

const _attributeDiscriminatedUnion = attributeSchema.or(contextAttributeSchema);

export type BaseAttributeAttributes = z.infer<typeof baseAttributeAttributes>;
export type GeneralAttributeResponse = z.infer<typeof attributeSchema>;
export type AttributeOptionResponse = z.infer<typeof attributeOptionSchema>;
export type AttributeResponse = z.infer<typeof _attributeDiscriminatedUnion>;
