import each from 'lodash/each';

import { COMPANY_SIZES } from '../constants/companies';

export const RECOMMENDED_FREQUENCIES = {
  1: ['1-25', '26-50', '51-150'],
  2: ['151-500'],
  4: ['501-1000', '1,001-5,000'],
  12: ['5,001-10,000', '>10,000'],
};

const getRecommendedFrequency = (size: string) => {
  let recommendedFrequency;

  if (size && COMPANY_SIZES.includes(size)) {
    each(RECOMMENDED_FREQUENCIES, (sizes, key) => {
      if (sizes.includes(size)) {
        recommendedFrequency = parseInt(key, 10);
      }
    });
  }

  return recommendedFrequency;
};

// eslint-disable-next-line import/no-default-export
export default getRecommendedFrequency;
