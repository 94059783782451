import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  type: z.any(),
  level: z.any(),
  expanded: z.any(),
  employeeCount: z.any(),
  hasLoadedQuestionScores: z.any(),
});
type Schema = z.infer<typeof schema>;

class RowRecord
  extends Record({
    id: undefined,
    type: 'segment',
    level: 0,
    expanded: false,
    employeeCount: undefined,
    hasLoadedQuestionScores: false,
  })
  implements Schema
{
  id!: Schema['id'];
  level: $TSFixMe;

  type: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'RowRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'RowRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  collapse() {
    return this.set('expanded', false);
  }

  expand() {
    return this.set('expanded', true);
  }

  isContext() {
    return this.type === 'context';
  }

  isSegment() {
    return !this.isContext();
  }

  isTopLevel() {
    return this.level === 0;
  }

  static fromContext(id: $TSFixMe) {
    return new RowRecord({
      id,
      type: 'context',
      level: 0,
      expanded: false,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default RowRecord;
