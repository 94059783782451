import { TranslatedString } from '@peakon/shared/features/i18next/t';

import { extractMessage } from './index';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../actions/NotificationActions';
import { Dispatch } from '../types/redux';

type AsyncDispatchArgs = {
  resource: string;
  data?: Record<string | number | symbol, unknown>;
  dispatch: Dispatch;
  action: Promise<unknown>;
  showNotification?: boolean;
  isActionCancelled?: () => boolean;
  successMessage?: TranslatedString;
};

const asyncDispatch = async ({
  resource,
  dispatch,
  data = {},
  action,
  showNotification = true,
  successMessage,
  isActionCancelled = () => false,
}: AsyncDispatchArgs) => {
  if (!action) {
    throw new Error(`Missing action`);
  }

  const dispatchPending = () => {
    dispatch({
      type: `${resource}_LOADING`,
      data,
    });
  };

  const extractPayload = (response: $TSFixMe) =>
    Array.isArray(response) || (typeof response === 'string' && response)
      ? response
      : Object.assign({}, data, response);

  const dispatchSuccess = (response: $TSFixMe) => {
    const payload = extractPayload(response);

    dispatch({
      type: `${resource}_SUCCESS`,
      data: payload,
    });

    if (successMessage) {
      dispatch(
        showSuccessNotification({
          message: successMessage,
        }),
      );
    }

    return payload;
  };

  const dispatchCancelled = (response: $TSFixMe) => {
    const payload = extractPayload(response);

    dispatch({
      type: `${resource}_CANCELLED`,
      data: payload,
    });

    return payload;
  };

  const dispatchFailure = (error: $TSFixMe) => {
    if (showNotification) {
      dispatch(
        showErrorNotification({
          ...extractMessage(error),
          code: error.status,
        }),
      );
    }

    dispatch({
      type: `${resource}_FAILED`,
      data: { ...data, error },
    });

    throw error;
  };

  dispatchPending();

  try {
    const response = await action;
    if (isActionCancelled()) {
      return dispatchCancelled(response);
    } else {
      return dispatchSuccess(response);
    }
  } catch (err) {
    dispatchFailure(err);
  }
};

// eslint-disable-next-line import/no-default-export
export default asyncDispatch;
