import { getCompanyAddOns } from '../selectors/CompanySelectors';
import { useAppSelector } from '../utils/reduxHooks';

export const useAddons = () => {
  return useAppSelector(getCompanyAddOns);
};

export const useAddon = (addOn: string) => {
  const addOns = useAddons();
  return addOns.includes(addOn);
};
