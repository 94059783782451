import { Right } from './rights/types';

const hasRight = (
  sessionRights: Right[] | undefined | null | string[], // string type accommodates `features`
  requiredRights: Right[] | string | string[] = [],
) => {
  if (!sessionRights) {
    return false;
  }

  return sessionRights.some((givenRight) => {
    if (Array.isArray(requiredRights)) {
      return requiredRights.some((requiredRight) => {
        return givenRight.startsWith(requiredRight);
      });
    } else {
      return givenRight.startsWith(requiredRights);
    }
  });
};

// eslint-disable-next-line import/no-default-export
export default hasRight;
