import React from 'react';

import styles from './styles.css';

type Props = {
  percent: number;
};

export const ProgressBar = ({ percent }: Props) => {
  return (
    <div
      className={styles.progress}
      role="progressbar"
      aria-valuenow={percent}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div
        className={styles.progressFill}
        style={{ width: `${percent}%` }}
        aria-hidden="true"
      />
    </div>
  );
};
