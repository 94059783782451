import React, { useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { Spinner } from '@peakon/bedrock/react/spinner';
import { t } from '@peakon/shared/features/i18next/t';

import { useConnectIntegrationWithOAuthMutationQuery } from './queries/useConnectIntegrationWithOAuthMutationQuery';
import { OAuthService } from './responseCheckerFactory';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../actions/NotificationActions';
import { useAppDispatch } from '../../../utils/reduxHooks';

import styles from './styles.css';

export const OAuthComplete = ({ service }: { service: OAuthService }) => {
  const { isSuccess, error } =
    useConnectIntegrationWithOAuthMutationQuery(service);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showSuccessNotification({
          message: t('integration__enabled__success'),
        }),
      );
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(catchHandler(error));
    }
  }, [error, dispatch]);

  if (isSuccess) {
    return <Redirect to={`/admin/integrations/${service}`} />;
  }
  if (error) {
    return <Redirect to="/admin/integrations" />;
  }
  return (
    <div className={styles.container}>
      <Spinner size="48" />
    </div>
  );
};
