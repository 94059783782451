import React from 'react';

import classNames from 'classnames';

import {
  NavigationBasicNavigationChevronRightIcon as ArrowRight,
  AccessLockIcon as RestrictedIcon,
} from '@peakon/bedrock/icons/system';
import { IconButton } from '@peakon/bedrock/react/button';
import { Button, Tooltip } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import ClassificationIcon from '../../../ClassificationIcon';

import styles from './styles.css';

const MAX_SEGMENTS = 30;

type AddButtonProps = {
  attributeName?: string;
  count?: number;
  disabled?: boolean;
  isHeatmap?: boolean;
  onClick: () => void;
};

const AddButton = ({
  attributeName,
  disabled,
  count,
  isHeatmap,
  onClick,
}: AddButtonProps) => {
  if (count && count > MAX_SEGMENTS && !isHeatmap) {
    return (
      <div className={styles.limit}>
        {t('segments_picker__too_many_segments')}
      </div>
    );
  }

  const button = (
    <Button
      onClick={onClick}
      disabled={disabled}
      size="small"
      type="primary"
      label={t('a11y__segments_picker__add_all', {
        replace: { attribute: attributeName, count },
      })}
    >
      <span>
        {t('segments_picker__add_attribute', {
          replace: { count },
        })}
      </span>
    </Button>
  );

  if (isHeatmap) {
    return (
      <Tooltip.Core trigger="mouseenter focus" placement="left" target={button}>
        <Tooltip.Content>{t('segments_picker__add_override')}</Tooltip.Content>
      </Tooltip.Core>
    );
  }

  return button;
};

type AttributeProps = {
  count?: number;
  positiveCount?: number;
  negativeCount?: number;
  isHeatmap?: boolean;
  isLoading?: boolean;
  group?: string;
  type?: 'critical' | 'link' | 'attribute';
  name?: string;
  onAddAllClick: () => void;
  onClick?: () => void;
  restricted?: boolean;
  hasCurrentAttribute?: boolean;
  selectedCount?: number;
  showAddAll?: boolean;
};

export const Attribute = ({
  count,
  positiveCount,
  negativeCount,
  isLoading,
  isHeatmap,
  group,
  type,
  name,
  onAddAllClick,
  onClick,
  restricted,
  hasCurrentAttribute,
  selectedCount,
  showAddAll,
}: AttributeProps) => {
  return (
    <div className={styles.root}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.attribute}
        onClick={onClick}
        data-test-id="attribute-row-clickable"
      >
        <div className={styles.name}>
          {type === 'link' ? t('segments_picker__group_link') : name}
        </div>

        {group === 'engagement' && type === 'critical' ? (
          <div className={styles.critical}>
            <div className={styles.criticalCount}>
              <ClassificationIcon
                // @ts-expect-error TS(2322): Type '{ position: string; classification: "strengt... Remove this comment to see the full error message
                position="bottom"
                classification="strength"
                size="md"
                withTooltip
              />

              <span className={styles.count}>
                {t('segments_picker__segment_count', {
                  replace: { count: positiveCount },
                })}
              </span>
            </div>

            <div className={styles.criticalCount}>
              <ClassificationIcon
                // @ts-expect-error TS(2322): Type '{ position: string; classification: "priorit... Remove this comment to see the full error message
                position="bottom"
                classification="priority"
                withTooltip
                size="md"
              />

              <span className={styles.count}>
                {t('segments_picker__segment_count', {
                  replace: { count: negativeCount },
                })}
              </span>
            </div>
          </div>
        ) : (
          <span className={styles.count}>
            {t('segments_picker__segment_count', {
              replace: { count },
            })}
          </span>
        )}

        {restricted && (
          <div className={styles.restricted}>
            <RestrictedIcon className={styles.restrictedIcon} />
            {t('attribute__restricted')}
          </div>
        )}

        {(selectedCount ?? 0) > 0 && (
          <div className={styles.selectedCount}>
            {t('segments_picker__attribute_total_checked', {
              replace: { count: selectedCount ?? 0 },
            })}
          </div>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={classNames(styles.actions, {
          [styles.hidden]: hasCurrentAttribute,
        })}
        onClick={showAddAll ? undefined : onClick}
      >
        {showAddAll && (
          <AddButton
            attributeName={name}
            count={count}
            isHeatmap={isHeatmap}
            disabled={isLoading}
            onClick={onAddAllClick}
          />
        )}
        <IconButton
          icon={<ArrowRight />}
          variant="tertiary"
          onClick={showAddAll ? onClick : undefined}
          busy={isLoading}
          accessibleName={t('segments_picker__too_many_segments')}
        />
      </div>
    </div>
  );
};
