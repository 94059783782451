type Params = {
  isFallbackActiveProduct: boolean;
  to: string;
  from: string;
};

export const getLinkType = ({ isFallbackActiveProduct, to, from }: Params) => {
  // We have no idea where in the product the user is.
  // Do a full page refresh to ensure navigation works correctly
  if (isFallbackActiveProduct) {
    return 'a';
  }

  // We are not navigating across products (from bundle to bundle)
  // So we don't need to do a full page refresh
  if (to === from) {
    return 'react';
  }

  // We are navigating to or from the employee dashboard.
  // We need to do a full page refresh to get the server to load the correct bundle
  if (to === 'employeeDashboard' || from === 'employeeDashboard') {
    return 'a';
  }

  // we have checked for all the edge cases, everything else should be react
  return 'react';
};
