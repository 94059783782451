import React, { Component } from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import { connect } from 'react-redux';

import { Notification } from '@peakon/components';
import { TranslatedString } from '@peakon/shared/features/i18next/t';

import * as NotificationActions from '../../actions/NotificationActions';
import { RootState, Dispatch } from '../../types/redux';
import { extractMessage } from '../../utils';

export const renderEphemeralNotification = ({
  title = null,
  message = null,
  error = null,
  timeout = 10000,
  type = 'success',
}: {
  title?: TranslatedString | null;
  message?: TranslatedString | null;
  error?: string | null;
  timeout?: number;
  type?: string;
}) => {
  let notification;
  if (title || message) {
    notification = {
      title,
      message,
      code: 200,
      type,
    };
  } else {
    notification = {
      ...extractMessage(error),
      // @ts-expect-error Property 'status' does not exist on type 'string'.ts(2339)
      code: error?.status,
      type: 'error',
    };
  }

  const node = window.document.createElement('div');
  window.document.body?.appendChild(node);

  const root = createRoot(node);

  const timeoutId = setTimeout(onDismiss, timeout);

  function onDismiss() {
    if (node) {
      root.unmount();
      window.document.body?.removeChild(node);
    }

    clearTimeout(timeoutId);
  }

  root.render(
    <Notification.Stack zIndex={2000}>
      {[notification].map((n, i) => (
        <Notification
          key={i}
          onDismiss={onDismiss}
          testId={`notification-${n.type}`}
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type '"error" |... Remove this comment to see the full error message
          type={n.type}
        >
          <Notification.Title>{n.title}</Notification.Title>
          {n.message ? (
            <Notification.Message>{n.message}</Notification.Message>
          ) : null}
        </Notification>
      ))}
    </Notification.Stack>,
  );
};

type SystemProps = ReturnType<
  // eslint-disable-next-line no-use-before-define
  typeof mapStateToProps
> &
  ReturnType<
    // eslint-disable-next-line no-use-before-define
    typeof mapDispatchToProps
  >;

export class System extends Component<SystemProps> {
  render() {
    const {
      notifications,
      notificationActions: { remove },
    } = this.props;

    return (
      <Notification.Stack zIndex={2000}>
        {/* @ts-expect-error React-18  Type 'List<Element>' is not assignable to type 'ReactNode'.ts(2769) */}
        {notifications.map((n) => (
          <Notification
            key={n.id}
            onDismiss={() => remove(n.id)}
            type={n.type}
            testId={`notification-${n.type}`}
          >
            <Notification.Title>{n.title}</Notification.Title>
            {n.message ? (
              <Notification.Message>{n.message}</Notification.Message>
            ) : null}
          </Notification>
        ))}
      </Notification.Stack>
    );
  }
}

const mapStateToProps = ({ notifications }: RootState) => ({ notifications });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
});

export const NotificationSystem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(System);

// eslint-disable-next-line import/no-default-export
export default NotificationSystem;
