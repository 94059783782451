import { createSelector } from 'reselect';

export const categoriesGroupSelector = (state: $TSFixMe) =>
  state.categoryGroups;

export const questionGroups = createSelector(
  [categoriesGroupSelector],
  // @ts-expect-error TS(7006): Parameter '_' implicitly has an 'any' type.
  (categoryGroups) => categoryGroups.filter((_, key) => key !== 'values'),
);

export const scoreGroups = createSelector(
  [categoriesGroupSelector],
  // @ts-expect-error TS(7006): Parameter '_' implicitly has an 'any' type.
  (categoryGroups) => categoryGroups.filter((_, key) => key !== 'open_ended'),
);

export const standardScoreGroups = createSelector(
  [categoriesGroupSelector],
  (categoryGroups) =>
    categoryGroups.filter(
      (_: $TSFixMe, key: $TSFixMe) => key !== 'open_ended' && key !== 'values',
    ),
);

export const mainCategoryGroup = createSelector(
  [categoriesGroupSelector],
  (categoryGroups) => {
    if (categoryGroups.size > 0) {
      return categoryGroups.first().id;
    }
    return null;
  },
);

export const hasFullGroupAccess = (state: $TSFixMe, group: $TSFixMe) => {
  return (
    state.categoryGroups.filter(
      (category: $TSFixMe) => category.group === group,
    ) && state.categoryGroups.getIn([group, 'access']) === 'full'
  );
};

export const hasOverviewAccess = (state: $TSFixMe, group: $TSFixMe) => {
  const categoryGroup = state.categoryGroups.get(group);

  return categoryGroup && categoryGroup.hasOutcome;
};

export const hasCategoryAccess = (state: $TSFixMe, category: $TSFixMe) => {
  if (!category) {
    return false;
  }

  const categoryGroup = state.categoryGroups.get(category.group);

  if (!categoryGroup) {
    return false;
  }

  if (categoryGroup.access === 'full') {
    return true;
  }

  return (
    categoryGroup.categoryIds &&
    (categoryGroup.categoryIds.includes(category.id) ||
      categoryGroup.categoryIds.includes(category.parentCategoryId))
  );
};
