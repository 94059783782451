import { Map } from 'immutable';

import GroupFrequency from '@peakon/records/GroupFrequencyRecord';

function groupFrequencies(
  state = Map<string, GroupFrequency>(),
  action: $TSFixMe,
): Map<string, GroupFrequency> {
  switch (action.type) {
    case 'SCHEDULE_FREQUENCIES_READ_SUCCESS': {
      const { data: groups } = action.data;

      return Map(
        // @ts-expect-error TS(7006): Parameter 'group' implicitly has an 'any' type.
        groups.map((group) => [
          group.attributes.group,
          GroupFrequency.fromGroup(group),
        ]),
      );
    }

    case 'SCHEDULE_FREQUENCIES_STATUS_UPDATE': {
      const { group, status } = action.data;
      return state.setIn([group, 'status'], status ? 'enabled' : 'disabled');
    }

    case 'SCHEDULE_FREQUENCIES_UPDATE': {
      const { group, type, frequency, categoryId } = action.data;

      if (categoryId) {
        return state.updateIn([group, 'categories'], (categories) => {
          const categoryIndex = categories.findIndex(
            (category: $TSFixMe) => category.id === categoryId,
          );

          // when doing List#update, negative numbers means updating the list
          // from the back. Ensure index >= 0
          if (categoryIndex < 0) {
            return categories;
          }

          // @ts-expect-error TS(7006): Parameter 'category' implicitly has an 'any' type.
          return categories.update(categoryIndex, (category) =>
            category.set(type, frequency),
          );
        });
      }

      // when updating the driver frequency, if setting it to a value that would make
      // the childFrequency be lower, then set it to the same value
      if (type === 'overallFrequency') {
        const childFrequency = state.getIn([group, 'childFrequency']);

        if (frequency > childFrequency) {
          return state
            .setIn([group, type], frequency)
            .setIn([group, 'childFrequency'], frequency);
        }
      }

      return state.setIn([group, type], frequency);
    }

    case 'SCHEDULE_FREQUENCY_FLUSH': {
      return Map<string, GroupFrequency>();
    }
  }

  return state;
}

// eslint-disable-next-line import/no-default-export
export default groupFrequencies;
