import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

const schema = z.object({
  total: z.number().optional(),
  totalFiltered: z.number().optional().nullable(),
});

type Schema = z.infer<typeof schema>;

class GroupExport extends Record<Schema>({
  total: undefined,
  totalFiltered: undefined,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'GroupExport',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}

// eslint-disable-next-line import/no-default-export
export default GroupExport;
