import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // Stale time needs to be slightly higher than 0 to ensure we don't refetch the data immediately again if a child component shares the same query as their parent component.
      staleTime: 15 * 1000, // 15 seconds
    },
  },
});
