import { Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Context from '../ContextRecord';
import { validateTestingSchema } from '../utils';

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

export class ObservationRecord
  extends Record({
    id: undefined,
    date: undefined,
    value: undefined,

    context: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  date?: string;
  value?: number;
  context?: Context;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ObservationRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ObservationRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { date, value },
      relationships: { context },
    } = data;

    return new ObservationRecord({
      id,
      date,
      value,
      context: Context.createFromApi(context),
    });
  }
}
