import managerOnboarding from './managerOnboarding/managerOnboarding';
import prioritiesOnboarding from './prioritiesOnboarding/prioritiesOnboarding';
import semanticTopics from './semanticTopicsOnboarding/semanticTopics';

const tours = {
  managerOnboarding,
  prioritiesOnboarding,
  semanticTopics,
};

// eslint-disable-next-line import/no-default-export
export default tours;
