import React from 'react';

import SharedCheckRedirectToSubdomain from '@peakon/shared/features/subdomains/components/CheckRedirectToSubdomain';

import { useAppSelector } from '../../../utils/reduxHooks';

type Props = {
  children: React.ReactNode;
};

/**
 *
 * Check whether the user needs to be redirected to the primary subdomain. If so, redirect them to the subdomain, if not, render the children.
 */
const CheckRedirectToSubdomain = ({ children }: Props) => {
  const isSessionLoading = useAppSelector((state) => {
    // session.isLoading is always false, so this is a poor man's way of checking if the session is loading
    return !state.session.accountId;
  });

  const primarySubdomain = useAppSelector((state) => {
    const subdomains =
      state.session.raw?.relationships?.company?.relationships
        ?.companySubdomains;
    return subdomains?.find((s) => s?.attributes.primary === true)?.attributes
      .subdomain;
  });

  return (
    <SharedCheckRedirectToSubdomain
      isLoading={isSessionLoading}
      primarySubdomain={primarySubdomain}
      shouldPreserveSession
    >
      {children}
    </SharedCheckRedirectToSubdomain>
  );
};

// eslint-disable-next-line import/no-default-export
export default CheckRedirectToSubdomain;
