import get from 'lodash/get';

import Account from '@peakon/records/AccountRecord';

type Action =
  | {
      type: 'SESSION_RESTORED' | 'SESSION_READ_SUCCESS';
      data: unknown;
    }
  | {
      type: 'LOCATION_SUCCESS';
      data: unknown;
    };

const account = (
  state: Account | null = null,
  action: Action,
): Account | null => {
  switch (action.type) {
    case 'SESSION_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const relationshipAccount = get(
        action.data,
        'data.relationships.account',
      );

      if (!relationshipAccount) {
        return state;
      }

      return Account.createFromApi(relationshipAccount);
    }
    case 'LOCATION_SUCCESS': {
      // @ts-expect-error Property 'city' does not exist on type 'unknown'.ts(2339)
      const { city } = action.data;
      // @ts-expect-error Property 'country' does not exist on type 'unknown'.ts(2339)
      const { country } = action.data;
      // @ts-expect-error Property 'countryCode' does not exist on type 'unknown'.ts(2339)
      const { countryCode } = action.data;

      return state
        ? state.setLocation({
            city,
            country,
            countryCode,
          })
        : state;
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default account;
