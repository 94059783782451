import { useEffect } from 'react';

import { z } from 'zod';

import usePrevious from '@peakon/shared/hooks/usePrevious';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { Task } from '../types';

const logItem = z.object({
  severity: z.enum(['warning', 'info']),
  message: z.string(),
});

const completedDataSchema = z.object({
  status: z.literal('completed'),
  result: z.object({
    created: z.number(),
    updated: z.number(),
    skipped: z.number(),
  }),
  logs: z.union([
    z.array(
      logItem.extend({
        position: z.never().optional(),
      }),
    ),

    z.array(
      logItem.extend({
        position: z.string(),
      }),
    ),

    z.array(
      logItem.extend({
        /**
         *
         * FIXME: Get the shape of this from the backend
         *
         */

        position: z.record(z.string(), z.unknown()),
      }),
    ),
  ]),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
  errorId: z.string().optional(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('externalMetricsImport');

const externalMetricsImportSchema = z.object({
  id: z.string(),
  onProgress: z.function(
    z.tuple([inProgressDataSchema]).rest(z.never()),
    z.void(),
  ),
  onError: z.function(z.tuple([errorDataSchema]).rest(z.never()), z.void()),
  onComplete: z.function(
    z.tuple([completedDataSchema]).rest(z.never()),
    z.void(),
  ),
  type: typeSchema,
  data: dataSchema,
});

export type ExternalMetricsImportTask = z.infer<
  typeof externalMetricsImportSchema
>;

export type ExternalMetricsImportTaskInitializationData = Pick<
  ExternalMetricsImportTask,
  'type' | 'onProgress' | 'onComplete' | 'onError'
>;

export const isExternalMetricsImportTask = (
  task: Task,
  // eslint-disable-next-line no-restricted-syntax
): task is ExternalMetricsImportTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, externalMetricsImportSchema, {
      errorMessagePrefix: 'ExternalMetricsImportTask',
    });
    return true;
  }
  return false;
};

export const ExternalMetricsImportHandler = ({
  task,
}: {
  task: ExternalMetricsImportTask;
}) => {
  const previousStatus = usePrevious(task.data.status);

  useEffect(() => {
    if (previousStatus !== task.data.status) {
      if (task.data.status === 'completed') {
        task.onComplete(task.data);
      }
      if (task.data.status === 'error') {
        task.onError(task.data);
      }
      if (task.data.status === 'inProgress') {
        task.onProgress(task.data);
      }
    }
  }, [task, previousStatus]);

  return null;
};
