import { useSyncExternalStore } from 'react';

import { z } from 'zod';

import { isLocalStorageSupported } from '@peakon/shared/utils/localStorage';

export const COOKIE_CONSENT_LOCAL_STORAGE_KEY = 'cookie_consent';

export const cookieConsentCategories = ['analytics'] as const;
export type CookieConsentCategories = (typeof cookieConsentCategories)[number];

export type CookieConsentRecord<TRecordValue> = Record<
  CookieConsentCategories,
  TRecordValue
>;

const defaultCookieConsentValues: CookieConsentRecord<undefined> = {
  analytics: undefined,
};

const cookieConsentVersions: CookieConsentRecord<string> = {
  analytics: 'v2024-10-16',
};

const localStorageCategoryAnswerSchema = z.object({
  version: z.string(),
  answer: z.union([z.literal('yes'), z.literal('no')]),
});

const localStorageDataSchema = z
  .object({
    analytics: localStorageCategoryAnswerSchema,
  })
  .optional()
  .catch(undefined)
  .transform((value) => value ?? defaultCookieConsentValues);

export type LocalStorageCategoryAnswer = z.infer<
  typeof localStorageCategoryAnswerSchema
>;

type CallBack = () => void;
const subscribers = new Set<CallBack>();

export const useCookieConsentService = () => {
  const subscribe = (callback: CallBack) => {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  };

  const notifySubscribers = () => {
    subscribers.forEach((callback) => {
      callback();
    });
  };

  const localStorageData = useSyncExternalStore(subscribe, () => {
    if (isLocalStorageSupported) {
      return window.localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY);
    }
  });

  const cookieConsentAnswers = localStorageData
    ? localStorageDataSchema.parse(JSON.parse(localStorageData))
    : defaultCookieConsentValues;

  const updateLocalStorage = (
    consent: CookieConsentRecord<LocalStorageCategoryAnswer>,
  ) => {
    if (isLocalStorageSupported) {
      window.localStorage.setItem(
        COOKIE_CONSENT_LOCAL_STORAGE_KEY,
        JSON.stringify(consent),
      );
      notifySubscribers();
    }
  };

  const setCookieConsent = (answers: CookieConsentRecord<boolean>) => {
    updateLocalStorage({
      analytics: {
        version: cookieConsentVersions.analytics,
        answer: answers.analytics ? 'yes' : 'no',
      },
    });
  };

  const acceptAllCookieCategories = () => {
    updateLocalStorage({
      analytics: { version: cookieConsentVersions.analytics, answer: 'yes' },
    });
  };

  const declineAllCookieCategories = () => {
    updateLocalStorage({
      analytics: { version: cookieConsentVersions.analytics, answer: 'no' },
    });
  };

  const hasGivenConsent = (cookieConsentCategory: CookieConsentCategories) => {
    const CookieConsentForCategory =
      cookieConsentAnswers[cookieConsentCategory];

    if (!CookieConsentForCategory) {
      return false;
    }

    if (
      CookieConsentForCategory.version !==
      cookieConsentVersions[cookieConsentCategory]
    ) {
      return false;
    }

    return CookieConsentForCategory.answer === 'yes';
  };

  const shouldPromptForConsent =
    isLocalStorageSupported &&
    cookieConsentCategories.some(
      (key) =>
        !cookieConsentAnswers[key] ||
        cookieConsentAnswers[key].version !== cookieConsentVersions[key],
    );

  return {
    acceptAllCookieCategories,
    declineAllCookieCategories,
    hasGivenConsent,
    shouldPromptForConsent,
    setCookieConsent,
    localStorageData,
    cookieConsentAnswers,
  };
};
