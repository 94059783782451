import React from 'react';

import classnames from 'classnames';
import { createPortal } from 'react-dom';

import { useShellContext } from '../../index';
import { SystemNewVersionBar } from '../SystemNewVersionBar';
import SystemStatusChecker from '../SystemStatusChecker';

import styles from './styles.css';

const SystemAlertBar = ({
  systemConsultantBar,
}: {
  systemConsultantBar?: React.ReactNode;
}) => {
  const { isAuthenticated, hasOutage, isConsultant, isPartner, hasNewVersion } =
    useShellContext();

  if (!isAuthenticated || !window.document.body) {
    return null;
  }

  const hasSystemBar = hasOutage || isConsultant || isPartner || hasNewVersion;

  const portalProps = hasSystemBar
    ? {
        'aria-hidden': false,
        role: 'alert',
      }
    : {
        'aria-hidden': true,
      };

  return createPortal(
    <div
      {...portalProps}
      className={classnames(styles.root, {
        [styles.hidden]: !hasSystemBar,
        [styles.positive]:
          !hasOutage && !isConsultant && !isPartner && hasNewVersion,
        [styles.negative]: hasOutage || isConsultant || isPartner,
      })}
    >
      <div className={styles.content}>
        <SystemStatusChecker />
        <SystemNewVersionBar />
        {systemConsultantBar}
      </div>
    </div>,
    window.document.body,
  );
};

// eslint-disable-next-line import/no-default-export
export default SystemAlertBar;
