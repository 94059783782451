import React from 'react';

import classnames from 'classnames';

import { NavigationAdministrationValuesIcon as ValueIcon } from '@peakon/bedrock/icons/system';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type BadgeProps = {
  iconOnly?: boolean;
  size?: string;
};

export const Badge = ({ iconOnly, size }: BadgeProps) => (
  <div
    className={classnames(styles.badge, styles.value, {
      // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
      [styles[size]]: size,
      [styles.iconOnly]: iconOnly,
    })}
  >
    {size === 'tiny' ? (
      <ValueIcon className={styles.tinyIcon} />
    ) : (
      <ValueIcon className={styles.icon} />
    )}
    {!iconOnly && (
      <div className={styles.label}>{t('dashboard__values__value')}</div>
    )}
  </div>
);

type ValueBadgeProps = {
  badgeSize?: string;
  nameSize?: 'default' | 'large';
  modifier?: string;
  onClick?: $TSFixMeFunction;
  value?: string;
  className?: string;
  iconOnly?: boolean;
};

const ValueBadge = ({
  badgeSize = 'tiny',
  nameSize = 'default',
  modifier,
  onClick,
  value,
  className,
  iconOnly = false,
}: ValueBadgeProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className={classnames(
      styles.container,
      {
        [styles.link]: onClick,
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        [styles[modifier]]: modifier,
      },
      className,
    )}
    onClick={onClick}
  >
    <span
      className={classnames(styles.name, {
        [styles[nameSize]]: nameSize,
      })}
      data-test-id="value-badge"
    >
      {value}
    </span>
    {/* @ts-expect-error TS(2322): Type '{ size: string; t: { (key: string): string &... Remove this comment to see the full error message */}
    <Badge size={badgeSize} t={t} iconOnly={iconOnly} />
  </div>
);

// eslint-disable-next-line import/no-default-export
export default ValueBadge;
