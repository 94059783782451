export const COMPANY_SIZES = [
  '1-25',
  '26-50',
  '51-150',
  '151-500',
  '501-1000',
  '1,001-5,000',
  '5,001-10,000',
  '>10,000',
];
