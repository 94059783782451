import get from 'lodash/get';

export const getLegacyDriver = (driver: $TSFixMe) => [
  get(driver, 'attributes.driver') ||
    get(driver, 'relationships.category.attributes.legacyDriver'),
  get(driver, 'attributes.subdriver') ||
    get(driver, 'relationships.category.attributes.legacySubdriver'),
];

const criticalFromJsonApi = (critical: $TSFixMe) => {
  if (!critical || critical.data === null) {
    return {};
  }

  return {
    id: get(critical, 'data.id') || get(critical, 'id'),
    context: get(critical, 'relationships.context', {}),
  };
};

const priorityFromJsonApi = (priority: $TSFixMe) => {
  if (!priority || priority.data === null) {
    return {};
  }

  return {
    id: get(priority, 'data.id') || get(priority, 'id'),
    context: get(priority, 'relationships.context', {}),
    setByEmployee: get(priority, 'relationships.setByEmployee', undefined),
  };
};

export const fromJsonApi = (data: $TSFixMe, { strict = true } = {}) => {
  const {
    id: driverSubdriverId,
    attributes: {
      driver: id,
      subdriver,
      impact,
      changes,
      scores = {},
      classification,
      rank,
      priorityStatus,
      teamSuggestionsCount,
      unreadTeamSuggestionsCount,
      allowTeamSuggestions,
      ...otherAttributes
    },
  } = data;

  const priority = get(data, 'relationships.priority');
  const critical = get(data, 'relationships.critical');
  const setByEmployee =
    get(data, 'relationships.setByEmployee') ||
    get(priority, 'relationships.setByEmployee');
  const context =
    get(data, 'relationships.context') ||
    get(priority, 'relationships.context');
  const category = get(data, 'relationships.category');
  const categoryId = category ? category.id : null;
  const [legacyDriver, legacySubdriver] = getLegacyDriver(data);

  const parsed = {
    id,
    driver: legacyDriver || id,
    driverSubdriverId,
    classification,
    subdriver: legacySubdriver || subdriver,
    rank,
    impact,
    changes,
    categoryId,
    scores: scores || {},
    subdrivers: scores.subdrivers || {},
    critical: criticalFromJsonApi(critical),
    priority: priorityFromJsonApi(priority),
    priorityStatus,
    teamSuggestionsCount,
    unreadTeamSuggestionsCount,
    allowTeamSuggestions,
    setByEmployee,
    categoryData: category,
    context,
  };

  if (!strict) {
    return { ...otherAttributes, ...parsed };
  }

  return parsed;
};
