import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { UxFlow } from '@peakon/records';

import ProductTourContextProvider from './ProductTourContextProvider/ProductTourContextProvider';
import { ProductTourKey } from './ProductTourContextProvider/types';
import { complete } from '../actions/UxFlowsActions';
import tours from '../tours/tours';
import { useAppDispatch, useAppSelector } from '../utils/reduxHooks';

type Props = {
  children: React.ReactNode;
};

const ProductTourProvider = ({ children }: Props) => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows);

  const handleComplete = async (tour: ProductTourKey) => {
    if (!tour) {
      return;
    }

    const { uxFlow, queryParam } = tours[tour];
    if (uxFlow) {
      const { entity, key } = uxFlow;

      const flowKey = UxFlow.id(entity, key);
      const flowStatus = flows.has(flowKey)
        ? flows.get(flowKey).completed
        : undefined;

      if (flowStatus === false) {
        await dispatch(complete(entity, key));
      }
    }

    if (queryParam) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has(queryParam)) {
        queryParams.delete(queryParam);
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  };

  return (
    <ProductTourContextProvider onComplete={handleComplete} tours={tours}>
      {children}
    </ProductTourContextProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductTourProvider;
