import React from 'react';

import { useController } from 'react-hook-form';
import { z } from 'zod';

import {
  Fieldset as BRFieldset,
  RadioButton as BRRadioButton,
} from '@peakon/bedrock/react/form';
import { Stack } from '@peakon/bedrock/react/layout';

import { ControlledProps, ZodSchemaOrRecord } from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type FieldsetProps = React.ComponentPropsWithRef<typeof BRFieldset>;

type RadioFieldProps = Omit<FieldsetProps, 'legend' | 'hideLegend'> & {
  required?: boolean;
  groupLabel: FieldsetProps['legend'];
  hideGroupLabel?: FieldsetProps['hideLegend'];
};

type RadioItemProps = React.ComponentPropsWithRef<typeof BRRadioButton>;

type FormRadioItem = React.FC<Omit<RadioItemProps, 'name' | 'required'>> & {
  children: React.ReactNode;
};

type Props<TSchema extends ZodSchemaOrRecord> = ControlledProps<
  TSchema,
  RadioFieldProps
>;

export function RadioGroup<TSchema extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<TSchema>,
) {
  const {
    name,
    shouldUnregister,
    disabled,
    required,
    children,
    groupLabel,
    hideGroupLabel,
    ...restFieldsetProps
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  const { onChange, ...restField } = field;

  // Pass required and name field to each child (radio item)
  const radioItems = React.Children.map(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    children as React.ReactElement<RadioItemProps>[],
    (radioItem) =>
      React.isValidElement(radioItem) &&
      React.cloneElement(radioItem, {
        required: isRequired,
        onChange,
        name,
        checked: field.value === radioItem.props.value,
      }),
  );

  return (
    <BRFieldset
      legend={groupLabel}
      hideLegend={hideGroupLabel}
      status={error && 'error'}
      feedbackMessage={error?.message}
      {...restFieldsetProps}
      {...restField}
    >
      <Stack spacing={8}>{radioItems}</Stack>
    </BRFieldset>
  );
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
RadioGroup.Item = BRRadioButton as unknown as FormRadioItem;
