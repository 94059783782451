import React, { Fragment } from 'react';

import { Route, RouteProps } from 'react-router-dom';

import { CoreCategoryGroup } from '@peakon/records/constants/questionSets';
import { CategoryGroupAccessType } from '@peakon/shared/utils/categoryGroups';
import { Right } from '@peakon/shared/utils/rights/types';

import RedirectToAvailableRoute from './RedirectToAvailableRoute';
import { useRouteAccess } from '../../hooks/useRouteAccess';

type ComputedMatch = {
  path: Array<string>;
  params: {
    group?: CoreCategoryGroup;
  };
};

type RestrictedRouteComponentProps = Required<Pick<RouteProps, 'component'>> & {
  render?: never;
};
type RestrictedRouteRenderProps = Required<Pick<RouteProps, 'render'>> & {
  component?: never;
};
type BaseRestrictedRouteProps = React.ComponentPropsWithoutRef<typeof Route> & {
  addOn?: string | string[];
  categoryGroup?: CoreCategoryGroup;
  categoryGroupAccessType?: CategoryGroupAccessType;
  sessionRight?: boolean;
  feature?: string;
  rights?: Right[];
  computedMatch?: ComputedMatch;
};
type RestrictedRouteProps = BaseRestrictedRouteProps &
  (RestrictedRouteComponentProps | RestrictedRouteRenderProps);

const isGroupRoute = (computedMatch?: ComputedMatch) => {
  if (!computedMatch) {
    return false;
  }

  if (
    computedMatch.path.includes(`/dashboard/group/:group`) &&
    computedMatch.params.hasOwnProperty('group')
  ) {
    return true;
  }

  return false;
};

export const RestrictedRoute = ({
  addOn,
  categoryGroup,
  categoryGroupAccessType,
  sessionRight = false,
  feature,
  rights,
  component: Component,
  render,
  ...rest
}: RestrictedRouteProps) => {
  const categoryGroupAccess = isGroupRoute(rest.computedMatch) || categoryGroup;
  const categoryGroupRoute = rest.computedMatch?.params?.group || categoryGroup;

  const { access: hasAccess } = useRouteAccess({
    addOn,
    categoryGroupAccess,
    categoryGroupAccessType,
    categoryGroupRoute,
    sessionRight,
    feature,
    right: rights,
  });

  if (!hasAccess) {
    return <RedirectToAvailableRoute />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Fragment>
            {render ? render(props) : <Component {...rest} {...props} />}
          </Fragment>
        );
      }}
    />
  );
};
