import React, { Fragment, useState, useEffect } from 'react';

import { t } from '@peakon/shared/features/i18next/t';

import Menu, {
  Title,
  List,
  AccordionList,
  Item,
  ItemLink,
  Spacer,
} from './Menu';
import { customAnalyticsEvents } from '../../../features/analytics/customAnalyticsEvents';
import { getLinkType } from '../getLinkType';
import useIsCollapsible from '../hooks/useIsCollapsible';
import { useNavigation } from '../hooks/useNavigation';
import { ProductName } from '../hooks/useNavigation/types';

import styles from './styles.css';

type Props = {
  isTest?: boolean;
  location?: 'side' | 'top';
  productToShow?: ProductName | null;
  onMenuItemClick?: () => void;
};

function SideNavigation({
  location = 'side',
  productToShow,
  isTest,
  onMenuItemClick,
}: Props) {
  const isCollapsible = useIsCollapsible();
  const {
    isLoading,
    navigation,
    activeProductName,
    activeMenuGroupName,
    isFallbackActiveProduct,
  } = useNavigation({ isTest });

  const [expanded, setExpanded] = useState<string | null>();

  useEffect(() => {
    if (!isLoading && activeMenuGroupName !== expanded) {
      setExpanded(activeMenuGroupName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuGroupName, isLoading]);

  if (!navigation || (location === 'side' && isCollapsible)) {
    return null;
  }

  // the collapsible nav can "browse" other product's menus
  const productCurrentlyShown = navigation[productToShow || activeProductName];
  const activeProduct = navigation[activeProductName];

  function handleExpandClick(name: string) {
    setExpanded(expanded === name ? null : name);
  }

  if (isLoading || !productCurrentlyShown || !activeProduct) {
    return (
      <Menu
        isCollapsible={isCollapsible}
        className={styles.root}
        data-test-id="navigation-side"
      >
        <List data-test-id="navigation-side-list" />
      </Menu>
    );
  }

  return (
    <Menu
      isCollapsible={isCollapsible}
      className={styles.root}
      data-test-id="navigation-side"
    >
      <List data-test-id="navigation-side-list">
        {productCurrentlyShown.children.map((menuGroup) => {
          const TitleComponent = menuGroup.isAccordion
            ? AccordionList.Title
            : Title;

          const ListComponent = menuGroup.isAccordion ? AccordionList : List;

          const titleProps = menuGroup.isAccordion
            ? {
                'aria-controls': `${menuGroup.name}_accordion`,
                onClick: () => handleExpandClick(menuGroup.name),
                isExpanded: expanded === menuGroup.name,
              }
            : {};

          const listProps = menuGroup.isAccordion
            ? {
                id: `${menuGroup.name}_accordion`,
                isExpanded: expanded === menuGroup.name,
              }
            : {};

          return (
            <Item key={menuGroup.name}>
              <TitleComponent
                {...titleProps}
                data-test-id={menuGroup.dataTestId}
              >
                {menuGroup.menuLabel}
              </TitleComponent>
              <ListComponent {...listProps}>
                {menuGroup.children?.map((menuItem) => {
                  const linkType = getLinkType({
                    isFallbackActiveProduct,
                    to: productToShow ?? activeProductName,
                    from: activeProductName,
                  });

                  return (
                    <Item key={menuItem.name}>
                      <ItemLink
                        data-test-id={menuItem.dataTestId}
                        onClick={() => {
                          customAnalyticsEvents.trackNavigation({
                            level: 'second',
                            name_first_level: productCurrentlyShown.name,
                            name_second_level: menuItem.name,
                          });
                          onMenuItemClick?.();
                        }}
                        {...(menuItem.getPath && { to: menuItem.getPath() })}
                        isActive={menuItem.isActive}
                        aria-current={menuItem.isActive && 'page'}
                        linkType={linkType}
                      >
                        {menuItem.menuLabel}
                      </ItemLink>
                    </Item>
                  );
                })}
                {menuGroup.name === 'profile' && isCollapsible && (
                  <Fragment>
                    <Spacer />
                    <Item>
                      <ItemLink href="/login/logout" linkType="a">
                        {t('navbar__logout')}
                      </ItemLink>
                    </Item>
                  </Fragment>
                )}
              </ListComponent>
            </Item>
          );
        })}
      </List>
    </Menu>
  );
}

// eslint-disable-next-line import/no-default-export
export default SideNavigation;
