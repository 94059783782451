import { assignWindowLocation } from '@peakon/shared/utils/window';

export type RedirectToOptions = {
  returnTo?: string;
  params?: URLSearchParams;
  keepQueryParamsOnRedirect?: boolean;
};

export function getRedirectToURL(
  url: `/${string}`,
  {
    returnTo = window.location.pathname,
    params = new URLSearchParams(window.location.search),
    keepQueryParamsOnRedirect = false,
  }: RedirectToOptions = {},
) {
  const newLocation = new URL(url, window.location.origin);

  if (returnTo?.length > 1 && !returnTo.includes('/login')) {
    params.set('redirectTo', returnTo);
  }

  for (const [key, value] of params.entries()) {
    if (keepQueryParamsOnRedirect && key === 'redirectTo') {
      newLocation.searchParams.set(
        key,
        `${returnTo}?${newLocation.searchParams.toString()}`,
      );
    } else {
      newLocation.searchParams.set(key, value);
    }
  }

  return newLocation;
}

export function redirectTo(...args: Parameters<typeof getRedirectToURL>) {
  const redirectUrl = getRedirectToURL(...args);
  assignWindowLocation(redirectUrl.toString());
}
