import { combineReducers } from '@reduxjs/toolkit';
import get from 'lodash/get';

import columns from './columns';
import rows from './rows';
import scores from './scores';
import segments from './segments';

const initialState = {
  booting: true,
  isLoadingColumns: false,
  mode: 'score',
  group: null,
  round: null,
  isScoresEmpty: false,
};

const filtersState = (state = initialState, action: $TSFixMe) => {
  switch (action.type) {
    case 'HEATMAP_ATTRITION':
    case 'CRITICAL_SEGMENTS_READ_SUCCESS': {
      return {
        ...state,
        booting: false,
      };
    }

    case 'HEATMAP_REVIVE': {
      const {
        isLoadingColumns: _isLoadingColumns,
        booting: _booting,
        ...filters
      } = action.data.filters;

      return {
        ...state,
        ...filters,
        booting: false,
      };
    }

    case 'HEATMAP_CLOSE': {
      return {
        ...initialState,
        group: state.group,
        isScoresEmpty: state.isScoresEmpty,
      };
    }

    case 'HEATMAP_SEGMENT_CONTEXT_LOADING': {
      return {
        ...state,
        isScoresEmpty: false,
      };
    }

    case 'HEATMAP_COLUMN_LIST_LOADING': {
      const { group } = action.data;

      return {
        ...state,
        group,
        isLoadingColumns: true,
      };
    }

    case 'HEATMAP_COLUMN_LIST_SUCCESS': {
      return {
        ...state,
        isLoadingColumns: false,
        booting: false,
      };
    }

    case 'HEATMAP_FILTERS_RESET': {
      return {
        ...initialState,
        group: state.group,
        booting: state.booting,
      };
    }

    case 'HEATMAP_FILTERS_UPDATED': {
      const { filter, value } = action.data;

      const nextState = {
        ...state,
        [filter]: value,
      };

      if (state.mode === 'diffToCustomRound' && state.mode !== nextState.mode) {
        nextState.round = null;
      }

      return nextState;
    }

    case 'CONTEXT_LIST_SUCCESS': {
      const categoryGroups = get(
        action.data,
        'data[0].relationships.contextCategoryGroups',
        [],
        // @ts-expect-error TS(7006): Parameter 'contextCategoryGroup' implicitly has an... Remove this comment to see the full error message
      ).map((contextCategoryGroup) =>
        get(contextCategoryGroup, 'relationships.categoryGroup.id'),
      );

      // switching to a context without this group
      if (!categoryGroups.includes(state.group)) {
        return {
          ...state,
          group: categoryGroups[0],
        };
      }

      return state;
    }

    case 'HEATMAP_SCORES_CHECK_IF_EMPTY': {
      const { scores: scoresData } = action.data;

      if (!scoresData) {
        return {
          ...state,
          isScoresEmpty: true,
        };
      }

      const scoresValues = scoresData.values();
      let iteratorValue = scoresValues.next().value;

      while (iteratorValue) {
        for (const val of Object.values(iteratorValue)) {
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          if (val?.scores && val.scores.size > 0 && !val.scores.anonymity) {
            return {
              ...state,
              isScoresEmpty: false,
            };
          }
        }
        iteratorValue = scoresValues.next().value;
      }

      return {
        ...state,
        isScoresEmpty: true,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  columns,
  rows,
  scores,
  segments,
  filters: filtersState,
});
