import z from 'zod';

const Role = [
  'admin',
  'cs_leader',
  'csm',
  'developer',
  'sales',
  'support',
  'tester',
  'partner_consultant',
] as const;
export const RoleEnum = z.enum(Role);

const ColorMode = ['normal', 'deuteranopia'] as const;
const CommentMachineTranslationMode = ['original', 'english'] as const;

export const ColorModeEnum = z.enum(ColorMode);
export const CommentMachineTranslationModeEnum = z.enum(
  CommentMachineTranslationMode,
);

const TwofactorStatus = ['enabled', 'disabled'] as const;
export const TwofactorStatusEnum = z.enum(TwofactorStatus);

export const accountSchema = z.object({
  id: z.string(),
  type: z.literal('accounts'),
  attributes: z.object({
    bouncedAt: z.string().nullable(),
    bounceReason: z.string().nullable(),
    email: z.string().nullable(),
    locale: z.string().nullable(),
    localeEffective: z.string().nullable(),
    timezone: z.string().nullable(),
    timezoneEffective: z.string().nullable(),
    role: RoleEnum.nullable(),
    settings: z
      .object({
        colorMode: ColorModeEnum,
        commentMachineTranslationMode:
          CommentMachineTranslationModeEnum.catch('original'),
      })
      .optional(),
  }),
});

export type AccountResponse = z.infer<typeof accountSchema>;
