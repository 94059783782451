import get from 'lodash/get';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';

import { SessionData } from '@peakon/shell/types/Session';

import RequirementEvaluator from './RequirementEvaluator';
import { and, or } from './requirements';
import { AuthType, Right } from './types';
import { ContextResponse } from '../../schemas/api/contexts';
import categoryGroupService from '../categoryGroups';
import featureFactory from '../featureFactory';
import hasRight from '../hasRight';

const requirementEvaluators = {
  actions: (auth: AuthType) =>
    auth.hasAddOn('actions') &&
    auth.hasContextRight(['action:admin', 'action:read']),

  attribute: (auth: AuthType) =>
    auth.hasSessionRight(['attribute:admin', 'segment:admin']),
  applicationAdmin: (auth: AuthType) =>
    auth.hasSessionRight('application:admin'),
  anyScore: (auth: AuthType) => auth.hasSessionRight('score'),
  companyAdmin: (auth: AuthType) => auth.hasSessionRight('company:admin'),
  datasetCompare: (auth: AuthType) => auth.hasContextRight('dataset:compare'),
  datasetRead: (auth: AuthType) => auth.hasContextRight('dataset:read'),
  commentRead: (auth: AuthType) =>
    auth.hasContextRight(['comment:driver:read', 'comment:text:read']),
  commentTopic: (auth: AuthType) =>
    auth.hasAddOn('comment_topics') || auth.hasFeature('comment_topics'),
  employeeRead: (auth: AuthType) => auth.hasContextRight('employee:read'),
  employeeExperience: (auth: AuthType) => auth.hasAddOn('employee_experience'),
  engagement: (auth: AuthType) =>
    categoryGroupService.hasGroupAccess('engagement', auth.context),
  engagementOverview: (auth: AuthType) =>
    categoryGroupService.hasOverviewAccess('engagement', auth.context),
  diversityInclusionOverview: (auth: AuthType) =>
    categoryGroupService.hasOverviewAccess('diversity_inclusion', auth.context),
  diversityInclusionFull: (auth: AuthType) =>
    categoryGroupService.hasGroupAccess(
      'diversity_inclusion',
      auth.context,
      'full',
    ),
  healthWellbeingOverview: (auth: AuthType) =>
    categoryGroupService.hasOverviewAccess('health_wellbeing', auth.context),
  transformationChangeOverview: (auth: AuthType) =>
    categoryGroupService.hasOverviewAccess(
      'transformation_change',
      auth.context,
    ),
  healthWellbeingFull: (auth: AuthType) =>
    categoryGroupService.hasGroupAccess(
      'health_wellbeing',
      auth.context,
      'full',
    ),
  engagementFull: (auth: AuthType) =>
    categoryGroupService.hasGroupAccess('engagement', auth.context, 'full'),
  externalMetrics: (auth: AuthType) => auth.hasAddOn('external_metrics'),
  externalMetricsRead: (auth: AuthType) =>
    auth.hasSessionRight(['externalMetric:admin', 'externalMetric:read']),
  externalMetricsScore: (auth: AuthType) =>
    auth.hasContextRight('externalMetric:score'),
  questionAdmin: (auth: AuthType) => auth.hasContextRight('question:admin'),
  questionScore: (auth: AuthType) =>
    auth.hasContextRight('questionScoresPage:read'),
  questionSetAccess: (auth: AuthType) =>
    Boolean(categoryGroupService.getQuestionSetGroup(auth.context)),
  groupAdmin: (auth: AuthType) => auth.hasSessionRight('group:admin'),
  scheduleAdmin: (auth: AuthType) => auth.hasContextRight('schedule:admin'),
  usageMetricsRead: (auth: AuthType) =>
    auth.hasContextRight('usageMetrics:read'),
  resourcesAdmin: (auth: AuthType) =>
    auth.hasAddOn('company_resources') &&
    auth.hasSessionRight('resource:company:admin'),
  companyActionsAdmin: (auth: AuthType) =>
    auth.hasAddOn('company_actions') &&
    auth.hasSessionRight('action:company:admin'),
  valueRead: (auth: AuthType) => auth.hasAddOn('values'),
  valueCommentRead: (auth: AuthType) =>
    auth.hasContextRight('comment:value:read'),
  valueAdmin: (auth: AuthType) => auth.hasSessionRight('value:admin'),
  brandingAdmin: (auth: AuthType) => auth.hasContextRight('branding:admin'),
  multiBrand: (auth: AuthType) => auth.hasAddOn('survey_multi_brand'),
  employeeDashboard: (auth: AuthType) =>
    auth.hasSessionRight('employeeDashboard:access'),
  attrition: (auth: AuthType) =>
    auth.hasSessionRight('score:driver:attrition') &&
    auth.hasAddOn('attrition_prediction'),
  curatedInsights: (auth: AuthType) =>
    auth.hasContextRight('curatedInsights:read'),
  sensitiveAdmin: (auth: AuthType) => auth.hasSessionRight('sensitive:admin'),
};

const rightRequirements = {
  action: 'actions',
  attribute: 'attribute',
  analysis: or(
    'datasetRead',
    'datasetCompare',
    'questionOverview',
    'externalMetricsReport',
  ),
  insight: or('datasetRead', 'comment'),
  navigation: or('insight', 'improve', 'action', 'usageMetrics'),
  comment: or('commentRead', and('valueRead', 'valueCommentRead')),
  company: 'companyAdmin',
  compare: and('datasetCompare'),
  compareEngagement: and('datasetCompare', 'engagementOverview'),
  dataset: 'datasetRead',
  datasetEngagement: and('datasetRead', 'engagementOverview'),
  datasetDiversity: and('datasetRead', 'diversityInclusionOverview'),
  datasetWellbeing: and('datasetRead', 'healthWellbeingOverview'),
  datasetTransformation: and('datasetRead', 'transformationChangeOverview'),
  employee: 'employeeRead',
  group: 'groupAdmin',
  improve: 'anyScore',
  integration: 'applicationAdmin',
  question: 'questionAdmin',
  questionOverview: and('questionScore', 'questionSetAccess'),
  schedule: 'scheduleAdmin',
  topic: and('comment', 'commentTopic'),
  usageMetrics: 'usageMetricsRead',
  value: and('valueRead', 'valueAdmin', 'engagement'),
  resources: and('resourcesAdmin', 'engagement'),
  companyActions: and('companyActionsAdmin', 'engagement'),
  employeeExperience: and(
    'datasetCompare',
    'employeeExperience',
    'engagementFull',
  ),
  externalMetrics: and('externalMetrics', 'externalMetricsRead', 'engagement'),
  externalMetricsReport: and('externalMetrics', 'externalMetricsScore'),
  branding: and('brandingAdmin', 'multiBrand'),
  employeeDashboard: 'employeeDashboard',
  segmentsCards: and('datasetCompare', 'engagementFull', 'engagementOverview'),
  attrition: and('attrition', 'engagementFull'),
  curatedInsights: 'curatedInsights',
  sensitiveAdmin: 'sensitiveAdmin',
};

export function getRights({
  context,
  session,
}: {
  context?: ContextResponse | null;
  session?: SessionData;
}) {
  const contextRights: Right[] = get(context, 'attributes.rights', []);
  const sessionRights: Right[] = get(session, 'attributes.rights', []);
  const features = get(session, 'attributes.features', []);
  const company = get(session, 'relationships.company');
  const addOns = get(company, 'attributes.addOns', []);

  const activeFeatures = featureFactory(features)
    .filter((feature) => feature.active)
    .map((feature) => feature.key);

  return {
    addOns,
    features: activeFeatures,
    contextRights,
    sessionRights,
    context,
    session,
  };
}

const hasAddOns = (addOns: string[], addon: string | string[]) => {
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  addon = Array.isArray(addon) ? addon : [addon];

  return addon.every((requiredAddon) => addOns.includes(requiredAddon));
};

export function getAuthorization({
  addOns,
  context,
  contextRights,
  session,
  sessionRights,
  features,
}: {
  addOns: string[];
  context?: ContextResponse | null;
  contextRights: Right[];
  session?: SessionData;
  sessionRights: Right[];
  features: string[];
}): AuthType {
  return {
    context,
    session,
    hasAddOn(addon) {
      return hasAddOns(addOns, addon);
    },

    hasContextRight(right) {
      return hasRight(contextRights, right);
    },

    hasSessionRight(right) {
      return hasRight(sessionRights, right);
    },

    hasFeature(feature) {
      return hasRight(features, feature);
    },

    hasCategoryGroupAccess(group, accessType) {
      return categoryGroupService.hasGroupAccess(group, context, accessType);
    },
  };
}

function getRightsMapFromAuth(auth: AuthType) {
  return rightsChecker.getRightsMap(auth);
}

export function getRightsMap({
  context,
  session,
}: {
  context?: ContextResponse | null;
  session?: SessionData;
}) {
  const rights = getRights({ context, session });
  const auth = getAuthorization(rights);

  return getRightsMapFromAuth(auth);
}

export const getRightsChecker = (
  currentRightRequirements: $TSFixMe,
  currentRequirementEvaluators: $TSFixMe,
) => {
  return {
    getRightsMap(auth: AuthType) {
      const evaluator = new RequirementEvaluator(
        auth,
        currentRequirementEvaluators,
      );

      return mapValues(currentRightRequirements, (requirement) => {
        return evaluator.evaluate(requirement, currentRightRequirements);
      });
    },

    getRights(auth: AuthType) {
      const rights = this.getRightsMap(auth);

      return keys(pickBy(rights));
    },

    hasRight(auth: AuthType, right: Right) {
      const rights = this.getRightsMap(auth);

      return rights[right] || false;
    },
  };
};

const rightsChecker = getRightsChecker(
  rightRequirements,
  requirementEvaluators,
);
