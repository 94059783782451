import get from 'lodash/get';

export function getMultipleQuestionScores(
  scores: $TSFixMe,
  categoryId: $TSFixMe,
  { driverMode }: $TSFixMe = {},
) {
  return (
    scores.relationships.question.relationships.category.id === categoryId ||
    (driverMode !== 'split' &&
      get(
        scores,
        'relationships.question.relationships.category.relationships.parentCategory.id',
      ) === categoryId)
  );
}
