import React from 'react';

import classnames from 'classnames';

import { GuidanceWhatDoesThisMeanOutlineIcon as HelpIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Menu, useMenuContext } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { useShellContext } from '@peakon/shell';
import { useCookieConsentContext } from '@peakon/shell/components/CookieConsent/CookieConsentContext';

import styles from '../styles.css';

function CookieSettingsItem() {
  const { closeMenu } = useMenuContext();
  const { setIsCookieConsentModalOpened } = useCookieConsentContext();

  const handleClick = () => {
    closeMenu();
    setIsCookieConsentModalOpened(true);
  };

  return (
    <li
      className={classnames('peak-menu-item peak-menu-item--link')}
      role="none"
    >
      <UnstyledButton
        accessibleName={t('multi_product__help__menu_item__cookie_settings')}
        className="peak-menu-item"
        onClick={handleClick}
      >
        {t('multi_product__help__menu_item__cookie_settings')}
      </UnstyledButton>
    </li>
  );
}

type Props = {
  helpLabel: string;
  welcomeTourItem?: React.ReactNode;
};

const Help = ({ helpLabel, welcomeTourItem }: Props) => {
  const { session } = useShellContext();
  const hasCookieConcentPrompt = session?.data.attributes.features.includes(
    'cookieConsentPrompt',
  );

  return (
    <Menu id="app-header-help">
      <Menu.Target aria-label={helpLabel}>
        <div className={styles.iconLink}>
          <HelpIcon />
        </div>
      </Menu.Target>
      <Menu.Popover placement="bottom-start">
        <Menu.ItemLink
          href="https://doc.workday.com/en-us/peakon.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__help_centre')}
        </Menu.ItemLink>

        <Menu.ItemLink
          href="https://community-content.workday.com/content/workday-community/en-us/kits-and-tools/get-help/support/create-a-case.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__contact_support')}
        </Menu.ItemLink>

        <Menu.ItemLink
          href="https://status.peakon.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('multi_product__help__menu_item__contact_status')}
        </Menu.ItemLink>

        {hasCookieConcentPrompt ? (
          <CookieSettingsItem />
        ) : (
          <Menu.ItemLink
            href="https://www.workday.com/content/dam/web/en-us/documents/privacy/workday-peakon-employee-voice-cookie-notice.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('multi_product__help__menu_item__cookie_policy')}
          </Menu.ItemLink>
        )}

        {welcomeTourItem}
      </Menu.Popover>
    </Menu>
  );
};

// eslint-disable-next-line import/no-default-export
export default Help;
