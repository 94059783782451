import React from 'react';

import { AcknowledgementIcon } from '@peakon/shared/components/AcknowledgementIcon';
import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

const getTranslations = () =>
  createTranslationMap({
    thanks_for_sharing: t('acknowledgement__thanks_for_sharing'),
    great_idea: t('acknowledgement__great_idea'),
    working_on_it: t('acknowledgement__working_on_it'),
    would_love_to_talk_in_person: t(
      'acknowledgement__would_love_to_talk_in_person',
    ),
    i_agree: t('acknowledgement__i_agree'),
  } as const satisfies Record<AcknowledgementType, string>);

type Props = {
  type: AcknowledgementType;
  role: string;
};

const AcknowledgementLabel = ({ type, role }: Props) => {
  return (
    <div
      role={role}
      className={styles.root}
      data-test-id="acknowledgement-label"
    >
      <div className={styles.icon}>
        <AcknowledgementIcon type={type} />
      </div>
      <span className={styles.label}>{getTranslations().get(type)}</span>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default AcknowledgementLabel;
