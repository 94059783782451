import React from 'react';

import MarkdownView, {
  GlobalConfiguration,
  MarkdownViewProps,
} from 'react-showdown';

import badgeExtensions from '@peakon/showdown-badges';

import xss from '../../utils/xss';

GlobalConfiguration.setOption('extensions', [badgeExtensions]);

type Props = {
  children: string;
  components?: MarkdownViewProps['components'];
};

const Markdown = ({ children, components }: Props) => (
  <MarkdownView
    markdown={children}
    sanitizeHtml={(html) => xss.process(html)}
    components={components}
  />
);

// eslint-disable-next-line import/no-default-export
export default Markdown;
