import { Map } from 'immutable';

import { UxFlow } from '@peakon/records';

type Action = {
  type: 'FLOW_CHECK_SUCCESS' | 'FLOW_COMPLETE_SUCCESS' | 'FLOW_REMOVE_SUCCESS';
  data: Pick<UxFlow, 'entity' | 'name' | 'completed'>;
};

const flows = (
  state: Map<string, UxFlow> = Map<string, UxFlow>(),
  action: Action,
): Map<string, UxFlow> => {
  switch (action.type) {
    case 'FLOW_CHECK_SUCCESS': {
      const { entity, name, completed } = action.data;
      const flow = new UxFlow({ entity, name, completed });

      return state.set(flow.id, flow);
    }

    case 'FLOW_COMPLETE_SUCCESS':
    case 'FLOW_REMOVE_SUCCESS': {
      const { completed, entity, name } = action.data;
      const id = UxFlow.id(entity, name);

      if (state.has(id)) {
        return state.update(id, (flow) => flow.set('completed', completed));
      }

      const flow = new UxFlow({ entity, name, completed });

      return state.set(flow.id, flow);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default flows;
