import React from 'react';

import Logo from '@peakon/shared/components/Navigation/Top/Logo';

import { LogoContainer } from './LogoContainer';
import { ConfidentialityDisclaimer } from '../ConfidentialityDisclaimer/ConfidentialityDisclaimer';

import styles from './styles.css';

type Props = {
  showDisclaimer: boolean;
  companyLogo?: string;
  companyName?: string;
};

export function PageHeader({
  showDisclaimer,
  companyLogo,
  companyName,
}: Props) {
  return (
    <div className={styles.header}>
      <header className={styles.topHeader}>
        <Logo />
      </header>
      <LogoContainer companyLogo={companyLogo} companyName={companyName} />
      {showDisclaimer && <ConfidentialityDisclaimer />}
    </div>
  );
}
