import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import noop from 'lodash/noop';

export type ProxyViewManager = {
  id?: string;
  name?: string;
};

type ProxyViewContext = {
  showProxyViewModal: boolean;
  setShowProxyViewModal: Dispatch<SetStateAction<boolean>>;
  showCloseProxyViewModal: boolean;
  setCloseProxyViewModal: Dispatch<SetStateAction<boolean>>;
  proxyViewId?: string | null;
  setProxyViewId: (val?: string | null) => void;
  removeProxyView: () => void;
  proxyViewManager?: ProxyViewManager | null;
  isOnProxyViewMode: boolean;
};

const DEFAULT_CONTEXT: ProxyViewContext = {
  showProxyViewModal: false,
  setShowProxyViewModal: noop,
  showCloseProxyViewModal: false,
  setCloseProxyViewModal: noop,
  proxyViewId: null,
  setProxyViewId: noop,
  removeProxyView: noop,
  proxyViewManager: null,
  isOnProxyViewMode: false,
};

export const ProxyViewContext = createContext(DEFAULT_CONTEXT);

export function useProxyViewContext() {
  const proxyViewContext = useContext(ProxyViewContext);

  if (!proxyViewContext) {
    throw new Error(
      '`useProxyViewContext` used outside of `ProxyViewContextProvider`.',
    );
  }

  return proxyViewContext;
}
