import {
  CategoryGroup,
  CoreCategoryGroup,
} from '@peakon/records/constants/questionSets';
import { AuthType, Right } from '@peakon/shared/utils/rights/types';
import { SessionData } from '@peakon/shell/types/Session';

import { ContextResponse } from '../../schemas/api/contexts';
import { CategoryGroupAccessType } from '../categoryGroups';
import { getAuthorization, getRights } from '../rights';

type CreateAccessCheckerParams = {
  session?: SessionData;
  context?: ContextResponse | null;
};

export type Requirements = {
  addOn?: string | string[];
  categoryGroupAccess?: boolean | string;
  categoryGroupAccessType?: CategoryGroupAccessType;
  categoryGroupRoute?: CoreCategoryGroup | null;
  sessionRight?: boolean;
  feature?: string;
  right?: Right | Right[];
};

const requirementEvaluators = {
  feature: (auth: AuthType, { feature }: { feature?: string }) =>
    feature && auth.hasFeature(feature),
  addOn: (auth: AuthType, { addOn }: { addOn?: string | string[] }) =>
    addOn && auth.hasAddOn(addOn),
  right: (
    auth: AuthType,
    {
      sessionRight,
      right,
    }: { sessionRight?: boolean; right?: Right | Right[] },
  ) => {
    if (!right) {
      return false;
    }

    return sessionRight
      ? auth.hasSessionRight(right)
      : auth.hasContextRight(right);
  },
  categoryGroupAccess: (
    auth: AuthType,
    {
      categoryGroupRoute,
      categoryGroupAccessType,
    }: {
      categoryGroupRoute?: CategoryGroup | null;
      categoryGroupAccessType?: CategoryGroupAccessType;
    },
  ) => {
    return (
      categoryGroupRoute &&
      auth.hasCategoryGroupAccess(categoryGroupRoute, categoryGroupAccessType)
    );
  },
};

export function createAccessChecker({
  session,
  context,
}: CreateAccessCheckerParams) {
  const rights = getRights({ context, session });
  const auth = getAuthorization(rights);

  return (requirements: Requirements) => {
    let reason;

    const hasAccess = Object.keys(requirementEvaluators)
      .filter(
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (key) => typeof requirements[key as keyof Requirements] !== 'undefined',
      )
      .every((key) => {
        const evaluator =
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          requirementEvaluators[key as keyof typeof requirementEvaluators];
        reason = key;

        return evaluator(auth, requirements);
      });

    return {
      access: hasAccess,
      reason: hasAccess ? null : reason,
    };
  };
}
