import React from 'react';

import styles from './styles.css';

type Props = {
  children?: React.ReactNode;
};

const Container = ({ children }: Props) => (
  <div className={styles.root}>{children}</div>
);

// eslint-disable-next-line import/no-default-export
export default Container;
