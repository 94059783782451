import { Map } from 'immutable';
import get from 'lodash/get';

const phases = (state = Map(), action: $TSFixMe): Map<unknown, unknown> => {
  switch (action.type) {
    case 'ENGAGEMENT/PHASES_READ_SUCCESS': {
      const { data } = action;

      // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
      return data.reduce((acc, curr) => {
        const { phase, group, segments } = curr;

        return acc.set(phase, {
          phase,
          group,
          segments,
        });
      }, Map());
    }
    case 'ENGAGEMENT/PHASE_READ_SUCCESS': {
      const {
        phase,
        data: {
          attributes,
          relationships: { segments, drivers, story },
        },
      } = action.data;

      return state.update(phase, () => {
        return {
          phase,
          attributes: {
            ...attributes,
          },
          // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
          segmentIds: segments.map((segment) => segment.id),
          drivers,
          stories: get(story, 'attributes.texts', []),
        };
      });
    }
    case 'ENGAGEMENT/PHASE_SEGMENTS_READ_LOADING': {
      const { phase } = action.data;

      return state.update(phase, (phaseValue) => {
        return {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...phaseValue,
          isLoadingSegments: true,
        };
      });
    }
    case 'ENGAGEMENT/PHASE_SEGMENTS_READ_SUCCESS': {
      const { phase, segments } = action.data;

      return state.update(phase, (phaseValue) => {
        return {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...phaseValue,
          segments,
          isLoadingSegments: false,
        };
      });
    }
    case 'ENGAGEMENT/PHASE_READ_LOADING':
    case 'ENGAGEMENT_RESET': {
      return Map();
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default phases;
