// eslint-disable-next-line import/no-default-export
export default function getRedirectUrl(redirectTo = window.location.href) {
  const a = window.document.createElement('a');
  a.href = redirectTo;

  const { protocol, hostname, pathname, search } = a;

  const isValid =
    protocol === window.location.protocol &&
    hostname === window.location.hostname;

  if (!isValid) {
    return;
  }

  try {
    return decodeURIComponent(pathname + search).replace(/\/{2}/, (_) => '/');
  } catch {}
}
