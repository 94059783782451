import React from 'react';

import { LinkButton } from '@peakon/bedrock/react/button';
import * as Typography from '@peakon/bedrock/react/typography';
import ErrorImage from '@peakon/shared/assets/images/temporary/misc/Unavailable_error-page@2x.png';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  title?: string;
  description?: string;
  showLinkToHelpCenter?: boolean;
};

const ErrorScreen = ({
  title,
  description,
  showLinkToHelpCenter = true,
}: Props) => {
  return (
    <div className={styles.container}>
      <img src={ErrorImage} className={styles.image} alt="" />
      <div className={styles.title}>
        <Typography.Heading1 level={1}>
          {title || t('generic_error_page__title')}
        </Typography.Heading1>
      </div>
      <div className={styles.paragraph}>
        <Typography.BodyText>
          {description || t('generic_error_page__description')}
        </Typography.BodyText>
      </div>
      <LinkButton variant="primary" href="/" as="a">
        {t('generic_error_page__go_to_dashboard')}
      </LinkButton>
      {showLinkToHelpCenter && (
        <div className={styles.helpCTA}>
          <LinkButton
            variant="tertiary"
            href="https://doc.workday.com/en-us/peakon.html"
            as="a"
          >
            {t('generic_error_page__visit_help_center')}
          </LinkButton>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default ErrorScreen;
