import React, { Suspense } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { analytics } from '@peakon/shared/features/analytics/analytics';
import { initializeI18n } from '@peakon/shared/features/i18next/i18n';
import TranslatedApp from '@peakon/shared/features/i18next/TranslatedApp';
import { queryClient } from '@peakon/shared/queries/queryClient';
import { CookieConsentProvider } from '@peakon/shell/components/CookieConsent/CookieConsentContext';

import ErrorBoundary from './components/ErrorBoundary';
import { store } from './configureStore';
import App from './containers/App';
import { GenericErrorScreen } from './containers/ErrorScreen';
import NotificationSystem from './containers/NotificationSystem';
import { MediaQueryListener } from './context/Media';
import { BackgroundTaskProvider } from './services/BackgroundTask/BackgroundTaskContext';

import './styles/global.css';

const AppWrapper = () => {
  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <TranslatedApp>
            <CookieConsentProvider>
              <ErrorBoundary fallback={() => <GenericErrorScreen />}>
                <Router>
                  <BackgroundTaskProvider>
                    <MediaQueryListener>
                      <App />
                      <NotificationSystem />
                    </MediaQueryListener>
                  </BackgroundTaskProvider>
                </Router>
              </ErrorBoundary>
            </CookieConsentProvider>
          </TranslatedApp>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
};

initializeI18n({
  namespace: 'web',
  getLocaleResource: async (localeId) => {
    const localeResource = await import(
      `@peakon/translations/locales/${localeId}.json`
    );
    return JSON.stringify(localeResource.default);
  },
});

analytics.init('manager-dashboard');

const rootElement = window.document.getElementById('root');
const root = rootElement && createRoot(rootElement);

if (root) {
  root.render(<AppWrapper />);
}

if (module.hot) {
  module.hot.accept();
}
