import { getStyles } from './getStyles';
import { validatedEnvConfig as env } from '../../utils/env';
import { errorReporter } from '../../utils/errorReporter';

let deferredUserId: string | undefined;

export const identifyUser = (userId: string) => {
  if (window.Sprig !== undefined) {
    // eslint-disable-next-line no-void
    void window.Sprig('setUserId', userId);
  } else {
    // if sprig is not initialized, defer the identification
    deferredUserId = userId;
  }
};

export function track({
  eventName,
  properties,
}: {
  eventName: string;
  properties?: Record<string, string>;
}) {
  if (window.Sprig !== undefined) {
    if (properties) {
      // eslint-disable-next-line no-void
      void window.Sprig('identifyAndTrack', { eventName, properties });
    } else {
      // eslint-disable-next-line no-void
      void window.Sprig('identifyAndTrack', { eventName });
    }
  }
}

// These attributes are already being synced to Sprig when the user logs in from the backend code
// we should not overwrite them from the frontend
const reservedAttributeNames = [
  'distinct_id',
  'company_id',
  'created',
  'locale',
  'is_manager',
  'is_segment_manager',
  'is_leader',
  'is_admin',
  'number_of_reports',
  'manager_segment_reporting_levels',
  'settings',
  'company_contract_start_date',
  'company_size',
  'company_feature_flips',
  'company_add_ons',
  'company_create_date',
  'company_industry',
  'company_latest_round_date',
] as const;

type ReservedAttributeNames = (typeof reservedAttributeNames)[number];

type AllowedSprigAttributes<TKey extends string> =
  TKey extends ReservedAttributeNames ? never : Record<TKey, string>;

export function setAttributes<TKey extends string>(
  attributes: AllowedSprigAttributes<TKey>,
) {
  if (window.Sprig !== undefined) {
    // Typescript cannot guarantee that we won't ever pass reserved attributes into
    // the function, so we need to check for that at runtime too.
    const hasReservedAttribute = Object.keys(attributes).some((key) =>
      reservedAttributeNames.includes(key),
    );
    if (!hasReservedAttribute) {
      // eslint-disable-next-line no-void
      void window.Sprig('setAttributes', attributes);
    } else {
      errorReporter.error(
        new Error(`Can't set reserved attributes in Sprig:`),
        {
          attributes,
          reservedAttributeNames,
        },
      );
    }
  }
}

export async function init() {
  try {
    if (window.Sprig === undefined && env.sprig?.environmentId) {
      // lazy load sprig lib
      const { sprig: sprigBrowser } = await import(
        '@sprig-technologies/sprig-browser'
      );

      // setup sprig - will also set window.Sprig
      const sprigConfig = sprigBrowser.configure({
        environmentId: env.sprig?.environmentId,
      });

      const styles = await getStyles();
      sprigConfig('applyStyles', styles);

      // in case we attempted to identify the user before init
      // we can now identify the user
      if (deferredUserId !== undefined) {
        identifyUser(deferredUserId);
        deferredUserId = undefined; // clear the deferred data
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      // Unsure what errors we could encounter here, so let's log it.
      // Ping Andy if this happens :)
      errorReporter.error(error);
    }
  }
}

export function cleanup() {
  logoutUser();
  window.localStorage.removeItem('userleap.ids');
  window.localStorage.removeItem('userleap.pageviews');
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'WindowSprig'....
  window.Sprig = undefined;
}

export function logoutUser() {
  if (window.Sprig !== undefined) {
    window.Sprig('logoutUser');
  }
}

export const sprig = {
  identifyUser,
  track,
  setAttributes,
  init,
  cleanup,
  logoutUser,
};
