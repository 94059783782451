/**
 * will attempt to set and remove an item from local storage
 * to check if localStorage is supported
 */
export function checkIfLocalStorageIsSupported() {
  try {
    const key = '__storage_test__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
}

export const isLocalStorageSupported = checkIfLocalStorageIsSupported();
