import React from 'react';

import { Link } from 'react-router-dom';

import { NavigationBasicNavigationChevronRightThickIcon as ChevronRight } from '@peakon/bedrock/icons/system';

import styles from './styles.css';

type BreadcrumbLinkProps = {
  children?: React.ReactNode;
  to?: string;
};

const BreadcrumbLink = ({ children, to }: BreadcrumbLinkProps) => (
  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  <Link className={styles.link} to={to}>
    {children}
  </Link>
);

type LabelProps = {
  children?: React.ReactNode;
};

const Label = ({ children }: LabelProps) => (
  <span className={styles.label}>{children}</span>
);

const Arrow = () => (
  <span className={styles.arrow}>
    <ChevronRight className={styles.chevronIcon} />
  </span>
);

type BreadcrumbsProps = {
  children?: React.ReactNode;
};

const Breadcrumbs = ({ children }: BreadcrumbsProps) => (
  <div className={styles.root}>{children}</div>
);

Breadcrumbs.Arrow = Arrow;
Breadcrumbs.Label = Label;
Breadcrumbs.Link = BreadcrumbLink;

// eslint-disable-next-line import/no-default-export
export default Breadcrumbs;
