export function isSameLanguage(a = '', b = '') {
  if (typeof a !== 'string' || typeof b !== 'string') {
    // In case where one is `null`, fallback to true
    // to prevent rendering UI on translate.
    return true;
  }

  const localeA = a.split('-')[0];
  const localeB = b.split('-')[0];

  return localeA === localeB;
}
