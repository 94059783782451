import { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

const COLLAPSIBLE_MAX_WIDTH = 1200;

function useIsCollapsible() {
  const [isCollapsible, setIsCollapsible] = useState(
    window.innerWidth < COLLAPSIBLE_MAX_WIDTH,
  );

  useEffect(() => {
    function handleResize() {
      setIsCollapsible(window.innerWidth < COLLAPSIBLE_MAX_WIDTH);
    }
    const handleResizeDebounced = debounce(handleResize, 200);
    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, [isCollapsible]);

  return isCollapsible;
}

// eslint-disable-next-line import/no-default-export
export default useIsCollapsible;
