import React, { Component } from 'react';

import debounce from 'lodash/debounce';
type Props<TValue> = {
  onChange: (value: NoInfer<TValue>) => void;
  value: TValue;
};

type State<TValue> = {
  value: TValue;
};

const debouncedInput = <TWrappedComponentProps,>(
  WrappedComponent: React.ComponentType<TWrappedComponentProps>,
  { delay = 300 } = {},
) => {
  return class DebouncedInput<TValue extends string | number> extends Component<
    Props<TValue> & TWrappedComponentProps,
    State<TValue>
  > {
    lastPropValue: $TSFixMe;

    constructor(props: Props<TValue> & TWrappedComponentProps) {
      super(props);

      this.onDebouncedChange = debounce(this.onDebouncedChange, delay);
      this.lastPropValue = this.props.value;

      this.state = {
        value: this.props.value,
      };
    }

    onDebouncedChange = (changes: TValue) => {
      const { onChange } = this.props;

      onChange(changes);
    };

    onChange = (value: TValue) => {
      this.setState({ value });
      this.onDebouncedChange(value);
    };

    getValue = () => {
      const { value: propValue } = this.props;
      const { value } = this.state;

      // Ensure that prop.value and state.value are not out of sync
      // https://github.com/erikras/redux-form/issues/1835#issuecomment-249424667
      const nextValue = propValue !== this.lastPropValue ? propValue : value;
      this.lastPropValue = propValue;

      return nextValue;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onChange={this.onChange}
          value={this.getValue()}
        />
      );
    }
  };
};

// eslint-disable-next-line import/no-default-export
export default debouncedInput;
