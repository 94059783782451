import Employee from '@peakon/records/EmployeeRecord';

const getBulkEditorEmployee = (employees: $TSFixMe) => {
  const { attributes, features, locale, optionAttributes, timezone } =
    employees.first();

  return employees.reduce(
    (acc: $TSFixMe, current: $TSFixMe) => {
      const nextAttributes = current.attributes.map(
        (value: $TSFixMe, key: $TSFixMe) =>
          acc.getIn(['attributes', key]) === value ? value : undefined,
      );

      const nextOptionAttributes = current.optionAttributes.map(
        (value: $TSFixMe, key: $TSFixMe) =>
          acc.getIn(['optionAttributes', key, 'id']) === value.id
            ? value
            : undefined,
      );

      const nextFeatures = current.features.map(
        (value: $TSFixMe, key: $TSFixMe) =>
          // Set to undefined as a falsy value, so that when toggled
          // the differ will enable the field.
          value === acc.getIn(['features', key]) ? value : undefined,
      );

      return acc.merge({
        attributes: nextAttributes,
        features: nextFeatures,
        optionAttributes: nextOptionAttributes,
        locale: acc.locale === current.locale ? current.locale : undefined,
        timezone:
          acc.timezone === current.timezone ? current.timezone : undefined,
      });
    },
    new Employee({
      attributes,
      features,
      locale,
      optionAttributes,
      timezone,
    }),
  );
};

// eslint-disable-next-line import/no-default-export
export default getBulkEditorEmployee;
