import React from 'react';

import classnames from 'classnames';

import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { AcknowledgementIcon } from '@peakon/shared/components/AcknowledgementIcon';
import { AcknowledgementType } from '@peakon/shared/constants/acknowledgementTypes';
import { createTranslationMap } from '@peakon/shared/features/i18next/createTranslationMap';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export const useAcknowledgementTranslationMap = () => {
  const acknowledgementTranslationMap = createTranslationMap({
    thanks_for_sharing: t('acknowledgement__thanks_for_sharing'),
    great_idea: t('acknowledgement__great_idea'),
    working_on_it: t('acknowledgement__working_on_it'),
    would_love_to_talk_in_person: t(
      'acknowledgement__would_love_to_talk_in_person',
    ),
    i_agree: t('acknowledgement__i_agree'),
  } as const satisfies Record<AcknowledgementType, string>);

  return { acknowledgementTranslationMap };
};

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  type: AcknowledgementType;
};

const Button = ({ disabled, isLoading, type }: Props) => {
  const { acknowledgementTranslationMap } = useAcknowledgementTranslationMap();

  return (
    <UnstyledButton
      className={classnames(styles.root, {
        [styles.disabled]: disabled || isLoading,
      })}
      accessibleName={
        isLoading
          ? t('a11y_acknowledge_loading')
          : type && acknowledgementTranslationMap.get(type)
      }
    >
      <span className={classnames(styles.tooltip)}>
        {type && acknowledgementTranslationMap.get(type)}
      </span>
      {isLoading ? (
        <Spinner size="32" />
      ) : (
        <AcknowledgementIcon
          as="span"
          className={styles.icon}
          size="large"
          type={type}
        />
      )}
    </UnstyledButton>
  );
};

// eslint-disable-next-line import/no-default-export
export default Button;
