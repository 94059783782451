import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import api from '../../../../utils/api';
import { validateData } from '../../../../utils/validateData/validateData';

const tokenSchema = z.object({
  token: z.string(),
});

const getTransferToken = async () => {
  const response = await api.post('/auth/transfer/generate');
  return validateData(response, tokenSchema, {
    errorMessagePrefix: 'tokenSchema',
  });
};

export const useTransferTokenQuery = () => {
  return useQuery({
    queryFn: getTransferToken,
    queryKey: ['subdomain', 'getTransferToken'],
    select: (data) => data.token,
  });
};
