import React from 'react';

import { ScoresEmojiOpenEndedQuestionIcon } from '@peakon/bedrock/icons/graphic';

import styles from './styles.css';

export const CommentTextIcon = () => (
  <div className={styles.scoreContainer}>
    <div className={styles.scoreIcon}>
      <ScoresEmojiOpenEndedQuestionIcon height={38} width={36} />
    </div>
  </div>
);
