import { List } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Row from '@peakon/records/heatmap/RowRecord';

const segmentIds = (state = List(), action: $TSFixMe) => {
  switch (action.type) {
    case 'HEATMAP_ATTRITION':
    case 'HEATMAP_REVIVE_SEGMENTS':
    case 'HEATMAP_REVIVE': {
      if (!action.data) {
        // when attrition is not the initial boot
        return state;
      }

      const { rows } = action.data;

      // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
      return List(rows.map((row) => new Row(row)));
    }

    case 'CONTEXT_LIST_SUCCESS': {
      const { data } = action.data;

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const rowIndex = state.findIndex((row) => row.type === 'context');

      if (rowIndex > -1 && data.length > 0) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        return state.update(rowIndex, (row) => row.set('id', data[0].id));
      }

      return state;
    }

    case 'HEATMAP_CONTEXT_SCORE_ADD': {
      const { context } = action.data;

      return (
        state
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          .filter((row) => row.type !== 'context')
          .unshift(Row.fromContext(context.id))
      );
    }

    case 'HEATMAP_CONTEXT_SCORE_REMOVE': {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return state.filter((row) => row.type !== 'context');
    }

    case 'CRITICAL_SEGMENTS_READ_SUCCESS': {
      const { data, shouldPreserveHeatmapState, isInitialLoad } = action.data;

      if (!isInitialLoad || shouldPreserveHeatmapState) {
        return state;
      }

      const ids = data
        // @ts-expect-error TS(7006): Parameter 'critical' implicitly has an 'any' type.
        .map((critical) => get(critical, 'relationships.segment.id'))
        // @ts-expect-error TS(7006): Parameter 'id' implicitly has an 'any' type.
        .map((id) => new Row({ id }));

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return state.filter((row) => row.type === 'context').concat(ids);
    }

    case 'HEATMAP_CLOSE': {
      return List();
    }

    case 'HEATMAP_SEGMENTS_CLEAR': {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return state.filter((row) => row.isContext());
    }

    case 'HEATMAP_SEGMENT_FAILED': {
      const { segmentId } = action.data;

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return state.filter((row) => row.id !== segmentId);
    }

    case 'HEATMAP_SEGMENT_SUCCESS':
    case 'HEATMAP_SEGMENT_CONTEXT_SUCCESS': {
      const {
        segmentId,
        categoryScores: { data },
        questionScores,
      } = action.data;

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const rowIndex = state.findIndex((row) => row.id === segmentId);

      if (rowIndex < 0) {
        return state;
      }

      return state.update(rowIndex, (row) =>
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        row.merge({
          employeeCount: get(data, 'attributes.size'),
          hasLoadedQuestionScores:
            questionScores && !isEmpty(questionScores.data),
        }),
      );
    }

    case 'HEATMAP_SEGMENT_QUESTION_SCORES_SUCCESS': {
      const { segmentId } = action.data;

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      const rowIndex = state.findIndex((row) => row.id === segmentId);

      if (rowIndex < 0) {
        return state;
      }

      return state.update(rowIndex, (row) =>
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        row.set('hasLoadedQuestionScores', true),
      );
    }

    case 'HEATMAP_SEGMENT_COLLAPSE': {
      const { rowIndex } = action.data;

      const collapsedRow = state.get(rowIndex);

      if (!collapsedRow) {
        // might happen if heatmap is closed before the
        // response is received
        return state;
      }

      let nextRowIndex = state.findIndex((row, index) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return index > rowIndex && collapsedRow.level >= row.level;
      });

      // when the collapsed row is the last one
      if (nextRowIndex === -1) {
        nextRowIndex = state.size;
      }

      return (
        state
          // @ts-expect-error TS(6133): 'v' is declared but its value is never read.
          .filter((v, index) => index <= rowIndex || index >= nextRowIndex)
          // @ts-expect-error TS(2339): Property 'update' does not exist on type 'Iterable... Remove this comment to see the full error message
          .update(rowIndex, (row) => row.collapse())
      );
    }

    case 'HEATMAP_SEGMENT_EXPAND_SUCCESS': {
      const { rowIndex, data } = action.data;

      const expanded = state.get(rowIndex);

      if (!expanded) {
        // might happen if heatmap is closed before the
        // response is received
        return state;
      }

      const links = data.map(
        (link: $TSFixMe) =>
          new Row({
            id: get(link, 'relationships.segment.id'),
            employeeCount: get(link, 'attributes.size'),
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            level: expanded.level + 1,
          }),
      );

      const pre = state.slice(0, rowIndex);
      const post = state.slice(rowIndex + 1, state.size);

      // @ts-expect-error TS(2339): Property 'push' does not exist on type 'Iterable<n... Remove this comment to see the full error message
      return pre.push(expanded.expand()).concat(links).concat(post);
    }

    case 'HEATMAP_SEGMENTS_ADD':
    case 'HEATMAP_SEGMENTS_SET': {
      const ids = action.data.map(
        (segment: $TSFixMe) =>
          new Row({
            id: segment.id,
            // employeeCount for the previously selected segments are lost
            // so we need to persist them by looking up the previous value
            employeeCount:
              segment?.employeeCount ??
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              state?.find(({ id }) => id === segment.id)?.employeeCount,
          }),
      );

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return state.filter((row) => row.isContext()).concat(ids);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segmentIds;
