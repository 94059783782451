import React, { useEffect, useLayoutEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { useDashboardContext } from '@peakon/app-manager/hooks/useDashboardContext';

import ProductTourStepRenderer from './renderer/ProductTourStepRenderer';
import { ProductTourKey, ProductTour, ProductTourStep } from './types';
import { shouldRenderStep, waitForElement } from './utils';
import { ProductTourContext } from '../../context/ProductTourContext';

type Props = {
  children: React.ReactNode;
  onComplete?: (tour: ProductTourKey) => Promise<void> | void;
  tours: Record<ProductTourKey, ProductTour>;
};

const ProductTourContextProvider = ({
  children,
  onComplete,
  tours,
}: Props): React.ReactElement => {
  const [tourName, setTourName] = useState<ProductTourKey | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [step, setStep] = useState<ProductTourStep | null>(null);
  const [hasSkippedTour, setHasSkippedTour] = useState(false);

  const [selectedTour, setSelectedTour] = useState<ProductTour | null>(null);
  const dashboardContext = useDashboardContext();

  // Filter out steps based on their contextRights & shouldRenderStep property
  useEffect(() => {
    const tour = tourName && tours[tourName];

    if (!tour || !dashboardContext) {
      return;
    }

    const filterSteps = async () => {
      const { steps } = tour;

      const stepIndexesFilterMap = await Promise.all(
        steps.map((s) => shouldRenderStep(s, dashboardContext)),
      );

      const filteredSteps = steps.filter(
        (_, index) => stepIndexesFilterMap[index],
      );

      // Only update if the steps have changed
      if (!isEqual(filteredSteps, selectedTour?.steps)) {
        setSelectedTour({ ...tour, steps: filteredSteps });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
    filterSteps();

    // Rendering the step can depend on DOM mutations (e.g. for query selectors),
    // so we need to ensure that the filtering is rerun after each DOM update
    const mutationObserver = new MutationObserver(filterSteps);
    const container =
      window.document.getElementById('app-content') ?? window.document.body;
    mutationObserver.observe(container, { subtree: true, childList: true });

    return () => {
      mutationObserver.disconnect();
    };
  }, [dashboardContext, selectedTour, tourName, tours]);

  useLayoutEffect(() => {
    const renderStep = async () => {
      const currentStep = selectedTour?.steps?.[currentStepIndex - 1];

      // handle race condition:
      // sometimes the user will navigate away before data is fetched
      if (!currentStep) {
        return;
      }

      if (currentStep.waitForElement) {
        await waitForElement(currentStep.waitForElement);
      }

      return setStep(currentStep);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
    renderStep();
  }, [currentStepIndex, selectedTour]);

  useEffect(() => {
    setCurrentStepIndex(1);
  }, [tourName]);

  const handleClose = async () => {
    if (selectedTour && tourName) {
      if (currentStepIndex < selectedTour.steps.length - 1) {
        const stepIndex = selectedTour.steps.findIndex((s) => s.showOnSkip);
        if (stepIndex !== -1) {
          setHasSkippedTour(true);
          setCurrentStepIndex(stepIndex + 1);
          setStep(null);
          return;
        }
      }

      await onComplete?.(tourName);
      setHasSkippedTour(false);
    }

    setStep(null);
    setTourName(null);
    setSelectedTour(null);
  };

  const handleStepClick = (nextStep: number) => {
    setCurrentStepIndex(nextStep);
  };

  const handleComplete = async () => {
    if (!tourName) {
      return;
    }

    await onComplete?.(tourName);
  };

  const totalSteps =
    selectedTour?.steps.filter((s) => !s.excludeFromProgress).length ?? 0;

  return (
    <ProductTourContext.Provider
      value={{
        setTour: setTourName,
        tour: tourName,
      }}
    >
      {children}
      {step && (
        <ProductTourStepRenderer
          step={step}
          onComplete={handleComplete}
          currentStep={currentStepIndex}
          onClose={handleClose}
          onStepClick={handleStepClick}
          totalSteps={totalSteps}
          hasSkippedTour={hasSkippedTour}
        />
      )}
    </ProductTourContext.Provider>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductTourContextProvider;
