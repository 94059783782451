import { combineReducers } from '@reduxjs/toolkit';

import bulkNotify from './bulkNotify';
import groupExport from './groupExport';
import groups from './groups';
import settings from './settings';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  bulkNotify,
  groupExport,
  groups,
  settings,
});
