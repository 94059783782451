import { useInfiniteQuery } from '@tanstack/react-query';

import { jsonapiparser } from '@peakon/shared/utils';
import api from '@peakon/shared/utils/api';

import { MessagesData } from './types/message';

const params = {
  'fields[employees]': 'firstName,lastName,name,avatar',
  include: 'employee',
  per_page: 15,
};

type FetchParams = {
  commentId: string;
  nextPageLink?: string;
};

async function fetchMessages({
  commentId,
  nextPageLink,
}: FetchParams): Promise<MessagesData> {
  const getParams: [string, typeof params | null] = nextPageLink
    ? [nextPageLink, null]
    : [`/conversations/comments/${commentId}/messages`, params];
  return api.get(...getParams).then(jsonapiparser);
}

type Params = {
  commentId: string;
  onError?: (error: Error) => void;
};

export const conversationMessagesKeys = {
  lists: ['conversation__messages'],
  list: (commentId: string) => [...conversationMessagesKeys.lists, commentId],
} as const;

export function useMessages({ commentId, onError }: Params) {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: conversationMessagesKeys.list(commentId),
    queryFn: ({ pageParam }) =>
      fetchMessages({ commentId, nextPageLink: pageParam }),
    onError,
    enabled: Boolean(commentId),
    getNextPageParam: (previousData) => {
      return previousData?.links?.next;
    },
  });

  return {
    data,
    messages: data?.pages?.flatMap((page) => page.data) ?? [],
    ...rest,
  };
}
