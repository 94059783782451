import { EmployeesImportTask } from '../services/BackgroundTask/Tasks/EmployeesImport/EmployeesImport';

// eslint-disable-next-line no-restricted-syntax
export enum ImportResultActionType {
  SetResults = 'EMPLOYEES_IMPORT_SET_RESULTS',
  ClearResults = 'EMPLOYEES_IMPORT_CLEAR_RESULTS',
}

type ImportResultAction = {
  type: ImportResultActionType;
  data: Extract<EmployeesImportTask['data'], { status: 'completed' }>;
};

const employeesImportResult = (state = {}, action: ImportResultAction) => {
  switch (action.type) {
    case ImportResultActionType.SetResults: {
      return action.data;
    }
    case ImportResultActionType.ClearResults: {
      return {};
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default employeesImportResult;
