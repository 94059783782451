import sortBy from 'lodash/sortBy';

import { all } from '@peakon/localization';
import type { Options, LocaleId } from '@peakon/localization';
import { validateLocaleId } from '@peakon/shared/features/i18next/localesValidation';
import { t } from '@peakon/shared/features/i18next/t';
import { getTranslatedLocaleNamesMap } from '@peakon/shared/features/i18next/translatedLocaleNames';

type LocaleOption = {
  label: string;
  value: LocaleId | 'not_set';
};

export function getLocales(
  localizedNames: boolean,
  localizationOptions: Options,
  isFilter?: boolean,
) {
  const locales: LocaleOption[] = sortBy(
    all(localizationOptions).map((locale) => {
      return {
        value: locale.id,
        label: localizedNames
          ? getTranslatedLocaleNamesMap().get(validateLocaleId(locale.id))
          : locale.title,
      };
    }),
    'label',
  );
  if (isFilter) {
    locales.push({
      label: t('employees__filter__not-set'),
      value: 'not_set',
    });
  }
  return locales;
}
