import React, { Fragment } from 'react';

import classnames from 'classnames';

import { NotificationsEmailBouncedIcon as WarningIcon } from '@peakon/bedrock/icons/graphic';
import {
  ViewEmployeePhoneIcon as EmployeePhone,
  ViewAnonymousEmployeeIcon as AnonymousIcon,
} from '@peakon/bedrock/icons/system';
import { Avatar, Ellipsis } from '@peakon/components';
import { Employee } from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';
import { getResizedImageUrl } from '@peakon/shared/utils';

import { EmploymentStatusLabel } from './EmploymentStatusLabel';

import styles from './styles.css';

const Phone = ({ number }: { number: string }) => (
  <div className={styles.phone}>
    <EmployeePhone height={16} />
    <Ellipsis>{number}</Ellipsis>
  </div>
);

const Email = ({
  children,
  bounced,
}: {
  children: React.ReactNode;
  bounced?: boolean;
}) => (
  <div
    className={classnames(styles.email, { [styles.bounced]: bounced })}
    data-test-id="employee-info-email"
  >
    {children && (
      <Fragment>
        {bounced && <WarningIcon className={styles.warning} />}
        {children}
      </Fragment>
    )}
  </div>
);

const PlainRenderer = ({
  modifier,
  name,
  email,
  testId,
}: {
  email: string;
  modifier?: string;
  name: string;
  testId?: string;
}) => (
  <div
    className={classnames(styles.container, styles.plain, {
      // @ts-expect-error - Type 'undefined' cannot be used as an index type.
      [styles[modifier]]: modifier,
    })}
    data-test-id={testId}
  >
    <div className={styles.nameContainer}>
      <div className={styles.name} data-test-id="employee-info-name">
        <Ellipsis>{name}</Ellipsis>
      </div>
      <div className={styles.details}>
        <Email>{email || ''}</Email>
      </div>
    </div>
  </div>
);

const DefaultRenderer = ({
  isNew,
  isUnbounced,
  employee,
  modifier,
  size,
  testId,
}: {
  isNew?: boolean;
  isUnbounced?: boolean;
  employee: Employee;
  modifier?: string;
  size: 'small' | 'medium';
  testId?: string;
}) => (
  <div
    className={classnames(styles.container, {
      // @ts-expect-error - Type 'undefined' cannot be used as an index type.
      [styles[modifier]]: modifier,
    })}
    data-test-id={testId}
  >
    <div className={styles.avatar}>
      {isNew ? (
        <div className={styles.avatarEmpty}>+</div>
      ) : employee.isAnonymized ? (
        <div className={styles.anonymousIcon}>
          <AnonymousIcon />
        </div>
      ) : (
        <Avatar
          size={size}
          abbreviation={employee.abbreviation}
          alt={employee.name}
          rounded
          src={getResizedImageUrl({
            src: employee.avatar,
            fit: 'crop',
            height: 40,
            width: 40,
          })}
        />
      )}
    </div>
    {isNew ? (
      <div className={classnames(styles.nameContainer, styles.new)}>
        <div className={styles.name}>
          <Ellipsis>{t('employees-page__header-new')}</Ellipsis>
        </div>
      </div>
    ) : (
      <div className={styles.nameContainer}>
        <div className={styles.name} data-test-id="employee-info-name">
          <Ellipsis>{employee.name}</Ellipsis>
        </div>
        {!employee.isAnonymized && (
          <div className={styles.details}>
            <Email
              bounced={
                Boolean(employee.email) && employee.isBounced() && !isUnbounced
              }
            >
              <Ellipsis>
                {employee.email ||
                  (employee.identifier ? `(${employee.identifier})` : '')}
                <EmploymentStatusLabel employee={employee} />
              </Ellipsis>
            </Email>
            {employee.phone ? <Phone number={employee.phone} /> : null}
          </div>
        )}
      </div>
    )}
  </div>
);

export const EmployeeInfo = ({
  isNew,
  isUnbounced,
  employee,
  modifier,
  type = 'default',
  size = 'small',
  testId,
}: {
  isNew?: boolean;
  isUnbounced?: boolean;
  employee: Employee;
  modifier?: string;
  type?: 'default' | 'plain';
  size?: 'small' | 'medium';
  testId?: string;
}) => {
  return type === 'plain' ? (
    <PlainRenderer
      modifier={modifier}
      name={employee.get('name')}
      email={employee.get('email') || employee.get('identifier')}
      testId={testId}
    />
  ) : (
    <DefaultRenderer
      isNew={isNew}
      isUnbounced={isUnbounced}
      employee={employee}
      modifier={modifier}
      size={size}
      testId={testId}
    />
  );
};
