import z from 'zod';

export const valueSchema = z.object({
  id: z.string(),
  type: z.literal('values'),
  attributes: z.object({
    name: z.string(),
    description: z.string(),
  }),
});

export type ValueResponse = z.infer<typeof valueSchema>;
