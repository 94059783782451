import React, { createContext, useState, useContext } from 'react';

import { CookieConsentDialog } from './CookieConsentDialog';
import { useCookieConsentService } from './CookieConsentService';
import { CookieConsentSettingsModal } from './CookieConsentSettingsModal';

export const CookieConsentContext = createContext<{
  isCookieConsentModalOpened: boolean;
  setIsCookieConsentModalOpened: (isOpen: boolean) => void;
  isCookieConsentFeatureEnabled: boolean; // FIXME: featureflip, remove after launch
  setIsCookieConsentFeatureEnabled: (enabled: boolean) => void; // FIXME: featureflip, remove after launch
}>({
  isCookieConsentModalOpened: false,
  setIsCookieConsentModalOpened: () => {},
  isCookieConsentFeatureEnabled: false,
  setIsCookieConsentFeatureEnabled: () => {},
});

export function useCookieConsentContext() {
  return useContext(CookieConsentContext);
}

export function CookieConsentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isCookieConsentModalOpened, setIsCookieConsentModalOpened] =
    useState(false);

  // Since we cannot read feature flips form here, we are depending
  // on the value being set when the app has bee initialized.
  // When the featureflip has been removed, we can remove this logic.
  const [isCookieConsentFeatureEnabled, setIsCookieConsentFeatureEnabled] =
    useState(false);

  const { shouldPromptForConsent, localStorageData } =
    useCookieConsentService();

  return (
    <CookieConsentContext.Provider
      value={{
        isCookieConsentModalOpened,
        setIsCookieConsentModalOpened,
        isCookieConsentFeatureEnabled,
        setIsCookieConsentFeatureEnabled,
      }}
    >
      {isCookieConsentFeatureEnabled &&
        !isCookieConsentModalOpened &&
        shouldPromptForConsent && <CookieConsentDialog />}
      {/* Pass localStorageData as key to force the form to re-render when the value changes */}
      <CookieConsentSettingsModal key={localStorageData} />

      {children}
    </CookieConsentContext.Provider>
  );
}
