import isFunction from 'lodash/isFunction';

const stringComparator = (a: string, b: string) => {
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  a = a.toLowerCase();
  // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
  b = b.toLowerCase();

  // old browsers
  if (!isFunction(String.prototype.localeCompare)) {
    // NOTE: will have problems with accents etc.
    return a < b ? -1 : a > b ? 1 : 0;
  }

  // new browser i18n api
  return a.localeCompare(b);
};

// eslint-disable-next-line import/no-default-export
export default stringComparator;
