import React from 'react';

import { DriverIcon } from '@peakon/bedrock/icons/driver';
import {
  NotificationsActionCreatedIcon,
  NotificationsActionsDeadlineIcon,
  NotificationsSensitiveCommentsIcon,
  NotificationsEngagementIncreasedIcon,
  NotificationsEngagementDecreasedIcon,
  NotificationsDriverCommentsIcon,
  NotificationsCommentMentionIcon,
  NotificationsDriverStrengthIcon,
  NotificationsMessageIcon,
  NotificationsValueCommentsIcon,
  NotificationsPriorityAddedIcon,
  NotificationsPresentationIcon,
  NotificationsOpenEndedCommentsIcon,
  NotificationsDriverPriorityRemovedIcon,
  NotificationsIconAlertIcon,
  FeatureTeamSuggestionsUnreadIcon,
} from '@peakon/bedrock/icons/graphic';

import {
  NotificationItemBaseAttributesData,
  NotificationItemBaseAttributesType,
} from './types';

const DRIVER_ACTIONS = [
  'criticalDriver',
  'criticalDriverRemoved',
  'criticalSegment',
  'criticalSegmentRemoved',
  'priorityDriver',
  'priorityDriverAccepted',
  'priorityDriverRemoved',
  'prioritySegment',
];

type Props = {
  data?: NotificationItemBaseAttributesData;
  type: NotificationItemBaseAttributesType;
};

export const Icon = ({ type, data }: Props) => {
  if (DRIVER_ACTIONS.includes(type) && data && Boolean(data.standard)) {
    return (
      <DriverIcon
        group={data.group}
        category={data.subdriver ? data.driver : data.standard}
        height={24}
        width={24}
      />
    );
  }

  switch (type) {
    case 'actionCreated':
      return <NotificationsActionCreatedIcon />;
    case 'actionsDue':
      return <NotificationsActionsDeadlineIcon />;
    case 'comments':
      return <NotificationsDriverCommentsIcon />;
    case 'criticalSegment':
    case 'criticalDriver': {
      return data?.classification === 'strength' ? (
        <NotificationsDriverStrengthIcon />
      ) : (
        <NotificationsPriorityAddedIcon />
      );
    }
    case 'criticalSegmentRemoved':
    case 'criticalDriverRemoved':
      return <NotificationsDriverPriorityRemovedIcon />;
    case 'engagementChange':
    case 'scoresChange': {
      if (data && typeof data.sign === 'number' && data.sign > 0) {
        return <NotificationsEngagementIncreasedIcon />;
      }

      return <NotificationsEngagementDecreasedIcon />;
    }
    case 'inputs':
      return <NotificationsOpenEndedCommentsIcon />;
    case 'message': {
      if (data?.mentioned) {
        return <NotificationsCommentMentionIcon />;
      }

      return <NotificationsMessageIcon />;
    }
    case 'presentation':
      return <NotificationsPresentationIcon />;
    case 'priorityDriver':
    case 'prioritySegment':
      return <NotificationsDriverPriorityRemovedIcon />;
    case 'sensitiveComment':
      return <NotificationsSensitiveCommentsIcon />;
    case 'valueComments':
      return <NotificationsValueCommentsIcon />;
    case 'teamSuggestionAdded':
      return <FeatureTeamSuggestionsUnreadIcon />;
    default:
      return <NotificationsIconAlertIcon />;
  }
};
