import variables from '@peakon/bedrock/variables';

export const getCustomProperties = () => {
  const customProperties = [
    'spacing-04',
    'spacing-08',
    'spacing-12',
    'spacing-16',
    'spacing-20',
    'spacing-24',
    'spacing-32',
    'spacing-40',
    'spacing-48',
    'spacing-64',

    'font-size-3xl',
    'font-size-2xl',
    'font-size-xl',
    'font-size-lg',
    'font-size-md',
    'font-size-sm',
    'font-size-xs',
    'font-size-2xs',
    'font-size-3xs',

    'color-text',
    'color-text-heading',
    'color-text-body',
    'color-text-hint',
    'color-text-inverted',

    'color-viz-grid-line',
    'color-viz-03',
    'color-viz-04',

    'color-bg-card',
    'border-radius-md',

    'color-btn--primary-bg',
    'color-btn--primary-border',
    'color-btn--primary-text',
    'color-btn--primary-hover-bg',
    'color-btn--primary-active-bg',

    'focus-base',
    'focus-inset',
    'focus-outline',

    'font-family-base',

    'color-controls--selected-bg',
    'color-controls-selected',
    'color-controls-bg',
    'color-controls-text',
    'color-controls-border',
    'color-border-section',
    'color-controls-hover-border',

    'font-weight-normal',
    'font-weight-bold',

    'line-height-body',
    'line-height-heading',
  ]
    .map((variableName) => `--${variableName}: ${variables[variableName]};`)
    .join('\n');

  return `:root{\n ${customProperties}\n }`;
};

export async function getStyles() {
  const sprigCss = await import('./sprig.css');

  /*
    To make our css files compatible with the typescript plugin `typescript-plugin-css-modules`
    we hardcode the types of the *.css files here: apps/config/typings/css.d.ts
    In this case we are using the raw-loader via the webpack config to import sprig.css as a string,
    so we need to cast it to have the proper type.
  */
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const sprigStyles = sprigCss.default as unknown as string;

  const customProperties = getCustomProperties();

  const finalStyles = `${customProperties} ${sprigStyles}`;

  return finalStyles;
}
