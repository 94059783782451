// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import qs from 'qs';

const getResizedImageUrl = ({
  src,
  fit = 'max',
  height = 200,
  width = 200,
  dpr = window.devicePixelRatio,
}: $TSFixMe) => {
  if (!src) {
    return;
  }

  const params = qs.stringify({
    fit,
    w: width,
    h: height,
    dpr,
  });

  return `${src}?${params}`;
};

// eslint-disable-next-line import/no-default-export
export default getResizedImageUrl;
