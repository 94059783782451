import { HANDHELDS_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';

import { t } from '../../../../../features/i18next/t';
import { ProductName } from '../types';
import { isActive, getPath, getDefaultQueryParams } from '../utils';

const administration: ProductName = 'administration';

export const getAdministrationMenu = ({
  rights,
  contextId,
  features,
  screenWidth,
}: {
  rights: {
    [x: string]: string;
  };
  contextId?: string;
  features: string[];
  screenWidth: number;
}) => {
  const shouldRenderPhase1 =
    screenWidth > HANDHELDS_MAX_WIDTH || features.includes('responsiveAdmin');
  const shouldRenderPhase2 =
    screenWidth > HANDHELDS_MAX_WIDTH ||
    features.includes('responsiveAdminPhase2');

  return {
    name: administration,
    dataTestId: 'mpn-administration',
    menuLabel: t('multi_product__administration'),
    children: [
      {
        menuLabel: t('multi_product__administration'),
        name: administration,
        children: [
          {
            name: 'overview',
            menuLabel: t('multi_product__administration__menu_item__overview'),
            getPath: getPath({
              path: '/admin',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return (
                shouldRenderPhase1 &&
                Boolean(
                  rights.schedule ||
                    rights.question ||
                    rights.value ||
                    rights.company ||
                    rights.resources ||
                    rights.companyActions ||
                    rights.employee ||
                    rights.attribute ||
                    rights.group ||
                    rights.branding ||
                    rights.externalMetrics ||
                    rights.integration ||
                    rights.sensitiveAdmin,
                )
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__administration__menu_header__survey'),
        name: 'survey',
        isAccordion: true,
        children: [
          {
            name: 'schedule',
            menuLabel: t(
              'multi_product__administration__menu_item__survey_schedules',
            ),
            getPath: getPath({
              path: '/admin/schedules',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.schedule);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/schedules',
                  '/admin/schedules/new',
                  '/admin/schedules/activity',
                  '/admin/schedules/edit/:contextId/:tab',
                  '/admin/schedules/new',
                ],
              });
            },
          },
          {
            name: 'questions',
            menuLabel: t(
              'multi_product__administration__menu_item__survey_questions',
            ),
            getPath: getPath({
              path: '/admin/questions',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.question);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/questions/:questionGroup',
                ],
              });
            },
          },
          {
            name: 'values',
            menuLabel: t('multi_product__administration__menu_item__values'),
            getPath: getPath({
              path: '/admin/values',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.value);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'dataSettings',
            menuLabel: t(
              'multi_product__administration__menu_item__survey_data_settings',
            ),
            getPath: getPath({
              path: '/admin/data-settings/dashboard',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return (
                shouldRenderPhase1 &&
                (Boolean(rights.company) || Boolean(rights.sensitiveAdmin))
              );
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/data-settings/:settingId',
                ],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__administration__menu_header__improve'),
        name: 'improve',
        isAccordion: true,
        children: [
          {
            name: 'resources',
            menuLabel: t('multi_product__administration__menu_item__resources'),
            getPath: getPath({
              path: '/admin/improve/resources',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.resources);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'actions',
            menuLabel: t('multi_product__administration__menu_item__actions'),
            getPath: getPath({
              path: '/admin/improve/actions',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.companyActions);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
      {
        menuLabel: t('multi_product__administration__menu_header__company'),
        name: 'company',
        isAccordion: true,
        children: [
          {
            name: 'employees',
            menuLabel: t('multi_product__administration__menu_item__employees'),
            getPath: getPath({
              path: '/admin/employees',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase2 && Boolean(rights.employee);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/employees/:employeeId((?!segments).*)', // matches any employee id parameter that is not "segments", since we /employees/segments is the route for the segments page
                  '/admin/employees/:employeeId/managing',
                  '/admin/employees/:employeeId/access',
                  '/admin/employees/:employeeId/rounds',
                  '/admin/employees/import',
                  '/admin/employees/import/completed',
                ],
              });
            },
          },
          {
            name: 'attributes',
            menuLabel: t(
              'multi_product__administration__menu_item__attributes',
            ),
            getPath: getPath({
              path: '/admin/attributes',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.attribute);
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'accessControl',
            menuLabel: t(
              'multi_product__administration__menu_item__access_control',
            ),
            getPath: getPath({
              path: '/admin/access/groups',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase2 && Boolean(rights.group);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/access/groups',
                  '/admin/access/groups/:groupId',
                  '/admin/access/specialist',
                  '/admin/access/specialist/:id',
                ],
              });
            },
          },
          {
            name: 'branding',
            menuLabel: t('multi_product__administration__menu_item__branding'),
            linkType: 'a',
            getPath: getPath({
              path: '/admin/branding',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.branding);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/branding/:segmentId',
                ],
              });
            },
          },
          {
            name: 'impact',
            menuLabel: t('multi_product__administration__menu_item__impact'),
            getPath: getPath({
              path: '/admin/external_metrics',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.externalMetrics);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/external_metrics/:metricId',
                ],
              });
            },
          },
          {
            name: 'integrations',
            menuLabel: t(
              'multi_product__administration__menu_item__integration',
            ),
            getPath: getPath({
              path: '/admin/integrations',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.integration);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/integrations/apps',
                  '/admin/integrations/:integrationId',
                  '/admin/integrations/:integrationId/connect',
                ],
              });
            },
          },
          {
            name: 'account',
            menuLabel: t('multi_product__administration__menu_item__account'),
            getPath: getPath({
              path: '/admin/company',
              defaultQueryParams: getDefaultQueryParams(contextId),
            }),
            get shouldRender() {
              return shouldRenderPhase1 && Boolean(rights.company);
            },
            get isActive() {
              return isActive({
                matchingPaths: [
                  this.getPath({ includeParams: false }),
                  '/admin/company/data',
                  '/admin/company/email',
                  '/admin/company/consent-request',
                  '/admin/company/custom-domain',
                ],
              });
            },
          },
        ],
      },
    ],
  };
};
