import { createSelector } from 'reselect';

import { DEFAULT_LOCALE_ID } from '@peakon/localization';

import { RootState } from '../types/redux';

const accountSelector = (state: RootState) => state.account;

export const getAccountLocale = createSelector([accountSelector], (account) =>
  account ? account.localeEffective : DEFAULT_LOCALE_ID,
);

export const getAccountSettings = createSelector(
  [accountSelector],
  (account) => {
    return account
      ? {
          commentMachineTranslationMode: account.commentMachineTranslationMode,
          colorMode: account.colorMode,
        }
      : {};
  },
);
