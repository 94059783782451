import { List } from 'immutable';
import get from 'lodash/get';

import { Company } from '@peakon/records';

const company = (state = new Company(), action: $TSFixMe): Company => {
  switch (action.type) {
    case 'COMPANY_COUNT_SUCCESS': {
      const {
        data: {
          attributes: { employeeCount },
        },
      } = action.data;

      return state.merge({
        employeeCount,
      });
    }
    case 'COMPANY_LOGO_UPLOAD_SUCCESS': {
      const { field, value } = action.data;

      return state.set(field, value);
    }
    case 'COMPANY_ACCOUNT_UPDATE_SUCCESS': {
      const { form } = action.data;

      return state.merge(form);
    }
    case 'COMPANY_UPDATE_SETTINGS_SUCCESS': {
      const { settings } = action.data;

      return state.updateIn(['settings'], (current) => current.merge(settings));
    }
    case 'COMPANY_UPDATE_SETTINGS_FAILED': {
      const { original } = action.data;

      return state.updateIn(['settings'], (current) => current.merge(original));
    }
    case 'COMPANY_UPDATE_DRIVER_MODE': {
      const { driverMode } = action.data;

      return state.updateIn(['settings'], (current) =>
        current.set('driverMode', driverMode),
      );
    }
    case 'COMPANY_DOMAINS_READ_SUCCESS': {
      const {
        data: {
          attributes: { allowAllDomains, domains },
        },
      } = action.data;

      return state.merge({
        allowAllDomains,
        domains: List(domains),
      });
    }
    case 'SESSION_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const currentCompany = get(action.data, 'data.relationships.company');

      if (!currentCompany || state.id) {
        return state;
      }

      return Company.createFromApi(currentCompany);
    }
    case 'COMPANY_UPDATE_BENCHMARK_TYPE_SUCCESS': {
      const { benchmarkType } = action.data;

      return state.merge({
        benchmarkType,
      });
    }
    case 'COMPANY_UPDATE_INDUSTRY_SUCCESS':
    case 'COMPANY_UPDATE_PERCENTILE_SUCCESS': {
      const { benchmarkSelector } = action.data;

      return state.set('benchmarkSelector', benchmarkSelector);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default company;
