import { useState } from 'react';

import useResize from './useResize';

// eslint-disable-next-line import/no-default-export
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useResize(handleResize, {
    triggerOnMount: true,
    wait: 200,
  });

  return windowSize;
}
