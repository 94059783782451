import React from 'react';

import classnames from 'classnames';

import {
  AcknowledgementsThanksForSharingIcon,
  AcknowledgementsGreatIdeaIcon,
  AcknowledgementsWorkingOnItIcon,
  AcknowledgementsTalkInPersonIcon,
  AcknowledgementsIAgreeIcon,
} from '@peakon/bedrock/icons/graphic';

import styles from './styles.css';

const mapAcknowledgementTypeToIcon = {
  thanks_for_sharing: AcknowledgementsThanksForSharingIcon,
  great_idea: AcknowledgementsGreatIdeaIcon,
  working_on_it: AcknowledgementsWorkingOnItIcon,
  would_love_to_talk_in_person: AcknowledgementsTalkInPersonIcon,
  i_agree: AcknowledgementsIAgreeIcon,
} as const;

type Props = {
  className?: string;
  size?: 'tiny' | 'small' | 'normal' | 'large' | 'huge';
  type: keyof typeof mapAcknowledgementTypeToIcon;
  as?: 'div' | 'span';
};

export const AcknowledgementIcon = ({
  as: Component = 'div',
  className,
  size = 'normal',
  type,
}: Props) => {
  const Icon = mapAcknowledgementTypeToIcon[type];

  return (
    <Component
      className={classnames(styles.root, className, {
        [styles[size]]: size,
      })}
      data-test-id={`${type}-acknowledgement-icon`}
    >
      <Icon />
    </Component>
  );
};
