import { t } from '@peakon/shared/features/i18next/t';

import { FeedbackType } from './CommentFeedback';

export const ModalTranslationKeys = {
  CommentButtonChange: 'CommentButtonChange',
  CommentButton: 'CommentButton',
  ModalTitle: 'ModalTitle',
  ModalSubTitle: 'ModalSubTitle',
  DialogInfo: 'DialogInfo',
  DialogRelevancySubTitle: 'DialogRelevancySubTitle',
  RadioButtonRelevant: 'RadioButtonRelevant',
  RadioButtonNotRelevant: 'RadioButtonNotRelevant',
  CheckboxIsBiased: 'CheckboxIsBiased',
  TextBoxBiasedLabel: 'TextBoxBiasedLabel',
  TextBoxBiasedPlaceHolder: 'TextBoxBiasedPlaceHolder',
  FeedbackProvided: 'FeedbackProvided',
  CommentRelevant: 'CommentRelevant',
  CommentIrrelevant: 'CommentIrrelevant',
  CommentBiased: 'CommentBiased',
  CommentRelevantAndBiased: 'CommentRelevantAndBiased',
  CommentIrrelevantAndBiased: 'CommentIrrelevantAndBiased',
  Cancel: 'Cancel',
  Submit: 'Submit',
} as const;

export const getTranslationsObjectByType = (
  type: (typeof FeedbackType)[keyof typeof FeedbackType],
): Record<
  (typeof ModalTranslationKeys)[keyof typeof ModalTranslationKeys],
  string
> => {
  const translationsObject: Record<
    (typeof FeedbackType)[keyof typeof FeedbackType],
    Record<
      (typeof ModalTranslationKeys)[keyof typeof ModalTranslationKeys],
      string
    >
  > = {
    [FeedbackType.SemanticSearch]: {
      [ModalTranslationKeys.CommentButtonChange]: t(
        'provide_feedback_for_comment_button_change',
      ),
      [ModalTranslationKeys.CommentButton]: t(
        'provide_feedback_for_comment_button',
      ),
      [ModalTranslationKeys.ModalTitle]: t(
        'provide_feedback_for_comment_dialog_title',
      ),
      [ModalTranslationKeys.ModalSubTitle]: '',
      [ModalTranslationKeys.DialogInfo]: t(
        'provide_feedback_for_comment_dialog_info',
      ),
      [ModalTranslationKeys.DialogRelevancySubTitle]: t(
        'provide_feedback_for_comment_dialog_relevancy',
      ),
      [ModalTranslationKeys.RadioButtonRelevant]: t(
        'provide_feedback_for_comment_dialog_relevant',
      ),
      [ModalTranslationKeys.RadioButtonNotRelevant]: t(
        'provide_feedback_for_comment_dialog_not_relevant',
      ),
      [ModalTranslationKeys.CheckboxIsBiased]: t(
        'provide_feedback_for_comment_dialog_biased_checkbox',
      ),
      [ModalTranslationKeys.TextBoxBiasedLabel]: t(
        'provide_feedback_for_comment_dialog_biased_comment',
      ),
      [ModalTranslationKeys.TextBoxBiasedPlaceHolder]: t(
        'provide_feedback_for_comment_biased_comment_placeholder',
      ),
      [ModalTranslationKeys.FeedbackProvided]: t(
        'provide_feedback_for_comment_label',
      ),
      [ModalTranslationKeys.CommentRelevant]: t(
        'provide_feedback_for_comment_result_relevant',
      ),
      [ModalTranslationKeys.CommentIrrelevant]: t(
        'provide_feedback_for_comment_result_not_relevant',
      ),
      [ModalTranslationKeys.CommentBiased]: t(
        'provide_feedback_for_comment_result_biased',
      ),
      [ModalTranslationKeys.CommentRelevantAndBiased]: t(
        'provide_feedback_for_comment_result_relevant_and_biased',
      ),
      [ModalTranslationKeys.CommentIrrelevantAndBiased]: t(
        'provide_feedback_for_comment_result_not_relevant_and_biased',
      ),
      [ModalTranslationKeys.Cancel]: t(
        'provide_feedback_for_comment_dialog_cancel',
      ),
      [ModalTranslationKeys.Submit]: t(
        'provide_feedback_for_comment_dialog_submit',
      ),
    },
    [FeedbackType.SemanticTopics]: {
      [ModalTranslationKeys.CommentButtonChange]: t(
        'semantic_topics__feedback_button_change',
      ),
      [ModalTranslationKeys.CommentButton]: t(
        'semantic_topics__feedback_button',
      ),
      [ModalTranslationKeys.ModalTitle]: t(
        'semantic_topics__feedback_modal__title',
      ),
      [ModalTranslationKeys.ModalSubTitle]: t(
        'semantic_topics__feedback_modal__sub_title',
      ),
      [ModalTranslationKeys.DialogInfo]: t(
        'semantic_topics__feedback_modal__info',
      ),
      [ModalTranslationKeys.DialogRelevancySubTitle]: t(
        'semantic_topics__feedback_modal__comment_relevancy_title',
      ),
      [ModalTranslationKeys.RadioButtonRelevant]: t(
        'semantic_topics__feedback_modal__comment_dialog_relevant',
      ),
      [ModalTranslationKeys.RadioButtonNotRelevant]: t(
        'semantic_topics__feedback_modal__comment_dialog_irrelevant',
      ),
      [ModalTranslationKeys.CheckboxIsBiased]: t(
        'semantic_topics__feedback_modal__comment_dialog_biased_checkbox',
      ),
      [ModalTranslationKeys.TextBoxBiasedLabel]: t(
        'semantic_topics__feedback_modal__dialog_provide_more_details',
      ),
      [ModalTranslationKeys.TextBoxBiasedPlaceHolder]: t(
        'semantic_topics__feedback_modal__comment_biased_comment_placeholder',
      ),
      [ModalTranslationKeys.FeedbackProvided]: t(
        'semantic_topics__provide_feedback_for_comment_label',
      ),
      [ModalTranslationKeys.CommentRelevant]: t(
        'semantic_topics__feedback_modal__comment_result_relevant',
      ),
      [ModalTranslationKeys.CommentIrrelevant]: t(
        'semantic_topics__feedback_modal__comment_result_irrelevant',
      ),
      [ModalTranslationKeys.CommentBiased]: t(
        'semantic_topics__feedback_modal__comment_result_biased',
      ),
      [ModalTranslationKeys.CommentRelevantAndBiased]: t(
        'semantic_topics__feedback_modal__comment_result_relevant_and_biased',
      ),
      [ModalTranslationKeys.CommentIrrelevantAndBiased]: t(
        'semantic_topics__feedback_modal__comment_result_irrelevant_and_biased',
      ),
      [ModalTranslationKeys.Cancel]: t(
        'semantic_topics__feedback_modal__cancel_button',
      ),
      [ModalTranslationKeys.Submit]: t(
        'semantic_topics__feedback_modal__submit_button',
      ),
    },
  };

  return translationsObject[type];
};

export const getFooterTextKey = (
  type: (typeof FeedbackType)[keyof typeof FeedbackType],
  isFeedbackProvided: boolean,
  isRelevant: boolean | undefined,
  isBiased: boolean,
) => {
  const translations = getTranslationsObjectByType(type);

  if (!isFeedbackProvided) {
    return translations[ModalTranslationKeys.FeedbackProvided];
  }
  if (isRelevant && isBiased) {
    return translations[ModalTranslationKeys.CommentRelevantAndBiased];
  }

  if (!isRelevant && isBiased) {
    return translations[ModalTranslationKeys.CommentIrrelevantAndBiased];
  }

  if (isRelevant) {
    return translations[ModalTranslationKeys.CommentRelevant];
  }
  if (!isRelevant) {
    return translations[ModalTranslationKeys.CommentIrrelevant];
  }

  if (isBiased) {
    return translations[ModalTranslationKeys.CommentBiased];
  }
  return null;
};
