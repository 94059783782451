import { Map } from 'immutable';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import { getMultipleQuestionScores } from './utils';

const scores = (state = Map(), action: $TSFixMe) => {
  switch (action.type) {
    case 'HEATMAP_ATTRITION':
    case 'HEATMAP_CLOSED_ATTRITION':
    case 'HEATMAP_CLOSE':
    case 'HEATMAP_CACHE_CLEAR':
    case 'HEATMAP_COLUMN_LIST_SUCCESS':
    case 'HEATMAP_SEGMENTS_SET':
    case 'HEATMAP_SEGMENTS_CLEAR': {
      return Map();
    }

    case 'HEATMAP_FILTERS_UPDATED': {
      const { reload } = action.data;
      // when the filter is one that requires a data reload, clear the cache
      // for `mode` we only need it depending on the previous value
      if (reload) {
        return Map();
      }

      return state;
    }

    case 'HEATMAP_SEGMENT_CONTEXT_SUCCESS': {
      const {
        segmentId,
        categoryScores: { data },
        questionScores,
        driverMode,
      } = action.data;

      const scoresByCategory = reduce(
        data.relationships.categoryScores,
        (acc, curr) => {
          const categoryId = curr.id;

          const categoryQuestionScores = questionScores.data.filter(
            (questionScore: $TSFixMe) =>
              getMultipleQuestionScores(questionScore, categoryId, {
                driverMode,
              }),
          );

          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          acc[categoryId] = curr.attributes;

          for (const categoryQuestionScore of categoryQuestionScores) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            acc[
              `${categoryId}-${categoryQuestionScore.relationships.question.id}`
            ] = categoryQuestionScore.attributes;
          }

          return acc;
        },
        { null: data.attributes },
      );

      if (!data.relationships.categoryScores?.length || !data.attributes) {
        return state;
      }

      return state.set(segmentId, scoresByCategory);
    }

    case 'HEATMAP_SEGMENT_SUCCESS': {
      const {
        segmentId,
        categoryScores: { data },
        questionScores,
        attrition,
        driverMode,
      } = action.data;

      if (attrition) {
        const scoresByCategory = reduce(
          data.relationships.drivers,
          (acc, curr) => {
            const categoryId = get(curr, 'relationships.category.id');

            // optimization, we only care about engagement here
            if (curr.id === 'engagement') {
              // @ts-expect-error TS(2339): Property 'loyalty' does not exist on type '{ null:... Remove this comment to see the full error message
              // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
              acc.loyalty = {
                scores: get(curr, 'attributes.scores.subdrivers.loyalty'),
              };
            }

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            acc[categoryId] = curr.attributes;
            return acc;
          },
          { null: data.attributes },
        );

        return state.set(segmentId, scoresByCategory);
      }

      if (!data.relationships.categoryScores?.length || !data.attributes) {
        return state;
      }

      const scoresByCategory = reduce(
        data.relationships.categoryScores,
        (acc, curr) => {
          const categoryId = curr.id;

          const categoryQuestionScores = questionScores.data.filter(
            (questionScore: $TSFixMe) =>
              getMultipleQuestionScores(questionScore, categoryId, {
                driverMode,
              }),
          );

          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
          acc[categoryId] = curr.attributes;

          for (const categoryQuestionScore of categoryQuestionScores) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
            acc[
              `${categoryId}-${categoryQuestionScore.relationships.question.id}`
            ] = categoryQuestionScore.attributes;
          }

          return acc;
        },
        { null: data.attributes },
      );

      return state.set(segmentId, scoresByCategory);
    }

    case 'HEATMAP_SEGMENT_QUESTION_SCORES_SUCCESS': {
      const { segmentId, data } = action.data;

      const questionScores = {};
      for (const questionScore of data) {
        const questionId = questionScore.relationships.question.id;
        const categoryId =
          questionScore.relationships.question.relationships.category.id;
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        questionScores[`${categoryId}-${questionId}`] =
          questionScore.attributes;
      }

      return state.updateIn([segmentId], (currentScores) => {
        return {
          ...currentScores,
          ...questionScores,
        };
      });
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default scores;
