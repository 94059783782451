import React from 'react';

import classnames from 'classnames';

import { Avatar } from '@peakon/components';
import { getAbbreviation, getResizedImageUrl } from '@peakon/shared/utils';

import styles from './styles.css';

type EmployeeAvatarProps = {
  small?: boolean;
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
};
export function EmployeeAvatar({ employee, small }: EmployeeAvatarProps) {
  return employee ? (
    <Avatar
      className={classnames(styles.avatar, { [styles.small]: small })}
      src={getResizedImageUrl({
        width: 90,
        height: 90,
        fit: 'crop',
        dpr: 2,
        src: employee.avatar,
      })}
      abbreviation={getAbbreviation(employee.firstName, employee.lastName)}
      rounded
      type="header"
    />
  ) : (
    <Avatar size="small" rounded type="header" />
  );
}

type ProfileSummaryProps = {
  company?: {
    name?: string;
  };
  employee?: {
    avatar?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  };
};

function ProfileSummary({ employee, company }: ProfileSummaryProps) {
  return (
    <div className={styles.root}>
      <EmployeeAvatar employee={employee} />
      {employee && company && (
        <div className={styles.info}>
          <div className={styles.companyName}>{company.name}</div>
          <div className={styles.employeeName}>{employee.name}</div>
        </div>
      )}
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default ProfileSummary;
