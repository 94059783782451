import React from 'react';

import classnames from 'classnames';

import { TranslatedString } from '../../features/i18next/t';

import styles from './NotificationBadge.css';

type Props = {
  label: number | TranslatedString;
  variant?: 'highlight' | 'default';
} & Omit<React.ComponentProps<'span'>, 'className' | 'style'>;

/**
 *
 * This is a temporary component and should be removed once bedrock version is released.
 *
 * */
export function NotificationBadge({
  label,
  variant = 'default',
  ...restOfProps
}: Props) {
  return (
    <span
      className={classnames(styles.notificationBadge, {
        [styles.notificationBadgeHighlight]: variant === 'highlight',
        [styles.notificationBadgeDefault]: variant === 'default',
      })}
      {...restOfProps}
    >
      {label}
    </span>
  );
}
