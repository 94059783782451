import React from 'react';

import classnames from 'classnames';

import { NavigationBasicNavigationChevronDownIcon as ChevronDownIcon } from '@peakon/bedrock/icons/system';
import { LinkButton } from '@peakon/bedrock/react/button';

import styles from './styles.css';

type OwnProps = {
  children: React.ReactNode;
  className?: string;
  contentId: string;
};

const SkipNav = ({ children, className, contentId }: OwnProps) => {
  const skipTo = () => {
    const elm: HTMLElement | null = window.document.querySelector(
      `#${contentId}`,
    );

    if (!elm) {
      return;
    }

    elm.scrollIntoView();
    elm.focus();
  };

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    skipTo();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      skipTo();
    }
  };

  return (
    <div className={classnames(styles.skip, className)}>
      <LinkButton
        variant="primary"
        icon={<ChevronDownIcon />}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tag="a"
        href={`#${contentId}`}
        data-test-id="skip-nav"
      >
        {children}
      </LinkButton>
    </div>
  );
};

SkipNav.defaultProps = {
  contentId: 'app-content',
};

// eslint-disable-next-line import/no-default-export
export default SkipNav;
