/**
 * Used to match `RegExp`
 * [syntax characters](http://ecma-international.org/ecma-262/7.0/#sec-patterns).
 */
const reRegExpChar = /\./g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

const escapeAttrName = (string: string) => {
  return string && reHasRegExpChar.test(string)
    ? string.replace(reRegExpChar, '\\$&')
    : string;
};

// eslint-disable-next-line import/no-default-export
export default escapeAttrName;
