import React, { Suspense, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { Transition, TransitionGroup } from 'react-transition-group';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import variables from '@peakon/bedrock/variables';
import engageHelpers from '@peakon/engage-helpers';
import { t } from '@peakon/shared/features/i18next/t';

import { getScoreRange } from './utils';

import styles from './styles.css';

const TIMEOUT = 800;
const ACTIVE_TIMEOUT = 700;

const COLORS = {
  deuteranopia: {
    passives: {
      entering: variables['color-score-number-passives'],
      entered: 'rgb(25, 112, 184)',
    },
    detractors: {
      entering: variables['color-score-number-detractors'],
      entered: 'rgb(20, 92, 151)',
    },
  },
  normal: {
    passives: {
      entering: variables['color-score-number-passives'],
      entered: 'rgb(33, 164, 106)',
    },
    detractors: {
      entering: variables['color-score-number-detractors'],
      entered: 'rgb(27, 135, 88)',
    },
  },
};

type ScoreNumberProps = {
  className?: string;
  colorScheme?: string;
  fontSize?: number;
  inline?: boolean;
  nps?: {
    promoters: number;
    passives: number;
    detractors: number;
    score: number;
  };
  scoreMode?: 'nps' | 'mean';
  testId?: string;
} & (
  | {
      animated: true;
      score: string;
    }
  | {
      animated?: false;
      score: number | string;
    }
);

const ScoreNumberInternal = ({
  animated,
  className,
  colorScheme,
  fontSize,
  inline,
  nps,
  score,
  scoreMode = 'mean',
  testId,
}: ScoreNumberProps) => {
  const [active, setActive] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    // @ts-expect-error - Type 'null' is not assignable to type 'number | undefined'.
    return () => clearTimeout(timer.current);
  });

  if (!animated) {
    return (
      <div
        className={classnames(styles.root, styles.static, className, {
          [styles.noScore]: score === '-',
          [styles.inline]: inline,
        })}
        style={{ fontSize }}
        data-test-id={testId}
      >
        <VisuallyHidden>
          {scoreMode === 'nps'
            ? t('dashboard__enps__count', { replace: { score } })
            : t('dashboard__avg_score__count', { replace: { score } })}
        </VisuallyHidden>
        <span
          className={styles.score}
          data-test-id="score"
          style={{ fontSize }}
          aria-hidden
        >
          {score}
        </span>
      </div>
    );
  }

  if (score === '-') {
    return (
      <div
        className={classnames(styles.root, styles.animated, styles.noScore)}
        data-test-id={testId}
      >
        <VisuallyHidden>{t('a11y_label__no-score')}</VisuallyHidden>
        <span className={styles.score} aria-hidden="true">
          {score}
        </span>
      </div>
    );
  }

  const { detractors, passives } = engageHelpers.npsBreakdown(nps);
  const range = getScoreRange(parseFloat(score), scoreMode);
  const characters = score.split('');

  const scoreStyle = {
    passives: {
      entering: {
        fontVariationSettings: "'fill' 0",
      },
      entered: {
        color: active
          ? undefined
          : colorScheme === 'deuteranopia'
            ? COLORS.deuteranopia.passives.entering
            : COLORS.normal.passives.entering,
        fontVariationSettings: `'fill' ${passives + detractors}`,
        textShadow: active ? undefined : `1px 1px 1px rgba(0, 0, 0, 0.2)`,
      },
    },
    detractors: {
      entering: {
        fontVariationSettings: "'fill' 0",
      },
      entered: {
        color: active
          ? undefined
          : colorScheme === 'deuteranopia'
            ? COLORS.deuteranopia.detractors.entering
            : COLORS.normal.detractors.entering,
        fontVariationSettings: `'fill' ${detractors}`,
        textShadow: active ? undefined : `1px 1px 1px rgba(0, 0, 0, 0.2)`,
      },
    },
  };

  return (
    <div
      className={classnames(styles.root, styles.animated, {
        [styles[`range${range}`]]: range,
        [styles.delay]: !active,
      })}
      data-test-id={testId}
    >
      <div className={styles.score}>
        <VisuallyHidden>
          {scoreMode === 'nps'
            ? t('dashboard__enps__count', { replace: { score } })
            : t('dashboard__avg_score__count', { replace: { score } })}
        </VisuallyHidden>
        <div className={styles.background} aria-hidden="true">
          {score}
        </div>

        <div
          className={styles.passives}
          aria-hidden="true"
          data-test-id="main-score-passives"
        >
          <TransitionGroup>
            {characters.map((n, index) => (
              <Transition
                key={`passives-${scoreMode}-${index}-${n}`}
                appear
                exit={false}
                timeout={TIMEOUT + index * 100}
              >
                {(status) => (
                  <span
                    style={
                      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      scoreStyle.passives[status]
                    }
                  >
                    {n}
                  </span>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </div>

        <div className={styles.detractors} aria-hidden="true">
          <TransitionGroup>
            {characters.map((n, index) => (
              <Transition
                key={`detractors-${scoreMode}-${index}-${n}`}
                appear
                exit={false}
                onEntered={() => {
                  if (index === characters.length - 1 && !active) {
                    timer.current = setTimeout(() => {
                      setActive(true);
                    }, ACTIVE_TIMEOUT);
                  }
                }}
                timeout={TIMEOUT + index * 100}
              >
                {(status) => (
                  <span
                    style={
                      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      scoreStyle.detractors[status]
                    }
                  >
                    {n}
                  </span>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export function ScoreNumber(props: ScoreNumberProps) {
  return (
    <Suspense fallback={null}>
      <ScoreNumberInternal {...props} />
    </Suspense>
  );
}
