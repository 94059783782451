import React from 'react';

import classnames from 'classnames';

import { customAnalyticsEvents } from '../../../../features/analytics/customAnalyticsEvents';
import Link from '../../components/Link';

import styles from './styles.css';

type Props = {
  linkType?: 'a' | 'react';
  children?: React.ReactNode;
  to?: string;
  isActive?: boolean;
  productName: string;
};

const Tab = ({
  children,
  isActive,
  to,
  linkType,
  productName,
  ...props
}: Props) => {
  return (
    <li>
      <Link
        onClick={() => {
          customAnalyticsEvents.trackNavigation({
            level: 'first',
            name_first_level: productName,
          });
        }}
        {...props}
        type={linkType ?? 'a'}
        to={to}
        className={classnames({ [styles.active]: isActive }, styles.link)}
      >
        <React.Fragment>{children}</React.Fragment>
      </Link>
    </li>
  );
};

// eslint-disable-next-line import/no-default-export
export default Tab;
