import RealtimeSocket from './RealtimeSocket';
import { errorReporter } from '../errorReporter';

type RealtimeChannelOptions = {
  getToken: () => string;
};

type Listener = {
  eventName: string;
  callback: (notification: $TSFixMe) => void;
};

class RealtimeChannel {
  channel: string;
  getToken: RealtimeChannelOptions['getToken'];
  joined: boolean;
  listeners: Listener[];
  realtimeSocket: RealtimeSocket;

  constructor(channel: string, { getToken }: RealtimeChannelOptions) {
    this.realtimeSocket = new RealtimeSocket();
    this.channel = channel;
    this.listeners = [];
    this.joined = false;
    this.getToken = getToken;
    this.realtimeSocket.getSocket().on('connect', () => {
      const event = new Event(`realtimeChannelConnected-${this.channel}`);
      window.dispatchEvent(event);
    });
  }

  isConnected() {
    return this.realtimeSocket.getSocket().connected;
  }

  isAuthenticated() {
    return Boolean(this.getToken());
  }

  on(eventName: Listener['eventName'], callback: Listener['callback']) {
    const fullEventName = `${this.channel}/${eventName}`;
    this.realtimeSocket.getSocket().on(fullEventName, callback);

    this.listeners.push({
      eventName: fullEventName,
      callback,
    });
  }

  waitUntilConnected() {
    return new Promise((resolve) =>
      window.addEventListener(
        `realtimeChannelConnected-${this.channel}`,
        resolve,
        {
          once: true,
          passive: true,
        },
      ),
    );
  }

  async join() {
    if (!this.realtimeSocket.getSocket().connected) {
      this.realtimeSocket.getSocket().connect();
      await this.waitUntilConnected();
    }

    if (!this.joined) {
      await this.joinChannel();
      // Add reconnect listener to rejoin channels
      this.realtimeSocket.getSocket().on('reconnect', this.onReconnect);
    }
  }

  async joinChannel() {
    if (!this.isAuthenticated()) {
      return errorReporter.warning(
        `Trying to connect to ${this.channel} without being authenticated.`,
      );
    }

    const response = await this.realtimeSocket
      .getSocket()
      // we have a theory this timeout is causing issues.
      // bumping to hopefully see less of errors like these `Error: operation has timed out` https://app.rollbar.com/a/peakon-all/fix/item/peakon-dashboard/5331
      // https://workday-dev.slack.com/archives/C01TN7LUFNE/p1701261927240079
      .timeout(30000)
      .emitWithAck('joinChannel', {
        channel: this.channel,
        token: this.getToken(),
      });

    if (response.status === 'error') {
      this.joined = false;
      if (response.errorCode === 401) {
        errorReporter.warning(response.errorMessage);
      } else {
        throw new Error(response.errorMessage);
      }
    } else {
      this.joined = true;
    }
  }

  leave(disconnectSocket = false) {
    if (this.joined) {
      this.joined = false;
      // Remove reconnect listener
      this.realtimeSocket
        .getSocket()
        .removeListener('reconnect', this.onReconnect);

      // Remove event listeners
      this.listeners.forEach(({ eventName, callback }) =>
        this.realtimeSocket.getSocket().removeListener(eventName, callback),
      );

      this.leaveChannel();
      if (disconnectSocket) {
        this.realtimeSocket.getSocket().disconnect();
      }
    }
  }

  leaveChannel() {
    this.realtimeSocket.getSocket().emit('leaveChannel', {
      channel: this.channel,
    });
  }

  async reconnect() {
    if (!this.realtimeSocket.getSocket().connected) {
      this.realtimeSocket.getSocket().connect();
      await this.waitUntilConnected();
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disable here to enable the rule globally
    this.joinChannel();
  }

  async onReconnect() {
    if (this.joined && this.isAuthenticated()) {
      await this.reconnect();
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default RealtimeChannel;
