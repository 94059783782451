import { Map } from 'immutable';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import Context from '@peakon/records/ContextRecord';
import EngagementSegment from '@peakon/records/EngagementSegmentRecord';

import isNumeric from '../../utils/isNumeric';

const segmentsState = (state = Map(), action: $TSFixMe) => {
  switch (action.type) {
    case 'HEATMAP_ATTRITION':
    case 'HEATMAP_REVIVE_SEGMENTS':
    case 'HEATMAP_REVIVE': {
      if (!action.data) {
        // when attrition is not the initial boot
        return state;
      }

      const { segments } = action.data;

      return reduce(
        segments,
        (acc, data, id) => {
          // poor man heuristic
          if (!isNumeric(id)) {
            return acc.set(id, Context.revive(data));
          }

          return acc.set(id, EngagementSegment.revive(data));
        },
        Map(),
      );
    }

    case 'HEATMAP_CONTEXT_SCORE_ADD': {
      const { context } = action.data;

      return state.set(context.id, context);
    }

    case 'CONTEXT_LIST_SUCCESS': {
      const { data } = action.data;

      return state.merge(
        data
          // @ts-expect-error TS(7006): Parameter 'context' implicitly has an 'any' type.
          .map((context) => Context.createFromApi(context))
          // @ts-expect-error TS(7006): Parameter 'context' implicitly has an 'any' type.
          .map((context) => [context.id, context]),
      );
    }

    case 'CRITICAL_SEGMENTS_READ_SUCCESS': {
      const { data, contextId, isInitialLoad } = action.data;

      if (!isInitialLoad) {
        return state;
      }

      // segments might have been modified once scores have been loaded (employeeCount)
      // this prevents overriding those properties, as we are caching their scores
      // if the new selection has those segments
      // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
      const segmentIds = data.map((segment) =>
        get(segment, 'relationships.segment.id'),
      );

      const prevSegments = state.filter(
        (segment) =>
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          segmentIds.includes(segment.id) || segment.id === contextId,
      );

      const filteredSegments = data
        .map(EngagementSegment.createFromApi)
        // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
        .filter((segment) => !state.has(segment.id))
        // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
        .map((segment) => [segment.id, segment]);

      return prevSegments.merge(filteredSegments);
    }

    case 'HEATMAP_SEGMENT_EXPAND_SUCCESS': {
      const { data } = action.data;

      const filteredSegments = data
        // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
        .filter((segment) => {
          const segmentId = get(segment, 'relationships.segment.id');
          return !state.has(segmentId);
        })
        .map(EngagementSegment.createFromApi)
        // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
        .map((segment) => [segment.id, segment]);

      return state.merge(filteredSegments);
    }

    case 'HEATMAP_SEGMENT_CONTEXT_LOADING':
    case 'HEATMAP_SEGMENT_LOADING': {
      const { segmentId, group } = action.data;

      if (!state.has(segmentId)) {
        // can happen when user switches segments / context / etc
        // while data is being loaded
        return state;
      }

      return state.update(segmentId, (segment) => {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        return segment.merge({ loading: true, group });
      });
    }

    case 'HEATMAP_SEGMENT_CONTEXT_SUCCESS':
    case 'HEATMAP_SEGMENT_SUCCESS': {
      const { segmentId } = action.data;

      if (!state.has(segmentId)) {
        // can happen when user switches segments / context / etc
        // while data is being loaded
        return state;
      }

      return state.update(segmentId, (segment) => {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        return segment.merge({
          loading: false,
        });
      });
    }

    case 'HEATMAP_SEGMENTS_ADD':
    case 'HEATMAP_SEGMENTS_SET': {
      // segments might have been modified once scores have been loaded (employeeCount)
      // this prevents overriding those properties, as we are caching their scores
      // if the new selection has those segments
      const newSegments = action.data.filter(
        (segment: $TSFixMe) => !state.has(segment.id),
      );

      // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
      return state.merge(newSegments.map((segment) => [segment.id, segment]));
    }

    case 'HEATMAP_CLOSE': {
      return Map();
    }

    case 'HEATMAP_SEGMENTS_CLEAR': {
      return state.filter((segment) => segment instanceof Context);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segmentsState;
