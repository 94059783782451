import { useQuery } from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';

import { catchHandler } from '../../../actions/NotificationActions';
import { useAppDispatch } from '../../../utils/reduxHooks';
import { PreviewType, TemplateOptions, Variation } from '../types';

type Data = {
  from: string;
  to: string;
  subject: string;
  html: string;
  text: string;
};

type GetPreviewParams = {
  type: PreviewType;
  locale?: string;
  variation?: Variation;
  templateOptions?: TemplateOptions;
};

const getPreview = ({
  type,
  variation,
  locale,
  templateOptions,
}: GetPreviewParams): Promise<Data> => {
  // @ts-expect-error TS(2322): Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
  return api.post(`/mails/preview/${type}`, null, {
    locale,
    ...templateOptions,
    ...variation?.templateOptions,
  });
};

type Params = {
  enabled: boolean;
  type?: PreviewType;
  onError: () => void;
} & Omit<GetPreviewParams, 'type'>;

const usePreviewQuery = ({
  enabled,
  type,
  variation,
  locale,
  templateOptions,
  onError,
}: Params) => {
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: ['emailPreview', type, variation, locale, templateOptions],
    queryFn: () => {
      if (!type) {
        throw Error('No type provided, this should never happen');
      }
      return getPreview({ type, variation, locale, templateOptions });
    },
    enabled: enabled && Boolean(type),
    onError: (error) => {
      dispatch(catchHandler(error));
      onError();
    },
  });
};

// eslint-disable-next-line import/no-default-export
export default usePreviewQuery;
