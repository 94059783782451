import React from 'react';

import flatten from 'lodash/flatten';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Highlighter from 'react-highlight-words';

import { Comment } from '@peakon/records';

import styles from './styles.css';

const SEARCH_HIGHLIGHT_REGEX = /\*{2}([^*]+)\*{2}/g;

type Props = {
  comment: Comment;
  topicId?: string;
};

export const HighlightedCommentText = ({ comment, topicId }: Props) => {
  let textToHighlight: string = comment.text;

  const topicSearchWords =
    topicId && comment.topics
      ? flatten(
          comment.topics
            .filter((topic) => topic.id === topicId)
            .map((topic) => topic.matches)
            .toJS(),
        )
      : [];

  const highlightClassName = {};
  topicSearchWords.forEach((word) => {
    // @ts-expect-error TS(2538): Type 'unknown' cannot be used as an index type.
    highlightClassName[word] = styles.highlight;
  });

  let sensitiveSearchWords: string[] = [];
  if (comment.sensitive && comment.sensitiveMatches) {
    sensitiveSearchWords = comment.sensitiveMatches.toJS();
    sensitiveSearchWords.forEach((word) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      highlightClassName[word] = styles.sensitiveHighlight;
    });
  }

  let searchedWords: string[] = [];
  const searchHighlight = comment.text.includes('**');
  if (searchHighlight) {
    const matches = comment.text.match(SEARCH_HIGHLIGHT_REGEX);
    if (matches) {
      searchedWords = matches.map((w) => w.replace(/\*/g, ''));
    }

    // ensure the highlighted double asterisk gets removed, but keep single/non-highlighted ones
    searchedWords.forEach(
      (w) => (textToHighlight = textToHighlight.replace(`**${w}**`, w)),
    );
  }

  const searchWords = topicSearchWords
    .concat(sensitiveSearchWords)
    .concat(searchedWords);

  return (
    <Highlighter
      autoEscape
      highlightClassName={highlightClassName}
      searchWords={searchWords}
      textToHighlight={textToHighlight}
    />
  );
};
