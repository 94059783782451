import z from 'zod';

export const OpenResponsesSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      type: z.literal('responses'),
    }),
  ),
});
