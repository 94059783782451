import z from 'zod';

import { contextAttributeSchema } from './attributes';
import { categorySchema } from './categories';
import { categoryGroupSchema } from './categoryGroups';

const contextCategoryGroupSchema = z.object({
  type: z.literal('context_category_groups'),
  id: z.string(),
  attributes: z.object({
    access: z.enum(['full', 'restricted', 'partial']),
    hasOutcome: z.boolean(),
    hasOtherDrivers: z.boolean(),
    resource: z.array(z.enum(['score', 'comment'])),
  }),
  relationships: z.object({
    categoryGroup: categoryGroupSchema,
    categories: z
      .array(
        categorySchema.partial({
          attributes: true,
        }),
      )
      .nullable(),
  }),
});

export const contextSchema = z.object({
  id: z.string(),
  type: z.literal('contexts'),
  links: z.object({
    self: z.string(),
  }),
  attributes: z.object({
    level: z.enum(['company', 'segment']),
    direct: z.boolean().optional(),
    isEmployeeDashboardTeam: z.boolean().optional(),
    rights: z.array(z.string()).optional(),
    logo: z.string().nullable(),
    name: z.string(),
    abbreviation: z.string(),
    employeeCount: z
      .object({
        hired: z.number().optional(),
        employed: z.number().optional(),
        left: z.number().optional(),
      })
      .optional(),
  }),
  relationships: z.object({
    employee: z
      .object({
        type: z.literal('employees'),
        id: z.string(),
      })
      .nullable(),
    attribute: contextAttributeSchema.nullable(),
    contextCategoryGroups: z.array(contextCategoryGroupSchema).optional(),
  }),
});

export type ContextResponse = z.infer<typeof contextSchema>;
export type ContextCategoryGroupResponse = z.infer<
  typeof contextCategoryGroupSchema
>;
