import z from 'zod';

import { BenchmarkTypeEnum } from '../../types/Benchmark';

const commentDateVisibility = ['date', 'round'] as const;
const CommentDateVisibilityEnum = z.enum(commentDateVisibility);

const conversationEmail = ['full', 'limited'] as const;
const ConversationEmailEnum = z.enum(conversationEmail);

const customSenderDomainDkimMode = ['easydkim', 'byodkim'] as const;
const CustomSenderDomainDkimModeEnum = z.enum(customSenderDomainDkimMode);

const driverMode = ['aggregated', 'split', 'mixed'] as const;
const DriverModeEnum = z.enum(driverMode);

const driversOrder = ['score', 'difference', 'priority'] as const;
const DriversOrderEnum = z.enum(driversOrder);

const engagementScore = ['average', 'enps'] as const;
const EngagementScoreEnum = z.enum(engagementScore);

const semanticSearchTerms = ['accepted', 'not-accepted'] as const;
const SemanticSearchTermsEnum = z.enum(semanticSearchTerms);

const companySettings = z.object({
  anonymityLevel: z.number().int().nullable(),
  commentDateVisibility: CommentDateVisibilityEnum.nullable(),
  commentLevel: z.number().int().nullable(),
  commentRoundLevel: z.number().int().nullable(),
  conversationEmail: ConversationEmailEnum.nullable(),
  customSenderDomainDkimMode: CustomSenderDomainDkimModeEnum.nullable(),
  dataRetentionPeriod: z.number().int().nullable(),
  differenceLevel: z.number().int().nullable(),
  driverMode: DriverModeEnum.nullable(),
  driversOrder: DriversOrderEnum.nullable(),
  engagementScore: EngagementScoreEnum.nullable(),
  leaverAutoDeletionPeriod: z.number().int().nullable(),
  semanticSearchTerms: SemanticSearchTermsEnum.nullable(),
  sensitiveComments: z.boolean().nullable(),
  significanceLevel: z.number().int().nullable(),
  smimeEnabled: z.boolean().nullable(),
  validityDriver: z.number().int().nullable(),
  validityEnded: z.number().int().nullable(),
  validityOverall: z.number().int().nullable(),
});

export const companySchema = z.object({
  id: z.string(),
  type: z.literal('companies'),
  attributes: z.object({
    abbreviation: z.string(),
    accessAllowed: z.boolean(),
    addOns: z.array(z.string()),
    benchmarkId: z.string().nullable(),
    benchmarkSelector: z.record(z.string(), z.unknown()).nullable(),
    benchmarkType: BenchmarkTypeEnum,
    createdAt: z.string(),
    employeeFeatures: z.array(z.string()),
    features: z.array(z.string()),
    location: z.string().nullish(),
    logo: z.string().nullable(),
    name: z.string().nullable(),
    sector: z.string().nullable(),
    settings: companySettings,
    size: z.string().nullable(),
    testCompany: z.boolean(),
    timezone: z.string().nullable(),
  }),
  links: z
    .object({
      self: z.string(),
    })
    .optional(),
  relationships: z
    .object({
      companySubdomains: z.array(
        z
          .object({
            attributes: z.object({
              primary: z.boolean(),
              subdomain: z.string(),
            }),
          })
          .nullable()
          .optional(),
      ),
    })
    .optional(),
});
