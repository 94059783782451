import React from 'react';

import classnames from 'classnames';

import { t } from '@peakon/shared/features/i18next/t';
import { getMembersStatus } from '@peakon/shared/utils';

import styles from './styles.css';

type Props = {
  attribute?: $TSFixMe;
  className?: string;
  direct?: boolean;
  hideReporting?: boolean;
  type?: 'light' | 'noBorder';
};

const AttributeBadge = ({
  attribute,
  className,
  direct,
  hideReporting,
  type = 'light',
}: Props) => {
  if (!attribute) {
    return null;
  }

  return (
    <span
      className={classnames(styles.root, className, {
        [styles[type]]: type,
      })}
      aria-label={t('a11y_label__attribute', {
        replace: { attribute: attribute.name },
      })}
    >
      {attribute.nameTranslated || attribute.name}
      {!hideReporting &&
      (attribute.type === 'employee' || attribute.type === 'tree') ? (
        <span>
          &nbsp;
          <span>{`(${getMembersStatus(attribute.type, direct)})`}</span>
        </span>
      ) : null}
    </span>
  );
};

// eslint-disable-next-line import/no-default-export
export default AttributeBadge;
