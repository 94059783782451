type Action = {
  type: `TOPIC_LOCALES_LIST_${'SUCCESS' | 'LOADING' | 'FAILED' | 'CANCELLED'}`;
  data: string[];
};

const locales = (state: Array<string> = [], action: Action): Array<string> => {
  switch (action.type) {
    case 'TOPIC_LOCALES_LIST_SUCCESS': {
      return action.data.filter((locale) => locale);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default locales;
