import { List } from 'immutable';
import { REHYDRATE } from 'redux-persist';

export const MIN_COLUMNS = 3;

export const DEFAULT_COLUMNS = [
  {
    id: 'identifier',
    label: 'identifier',
    key: 'employees-page__edit-field-identifier',
    type: 'property',
  },
  {
    id: 'engagement',
    label: 'engagement',
    key: 'employees__filtering__advanced__engagement',
    type: 'property',
  },
  {
    id: 'createdAt',
    label: 'createdAt',
    key: 'employees-page__edit-field-createdAt',
    type: 'property',
  },
  {
    id: 'timezone',
    label: 'timezone',
    key: 'employees-page__edit-field-timezone',
    type: 'property',
  },
];

export type DefaultColumnLabelKey =
  | 'employees-page__edit-field-identifier'
  | 'employees__filtering__advanced__engagement'
  | 'employees-page__edit-field-createdAt'
  | 'employees-page__edit-field-timezone';

const filterColumnsState = (state = List(), action: $TSFixMe) => {
  switch (action.type) {
    case REHYDRATE: {
      const { filterColumns } = action.payload ?? {};

      return Array.isArray(filterColumns) && filterColumns.length >= 1
        ? List(filterColumns)
        : List();
    }
    case 'ATTRIBUTE_READ_SUCCESS': {
      const { data } = action.data;
      // @ts-expect-error TS(7006): Parameter 'filter' implicitly has an 'any' type.
      const filterIds = data.map((filter) => filter.id);
      const defaultIds = DEFAULT_COLUMNS.map((filter) => filter.id);

      // Filter any current columns that are not present any more
      const nextColumns = state.filter(
        // @ts-expect-error TS(2769): No overload matches this call.
        (id) => filterIds.includes(id) || defaultIds.includes(id),
      );

      if (state.size === 0) {
        return List(filterIds.slice(0, MIN_COLUMNS));
      } else if (nextColumns.size < state.size) {
        const extraColumns = data
          // @ts-expect-error TS(7006): Parameter 'filter' implicitly has an 'any' type.
          .filter((filter) => !nextColumns.includes(filter.id))
          // @ts-expect-error TS(7006): Parameter 'filter' implicitly has an 'any' type.
          .map((filter) => filter.id);

        // append columns until we have as many as there were
        return List(nextColumns).concat(extraColumns).slice(0, state.size);
      }

      return List(nextColumns);
    }
    case 'FILTER_COLUMN_TOGGLED': {
      const { id } = action.data;

      return state.includes(id)
        ? state.filter((column) => column !== id)
        : state.push(id);
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default filterColumnsState;
