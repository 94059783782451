import { SegmentEditor, Translation } from '@peakon/records';

const segmentEditor = (
  state = new SegmentEditor(),
  action: $TSFixMe,
): SegmentEditor => {
  switch (action.type) {
    case 'SEGMENT_EDITOR_START': {
      const { segment } = action.data;

      return new SegmentEditor({
        original: segment,
        current: segment,
      });
    }

    case 'SEGMENT_EDITOR_STOP': {
      return new SegmentEditor();
    }

    case 'SEGMENT_EDITOR_UPDATE_FIELD': {
      const { field, value } = action.data;

      return state.setIn(['current', field], value);
    }

    case 'SEGMENT_EDITOR_UPDATE_TRANSLATION': {
      const { autoFocus, locale, text } = action.data;

      const selector = ['current', 'nameTranslations', locale];

      if (state.hasIn(selector)) {
        // @ts-expect-error - Type 'Map<string, any>' is not assignable to type 'SegmentEditor'.
        return state.updateIn(selector, (translation) =>
          translation.set('translation', text),
        );
      }

      return state.setIn(
        selector,
        new Translation({
          locale,
          translation: text,
          autoFocus,
        }),
      );
    }

    case 'SEGMENT_EDITOR_REMOVE_TRANSLATION': {
      const { locale, type } = action.data;

      // @ts-expect-error - Type 'Map<string, any>' is not assignable to type 'SegmentEditor'.
      return state.removeIn(['current', `${type}Translations`, locale]);
    }

    case 'SEGMENT_EDITOR_LOGO_PREVIEW': {
      const { preview, original } = action.data;

      return state.set('file', { preview, original });
    }

    case 'SEGMENT_EDITOR_REMOVE_LOGO_PREVIEW': {
      return state.set('file', null);
    }

    case 'SEGMENT_EDITOR_UPLOAD_SUCCESS': {
      const { value } = action.data;

      return state.setIn(['current', 'logo'], value);
    }

    case 'SEGMENT_EDITOR_UPLOAD_FAILED': {
      const { error } = action.data;

      return state.set('file', {
        error,
      });
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segmentEditor;
