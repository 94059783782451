import { Context, createContext, useContext } from 'react';

import { ContextResponse } from '@peakon/shared/schemas/api/contexts';

import { NullableSession } from '../types/Session';

export type CompanyForm = {
  abbreviation: string;
  name: string;
  logo: string;
};

export type ShellContextType<TSession extends NullableSession> = {
  session: TSession;
  onToggleExpand: () => void;
  onToggleOutage: () => void;
  onNewVersion: () => void;
  onCompanyUpdated: (form?: CompanyForm) => void;
  authenticate: () => void;
  statusPageHost?: string;
  version?: string;
  hasOutage?: boolean;
  hasNewVersion?: boolean;
  isConsultant?: boolean;
  isPartner?: boolean;
  context?: ContextResponse | null;
  contexts: ContextResponse[];
  rights: { [x: string]: string };
  mainCategoryGroup?: string | null;
  mainCategoryGroupWithDrivers?: string | null;
  features: string[];
  isAuthenticated?: boolean;
  isLoading?: boolean;
};

// @ts-expect-error Variable 'shellContext' implicitly has type 'any' in some locations where its type cannot be determined.ts(7034)
let shellContext;

export const defaultContext = {
  session: undefined,
  onToggleExpand: () => {
    // noop
  },
  onToggleOutage: () => {
    // noop
  },
  onNewVersion: () => {
    // noop
  },
  onChangeContext: () => {
    // noop
  },
  onCompanyUpdated: () => {
    // noop
  },
  authenticate: () => {
    // noop
  },
  statusPageHost: undefined,
  version: undefined,
  hasOutage: undefined,
  hasNewVersion: undefined,
  isConsultant: undefined,
  isPartner: undefined,
  context: undefined, // have context as undefined until loaded. context can legitimately be null, since eg. an employee does not have access to a context
  rights: {},
  mainCategoryGroup: undefined,
  mainCategoryGroupWithDrivers: undefined,
  features: [],
  contexts: [],
  isLoadingContext: true,
  isLoading: true,
};

export function getShellContext<TSession extends NullableSession>(): Context<
  ShellContextType<TSession>
> {
  // @ts-expect-error Variable 'shellContext' implicitly has an 'any' type.ts(7005)
  if (!shellContext) {
    shellContext = createContext(defaultContext);
  }

  return shellContext;
}

export function resetShellContext() {
  shellContext = createContext(defaultContext);
}

export function useShellContext<TSession extends NullableSession>() {
  return useContext<ShellContextType<TSession>>(getShellContext());
}
