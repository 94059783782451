import { List } from 'immutable';

import Filter, { FilterOption } from './FilterRecord';

const filters = [
  {
    id: 'employee_role',
    label: 'employees__filtering__advanced__manager',
    group: 'job',
    type: 'radio',
    options: [
      {
        id: 'employees__filtering__advanced__manager__true',
        label: 'employees__filtering__advanced__manager__true',
      },
      {
        id: 'employees__filtering__advanced__manager__false',
        label: 'employees__filtering__advanced__manager__false',
      },
    ],
  },
  {
    id: 'email_bounced',
    label: 'employees__filtering__advanced__email_bounced',
    group: 'contact',
    type: 'radio',
    options: [
      {
        id: 'employees__filtering__advanced__email_bounced__true',
        label: 'employees__filtering__advanced__email_bounced__true',
      },
      {
        id: 'employees__filtering__advanced__email_bounced__false',
        label: 'employees__filtering__advanced__email_bounced__false',
      },
    ],
  },
  {
    id: 'engagement',
    label: 'employees__filtering__advanced__engagement',
    group: 'contact',
    type: 'radio',
    options: [
      {
        id: 'employees__filtering__advanced__engagement__true',
        label: 'employees__filtering__advanced__engagement__true',
      },
      {
        id: 'employees__filtering__advanced__engagement__false',
        label: 'employees__filtering__advanced__engagement__false',
      },
    ],
  },
  {
    id: 'timezone',
    label: 'employees__filtering__advanced__timezone',
    group: 'contact',
    type: 'timezone',
    options: [],
  },
  {
    id: 'locale',
    label: 'employees__filtering__advanced__locale',
    group: 'contact',
    type: 'locale',
    options: [],
  },
  {
    id: 'createdAt',
    label: 'employees-page__edit-field-createdAt',
    group: 'employment',
    type: 'date',
    options: [],
  },
  {
    id: 'employmentStatus',
    label: 'employees-page__edit-field-employmentStatus',
    group: 'employment',
    type: 'option',
    options: [
      {
        id: 'hired',
        label: 'employees__filtering__advanced__employmentStatus__hired',
      },
      {
        id: 'employed',
        label: 'employees__filtering__advanced__employmentStatus__employed',
      },
      {
        id: 'left',
        label: 'employees__filtering__advanced__employmentStatus__left',
      },
    ],
  },
  {
    id: 'visibility',
    label: 'employees__filtering__visibility',
    group: 'other',
    type: 'radio',
    options: [
      {
        id: 'employees__filtering__visibility__true',
        label: 'employees__filtering__visibility__true',
      },
      {
        id: 'employees__filtering__visibility__false',
        label: 'employees__filtering__visibility__false',
      },
    ],
  },
  {
    id: 'type',
    label: 'employees__filtering__type',
    group: 'employment',
    type: 'option',
    options: [
      {
        id: 'permanent',
        label: 'employees__filtering__type__permanent',
      },
      {
        id: 'ephemeral',
        label: 'employees__filtering__type__ephemeral',
      },
    ],
  },
  {
    id: 'email',
    label: 'employees__filtering__advanced__email',
    group: 'contact',
    type: 'radio',
    options: [
      {
        id: 'employees__filtering__advanced__email__true',
        label: 'employees__filtering__advanced__email__true',
      },
      {
        id: 'employees__filtering__advanced__email__false',
        label: 'employees__filtering__advanced__email__false',
      },
    ],
  },
];

const createDefaultFilters = (currentFilters: typeof filters) =>
  currentFilters.map(
    ({ id, label, group, type, options }) =>
      new Filter({
        id,
        label,
        group,
        type,
        default: true,
        options: List(
          options.map(
            (option) =>
              new FilterOption({
                ...option,
                default: true,
              }),
          ),
        ),
      }),
  );

// eslint-disable-next-line import/no-default-export
export default createDefaultFilters(filters);

export type DefaultFilterLabelKey =
  | 'employees__filtering__advanced__manager'
  | 'employees__filtering__advanced__email_bounced'
  | 'employees__filtering__advanced__engagement'
  | 'employees__filtering__advanced__timezone'
  | 'employees__filtering__advanced__locale'
  | 'employees-page__edit-field-createdAt'
  | 'employees-page__edit-field-employmentStatus'
  | 'employees__filtering__visibility'
  | 'employees__filtering__type'
  | 'employees__filtering__advanced__email';

export type DefaultOptionFilterOptionLabelKey =
  | 'employees__filtering__advanced__employmentStatus__hired'
  | 'employees__filtering__advanced__employmentStatus__employed'
  | 'employees__filtering__advanced__employmentStatus__left'
  | 'employees__filtering__type__permanent'
  | 'employees__filtering__type__ephemeral';

export type DefaultRadioFilterOptionLabelKey =
  | 'employees__filtering__advanced__manager__true'
  | 'employees__filtering__advanced__manager__false'
  | 'employees__filtering__advanced__email_bounced__true'
  | 'employees__filtering__advanced__email_bounced__false'
  | 'employees__filtering__advanced__engagement__true'
  | 'employees__filtering__advanced__engagement__false'
  | 'employees__filtering__visibility__true'
  | 'employees__filtering__visibility__false'
  | 'employees__filtering__advanced__email__true'
  | 'employees__filtering__advanced__email__false';
