import store from 'store2';

import { Session } from '@peakon/records';
import { getSnapshot } from '@peakon/shell/hooks/useTokenFromStorage';

type Action =
  | { type: 'SESSION_READ_FAILED' }
  | { type: 'SESSION_2FA_VERIFY_FAILED' }
  | { type: 'SESSION_TOKEN_READ_FAILED' }
  | { type: 'SESSION_RESTORED'; data: $TSFixMe }
  | { type: 'SESSION_READ_SUCCESS'; data: $TSFixMe }
  | { type: 'SESSION_SHARE_RESTORED'; data: $TSFixMe }
  | { type: 'SESSION_TOKEN_READ_LOADING' }
  | { type: 'SESSION_2FA_VERIFY_LOADING' }
  | { type: 'SESSION_READ_LOADING' };

const session = (state = new Session(), action: Action): Session => {
  switch (action.type) {
    case 'SESSION_READ_FAILED':
    case 'SESSION_2FA_VERIFY_FAILED':
    case 'SESSION_TOKEN_READ_FAILED':
      return state.merge({
        isAuthenticated: false,
        isLoading: false,
      });
    case 'SESSION_RESTORED':
    case 'SESSION_SHARE_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const { data } = action.data;

      const scopeGroup = data.attributes?.scopeGroup;
      const initialAuthToken = getSnapshot('initial_auth_token');
      if (scopeGroup !== 'proxyView' && initialAuthToken) {
        store.remove('initial_auth_token');
      }

      return Session.createFromApi(data || action.data);
    }
    case 'SESSION_TOKEN_READ_LOADING':
    case 'SESSION_2FA_VERIFY_LOADING':
    case 'SESSION_READ_LOADING': {
      return state.set('isLoading', true);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default session;
