/* eslint-disable */
// Source: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html

const makeCancelable = <Value>(promise: Promise<Value>) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<Value>((resolve, reject) =>
    promise.then(
      (val: Value) =>
        hasCanceled ? reject({ isCanceled: true }) : resolve(val),
      (error: Value) =>
        hasCanceled ? reject({ isCanceled: true }) : reject(error),
    ),
  );

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

export default makeCancelable;
