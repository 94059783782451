import React from 'react';

import { useController } from 'react-hook-form';
import { z } from 'zod';

import { Checkbox as BRCheckbox } from '@peakon/bedrock/react/form';

import { ControlledProps, ZodSchemaOrRecord } from '../../utils/types';
import { useRequiredFields } from '../RequiredFieldsProvider';

type CheckboxProps = React.ComponentPropsWithoutRef<typeof BRCheckbox>;

type Props<TSchema extends ZodSchemaOrRecord> = ControlledProps<
  TSchema,
  CheckboxProps
>;

export function Checkbox<TSchema extends ZodSchemaOrRecord = z.ZodRawShape>(
  props: Props<TSchema>,
) {
  const {
    name,
    shouldUnregister,
    disabled,
    required,
    hideLabel,
    feedbackMessage,
    description,
    ...restCheckboxProps
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    shouldUnregister,
    disabled,
  });

  const requiredFields = useRequiredFields();
  const isRequired = Boolean(required ?? requiredFields?.includes(name));

  const visibleLabelProps = {
    hideLabel: false,
    description,
    feedbackMessage: feedbackMessage ?? error?.message,
  };

  const hiddenLabelProps = {
    hideLabel: true,
  };

  return (
    <BRCheckbox
      checked={Boolean(field.value)}
      status={error && 'error'}
      disabled={disabled}
      required={isRequired}
      {...(hideLabel ? hiddenLabelProps : visibleLabelProps)}
      {...restCheckboxProps}
      {...field}
    />
  );
}
