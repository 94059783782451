// eslint-disable-next-line no-restricted-imports -- Automatically disabled here to enable the rule globally
import { ParsedQs } from 'qs';
import localStorage from 'store2';
import { v4 as uuidv4 } from 'uuid';

import { OAuthService } from '../containers/Integrations/OAuthComplete/responseCheckerFactory';

const getKey = (name: OAuthService) => `peakon.state.${name}`;

export const store = (name: OAuthService) => {
  const state = uuidv4();

  localStorage.set(getKey(name), state);
  return state;
};

export const validate = (name: OAuthService, expected: ParsedQs[string]) => {
  const stored = localStorage.get(getKey(name));
  localStorage.remove(getKey(name));

  let currentState = expected;
  if (typeof expected === 'string') {
    [currentState] = expected.split('#');
  }

  return stored && currentState && stored === currentState;
};
