type LoginFlow =
  | '2fa'
  | 'activation'
  | 'company'
  | 'confirmMailRatelimited'
  | 'confirmMailSent'
  | 'forgotpassword'
  | 'mailLink'
  | 'mailLinkInvalid'
  | 'password'
  | 'reason'
  | 'redirect'
  | 'enterSubdomain';

type LoginCompany = {
  companyId: string;
  name: string;
  logo: string | null;
  abbreviation: string;
};

type LoginFlowState = {
  account: string | null;
  accountId: string | null;
  checked: boolean;
  flow: LoginFlow;
  email: string;
  password: string | null;
  companies: LoginCompany[] | null;
  passwordUpdated: boolean;
  requiredSSO: boolean | null;
  ssoUrl?: string;
};

export const getInitialState = (): LoginFlowState => {
  return {
    account: null,
    accountId: null,
    checked: false,
    flow: 'password',
    email: '',
    password: null,
    companies: null,
    passwordUpdated: false,
    requiredSSO: null,
  };
};

const loginFlow = (
  state = getInitialState(),
  action: $TSFixMe,
): LoginFlowState => {
  switch (action.type) {
    case 'LOGIN_FLOW_SET_FLOW': {
      const { flow, email, password, accountId, account } = action.data;

      return {
        ...state,
        flow,
        email,
        password,
        accountId,
        account,
      };
    }
    case 'LOGIN_FLOW_CHECK_EMAIL_LOADING': {
      return {
        ...state,
        checked: false,
      };
    }
    case 'LOGIN_FLOW_CHECK_EMAIL_SUCCESS': {
      const { url, required } = action.data;

      return {
        ...state,
        ssoUrl: url,
        requiredSSO: required,
        checked: true,
      };
    }
    case 'LOGIN_FLOW_SSO': {
      const { url, required } = action.data;

      return {
        ...state,
        ssoUrl: url,
        requiredSSO: required,
      };
    }
    case 'LOGIN_FLOW_CHOOSE_COMPANY_SUCCESS': {
      const { companies } = action.data;

      return {
        ...state,
        companies,
      };
    }
    case 'LOGIN_FLOW_UPDATE_PASSWORD_SUCCESS': {
      return {
        ...state,
        passwordUpdated: true,
      };
    }
    case 'LOGIN_FLOW_STORE_CREDENTIALS': {
      const { email, password } = action.data;

      return {
        ...state,
        email,
        password,
      };
    }
    case 'LOGIN_FLOW_RESET': {
      return getInitialState();
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default loginFlow;
