import React, { Fragment } from 'react';

import { ScoresEmojiNoScoreIcon } from '@peakon/bedrock/icons/graphic';
import CommentRecord from '@peakon/records/CommentRecord';
import { ScoreNumber } from '@peakon/shared/components/ScoreNumber';

import { ScoreComponent } from '../ScoreComponent';

import styles from './styles.css';

type Props = {
  type?: 'text' | 'driver' | 'value';
  score?: CommentRecord['score'];
};

export const CommentScore = ({ type, score }: Props) => {
  if (type !== 'driver' && type !== 'value') {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={styles.scoreContainer} tabIndex={0} role="status">
      <div className={styles.scoreIcon}>
        {score === null || score === undefined ? (
          <ScoresEmojiNoScoreIcon />
        ) : (
          <Fragment>
            <div className={styles.scoreWrapper} data-score={score}>
              <div className={styles.score}>
                <ScoreNumber fontSize={21} score={score} />
              </div>
            </div>
            <ScoreComponent score={score} />
          </Fragment>
        )}
      </div>
    </div>
  );
};
