import { useEffect, useState } from 'react';

import { useCookieConsentService } from '@peakon/shell/components/CookieConsent/CookieConsentService';

import { init, identifyUser, cleanup } from './sprig';

export function useSprig({
  hasSprigAddOn,
  userId,
}: {
  hasSprigAddOn: boolean;
  userId?: string;
}) {
  const [isInitialized, setIsInitialized] = useState(false);

  const { hasGivenConsent } = useCookieConsentService();

  const hasConsent = hasGivenConsent('analytics');

  // identify user
  useEffect(() => {
    if (isInitialized && hasSprigAddOn && hasConsent && userId) {
      identifyUser(userId);
    }
  }, [isInitialized, hasSprigAddOn, hasConsent, userId]);

  // init and cleanup
  useEffect(() => {
    if (hasSprigAddOn && hasConsent && !isInitialized) {
      // eslint-disable-next-line no-void
      void init().then(() => {
        setIsInitialized(true);
      });
    }

    if (hasSprigAddOn && !hasConsent && isInitialized) {
      cleanup();
      setIsInitialized(false);
    }
  }, [isInitialized, hasConsent, hasSprigAddOn]);
}
