import React from 'react';

import classnames from 'classnames';
import moment from 'moment';

import { AccessVisibilityOnIcon as EyeIcon } from '@peakon/bedrock/icons/system';
import { Ellipsis } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

const getLastSeenLabel = ({ date }: $TSFixMe) => {
  if (!date) {
    return '';
  }

  const utcDate = moment.utc(date);

  return t('roles__people__last_seen', {
    replace: {
      since: utcDate.fromNow(),
    },
  });
};

type OwnLastSeenAtProps = {
  date?: string;
  size?: 'small' | 'medium';
  withIcon?: boolean;
};

type LastSeenAtProps = OwnLastSeenAtProps;

const LastSeenAt = ({
  date,
  size = 'medium',
  withIcon = false,
}: LastSeenAtProps) => {
  // Prevent moment.utc(undefined) which would return today's date
  if (!date) {
    return null;
  }
  const utcDate = moment.utc(date);

  const text = (
    <span
      className={classnames(styles.lastSeenAt, {
        [styles[size]]: size,
      })}
      title={utcDate.format('YYYY-MM-DD HH:mm:ss')}
    >
      {t('roles__people__last_seen', { replace: { since: utcDate.fromNow() } })}
    </span>
  );

  if (withIcon) {
    return (
      <div className={styles.wrapper}>
        <EyeIcon />
        <Ellipsis title={getLastSeenLabel({ date })}>{text}</Ellipsis>
      </div>
    );
  }

  return text;
};

// eslint-disable-next-line import/no-default-export
export default LastSeenAt;
