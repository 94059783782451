import { Map } from 'immutable';

import { Category } from '@peakon/records';

const categories = (
  state = Map<string, Category>(),
  action: $TSFixMe,
): Map<string, Category> => {
  switch (action.type) {
    case 'CATEGORY_LIST_LOADING': {
      if (action.data && action.data.flush) {
        return Map<string, Category>();
      }

      return state;
    }

    case 'CATEGORY_LIST_SUCCESS': {
      const { data } = action.data;

      return state.merge(
        // @ts-expect-error TS(7006): Parameter 'category' implicitly has an 'any' type.
        data.map((category) => [category.id, Category.createFromApi(category)]),
      );
    }

    case 'CATEGORY_FETCH_QUESTIONS_SUCCESS':
    case 'ENGAGEMENT_FETCH_QUESTIONS_SUCCESS': {
      const allCategories = action.data.map(
        (data: $TSFixMe) => data.relationships.category,
      );

      // only add missing ones
      // avoid merging as current categories might have extra fields
      const addedCategories = allCategories.filter(
        (category: $TSFixMe) => !state.has(category.id),
      );

      return state.merge(
        // @ts-expect-error TS(7006): Parameter 'category' implicitly has an 'any' type.
        addedCategories.map((category) => [
          category.id,
          Category.createFromApi(category),
        ]),
      );
    }

    // When fetched through relationships
    case 'CATEGORY_OVERVIEW_READ_SUCCESS':
    case 'GROUP_CATEGORIES_SCORES_SUCCESS':
    case 'GROUP_QUESTIONS_LIST_SUCCESS':
    case 'COMMENT_LIST_SUCCESS':
    case 'COMMENT_GET_SUCCESS':
    case 'ENGAGEMENT/QUESTIONS_LIST_SUCCESS': {
      const { included = [] } = action.data;
      const allCategories = included.filter(
        (entity: $TSFixMe) => entity.type === 'categories',
      );

      const newCategories = allCategories.filter(
        (category: $TSFixMe) => !state.has(category.id),
      );

      return state
        .asMutable()
        .merge(
          // @ts-expect-error TS(7006): Parameter 'category' implicitly has an 'any' type.
          newCategories.map((category) => [
            category.id,
            Category.createFromApi(category),
          ]),
        )
        .asImmutable();
    }

    case 'CATEGORY_GET_SUCCESS': {
      const { data } = action.data;

      const category = Category.createFromApi(data);

      if (category.hasParentCategory()) {
        return (
          state
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            .set(category.id, category)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .set(category.parentCategoryId, category.parentCategory!)
        );
      }

      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return state.set(category.id, category);
    }

    case 'CATEGORY_CREATE_SUCCESS': {
      const { data } = action.data;

      return state.set(data.id, Category.createFromApi(data));
    }

    case 'CATEGORY_UPDATE_SUCCESS': {
      const { data } = action.data;

      return state.update(data.id, () => Category.createFromApi(data));
    }

    case 'CATEGORY_DELETE_SUCCESS': {
      const { id } = action.data;

      return state.delete(id);
    }

    case 'GROUP_CATEGORIES_SCORES_RESET':
    case 'CATEGORY_FLUSH': {
      return Map<string, Category>();
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default categories;
