import React, { createContext } from 'react';

import Media from 'react-media';

import { MOBILE_MAX_WIDTH } from '@peakon/shared/constants/breakpoints';

const DEFAULT_CONTEXT = {
  isMobile: false,
};

export const MediaContext = createContext(DEFAULT_CONTEXT);

export const MediaQueryListener = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Media query={{ maxWidth: MOBILE_MAX_WIDTH }}>
    {(matches) => (
      <MediaContext.Provider value={{ isMobile: matches }}>
        {children}
      </MediaContext.Provider>
    )}
  </Media>
);
