import { Record } from 'immutable';
import { z } from 'zod';

import { ACKNOWLEDGEMENT_TYPES } from '@peakon/shared/constants/acknowledgementTypes';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

export type AcknowledgementRaw = {
  attributes: {
    type: (typeof ACKNOWLEDGEMENT_TYPES)[number];
    kind: 'message' | 'direct';
  };
  id: string;
  relationships: {
    employee: {
      type: 'employees';
      id: string;
    };
  };
  type: 'answer_acknowledgements';
};

export const schema = z.object({
  id: z.string(),
  kind: z.enum(['message', 'direct']),
  type: z.enum(ACKNOWLEDGEMENT_TYPES),
});

type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class Acknowledgement extends Record<Schema>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  id: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  kind: undefined!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  type: undefined!,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'Acknowledgement',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: AcknowledgementRaw) {
    const {
      id,
      attributes: { kind, type },
    } = data;

    return new Acknowledgement({
      id,
      kind,
      type,
    });
  }
}
