import React, { useCallback } from 'react';

import { IntersectionOptions, useInView } from 'react-intersection-observer';

type OffsetStrategy = 'relative-positioning' | 'native-root-margin';

type Props = React.ComponentPropsWithoutRef<'div'> & {
  /** Vertical offset for the callback trigger.
   * Providing a negative value will trigger the callbacks earlier in the
   * scrollable view, and a positive value will trigger it later. */
  offset?: string | number;

  /** Horizontal offset for the callback trigger.
   * Providing a negative value will trigger the callbacks earlier in the
   * scrollable view, and a positive value will trigger it later. */
  offsetHorizontal?: string | number;

  /** Callback for when the marker is visible. */
  onVisible?: () => void;

  /** Callback for when the marker is hidden. */
  onHide?: () => void;

  /** Callback for when the marker visibility changes. */
  onChange?: (inView: boolean) => void;

  /** Whether or not to rely on `IntersectionObserver`'s `rootMargin` or
   * relative (CSS) positioning for the offsets. `native-root-margin` will be
   * unaffected by external styling, but it usually requires the correct root
   * element to be provided if there are scrollable elements higher
   * up the DOM tree. */
  offsetStrategy?: OffsetStrategy;

  /** Scrollable element root override. */
  root?: IntersectionOptions['root'];

  /** Whether or not to inline the marker. */
  inline?: boolean;

  /** Additional options passed down to the `IntersectionObserver`. */
  intersectionOptions?: IntersectionOptions;
};

const styles = {
  position: 'relative',
  zIndex: 10000,
} as const;

/**
 * Component for triggering callbacks when it shows/hides in a scrollable view.
 * Useful for things like infinite scrolling or lazy loading.
 */
export function IntersectionMarker({
  offset = 0,
  offsetHorizontal = 0,
  onChange,
  onVisible,
  onHide,
  offsetStrategy = 'relative-positioning',
  root,
  inline = false,
  intersectionOptions = {},
  style,
  ...rest
}: Props) {
  const isUsingRootMargin = offsetStrategy === 'native-root-margin';
  const rootMarginBottom = getRootMarginOffset(offset);
  const rootMarginRight = getRootMarginOffset(offsetHorizontal);
  const rootMargin = isUsingRootMargin
    ? `0px ${rootMarginRight} ${rootMarginBottom} 0px`
    : '0px 0px 0px 0px';

  const handleChange = useCallback(
    (inView: boolean) => {
      onChange?.(inView);

      if (inView) {
        onVisible?.();
      } else {
        onHide?.();
      }
    },
    [onChange, onVisible, onHide],
  );

  const { ref } = useInView({
    root,
    rootMargin,
    ...intersectionOptions,
    onChange: handleChange,
  });

  const offsetStyling = {
    top: offset,
    left: offsetHorizontal,
  };

  return (
    <div
      {...rest}
      ref={ref}
      style={{
        ...styles,
        ...(!isUsingRootMargin && offsetStyling),
        display: inline ? 'inline-block' : 'block',
        ...style,
      }}
    />
  );
}

/* Utility function for converting `offset` to a value that 
`rootMargin` supports */
const getRootMarginOffset = (offset: string | number) => {
  // Invert offset and convert to px (if of type number),
  // since rootMargin doesn't support unitless numbers
  if (typeof offset === 'number') {
    return `${-offset}px`;
  }

  return offset.startsWith('-') ? offset.replace('-', '') : `-${offset}`;
};
