import React from 'react';

import classnames from 'classnames';

import {
  ScoresFlagsStrengthDiscIcon as StrengthOutlinedIcon,
  ScoresFlagsWarningDiscIcon as WarningOutlinedIcon,
  ScoresFlagsPriorityMcpDiscIcon as ManualOutlinedIcon,
  ScoresFlagsStrengthIcon as StrengthIcon,
  ScoresFlagsStrengthTinyIcon as StrengthTinyIcon,
  ScoresFlagsWarningIcon as WarningIcon,
  ScoresFlagsWarningTinyIcon as WarningTinyIcon,
  ScoresFlagsPriorityMcpIcon as PriorityIcon,
  ScoresFlagsPriorityTinyIcon as PriorityTinyIcon,
  McpRejectedTinyIcon as RejectedIcon,
} from '@peakon/bedrock/icons/graphic';
import { t } from '@peakon/shared/features/i18next/t';

import Tooltip from '../Tooltip';

function getClassificationIcon(
  classification: $TSFixMe,
  outline: $TSFixMe,
  size: $TSFixMe,
) {
  switch (classification) {
    case 'priority':
    case 'suggested': {
      if (size === 'xs' || size === 'sm') {
        return WarningTinyIcon;
      }

      return outline ? WarningOutlinedIcon : WarningIcon;
    }
    case 'strength': {
      if (size === 'xs' || size === 'sm') {
        return StrengthTinyIcon;
      }

      return outline ? StrengthOutlinedIcon : StrengthIcon;
    }
    case 'manual':
    case 'accepted':
      if (size === 'xs' || size === 'sm') {
        return PriorityTinyIcon;
      }

      return outline ? ManualOutlinedIcon : PriorityIcon;
    case 'rejected':
      return RejectedIcon;
    default:
      return null;
  }
}

function getClassificationTooltip(classification: $TSFixMe) {
  switch (classification) {
    case 'priority': {
      return t('dashboard__critical__negative__tooltip');
    }
    case 'strength': {
      return t('dashboard__critical__positive__tooltip');
    }
    default:
      return null;
  }
}

type Props = {
  classification?:
    | 'strength'
    | 'manual'
    | 'priority'
    | 'suggested'
    | 'accepted'
    | 'rejected';
  className?: string;
  outline?: boolean;
  tooltipClassName?: string;
  withTooltip?: boolean;
  size?: 'xs' | 'md' | 'sm';
};

const ClassificationIcon = ({
  classification,
  className,
  size = 'md',
  tooltipClassName,
  withTooltip,
  outline = true,
  ...props
}: Props) => {
  const Icon = getClassificationIcon(classification, outline, size);
  const title = getClassificationTooltip(classification);

  if (!Icon) {
    return null;
  }

  const inner = (
    <Icon
      className={classnames('peak-score-icon', className, {
        'is-positive': classification === 'strength',
        'is-negative': classification === 'priority',
      })}
      {...props}
    />
  );

  if (withTooltip && title) {
    return (
      <Tooltip className={tooltipClassName} title={title}>
        {inner}
      </Tooltip>
    );
  }

  return inner;
};

// eslint-disable-next-line import/no-default-export
export default ClassificationIcon;
