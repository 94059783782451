import { Record, fromJS, Map, List } from 'immutable';
import get from 'lodash/get';
import { z } from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { categorySchema } from '@peakon/shared/schemas/api/categories';
import { validateData } from '@peakon/shared/utils/validateData/validateData';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ActionItemRecord from '../ActionItemRecord';
import CategoryRecord from '../CategoryRecord';
import {
  CORE_QUESTION_SETS,
  MOMENTS_THAT_MATTER_QUESTION_SETS,
} from '../constants/questionSets';
import { validateTestingSchema } from '../utils';

const REQUIRED_FIELDS = [
  'categoryGroup',
  'category',
  'status',
  'text',
  'notes',
];

const DURATIONS = ['2_weeks', '1_month', '2_months', '3_months'] as const;

export type Duration = (typeof DURATIONS)[number];

const schema = z.object({
  id: z.string().optional(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

const coreQuestionSetAndStandardSchema = z.object({
  categoryGroup: z.enum(CORE_QUESTION_SETS),
  driver: z.string().nullable(),
  subdriver: z.string().nullable(),
});

const otherQuestionSetAndStandardSchema = z.object({
  categoryGroup: z.enum([
    ...MOMENTS_THAT_MATTER_QUESTION_SETS,
    'other',
    'covid19',
  ]),
  driver: z.null(),
  subdriver: z.null(),
});

const valuesAndStandardSchema = z.object({
  categoryGroup: z.literal('values'),
  driver: z.null(),
  subdriver: z.null(),
});

const customActionSchema = z.object({
  id: z.string(),
  type: z.literal('company_actions'),
  attributes: z.intersection(
    z.object({
      status: z.enum(['active', 'inactive']),
      text: z.string(),
      textTranslated: z.string(),
      textTranslations: z.record(translatedStringSchema).nullable(),
      notes: z.string(),
      notesTranslated: z.string(),
      notesTranslations: z.record(translatedStringSchema).nullable(),
      duration: z.enum(DURATIONS).nullable(),
    }),
    z.discriminatedUnion('categoryGroup', [
      coreQuestionSetAndStandardSchema,
      otherQuestionSetAndStandardSchema,
      valuesAndStandardSchema,
    ]),
  ),
  relationships: z.object({
    category: categorySchema,
  }),
});

// eslint-disable-next-line import/no-default-export
export default class CustomActionRecord
  extends Record({
    id: undefined,
    status: 'active',
    text: undefined,
    notes: undefined,
    duration: undefined,
    dueAt: undefined,
    textTranslations: Map(),
    notesTranslations: Map(),

    items: undefined,

    category: undefined,
    categoryGroup: undefined,
    driver: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  notesTranslations!: Map<string, string>;
  notes?: string;

  status!: 'active' | 'inactive';

  text?: string;
  category?: CategoryRecord;

  textTranslations!: Map<string, string>;
  duration?: Duration;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CustomActionRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CustomActionRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  get isPublished() {
    return this.status === 'active';
  }

  get isValid() {
    return REQUIRED_FIELDS.every((property) => Boolean(this.get(property)));
  }

  get title() {
    return this.text;
  }

  static get translationProperties() {
    return ['textTranslations', 'notesTranslations'] as const;
  }

  getTranslationKeys() {
    return this.textTranslations.map((_discard, locale) => locale);
  }

  get validateTranslations() {
    return (
      this.textTranslations.every((entry) => Boolean(entry?.length)) &&
      this.notesTranslations.every((entry) => Boolean(entry?.length))
    );
  }

  static createFromApi(data: unknown) {
    const parsedData = validateData(data, customActionSchema, {
      errorMessagePrefix: 'CustomActionRecord.createFromApi',
    });
    const {
      id,
      attributes: { textTranslations, notesTranslations, ...attributes },
    } = parsedData;

    const items = get(parsedData, 'relationships.items');
    const driver = get(parsedData, 'attributes.driver');
    const categoryData = get(parsedData, 'relationships.category');
    const category = categoryData
      ? CategoryRecord.createFromApi(categoryData)
      : undefined;

    return new CustomActionRecord(
      fromJS({
        id,
        ...attributes,
        textTranslations: Map(textTranslations || {}),
        notesTranslations: Map(notesTranslations || {}),
        items: items
          ? // @ts-expect-error items is undefined on Admin > Improve > Actions
            List(items.map(ActionItemRecord.createFromApi))
          : undefined,
        category,
        driver,
      }),
    );
  }
}
