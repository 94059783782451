import animateScrollTo from 'animated-scroll-to';

import { ProductTourStep, ProductTourTooltip } from './renderer/types';

const getHighlightDimensions = (target: Element, step: ProductTourTooltip) => {
  const { padding = 0 } = step.highlightStyle ?? {};

  const { left, right, top } = target.getBoundingClientRect();

  const isRtl = window.document.dir === 'rtl';
  const insetInlineStart = isRtl ? window.innerWidth - right : left;

  return {
    ...step.highlightStyle,
    padding,
    height: target.clientHeight + padding,
    width: target.clientWidth + padding,
    insetBlockStart: top - padding / 2,
    insetInlineStart: insetInlineStart - padding / 2,
  };
};

const getTooltipTarget = (
  step: Pick<ProductTourTooltip, 'parentElement' | 'selector'>,
) => {
  const root =
    window.document.querySelector('.appContent') || window.document.body;
  const container = step.parentElement || root;

  const selectorArray = [step.selector].flat();

  for (const selector of selectorArray) {
    const target = container.querySelector(selector);

    if (target) {
      return target;
    }
  }

  return null;
};

export const getTooltipHighlightDimensions = (step: ProductTourTooltip) => {
  const target = getTooltipTarget(step);

  if (!target) {
    return;
  }

  return getHighlightDimensions(target, step);
};

export const scrollToTarget = async (step: ProductTourTooltip) => {
  const root =
    window.document.querySelector('.appContent') || window.document.body;
  const container = step.parentElement || root;
  const target = getTooltipTarget(step);

  if (!target) {
    return false;
  }

  return await animateScrollTo(target, {
    elementToScroll: container,
    cancelOnUserAction: false,
    maxDuration: 300,
    speed: 200,
    verticalOffset: -60 - (step.verticalOffset ?? 0),
  });
};

export const waitForElement = (selector: string) => {
  return new Promise((resolve) => {
    if (window.document.querySelector(selector)) {
      return resolve(window.document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (window.document.querySelector(selector)) {
        resolve(window.document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(window.document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const shouldRenderStep = async (
  step: ProductTourStep,
  dashboardContext: $TSFixMe,
) => {
  if (step.contextRights && !dashboardContext.hasRight(step.contextRights)) {
    return false;
  }

  if (!step.shouldRenderStep) {
    return true;
  }

  return await step.shouldRenderStep(dashboardContext);
};
