import { Branding } from '@peakon/records';
import type { BrandingResponse } from '@peakon/shared/schemas/api/brandings';

type BrandingFields =
  | 'contextBranded'
  | 'engageName'
  | 'engageNameTranslations'
  | 'engageLogo'
  | 'rights'
  | 'context'
  | 'excludedSegments'
  | 'includedSegments';

export type BrandingFieldObject = {
  [P in BrandingFields]: {
    field: P;
    value: Branding[P];
  };
}[BrandingFields];

type Action =
  | {
      type: 'BRANDING_GET_SUCCESS' | 'BRANDING_UPDATE_SUCCESS';
      data: BrandingResponse;
    }
  | {
      type: 'BRANDING_UPDATE_FIELD';
      data: BrandingFieldObject;
    };

const form = (
  state: Branding | null = null,
  action: Action,
): Branding | null => {
  switch (action.type) {
    case 'BRANDING_GET_SUCCESS': {
      return Branding.createFromApi(action.data);
    }

    case 'BRANDING_UPDATE_FIELD': {
      const { field, value } = action.data;

      if (!state) {
        return state;
      }

      return state.set(field, value);
    }

    case 'BRANDING_UPDATE_SUCCESS': {
      if (!state) {
        return state;
      }

      return state.merge(Branding.createFromApi(action.data));
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default form;
