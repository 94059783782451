import { List } from 'immutable';
import get from 'lodash/get';

import Column from '@peakon/records/heatmap/ColumnRecord';

import { getMultipleQuestionScores } from './utils';

const defaultColumns = List([
  Column.createAggregatedParticipation(),
  Column.createLatestParticipation(),
]);

const createAttritionColumns = () => {
  return List(
    [
      'classification',
      'employmentPhaseTotals.on_boarding',
      'employmentPhaseTotals.development',
      'employmentPhaseTotals.retention',
      'separationTotals.resigned',
    ].map((id) => {
      return new Column({
        id,
        type: 'attrition',
      });
    }),
  );
};

const filterColumns = (
  state: $TSFixMe,
  { filter, value, columnId }: $TSFixMe,
) => {
  switch (filter) {
    case 'mode': {
      if (value === 'diffToCustomRound') {
        return (
          state
            .asMutable()
            // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
            .map((column) => {
              if (column.type === 'participation') {
                return column.hide();
              }

              return column;
            })
            .asImmutable()
        );
      }

      return state;
    }
    case 'participation': {
      return (
        state
          .asMutable()
          // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
          .map((column) => {
            if (column.type === 'participation') {
              return column.toggleHidden();
            }

            return column;
          })
          .asImmutable()
      );
    }
    case 'expanded': {
      return (
        state
          .asMutable()
          // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
          .map((column) => {
            if (
              (columnId &&
                (columnId === column.parentColumnId ||
                  columnId === column.id)) ||
              (!columnId && column.type !== 'participation')
            ) {
              return column.toggleExpand();
            }

            return column;
          })
          .asImmutable()
      );
    }
    default:
      return state;
  }
};

const columnsState = (state = defaultColumns, action: $TSFixMe) => {
  switch (action.type) {
    case 'HEATMAP_CLOSE': {
      return defaultColumns;
    }

    case 'HEATMAP_REVIVE': {
      const { columns, filters } = action.data;

      const queueForExpand: $TSFixMe = [];

      state.forEach((column) => {
        if (
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          columns.includes(column.id) &&
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          column.isCollapsible() &&
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          !queueForExpand.includes(column.parentColumnId)
        ) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          queueForExpand.push(column.parentColumnId);
        }
      });

      return state
        .asMutable()
        .map((column) => {
          if (columns.includes(column.id)) {
            const shouldExpand =
              queueForExpand.includes(column.id) ||
              queueForExpand.includes(column.parentColumnId);

            // double check to avoid ending in an inconsistent state
            if (filters.participation) {
              return column.show({ expanded: shouldExpand });
            }

            if (shouldExpand) {
              return column.expand();
            }
          }

          return column;
        })
        .asImmutable();
    }

    case 'HEATMAP_ATTRITION': {
      const attritionColumns = createAttritionColumns();

      return attritionColumns
        .concat(defaultColumns)
        .map((column) => column.expand());
    }

    case 'HEATMAP_CLOSE_ATTRITION':
    case 'HEATMAP_COLUMNS_RESET': {
      return defaultColumns;
    }

    case 'HEATMAP_ATTRITION_COLUMN_LIST_SUCCESS': {
      const { data } = action.data;

      const filteredColumns = data
        // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
        .filter((column) => {
          return ['engagement', 'engagement__loyalty', 'growth'].includes(
            column.attributes.standard,
          );
        })
        .map(Column.createFromApi)
        .sort(Column.comparator);

      return state.concat(filteredColumns);
    }

    case 'HEATMAP_COLUMN_LIST_SUCCESS': {
      const { data, questionScores, driverMode } = action.data;

      const participationColumns = state.filter(
        (column) => column.type === 'participation',
      );

      const multipleQuestionScores = questionScores.data
        .filter(
          (questionScore: $TSFixMe) =>
            // @ts-expect-error TS(7006): Parameter 'qs' implicitly has an 'any' type.
            questionScores.data.filter((qs) =>
              getMultipleQuestionScores(
                qs,
                questionScore.relationships.question.relationships.category.id,
                { driverMode },
              ),
            ).length > 1,
        )
        // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
        .sort((a, b, order = -1) => {
          if (
            get(
              a,
              'relationships.question.relationships.category.attributes.group',
            ) === 'other'
          ) {
            return (
              a.relationships.question.attributes.text.localeCompare(
                b.relationships.question.attributes.text,
              ) * order
            );
          }

          return 1;
        });

      // columns have children when they are a parent of another one
      const hasChildren = [
        // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
        ...data.reduce((acc, curr) => {
          const parentId = get(curr, 'relationships.parentCategory.id');

          if (parentId) {
            acc.push(parentId);
          }

          return acc;
        }, []),
        ...multipleQuestionScores.map(
          (questionScore: $TSFixMe) =>
            questionScore.relationships.question.relationships.category.id,
        ),
      ];

      return participationColumns.concat(
        [
          // @ts-expect-error TS(7006): Parameter 'category' implicitly has an 'any' type.
          ...data.map((category) =>
            Column.createFromApi(category, hasChildren.includes(category.id)),
          ),
          ...multipleQuestionScores
            // @ts-expect-error TS(7006): Parameter 'questionScore' implicitly has an 'any' ... Remove this comment to see the full error message
            .map((questionScore) =>
              Column.createFromQuestionScore(questionScore),
            )
            // @ts-expect-error TS(7006): Parameter 'column' implicitly has an 'any' type.
            .sort((column) => {
              if (column.isSubcategory) {
                return column.standard ? -1 : 1;
              }

              return column.standard ? 1 : -1;
            }),
        ].sort(Column.comparator),
      );
    }

    case 'HEATMAP_COLUMNS_EXPAND': {
      return state
        .asMutable()
        .map((column) => {
          if (column.isParticipation()) {
            return column;
          }

          return column.expand();
        })
        .asImmutable();
    }

    case 'HEATMAP_COLUMNS_COLLAPSE': {
      return state
        .asMutable()
        .map((column) => {
          if (column.isParticipation()) {
            return column;
          }

          return column.collapse();
        })
        .asImmutable();
    }

    case 'HEATMAP_FILTERS_UPDATED': {
      return filterColumns(state, action.data);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default columnsState;
