import React from 'react';

import classnames from 'classnames';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  active?: boolean;
  className?: string;
  withLabel?: boolean;
  size?: 'medium' | 'small';
  id?: string;
};

export const StatusIndicator = ({
  active,
  className,
  size = 'medium',
  withLabel = true,
  id,
}: Props) => {
  const label = active ? t('attributes__active') : t('attributes__inactive');

  return (
    <span
      className={classnames(styles.root, className, {
        [styles.active]: active,
        [styles[size]]: size,
      })}
      id={id}
    >
      <span className={styles.indicator} />
      {withLabel ? label : <VisuallyHidden>{label}</VisuallyHidden>}
    </span>
  );
};
