import { sessionDataSchema } from '@peakon/shared/schemas/api/sessions';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { Session } from '../types/Session';

const SESSION_PARAMS = {
  include:
    'company,company.companySubdomains,account,employee,employee.account',
  fields: {
    accounts: '+,settings',
    companies: [
      'abbreviation',
      'accessAllowed',
      'addOns',
      'benchmarkId',
      'benchmarkSelector',
      'benchmarkType',
      'code',
      'createdAt',
      'employeeFeatures',
      'features',
      'location',
      'logo',
      'name',
      'sector',
      'settings',
      'size',
      'timezone',
      'testCompany',
      'companySubdomains',
    ].join(','),
  },
};

export async function authWithToken({
  remember = true,
} = {}): Promise<Session | null> {
  const token = api.auth.restoreToken();

  if (token) {
    // this method only sets the token variable on api.auth.
    // `remember` just means if it also be persisted on localStorage
    api.auth.authenticate(token, { remember });

    const response = await api.root.session(SESSION_PARAMS);

    validateData(response.data, sessionDataSchema, {
      errorMessagePrefix: 'sessionDataSchema',
    });

    return response;
  } else {
    return null;
  }
}
