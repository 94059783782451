import { Set, Record } from 'immutable';

const Selection = Record({
  total: 0,
  type: 'RANGE', // ALL || RANGE || RANGE_ALL
  range: Set(),
});

const selectedEmployees = (state = new Selection(), action: $TSFixMe) => {
  switch (action.type) {
    case 'FILTER_SELECT_ALL': {
      const { total } = action.data;

      return state.merge({
        type: 'ALL',
        total,
        range: Set(),
      });
    }
    case 'FILTER_SELECT_ALL_LOADED': {
      const { ids, total } = action.data;

      return new Selection({ type: 'RANGE_ALL', range: Set(ids), total });
    }
    case 'EMPLOYEE_LOAD_MORE_SUCCESS': {
      const { data: employees } = action.data;

      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'Map<string... Remove this comment to see the full error message
      return state.type === 'RANGE_ALL'
        ? state.merge({
            // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
            range: state.range.concat(Set(employees.map((e) => e.id))),
            // @ts-expect-error TS(2339): Property 'total' does not exist on type 'Map<strin... Remove this comment to see the full error message
            total: state.total + employees.length,
          })
        : state;
    }
    case 'EMPLOYEE_BULK_DELETE_SUCCESS':
    case 'EMPLOYEE_FILTER_SUCCESS':
    case 'FILTER_DESELECT_ALL': {
      return new Selection();
    }
    case 'FILTER_SELECT_EMPLOYEE': {
      const { id } = action.data;

      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'Map<string... Remove this comment to see the full error message
      if (state.type === 'ALL') {
        // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
        const excludedEmployees = state.range.remove(id);
        // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
        const wasRemoved = state.range.size !== excludedEmployees.size;

        return state.merge({
          range: excludedEmployees,
          // @ts-expect-error TS(2339): Property 'total' does not exist on type 'Map<strin... Remove this comment to see the full error message
          total: wasRemoved ? state.total + 1 : state.total,
        });
      }

      return state.merge({
        type: 'RANGE',
        // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
        range: state.range.add(id),
        // @ts-expect-error TS(2339): Property 'total' does not exist on type 'Map<strin... Remove this comment to see the full error message
        total: state.total + 1,
      });
    }
    case 'FILTER_DESELECT_EMPLOYEE': {
      const { id } = action.data;

      // @ts-expect-error TS(2339): Property 'type' does not exist on type 'Map<string... Remove this comment to see the full error message
      if (state.type === 'ALL') {
        // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
        const excludedEmployees = state.range.add(id);

        return state.merge({
          range: excludedEmployees,
          // @ts-expect-error TS(2339): Property 'total' does not exist on type 'Map<strin... Remove this comment to see the full error message
          total: state.total - 1,
        });
      }

      return state.merge({
        type: 'RANGE',
        // @ts-expect-error TS(2339): Property 'range' does not exist on type 'Map<strin... Remove this comment to see the full error message
        range: state.range.remove(id),
        // @ts-expect-error TS(2339): Property 'total' does not exist on type 'Map<strin... Remove this comment to see the full error message
        total: state.total - 1,
      });
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default selectedEmployees;
