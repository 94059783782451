import React, { ElementType } from 'react';

import classnames from 'classnames';

import { NavigationBasicNavigationChevronDownIcon as ChevronDown } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';

import Link from '../../components/Link';

import styles from './styles.css';

type ListProps = {
  children?: React.ReactNode;
  className?: string;
};

export function List({ children, className, ...props }: ListProps) {
  return (
    <ul
      className={classnames(styles.list, {
        ...(className && { [className]: className }),
      })}
      {...props}
    >
      {children}
    </ul>
  );
}

type AccordionListProps = {
  children?: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
  id?: string;
};

export function AccordionList({
  children,
  isExpanded,
  className,
  ...props
}: AccordionListProps) {
  return (
    <List
      className={classnames(styles.accordion, {
        [styles.isExpanded]: isExpanded,
        ...(className && { [className]: className }),
      })}
      aria-hidden={!isExpanded}
      {...props}
    >
      {children}
    </List>
  );
}

type AccordionListTitleProps = {
  as?: ElementType;
  className?: string;
  children?: React.ReactNode;
  isExpanded?: boolean;
  onClick?: () => void;
  'aria-controls'?: string;
};

function AccordionListTitle({
  as = 'h3',
  children,
  isExpanded,
  onClick,
  ...props
}: AccordionListTitleProps) {
  return (
    <Title as={as} withAccordion>
      <UnstyledButton
        className={styles.titleButton}
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        accessibleName={children as string}
        onClick={onClick}
        aria-expanded={isExpanded ? 'true' : 'false'}
        {...props}
      >
        <span className={styles.titleLabel}>{children}</span>
        <ChevronDown className={styles.titleChevron} aria-hidden />
      </UnstyledButton>
    </Title>
  );
}

AccordionList.Title = AccordionListTitle;

type ItemProps = {
  children?: React.ReactNode;
  className?: string;
};

export function Item({ children, className, ...props }: ItemProps) {
  return (
    <li
      className={classnames(styles.item, {
        ...(className && { [className]: className }),
      })}
      {...props}
    >
      {children}
    </li>
  );
}

type TitleProps = {
  as?: ElementType;
  children?: React.ReactNode;
  withAccordion?: boolean;
};

export function Title({
  as: Component = 'h3',
  children,
  withAccordion = false,
  ...props
}: TitleProps) {
  return (
    <Component
      className={classnames(styles.title, {
        // eslint-disable-next-line css-modules/no-undef-class -- (Classes in :not() are currently excluded (https://github.com/atfzl/eslint-plugin-css-modules/pull/81))
        [styles.withAccordion]: withAccordion,
      })}
      {...props}
    >
      {children}
    </Component>
  );
}

type ItemLinkProps = {
  to?: string;
  children?: React.ReactNode;
  className?: string;
  isActive?: boolean;
  href?: string;
  onClick?: () => void;
  linkType: 'react' | 'a';
};

export function ItemLink({
  children,
  className,
  isActive,
  linkType,
  ...props
}: ItemLinkProps) {
  return (
    <Link
      className={classnames(styles.itemLink, {
        [styles.isActive]: isActive,
        ...(className && { [className]: className }),
      })}
      type={linkType}
      {...props}
    >
      {children}
    </Link>
  );
}

type SpacerProps = {
  fill?: boolean;
};

export function Spacer({ fill }: SpacerProps) {
  return (
    <div
      className={classnames(styles.spacer, { [styles.fill]: fill })}
      aria-hidden
    />
  );
}

type BadgeProps = {
  children?: React.ReactNode;
};

export function Badge({ children }: BadgeProps) {
  return <div className={styles.badge}>{children}</div>;
}

type MenuProps = {
  isCollapsible?: boolean;
};

function Menu({
  children,
  className,
  isCollapsible,
  ...props
}: React.HTMLProps<HTMLDivElement> & MenuProps) {
  return (
    <div
      className={classnames(styles.menu, {
        ...(className && { [className]: className }),
        [styles.isCollapsible]: isCollapsible,
      })}
      {...props}
    >
      {children}
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default Menu;
