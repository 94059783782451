import { useQuery } from '@tanstack/react-query';

import { NewSummariesSchema } from './schema';
import api from '../../utils/api';
import { validateData } from '../../utils/validateData/validateData';

type GetUpdatedSummariesArgs = {
  contextId?: string;
  enabled?: boolean;
};

async function getUpdatedSummaries({ contextId }: GetUpdatedSummariesArgs) {
  const response = await api.get(`/summaries/nps/contexts/${contextId}/new`);

  return validateData(response, NewSummariesSchema, {
    errorMessagePrefix: 'NewSummariesSchema',
  });
}

export const useCheckUpdatedSummaries = ({
  contextId = '',
  enabled,
}: GetUpdatedSummariesArgs) => {
  return useQuery({
    queryKey: checkUpdatedSummariesKeys.context(contextId),
    queryFn: () => getUpdatedSummaries({ contextId }),
    enabled: Boolean(contextId) && enabled,
    select: (data) => data?.hasNewSummaries,
  });
};

export const checkUpdatedSummariesKeys = {
  all: ['new_nps_summaries'] as const,
  context: (contextId: string) =>
    [...checkUpdatedSummariesKeys.all, contextId] as const,
} as const;
