import React from 'react';

import { FormProviderProps, FormProvider } from 'react-hook-form';
import z from 'zod';

import { RequiredFieldsProvider } from './RequiredFieldsProvider';

export type FormProps<TSchema extends z.Schema> = FormProviderProps<
  z.infer<TSchema>
> & {
  schema: TSchema;
  children: React.ReactNode;
};

export function ZodFormProvider<TSchema extends z.Schema>({
  children,
  schema,
  ...methods
}: FormProps<TSchema>) {
  return (
    <RequiredFieldsProvider schema={schema}>
      <FormProvider {...methods}>{children}</FormProvider>
    </RequiredFieldsProvider>
  );
}
