import { Record } from 'immutable';
import { z } from 'zod';

import diff from '@peakon/shared/utils/diff';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Segment from './SegmentRecord';
import { validateTestingSchema } from './utils';
import { Base64ResultType } from '../../app-manager/src/utils/getBase64Image';

type Editables = {
  name: string;
  nameTranslations: { locale?: string; translation?: string };
  standard: boolean;
  logo: string;
  comparisonAccess: boolean;
  externalId: string;
};

const EDITABLE_FIELDS = [
  'name',
  'nameTranslations',
  'standard',
  'logo',
  'comparisonAccess',
  'externalId',
] as const;

export type EditableAttribute =
  | {
      [key in (typeof EDITABLE_FIELDS)[number]]: Editables[key];
    }
  | Record<string, never>;

const schema = z.object({});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class SegmentEditor
  extends Record({
    original: new Segment(),
    current: new Segment(),
    file: undefined,
  })
  implements Schema
{
  current!: Segment;
  original!: Segment;
  file?: {
    preview?: Base64ResultType;
    original?: File;
    error?: unknown;
  };

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'SegmentEditor',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'SegmentEditor',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasChanges() {
    return Boolean(this.diff().length);
  }

  diff() {
    return diff(this.current.toJS(), this.original.toJS());
  }

  isInvalid() {
    return (
      !this.current.name ||
      this.current.nameTranslations?.some(
        (item) =>
          // @ts-expect-error 'item' is possibly 'undefined'.ts(18048)
          !item.translation,
      )
    );
  }

  toJsonApi() {
    const editorDiff = this.diff();

    const data: {
      type: 'segments';
      attributes: EditableAttribute;
    } = {
      type: 'segments' as const,
      attributes: {},
    };

    editorDiff.forEach((change) => {
      const field = change[0];
      if (EDITABLE_FIELDS.includes(field)) {
        // @ts-expect-error No index signature with a parameter of type 'string' was found on type 'EditableAttribute'
        data.attributes[field] = this.current.get(field);

        if (field === 'nameTranslations') {
          data.attributes[field] = this.current
            .get(field)
            .reduce((acc: $TSFixMe, cur: $TSFixMe) => {
              // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
              acc[cur.locale] = cur.translation;
              return acc;
            }, {});
        }
      }
    });

    return data;
  }
}
