import { APP_SUBDOMAIN } from './constants';
import { isPreviewApp } from '../../utils/isPreviewApp';

/**
 * Extracts the current subdomain from the hostname.
 * @throws Error if the hostname is invalid or does not conform to the expected format.
 * @example
 * getCurrentSubdomain() // hostname: 'kinetar.peakon.com' ==> 'kinetar'
 * getCurrentSubdomain() // hostname: 'kinetar-1234.peakon.com' ==> 'kinetar-1234'
 * getCurrentSubdomain() // hostname: 'kinetar-1234.preview.peakon.com' ==> 'kinetar'
 * getCurrentSubdomain() // hostname: 'kinetar-1234-13345.preview.peakon.com' ==> 'kinetar-1234'
 **/
export const getCurrentSubdomain = () => {
  const hostname = window.location.hostname;
  // handle preview app URLs `kinetar-1234.preview.staging-peakon.com`
  if (isPreviewApp()) {
    const subdomain = hostname.split('.')[0];
    // remove the last bit of the string matching the pattern "-number"
    // e.g. kinetar-1234-1234 -> kinetar-1234
    // @ts-expect-error TS(18048): 'subdomain' is possibly 'undefined'.
    return subdomain.replace(/-\d*$/, '');
  }

  // handle non preview apps URLS like `kinetar.peakon.com` or `kinetar.staging-peakon.com`
  if (/^(?!.*preview).*\..*peakon/.test(hostname)) {
    return hostname.split('.')[0];
  }

  // fallback to the "old" behavior (before subdomains) in case user is trying to access via google translate for example
  return APP_SUBDOMAIN;
};
