import React from 'react';

import classnames from 'classnames';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { t } from '@peakon/shared/features/i18next/t';

import { AttributeType } from '../../../types/Attribute';
import { getMembersStatus } from '../../../utils';

import styles from './styles.css';

type Props = {
  attribute?: {
    nameTranslated?: string;
    name: string;
    type: AttributeType;
  } | null;
  className?: string;
  direct?: boolean;
  hideReporting?: boolean;
  type?: 'light' | 'noBorder';
};

export const AttributeBadge = ({
  attribute,
  className,
  direct,
  hideReporting,
  type = 'light',
}: Props) => {
  if (!attribute) {
    return null;
  }

  return (
    <span
      className={classnames(styles.root, className, {
        [styles[type]]: type,
      })}
    >
      <VisuallyHidden>{t('a11y_attribute')}</VisuallyHidden>
      {attribute.nameTranslated || attribute.name}
      {!hideReporting &&
      (attribute.type === 'employee' || attribute.type === 'tree') ? (
        <span className={styles.direct}>
          {getMembersStatus(attribute.type, direct)}
        </span>
      ) : null}
    </span>
  );
};
