import React, { useEffect, useState, Fragment } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import store from 'store2';

import { ButtonLink } from '@peakon/bedrock/react/link';
import { BodyText } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';
import { reloadWindowLocation } from '@peakon/shared/utils/window';

import { useShellContext } from '../../context/ShellContext';

const INTERVAL = 5 * 60 * 1000; // 5 minutes

export const SystemNewVersionBar = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { version, onNewVersion } = useShellContext();
  const [newVersion, setNewVersion] = useState<string | null>(null);

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    return history.listen(({ pathname: newPathname }) => {
      if (newVersion && pathname !== newPathname) {
        reloadWindowLocation();
      }
    });
  }, [history, newVersion, pathname]);

  function handleVersionChange(newVersionFromResponse: string) {
    setNewVersion(newVersionFromResponse);
    onNewVersion();
  }

  useEffect(() => {
    if (!version) {
      return;
    }

    const disableVersionChecker = store.local.get('disable_version_checker');
    if (disableVersionChecker) {
      return;
    }

    async function checkVersion() {
      try {
        const response = await fetch('/source-version');
        const newVersionFromResponse = await response.text();

        if (
          newVersionFromResponse.length > 0 &&
          newVersionFromResponse !== version
        ) {
          handleVersionChange(newVersionFromResponse);
        }
      } catch {
        // noop
      }
    }

    const poller = setInterval(checkVersion, INTERVAL);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkVersion();

    return () => {
      clearInterval(poller);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  if (!newVersion) {
    return null;
  }

  return (
    <Fragment>
      <BodyText>{t('new_source_version')}</BodyText>
      <ButtonLink onClick={handleReload}>
        {t('new_source_version__refresh')}
      </ButtonLink>
    </Fragment>
  );
};
