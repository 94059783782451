import z from 'zod';

const BENCHMARK_TYPES = [
  'overall',
  'industry',
  'automatic',
  'context',
] as const;

export const BenchmarkTypeEnum = z.enum(BENCHMARK_TYPES).or(z.null());

export type BenchmarkType = z.infer<typeof BenchmarkTypeEnum> | null;
