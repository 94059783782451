import { List } from 'immutable';

const featuresState = (
  state = List<string>(),
  action: $TSFixMe,
): List<string> => {
  switch (action.type) {
    case 'FEATURE_ADDED': {
      const { name } = action.data;

      return state.push(name);
    }
    case 'FEATURE_REMOVED': {
      const { name } = action.data;

      return state.filter((feature) => feature !== name);
    }
    case 'FEATURE_READ': {
      const { features } = action.data;

      return List(features);
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default featuresState;
