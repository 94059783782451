import { List, Record } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import ActionRecord from './ActionRecord';
import { Override } from './types/Override';
import { validateTestingSchema } from './utils';

const schema = z.object({
  isLoading: z.boolean(),
});
const testingSchema = schema.extend({
  list: z.array(z.object({}).passthrough()),
  nextUrl: z.string().optional(),
  total: z.number(),
});
type Schema = Override<
  z.infer<typeof testingSchema>,
  {
    list: List<ActionRecord>;
  }
>;

// eslint-disable-next-line import/no-default-export
export default class ActionsListRecord extends Record<Schema>({
  isLoading: false,
  list: List<ActionRecord>(),
  nextUrl: undefined,
  total: 0,
}) {
  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ActionsListRecord',
      defaultValues: {
        isLoading: false,
        list: List<ActionRecord>(),
        total: 0,
      },
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ActionsListRecord',
      defaultValues: {
        isLoading: false,
        list: List<ActionRecord>(),
        total: 0,
      },
      log: [
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }
}
