import { Record, fromJS } from 'immutable';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Attribute from './AttributeRecord';
import { validateTestingSchema } from './utils';

const schema = z.object({
  id: z.string(),
});
const testingSchema = schema.extend({
  // no testing props yet
});
type Schema = z.infer<typeof schema>;

// eslint-disable-next-line import/no-default-export
export default class ScheduleAttribute
  extends Record({
    id: undefined,
    required: undefined,
    attribute: undefined,
  })
  implements Schema
{
  id!: Schema['id'];

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ScheduleAttribute',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ScheduleAttribute',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  static createFromApi(data: $TSFixMe) {
    const {
      id,
      attributes: { required },
      relationships: { attribute },
    } = data;

    return new ScheduleAttribute(
      fromJS({
        id,
        required,
        attribute: Attribute.createFromApi(attribute),
      }),
    );
  }
}
