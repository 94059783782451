import React from 'react';

import classnames from 'classnames';

type AnchorElementProps = React.ComponentPropsWithoutRef<'a'>;

type AllowedAnchorElementProps = Omit<AnchorElementProps, 'aria-label' | 'rel'>;

type Props = {
  ariaLabel?: AnchorElementProps['aria-label'];
  children?: React.ReactNode;
} & AllowedAnchorElementProps;

const ExternalLink = ({ className, children, ariaLabel, ...props }: Props) => (
  <a
    {...props}
    className={classnames('peak-link', className)}
    rel="noopener noreferrer"
    aria-label={ariaLabel}
  >
    {children}
  </a>
);

// eslint-disable-next-line import/no-default-export
export default ExternalLink;
