import { getSubdomainHref } from './getSubdomainHref';

/**
 * Returns the href used to transfer users to a new subdomain, while preserving their session.
 * @example
 * getPrimarySubdomainHref({
 *   href: 'https://app.peakon.com/dashboard?foo=bar',
 *   primarySubdomain: 'kinetar',
 *   currentSubdomain: 'app',
 *   transferToken: 'abc123',
 * }) // 'https://kinetar.peakon.com/transfer/subdomain/abc123?returnTo=%2Fdashboard&searchParams=foo%3Dbar'
 **/
export const getSubdomainTransferHref = ({
  href,
  newSubdomain,
  currentSubdomain,
  transferToken,
}: {
  href: string;
  newSubdomain: string;
  currentSubdomain: string;
  transferToken: string;
}) => {
  const currentURL = new URL(
    getSubdomainHref({
      href,
      newSubdomain,
      currentSubdomain,
    }),
  );

  const newURL = new URL(
    `${currentURL.origin}/transfer/subdomain/${transferToken}`,
  );
  newURL.searchParams.set('returnTo', currentURL.pathname);
  newURL.searchParams.set('searchParams', currentURL.searchParams.toString());

  return newURL.toString();
};
