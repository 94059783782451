import { Record } from 'immutable';
import get from 'lodash/get';
import has from 'lodash/has';
import reduce from 'lodash/reduce';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import Category from '../CategoryRecord';
import { validateTestingSchema } from '../utils';

function calculateTotal(totalCounts: $TSFixMe) {
  return reduce(
    Object.keys(totalCounts),
    (acc, curr) => {
      return acc + (totalCounts[curr] || 0);
    },
    0,
  );
}

const schema = z.object({
  id: z.string(),
  type: z.enum(['category', 'participation', 'attrition']),
  hidden: z.boolean().optional(),
});
const testingSchema = schema.extend({
  expanded: z.boolean().optional(),
  value: z.any(), // CategoryRecord
  parent: z.boolean().optional(),
});

type Schema = z.infer<typeof schema>;

class ColumnRecord
  extends Record({
    id: undefined,
    type: 'category',
    expanded: false,
    hidden: false,
    value: undefined,
    parent: false,
    questionId: undefined,
    standard: false,
    isSubcategory: false,
  })
  implements Schema
{
  expanded: $TSFixMe;

  hidden!: Schema['hidden'];

  id!: Schema['id'];

  questionId: $TSFixMe;

  type!: Schema['type'];

  value: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'ColumnRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'ColumnRecord',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isExpanded() {
    return this.expanded;
  }

  isCollapsible() {
    return this.type === 'category' && this.value.isSubcategory();
  }

  isExpandable() {
    return (
      this.type === 'category' && this.value && this.value.isParentCategory()
    );
  }

  isParticipation() {
    return this.type === 'participation';
  }

  isAggregated() {
    return this.id === 'aggregated';
  }

  isAttritionPhase() {
    return this.id.startsWith('employmentPhaseTotals');
  }

  expand() {
    return this.merge({
      expanded: true,
      hidden: false,
    });
  }

  show({ expanded = false } = {}) {
    return this.merge({
      expanded,
      hidden: false,
    });
  }

  hide() {
    return this.merge({
      hidden: true,
    });
  }

  collapse() {
    return this.merge({
      expanded: false,
      hidden: this.isCollapsible(),
    });
  }

  get parentColumnId() {
    return this.value ? this.value.parentCategoryId : null;
  }

  toggleHidden() {
    return this.hidden ? this.show() : this.hide();
  }

  toggleExpand() {
    return this.expanded ? this.collapse() : this.expand();
  }

  getAggregatedParticipation(scores: $TSFixMe, participationKey: string) {
    // null -> no categoryId | driver, refers to the segment
    // @ts-expect-error TS(2769): No overload matches this call.
    return get(scores, [null, participationKey]);
  }

  getLastParticipation(scores: $TSFixMe) {
    // null -> no categoryId | driver, refers to the segment
    return get(scores, [
      // @ts-expect-error TS(2769): No overload matches this call.
      null,
      'participation',
      'round',
    ]);
  }

  getAttrition(scores: $TSFixMe) {
    // @ts-expect-error TS(2769): No overload matches this call.
    const attrition = get(scores, [null, 'attrition']);

    if (this.isAttritionPhase()) {
      const totals = get(attrition, 'employmentPhaseTotals', {});

      const total = calculateTotal(totals);
      const number = get(attrition, this.id, null);

      return Math.round((number / total) * 100);
    }

    return get(attrition, this.id, null);
  }

  getParticipation(
    scores: $TSFixMe,
    hasUseScoreParticipationCategoryGroups = false,
  ) {
    const aggregatedParticipationKey = hasUseScoreParticipationCategoryGroups
      ? 'globalParticipation'
      : 'participation';
    const participation =
      this.id === 'aggregated'
        ? this.getAggregatedParticipation(scores, aggregatedParticipationKey)
        : this.getLastParticipation(scores);

    if (!participation) {
      return;
    }

    if (typeof participation.mean !== 'undefined') {
      const mean = Math.round(participation.mean);
      const benchmark = get(participation, 'benchmark.mean');

      return {
        mean,
        benchmarkDiff: benchmark ? mean - Math.round(benchmark) : undefined,
      };
    }

    return {
      mean: '-',
      benchmarkDiff: '-',
    };
  }

  hasScore(scores: $TSFixMe) {
    return has(scores, this.id) && get(scores, [this.id, 'scores', 'size']) > 0;
  }

  isAnonymised(scores: $TSFixMe) {
    return this.getAnonymity(scores) !== null;
  }

  getAnonymity(scores: $TSFixMe) {
    const anonymity = get(scores, [this.id, 'scores', 'anonymity'], null);

    if (!anonymity) {
      return null;
    }

    return anonymity;
  }

  getScore(scores: $TSFixMe, legacy: $TSFixMe) {
    if (!scores) {
      return;
    }

    if (legacy && this.value && Boolean(this.value.subdriver)) {
      return get(scores, this.value.subdriver);
    }

    return get(scores, this.id);
  }

  getUrl(segmentId: $TSFixMe, contextId: $TSFixMe) {
    if (!this.value) {
      return null;
    }

    if (this.questionId) {
      if (segmentId === contextId) {
        return `/dashboard/question/${this.questionId}?contextId=${contextId}`;
      }

      return `/dashboard/segment/${segmentId}/question/${this.questionId}?contextId=${contextId}`;
    }

    if (segmentId === contextId) {
      if (this.value.main) {
        if (this.value.isGroup('engagement')) {
          return `/dashboard?contextId=${contextId}`;
        }

        return `/dashboard/group/${this.value.group}?contextId=${contextId}`;
      }

      // overall segment
      return `/dashboard/driver/${this.id}?contextId=${contextId}`;
    }

    if (this.value.main) {
      return `/dashboard/group/${this.value.group}/segment/${segmentId}?contextId=${contextId}`;
    }

    return `/dashboard/segment/${segmentId}/driver/${this.id}?contextId=${contextId}`;
  }

  static comparator(columnA: $TSFixMe, columnB: $TSFixMe) {
    if (columnA.type === 'values' || columnB.type === 'values') {
      return 0;
    }

    return Category.compare(columnA.value, columnB.value);
  }

  static createFromApi(data: $TSFixMe, hasChildren: $TSFixMe) {
    const category = Category.createFromApi(data);

    return new ColumnRecord({
      id: data.id,
      type: 'category',
      hidden: category.isSubcategory(),
      expanded: false,
      value: category,
      parent: Boolean(category.isParentCategory() && hasChildren),
    });
  }

  static createFromQuestionScore(data: $TSFixMe) {
    const categoryData = data.relationships.question.relationships.category;
    const category = Category.createFromApi(categoryData);
    const questionId = data.relationships.question.id;

    const value = !category.hasParentCategory()
      ? category.set('parentCategory', category)
      : category;

    const questionStandard = get(
      data,
      'relationships.question.attributes.standard',
      false,
    );

    const isSubcategory = Boolean(
      get(
        data,
        'relationships.question.relationships.category.relationships.parentCategory',
      ),
    );

    return new ColumnRecord({
      id: `${categoryData.id}-${questionId}`,
      type: 'category',
      hidden: true,
      expanded: false,
      value,
      parent: false,
      questionId,
      standard: questionStandard,
      isSubcategory,
    });
  }

  static createAggregatedParticipation() {
    return new ColumnRecord({
      id: 'aggregated',
      type: 'participation',
      hidden: true,
    });
  }

  static createLatestParticipation() {
    return new ColumnRecord({
      id: 'last',
      type: 'participation',
      hidden: true,
    });
  }
}

// eslint-disable-next-line import/no-default-export
export default ColumnRecord;
