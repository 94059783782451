import React from 'react';

import { LogosWdayDubIcon as WorkdayLogo } from '@peakon/bedrock/icons/graphic';
import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';
import { t } from '@peakon/shared/features/i18next/t';

import Link from '../../components/Link';

import styles from './styles.css';

function Workmark() {
  return (
    <div className={styles.logoWorkmark} role="presentation">
      Peakon Employee Voice
    </div>
  );
}

type LogoProps = {
  to?: string;
};

const Logo = ({ to }: LogoProps) => {
  if (!to) {
    return (
      <div className={styles.root} aria-hidden>
        <WorkdayLogo width={32} />
        <Workmark />
      </div>
    );
  }

  return (
    <Link type="a" to={to} className={styles.root}>
      <VisuallyHidden>{t('navbar__logo__back')}</VisuallyHidden>
      <WorkdayLogo width={32} />
      <Workmark />
    </Link>
  );
};

// eslint-disable-next-line import/no-default-export
export default Logo;
