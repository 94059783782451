import { LegacyPagination } from '@peakon/records';

import { employeeSearch } from './employees';
import getParams, { getPagesFromLinks } from '../utils/getParams';

export const createPaginated = (reducer: $TSFixMe) => {
  const initialState = new LegacyPagination({
    list: reducer(undefined, {}),
  });

  return (state = initialState, action: $TSFixMe) => {
    switch (action.type) {
      case 'EMPLOYEE_LIST_LOADING':
      case 'EMPLOYEE_FILTER_LOADING': {
        const { id, params, isContinuation } = action.data;

        return state.merge({
          id,
          isLoading: true,
          isContinuation,
          query: params,
        });
      }
      case 'EMPLOYEE_APPEND_LOADING':
      case 'EMPLOYEE_LOAD_MORE_LOADING': {
        return state.set('isLoading', true);
      }
      case 'EMPLOYEE_FILTER_FAILED':
      case 'EMPLOYEE_APPEND_FAILED':
      case 'EMPLOYEE_LOAD_MORE_FAILED': {
        return state.set('isLoading', false);
      }
      case 'EMPLOYEE_LIST_SUCCESS':
      case 'EMPLOYEE_APPEND_SUCCESS':
      case 'EMPLOYEE_LOAD_MORE_SUCCESS':
      case 'EMPLOYEE_FILTER_SUCCESS': {
        const {
          id,
          links,
          meta: {
            page: { total },
          },
        } = action.data;

        if (state.id !== id && typeof state.id !== 'undefined') {
          // There has been a new filter done
          return state;
        }

        const { isContinuation } = state;

        let opts = {
          id,
          isLoading: false,
          query: getParams(links.self),
          pages: getPagesFromLinks(links),
          list: reducer(state.list, action),
          total,
          isContinuation,
        };

        if (isContinuation && links.next) {
          const { continuation } = getParams(links.next);

          if (continuation) {
            opts = {
              ...opts,
              // @ts-expect-error TS(2322): Type '{ continuation: string | string[] | QueryStr... Remove this comment to see the full error message
              continuation,
            };
          }
        }

        return new LegacyPagination(opts);
      }
      case 'EMPLOYEE_BULK_DELETE_SUCCESS': {
        const { type, count, range } = action.data;

        if (type === 'ALL' && range && !range.isEmpty()) {
          return state.merge({
            total: range.size + 1,
            list: reducer(state.list, action),
            // avoid a pagination
            pages: { self: 1, prev: null, next: null },
          });
        }

        return state.merge({
          total: type === 'RANGE' ? state.total - count : 0,
          list: reducer(state.list, action),
          pages: { self: 1, prev: null, next: null },
        });
      }
      case 'EMPLOYEE_SEARCH_CLEAR':
      case 'EMPLOYEE_FORCE_REFRESH': {
        return initialState;
      }
      default:
        return state.set('list', reducer(state.list, action));
    }
  };
};

// eslint-disable-next-line import/no-default-export
export default createPaginated(employeeSearch);
