import React, { useContext, useRef, useState } from 'react';

import classnames from 'classnames';

import { Button, UnstyledButton } from '@peakon/bedrock/react/button';
import { Label, TextArea } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import emphasize, {
  DEFAULT_EMPHASIS_KEY,
} from '@peakon/shared/utils/emphasizeTranslationString';

import MentionsInput from './MentionsInput';
import {
  ConversationsContext,
  ConversationKind,
} from '../../../../context/ConversationsContext';
import { useAddon } from '../../../../hooks/useAddon';
import ConfirmModal from '../../../ConfirmModal';

import styles from './styles.css';

type Props = {
  isConsultantAccess?: boolean;
  isSaving: boolean;
  onConfirm: (isConfirmed: boolean) => void;
  onSubmit: (value: string) => void;
};

const hasMentions = (message: string) => {
  // matches mention format returned by api, ie: @E140
  return /<@E\d+>/g.test(message);
};

export const ConversationForm = ({
  isConsultantAccess,
  isSaving,
  onConfirm,
  onSubmit,
}: Props) => {
  const [value, setValue] = useState<string>('');
  const [shouldConfirm, setShouldConfirm] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);

  const { comment, kind, onKindChange } = useContext(ConversationsContext);

  const hasMentionsAddOn = useAddon('collaboration_mention');
  const hasNotesAddOn = useAddon('collaboration_notes');

  const setFocus = () => {
    if (node?.current) {
      const field = node.current.querySelector('textarea');
      field?.focus();
    }
  };

  const handleKindChange = (tab: ConversationKind) => {
    onKindChange(tab);

    setFocus();
  };

  const handleSubmit = () => {
    if (kind !== ConversationKind.note && hasMentions(value)) {
      onConfirm(true);
      setShouldConfirm(true);
    } else {
      return handleMessageSubmit();
    }
  };

  const handleCancelConfirm = () => {
    onConfirm(false);
    setShouldConfirm(false);
  };

  const handleMessageSubmit = async () => {
    await onSubmit(value);

    if (shouldConfirm) {
      setShouldConfirm(false);
    }

    setValue('');
    setFocus();
  };

  const isNote = kind === ConversationKind.note;

  const labelTranslation = isNote
    ? t('conversation__note')
    : t('conversation__reply');

  return (
    <div ref={node} className={styles.root} data-kind={kind}>
      <div className={styles.kinds} data-test-id="conversation-form-buttons">
        <UnstyledButton
          className={classnames(styles.button, {
            [styles.active]: kind === ConversationKind.reply,
          })}
          accessibleName={t('conversation__reply')}
          data-active={kind === ConversationKind.reply}
          onClick={() => handleKindChange(ConversationKind.reply)}
          data-test-id="conversation-reply-tab"
        >
          {t('conversation__reply')}
        </UnstyledButton>

        {hasNotesAddOn && (
          <UnstyledButton
            className={classnames(styles.button, {
              [styles.active]: isNote,
            })}
            accessibleName={t('conversation__note')}
            data-active={kind === ConversationKind.note}
            onClick={() => handleKindChange(ConversationKind.note)}
            data-test-id="conversation-note-tab"
          >
            {t('conversation__note')}
          </UnstyledButton>
        )}
      </div>
      <div className={styles.container}>
        <div
          id={`conversation-panel-form-info-${kind}`}
          className={styles.info}
        >
          {kind === ConversationKind.note
            ? emphasize(
                t('conversation__messages_info__note', {
                  replace: {
                    emphasis_start: DEFAULT_EMPHASIS_KEY,
                    emphasis_end: DEFAULT_EMPHASIS_KEY,
                  },
                }),
              )
            : t('conversation__messages_info__reply')}
        </div>

        <div className={styles.form} id="conversation-message-form">
          <Label htmlFor="conversation-panel-textarea">
            {labelTranslation}
          </Label>
          <div className={styles.textarea}>
            {hasMentionsAddOn ? (
              <MentionsInput
                // @ts-expect-error TS(2322): Type 'ConversationContent | null' is not assignabl... Remove this comment to see the full error message
                comment={comment}
                kind={kind}
                onChange={setValue}
                value={value}
                id="conversation-panel-textarea"
                ariaDescribedby={`conversation-panel-form-info-${kind} conversation-mentions-info`}
              />
            ) : (
              <TextArea
                autoSize
                onChange={setValue}
                placeholder={
                  isNote
                    ? t('conversation__note__placeholder')
                    : t('conversation__message__placeholder')
                }
                value={value}
                id="conversation-panel-textarea"
                aria-describedby={`conversation-panel-form-info-${kind}`}
              />
            )}
          </div>

          <div className={styles.actions}>
            <Button
              disabled={isConsultantAccess || !value.trim() || isSaving}
              onClick={handleSubmit}
              size="small"
              data-test-id="conversation-reply"
              variant="primary"
            >
              {isNote ? t('conversation__add_note') : t('conversation__reply')}
            </Button>
            {hasMentionsAddOn && (
              <div
                className={styles.mentionsInfo}
                id="conversation-mentions-info"
              >
                {t('conversation__messages_mention_info')}
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        asyncConfirm
        confirmLabel={t('mentions__reply_confirm__button')}
        isOpen={shouldConfirm}
        onConfirm={handleMessageSubmit}
        onCancel={handleCancelConfirm}
        title={t('mentions__reply_confirm__header')}
      >
        {t('mentions__reply_confirm')}
      </ConfirmModal>
    </div>
  );
};
