import { combineReducers } from '@reduxjs/toolkit';

import { BrandingFormEditorRecord } from '@peakon/records/FormEditorRecord';

import brandings from './brandings';
import form from './form';

const createForm = (
  reducer: $TSFixMe,
  { persistAndReset = [], reset, persist = [] }: $TSFixMe = {},
) => {
  const initialState = new BrandingFormEditorRecord();

  return (state = initialState, action: $TSFixMe): BrandingFormEditorRecord => {
    if (persistAndReset.includes(action.type)) {
      const current = reducer(state.current, action);

      return BrandingFormEditorRecord.startEditing(current);
    } else if (reset === action.type) {
      return BrandingFormEditorRecord.startEditing(state.original);
    } else if (persist.includes(action.type)) {
      return state
        .set('current', reducer(state.current, action))
        .set('original', reducer(state.original, action));
    }

    return state.set('current', reducer(state.current, action));
  };
};

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  brandings,
  form: createForm(form, {
    persistAndReset: ['BRANDING_GET_SUCCESS', 'BRANDING_UPDATE_SUCCESS'],
    reset: 'BRANDING_FORM_RESET',
  }),
});
