import { z } from 'zod';

import { errorReporter } from './errorReporter';

/** Logs Zod validation error to `errorReporter` wrapped within a `console.group` locally  */
export function reportZodError({
  error,
  errorMessagePrefix,
  data,
  logLevel = 'error',
}: {
  data: unknown;
  error: z.ZodError;
  errorMessagePrefix: string;
  logLevel?: 'info' | 'error' | 'warning';
}) {
  /**
   * NOTE: Need to use ENV from global scope here to avoid circular dependency
   */
  // eslint-disable-next-line no-restricted-globals
  const isLocal = !ENV.clusterEnv;
  if (isLocal) {
    // eslint-disable-next-line no-console
    console.group('Zod Validation Error');
  }

  // eslint-disable-next-line no-restricted-globals
  const isDataEnv = JSON.stringify(data) === JSON.stringify(ENV);
  errorReporter[logLevel](
    new Error(`${errorMessagePrefix}: ${error.message}`),
    // eslint-disable-next-line no-restricted-globals
    ENV.clusterEnv === 'staging' && !isDataEnv
      ? {
          data,
        }
      : undefined,
  );

  if (isLocal) {
    // eslint-disable-next-line no-console
    console.info('data', data);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}
