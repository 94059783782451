import React from 'react';

import classnames from 'classnames';

import styles from './styles.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Divider = ({ children, className, ...props }: Props) => (
  <div {...props} className={classnames(styles.root, className)}>
    <hr className={styles.ruler} />
    <div className={styles.caption}>{children}</div>
    <hr className={styles.ruler} />
  </div>
);

// eslint-disable-next-line import/no-default-export
export default Divider;
