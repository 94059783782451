import React from 'react';

import classnames from 'classnames';

import {
  EmptyStateFeatureCommentsStartConversation,
  EmptyStateFeatureCommentsAddNote,
} from '@peakon/bedrock/illustrations/react/empty-state';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import emphasize, {
  DEFAULT_EMPHASIS_KEY,
} from '@peakon/shared/utils/emphasizeTranslationString';

import { ConversationKind } from '../../../../../context/ConversationsContext';

import styles from './styles.css';

type Props = {
  kind: ConversationKind;
};

const EmptyState = ({ kind }: Props) => {
  const isNote = kind === ConversationKind.note;
  const translationKey = isNote ? kind : 'message';

  const translationKeyMap = {
    message: t('conversation__message_placeholder__text'),
    note: t('conversation__note_placeholder__text', {
      replace: {
        emphasis_start: DEFAULT_EMPHASIS_KEY,
        emphasis_end: DEFAULT_EMPHASIS_KEY,
      },
    }),
  } as const;

  return (
    <div
      className={classnames(styles.root, {
        [styles[kind]]: kind,
      })}
      data-test-id="conversation-messages-placeholder"
    >
      <div>
        {isNote ? (
          <EmptyStateFeatureCommentsAddNote />
        ) : (
          <EmptyStateFeatureCommentsStartConversation />
        )}
      </div>
      <Typography.H3 className={styles.title}>
        {translationKey === ConversationKind.note
          ? t('conversation__note_placeholder__title')
          : t('conversation__message_placeholder__title')}
      </Typography.H3>
      <Typography.P className={styles.text}>
        {emphasize(translationKeyMap[translationKey])}
      </Typography.P>
      <Typography.P className={styles.text}>
        <a
          className={styles.linkText}
          href="https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/acting-on-feedback/responding-to-comments/lrv1651063694337.html?toc=6.1.1"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('dashboard__learn_more')}
        </a>
      </Typography.P>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default EmptyState;
