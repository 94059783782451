import { Map } from 'immutable';

import { Question } from '@peakon/records';

const questions = (
  state = Map<string, Question>(),
  action: $TSFixMe,
): Map<string, Question> => {
  switch (action.type) {
    case 'COMMENT_LIST_SUCCESS': {
      const { included } = action.data;

      const includedQuestions =
        // @ts-expect-error TS(7006): Parameter 'incl' implicitly has an 'any' type.
        included && included.filter((incl) => incl.type === 'questions');

      return state.merge(
        (includedQuestions || [])
          .map(Question.createFromApi)
          // @ts-expect-error TS(7006): Parameter 'question' implicitly has an 'any' type.
          .map((question) => [question.id, question]),
      );
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default questions;
