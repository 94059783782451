import React from 'react';

import classnames from 'classnames';

import { UtilityCheckmarkIcon as Checkmark } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Avatar } from '@peakon/components';
import { getMembersStatus } from '@peakon/shared/utils';

import AttributeBadge from '../../../AttributeBadge';

import styles from './styles.css';

type Props = {
  checked?: boolean;
  onClick?: $TSFixMeFunction;
  segment: $TSFixMe; // TODO: PropTypes.instanceOf(SegmentRecord) | PropTypes.instanceOf(EngagementSegment)
  showAttribute?: boolean;
};

export const Segment = ({
  checked,
  onClick,
  segment: {
    abbreviation,
    direct,
    email,
    employeeCounts,
    identifier,
    name,
    nameTranslated,
    attribute,
  },
  showAttribute,
}: Props) => {
  const type = attribute && attribute.type;
  const employed = employeeCounts?.get('employed');

  return (
    <UnstyledButton
      className={classnames(styles.root, {
        [styles.checked]: checked,
        [styles.employee]:
          !showAttribute && (type === 'employee' || type === 'tree'),
      })}
      onClick={onClick}
      data-test-id="segment-row-clickable"
      accessibleName={nameTranslated || name}
    >
      <div className={styles.avatar}>
        <Avatar
          abbreviation={abbreviation}
          gradient={type !== 'employee'}
          icon={type === 'date' || type === 'number' ? type : undefined}
          size="small"
        />
      </div>
      {type === 'employee' || type === 'tree' ? (
        <div className={styles.employee}>
          <div className={styles.name}>
            {nameTranslated || name}
            {showAttribute && (
              <div className={styles.badge}>
                <AttributeBadge
                  type="light"
                  attribute={attribute}
                  direct={direct}
                />
              </div>
            )}

            {Boolean(employed) && (
              <div className={styles.count}>({employed})</div>
            )}

            {!showAttribute && (
              <div className={styles.reportType}>
                {getMembersStatus(type, direct)}
              </div>
            )}
          </div>
          {!showAttribute && type === 'employee' && (email || identifier) && (
            <div className={styles.identifier}>{email || identifier}</div>
          )}
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.name}>{nameTranslated}</div>
          {showAttribute && (
            <div className={styles.badge}>
              <AttributeBadge
                type="light"
                attribute={attribute}
                direct={direct}
              />
            </div>
          )}

          {Boolean(employed) && (
            <div className={styles.count}>({employed})</div>
          )}
        </div>
      )}
      {checked && (
        <div className={styles.checkmark}>
          <Checkmark />
        </div>
      )}
    </UnstyledButton>
  );
};
