import { fromJS, List } from 'immutable';

import Action from '@peakon/records/ActionRecord';

const suggested = (state = List<Action>(), action: $TSFixMe): List<Action> => {
  switch (action.type) {
    case 'ACTION_SUGGESTED_READ_SUCCESS': {
      const { data } = action.data;

      return List(
        // @ts-expect-error TS(7006): Parameter 'dataAction' implicitly has an 'any' typ... Remove this comment to see the full error message
        fromJS(data.map((dataAction) => Action.createFromApi(dataAction))),
      );
    }
    case 'ACTION_SUGGESTED_ACCEPT_SUCCESS':
    case 'ACTION_SUGGESTED_REJECT_SUCCESS': {
      const { id } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return state.removeIn([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.findIndex((i) => i!.id === id),
      ]) as List<Action>;
    }
    case 'IMPROVE_OVERVIEW_V2_RESET': {
      return List();
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default suggested;
