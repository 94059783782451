import React from 'react';

import store from 'store2';

import { Spinner } from '@peakon/bedrock/react/spinner';
import { t } from '@peakon/shared/features/i18next/t';

import ErrorScreen from '../../../../components/ErrorScreen/ErrorScreen';
import { assignWindowLocation } from '../../../../utils/window';
import { getSubdomainTransferHref } from '../../getSubdomainTransferHref';
import { useTransferTokenQuery } from '../queries/useTransferTokenQuery';

import styles from './styles.css';

type Props = {
  newSubdomain: string;
  currentSubdomain: string;
};

/**
 *
 * Transfers the user to the subdomain through the transfer route and transfer token, which will preserve the user's session.
 */
const TransferToSubdomain = ({ newSubdomain, currentSubdomain }: Props) => {
  const { data: transferToken, isLoading } = useTransferTokenQuery();

  if (isLoading) {
    return (
      <div
        data-test-id="transfer-token-loading"
        className={styles.loadingScreen}
      >
        <Spinner size="48" />
      </div>
    );
  }

  if (!transferToken) {
    return (
      <ErrorScreen
        title={t('fetch_transfer_token_error__title')}
        description={t('fetch_transfer_token_error__description')}
        showLinkToHelpCenter={false}
      />
    );
  }

  store.remove('auth_token');

  const redirectURL = getSubdomainTransferHref({
    href: window.location.href,
    currentSubdomain,
    newSubdomain,
    transferToken,
  });

  assignWindowLocation(redirectURL);
  return null;
};

// eslint-disable-next-line import/no-default-export
export default TransferToSubdomain;
