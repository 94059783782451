import z from 'zod';

import { translatedStringSchema } from '../../features/i18next/t';

export const categoryGroupSchema = z.object({
  type: z.literal('category_groups'),
  id: z.string(),
  attributes: z
    .object({
      standard: z.string(),
      nameTranslated: translatedStringSchema,
    })
    .optional(),
});

export type CategoryGroup = z.infer<typeof categoryGroupSchema>;
