import { OrderedMap } from 'immutable';

type BlacklistTopics = {
  data: {
    attributes: { topic: string };
    id: string;
    type: 'denylisted_topics';
  }[];
};

type Action = {
  type?: `${'TOPICS_BLACKLIST_LIST' | 'TOPIC_BLACKLIST_REMOVE'}_${
    | 'SUCCESS'
    | 'LOADING'
    | 'FAILED'
    | 'CANCELLED'}`;
  data?: BlacklistTopics | { id: string };
};

const blacklist = (
  state = OrderedMap<string, string>(),
  action: Action,
): OrderedMap<string, string> => {
  switch (action.type) {
    case 'TOPICS_BLACKLIST_LIST_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { data: topics } = action.data as BlacklistTopics;

      return OrderedMap(
        topics.map((topic) => [topic.id, topic.attributes.topic]),
      );
    }

    case 'TOPIC_BLACKLIST_REMOVE_SUCCESS': {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const { id } = action.data as { id: string };

      return state.remove(id);
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default blacklist;
