import { List } from 'immutable';

import { Branding } from '@peakon/records';
import type { BrandingResponse } from '@peakon/shared/schemas/api/brandings';

type Action =
  | {
      type: 'BRANDING_LIST_SUCCESS';
      data: BrandingResponse[];
    }
  | {
      type: 'BRANDING_LIST_LOADING';
    }
  | {
      type: 'BRANDING_LIST_FAILED';
    }
  | {
      type: 'BRANDING_LIST_CANCELLED';
    };

const brandings = (
  state = List<Branding>(),
  action: Action,
): List<Branding> => {
  switch (action.type) {
    case 'BRANDING_LIST_SUCCESS': {
      return List(action.data.map(Branding.createFromApi));
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default brandings;
