import React from 'react';

import classnames from 'classnames';

import { Stack } from '@peakon/bedrock/react/layout';
import { BodyText, Heading1 } from '@peakon/bedrock/react/typography';
import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';
import { TranslatedString, t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type TitleProps = {
  children: TranslatedString;
};

const Title = ({ children }: TitleProps) => (
  <Heading1 level={1}>{children}</Heading1>
);

type DetailsProps = {
  children: TranslatedString;
};

const Details = ({ children }: DetailsProps) => <BodyText>{children}</BodyText>;

type CustomErrorScreenProps = {
  children: React.ReactNode;
};

export const CustomErrorScreen = ({ children }: CustomErrorScreenProps) => (
  <div className={classnames(styles.container, styles.genericError)}>
    <Stack spacing={16}>{children}</Stack>
  </div>
);

CustomErrorScreen.Title = Title;
CustomErrorScreen.Details = Details;

type GenericErrorScreenProps = {
  message?: TranslatedString;
  title?: TranslatedString;
};

export const GenericErrorScreen = ({
  title,
  message,
}: GenericErrorScreenProps) => (
  <div className={classnames(styles.container, styles.genericError)}>
    <Stack spacing={16}>
      <Heading1 level={1}>{title || t('generic_error__title')}</Heading1>
      <BodyText>{message || t('generic_error__text_v2')}</BodyText>
    </Stack>
  </div>
);

type ErrorScreenProps = {
  message?: TranslatedString;
  title?: TranslatedString;
  type?: 404 | 422 | 500;
};

const ErrorScreen = ({ message, title, type = 404 }: ErrorScreenProps) => {
  if (type === 404) {
    return <NotFoundScreen />;
  }

  return <GenericErrorScreen title={title} message={message} />;
};

// eslint-disable-next-line import/no-default-export
export default ErrorScreen;
