import { List } from 'immutable';
import { createSelector } from 'reselect';
import store from 'store2';

import { currentContext } from './ContextSelectors';
import { RootState } from '../types/redux';
import getRecommendedFrequency from '../utils/getRecommendedFrequency';

export const companySelector = (state: RootState) => state.company;

const settingsSelector = (state: RootState) => state.settings;

export const hasActions = createSelector(
  [companySelector, currentContext],
  (company, context) => {
    return (
      context &&
      company.hasAddOn('actions') &&
      context.hasRight(['action:admin', 'action:read'])
    );
  },
);

export const hasSemanticSearchAddOn = createSelector(
  [companySelector],
  (company) => company.hasAddOn('semantic_search'),
);

export const hasEngagement = createSelector([companySelector], (company) =>
  company.features.includes('engagement'),
);

export const getNewEmployeeFeatures = createSelector(
  [companySelector, hasEngagement],
  (company, engagement) => ({
    engagement: engagement && company.employeeFeatures.includes('engagement'),
  }),
);

export const companyRecommendedFrequency = createSelector(
  [companySelector],
  // @ts-expect-error Type 'undefined' is not assignable to type 'string'.ts(2345)
  (company) => getRecommendedFrequency(company.companySize),
);

export const getScoreMode = createSelector(
  [companySelector, settingsSelector],
  (company, settings) =>
    settings.scoreMode ? settings.scoreMode : company.scoreMode,
);

export const getCompanySettings = createSelector(
  [companySelector],
  (company) => ({
    scoreMode: company.scoreMode,
    driversOrder: company.driversOrder,
  }),
);

export const getStoredCompanySettings = createSelector(
  [getCompanySettings],
  (companySettings) => {
    return {
      ...companySettings,
      scoreMode:
        store.session.get('peakon.ui.scoreMode') || companySettings.scoreMode,
    };
  },
);

export const getCompanyAddOns = createSelector([companySelector], (company) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const addOns = company?.addOns as List<string> | undefined;
  return addOns?.toArray() ?? [];
});

export const getCompanyBackofficeSettings = createSelector(
  [companySelector],
  (company) => {
    const settings = company?.settings;

    return {
      anonymityLevel: settings?.get('anonymityLevel'),
      commentLevel: settings?.get('commentLevel'),
      differenceLevel: settings?.get('differenceLevel'),
    };
  },
);
