import React, { Component, createRef } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { InView } from 'react-intersection-observer';

import { NavigationBasicNavigationChevronLeftIcon as ArrowLeft } from '@peakon/bedrock/icons/system';
import { Button, UnstyledButton } from '@peakon/bedrock/react/button';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { InputField } from '@peakon/components';
import jsonapiparser from '@peakon/jsonapiparser';
import {
  EngagementSegment,
  Segment as SegmentRecord,
  SegmentGroup,
} from '@peakon/records';
import { t } from '@peakon/shared/features/i18next/t';
import { EngagementSegmentSchema } from '@peakon/shared/schemas/api/engagementSegments';
import { BaseSegmentWithRelationships } from '@peakon/shared/schemas/api/segments';
import api from '@peakon/shared/utils/api';

import { Attribute } from './Attribute';
import { Segment } from './Segment';
import makeCancelable from '../../../utils/makeCancelable';
import debouncedInput from '../../hoc/debouncedInput';

import styles from './styles.css';

const DebouncedInput = debouncedInput(InputField);

type Props = {
  contextId?: string;
  driverId?: string;
  group?: string;
  excludedAttributeIds?: string[];
  includeHighlights?: boolean;
  isHeatmap?: boolean;
  loadAttributesFor?: (param: {
    contextId: string;
    driverId: string;
    subdriverId: string;
    valueId: string;
  }) => Promise<unknown>;
  loadSegmentGroups: () => Promise<unknown>;
  loadSegments: (
    id: string,
    params: Record<string, unknown>,
  ) => Promise<unknown>;
  onClose: () => void;
  onSearch: (query: string) => {
    data: BaseSegmentWithRelationships[] | EngagementSegmentSchema[];
  };
  onSelect: (
    selected: EngagementSegment[],
    options?: { all?: boolean; id?: string; deselected?: boolean },
  ) => void;
  selected?: EngagementSegment[];
  single?: boolean;
  showAddAll?: boolean;
  subdriverId?: string;
  valueId?: string;
  hasSearch?: boolean;
};

type State = {
  attributes: SegmentGroup[];
  currentAttribute: SegmentGroup | null;
  nextUrl: string | null;
  query: string;
  segments: (EngagementSegment | SegmentRecord)[];
  selected: EngagementSegment[];
  deselected: boolean;
  isLoading: boolean;
  isLoadingSegments: boolean;
  isSearching: boolean;
};

export class SegmentsPicker extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    hasSearch: true,
    group: 'engagement',
    includeHighlights: false,
    selected: [],
  };

  cancelable?: ReturnType<typeof makeCancelable>;
  segmentsContainer: React.RefObject<HTMLDivElement>;
  containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.segmentsContainer = createRef();
    this.containerRef = createRef();

    this.state = {
      attributes: [],
      currentAttribute: null,
      nextUrl: null,
      query: '',
      segments: [],
      // @ts-expect-error - Type 'EngagementSegment[] | undefined' is not assignable to type 'EngagementSegment[]'.
      selected: props.selected,
      deselected: false,
      isLoading: true,
      isLoadingSegments: false,
      isSearching: false,
    };
  }

  componentDidMount() {
    const {
      contextId,
      valueId,
      driverId,
      subdriverId,
      loadSegmentGroups,
      loadAttributesFor,
      includeHighlights,
    } = this.props;

    setTimeout(() => {
      this.containerRef.current?.focus();
    });

    const method = includeHighlights ? loadAttributesFor : loadSegmentGroups;

    this.cancelable = makeCancelable(
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      method({ contextId, driverId, subdriverId, valueId }),
    );

    this.cancelable.promise
      .then((response) => {
        this.setState({
          // @ts-expect-error - 'response' is of type 'unknown'.
          attributes: response.data.map(SegmentGroup.createFromApi),
          isLoading: false,
        });
      })
      .catch((error) => {
        if (!error.isCanceled) {
          return this.setState({ isLoading: false });
        }
      });
  }

  componentWillUnmount() {
    this.cancelable?.cancel();
  }

  renderEmpty() {
    return (
      <div className={styles.emptyContainer}>
        <span className={styles.emptyText}>
          {t('segments_picker__no_data')}
        </span>
      </div>
    );
  }

  render() {
    const {
      includeHighlights,
      group: categoryGroup,
      excludedAttributeIds,
      onClose,
      showAddAll,
      isHeatmap,
      single,
      hasSearch,
    } = this.props;
    const {
      attributes,
      currentAttribute,
      isLoading,
      isLoadingSegments,
      isSearching,
      nextUrl,
      query,
      segments,
      selected,
    } = this.state;

    const isEmptyAttributes = !isLoading && isEmpty(attributes);
    const isEmptySegments = !isLoading && isEmpty(segments);

    const backButtonText = currentAttribute?.isLink()
      ? t('segments_picker__group_link')
      : currentAttribute?.isCritical()
        ? t('dashboard__v3__highlight')
        : currentAttribute?.nameTranslated;

    return (
      <div ref={this.containerRef} tabIndex={-1} className={styles.root}>
        <div className={styles.header}>
          <div className={styles.title}>
            {isSearching ? (
              <div className={styles.searchTitle}>
                {t('segments_picker__search_results')}
                <Button
                  onClick={this.onSearchClear}
                  size="small"
                  variant="secondary"
                >
                  {t('segments_picker__clear_search')}
                </Button>
              </div>
            ) : currentAttribute ? (
              <UnstyledButton
                accessibleName={backButtonText ?? ''}
                className={styles.back}
                onClick={this.handleBackClick}
              >
                <ArrowLeft />
                {backButtonText}
              </UnstyledButton>
            ) : (
              t('segments_picker__select_segments')
            )}
          </div>
          {hasSearch && (
            <div className={styles.search}>
              <DebouncedInput
                inputType="search"
                disabled={isEmptyAttributes}
                onChange={this.onSearch}
                // @ts-expect-error Type '(e: React.MouseEvent) => void' is not assignable to type '() => void'.
                onClear={this.onSearchClear}
                placeholder={t('segments_picker__search_placeholder')}
                value={query}
                testId="segments-picker-search"
                clearLabel={t('a11y__search__clear_input')}
                aria-label={t('segments_picker__search_placeholder')}
              />
            </div>
          )}
        </div>
        <div className={styles.content}>
          {isLoading ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : isEmptyAttributes ? (
            this.renderEmpty()
          ) : (
            <div
              className={classnames(styles.wrapper, {
                [styles.isLoading]: isLoading || isLoadingSegments,
                [styles.hasSegments]: !isEmptySegments || isSearching,
              })}
            >
              <div className={styles.list}>
                {attributes.map((group) => {
                  const { attributeId, id } = group;

                  if (
                    id === 'critical' &&
                    categoryGroup !== 'engagement' &&
                    includeHighlights
                  ) {
                    return null;
                  }

                  return (
                    <div
                      key={id}
                      className={classnames(styles.attribute, {
                        [styles.hidden]:
                          isArray(excludedAttributeIds) &&
                          attributeId &&
                          excludedAttributeIds.includes(attributeId),
                      })}
                      data-test-id="attribute-row"
                    >
                      <Attribute
                        count={group.segmentCount}
                        positiveCount={group.positiveCount}
                        negativeCount={group.negativeCount}
                        isHeatmap={isHeatmap}
                        hasCurrentAttribute={Boolean(currentAttribute)}
                        isLoading={Boolean(
                          isLoadingSegments &&
                            currentAttribute &&
                            currentAttribute.id === id,
                        )}
                        name={
                          group.isCritical()
                            ? t('dashboard__v3__highlight')
                            : group.nameTranslated
                        }
                        onAddAllClick={() => this.handleAddAll(group)}
                        onClick={() => this.loadSegments(group)}
                        group={categoryGroup}
                        restricted={group.isRestricted()}
                        selectedCount={
                          single
                            ? 0
                            : group.isCritical()
                              ? selected.filter(
                                  (segment) => segment.classification,
                                ).length
                              : selected.filter(
                                  (segment) =>
                                    segment.attribute &&
                                    segment.attribute.id === attributeId,
                                ).length
                        }
                        showAddAll={showAddAll && !single}
                        type={group.type}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                ref={this.segmentsContainer}
                className={classnames(styles.list)}
              >
                {isSearching && isEmptySegments ? (
                  <div
                    className={classnames(
                      styles.emptyContainer,
                      styles.emptySegments,
                    )}
                  >
                    <span className={styles.emptyText}>
                      {t('dashboard__v3__compare__no_segments')}
                    </span>
                  </div>
                ) : (
                  segments.map((segment) => {
                    return (
                      <div
                        key={segment.id}
                        className={styles.segment}
                        data-test-id="segment-row"
                      >
                        <Segment
                          checked={Boolean(
                            selected.find((value) => value.id === segment.id),
                          )}
                          onClick={(e) => {
                            // prevent click outside to be triggered when using portals
                            if (e) {
                              e.nativeEvent.stopImmediatePropagation();
                            }

                            this.handleSegmentClick(
                              // TODO: Fix this the next time the file is edited.
                              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                              segment as EngagementSegment,
                            );
                          }}
                          segment={segment}
                          showAttribute={
                            !currentAttribute ||
                            currentAttribute.isLink() ||
                            currentAttribute.isCritical()
                          }
                        />
                      </div>
                    );
                  })
                )}

                {nextUrl && (
                  <InView onChange={this.loadNextPage}>
                    <div className={styles.segmentsLoader}>
                      <Spinner />
                    </div>
                  </InView>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.cancel}>
            <Button onClick={onClose} variant="secondary" data-test-id="cancel">
              {t('cancel')}
            </Button>
          </div>
          {!single && (
            <div className={styles.select}>
              {!isLoading && selected.length > 0 && (
                <div className={styles.deselect}>
                  <Button
                    onClick={this.deselect}
                    data-test-id="deselect"
                    variant="tertiary"
                  >
                    {t('segments_picker__deselect_all')}
                  </Button>
                </div>
              )}

              <Button
                disabled={selected.length === 0}
                onClick={this.select}
                variant="primary"
                data-test-id="save"
              >
                {selected.length > 0 ? (
                  <span>
                    {t('segments_picker__add_selected_segments', {
                      replace: { count: selected.length },
                    })}
                  </span>
                ) : (
                  t('segments_picker__add')
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  loadSegments(attribute: SegmentGroup) {
    const {
      loadSegments,
      contextId,
      valueId,
      driverId,
      subdriverId,
      includeHighlights,
      isHeatmap,
    } = this.props;
    const { isLoadingSegments } = this.state;

    if (isLoadingSegments) {
      return;
    }

    this.setState(
      {
        currentAttribute: attribute,
        isLoadingSegments: true,
        nextUrl: null,
      },

      async () => {
        const params = {
          contextId,
          driverId,
          subdriverId,
          valueId,
          includeHighlights,
          shouldPreserveHeatmapState: isHeatmap && attribute.isCritical(),
        };

        const response = await loadSegments(
          // @ts-expect-error first argument should be a string
          attribute.isCritical() || attribute.isLink()
            ? attribute.id
            : attribute.attributeId,
          params,
        );

        this.setState({
          isLoadingSegments: false,
          nextUrl: get(response, 'links.next') || null,
          // @ts-expect-error return from jsonapiparser is unknown
          segments: response.data.map(
            attribute.isCritical() || attribute.isLink() || includeHighlights
              ? EngagementSegment.createFromApi
              : SegmentRecord.createFromApi,
          ),
        });
      },
    );
  }

  loadNextPage = (isVisible: boolean) => {
    if (!isVisible) {
      return;
    }

    const { includeHighlights } = this.props;
    const { currentAttribute, nextUrl, segments } = this.state;

    this.setState(
      {
        isLoadingSegments: true,
      },

      async () => {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const response = jsonapiparser(await api.get(nextUrl ?? undefined));
        const isEngagement =
          (currentAttribute &&
            (currentAttribute.id === 'link' ||
              currentAttribute.id === 'critical')) ||
          includeHighlights;

        this.setState({
          isLoadingSegments: false,
          // @ts-expect-error return from jsonapiparser is unknown
          nextUrl: get(response, 'links.next'),
          segments: segments.concat(
            // @ts-expect-error return from jsonapiparser is unknown
            response.data.map(
              isEngagement
                ? EngagementSegment.createFromApi
                : SegmentRecord.createFromApi,
            ),
          ),
        });
      },
    );
  };

  handleBackClick = (e: React.MouseEvent) => {
    // prevent click outside to be triggered when using portals
    if (e) {
      e.nativeEvent.stopImmediatePropagation();
    }

    this.setState({
      currentAttribute: null,
      nextUrl: null,
      segments: [],
    });
  };

  onSearch = (query: string) => {
    const { excludedAttributeIds, onSearch } = this.props;

    if (!trim(query)) {
      return;
    }

    if (query.length === 1) {
      return;
    }

    this.setState(
      {
        currentAttribute: null,
        isLoading: true,
        isLoadingSegments: true,
        nextUrl: null,
        query,
      },

      async () => {
        const response = await onSearch(query);
        const data = isArray(excludedAttributeIds)
          ? response.data.filter(
              (segment) =>
                !excludedAttributeIds.includes(
                  // @ts-expect-error No overload matches this call.
                  get(segment, 'relationships.attribute.id'),
                ),
            )
          : response.data;

        this.setState({
          isLoading: false,
          isLoadingSegments: false,
          segments: data.map((item) => {
            if (item.type === 'segments') {
              return SegmentRecord.createFromApi(item);
            }

            return EngagementSegment.createFromApi(item);
          }),
          isSearching: true,
        });
      },
    );
  };

  onSearchClear = (e: React.MouseEvent) => {
    // prevent click outside to be triggered when using portals
    if (e) {
      e.nativeEvent.stopImmediatePropagation();
    }

    this.setState({
      isSearching: false,
      query: '',
      segments: [],
    });
  };

  handleSegmentClick(segment: EngagementSegment) {
    const { onSelect, single } = this.props;
    const { selected } = this.state;

    const deselected = selected.find((value) => value.id === segment.id);

    if (single) {
      return this.setState(
        () => ({
          selected: [segment],
        }),
        () => {
          onSelect?.([segment]);
        },
      );
    } else if (deselected) {
      return this.setState((prev) => ({
        selected: prev.selected.filter((value) => value.id !== segment.id),
        deselected: true,
      }));
    } else {
      return this.setState((prev) => ({
        selected: prev.selected.concat([segment]),
      }));
    }
  }

  handleAddAll = (group: SegmentGroup) => {
    const {
      loadSegments,
      onClose,
      onSelect,
      contextId,
      driverId,
      subdriverId,
      valueId,
      isHeatmap,
      includeHighlights,
    } = this.props;

    this.setState(
      {
        currentAttribute: group,
        isLoadingSegments: true,
      },

      async () => {
        const params = {
          contextId,
          driverId,
          subdriverId,
          valueId,
          includeHighlights,
          perPage: isHeatmap ? group.segmentCount : 30,
        };

        const response = await loadSegments(
          group.attributeId || group.id,
          params,
        );

        const recordType =
          group.isCritical() || includeHighlights
            ? EngagementSegment
            : SegmentRecord;

        // @ts-expect-error return from jsonapiparser is unknown
        const parsed = response.data.map(recordType.createFromApi);

        onSelect?.(parsed, { all: true, id: group.id });
        onClose();
      },
    );
  };

  select = () => {
    const { onClose, onSelect } = this.props;
    const { selected, deselected } = this.state;

    onSelect?.(selected, { deselected });
    onClose();
  };

  deselect = () => {
    this.setState(
      {
        selected: [],
      },

      () => {
        const { onClose, onSelect } = this.props;

        onSelect?.([]);
        onClose();
      },
    );
  };
}
