import { v4 as uuidv4 } from 'uuid';

import { AppError } from '@peakon/error-reporter';
import { SystemNotificationSchema } from '@peakon/records/SystemNotificationRecord';
import { TranslatedString, t } from '@peakon/shared/features/i18next/t';

import { Dispatch } from '../types/redux';
import extractMessage from '../utils/extractMessage';

type NotificationData = Omit<
  SystemNotificationSchema,
  'id' | 'title' | 'type'
> & {
  id?: string;
  title?: TranslatedString;
};

export const catchHandler = (error: unknown | Error | AppError) => {
  const { title, message } = extractMessage(error);

  return showErrorNotification({
    title,
    message,
  });
};

export const showSuccessNotification = (data: NotificationData) =>
  show({ title: t('notifications__success'), ...data, type: 'success' });

export const showErrorNotification = (data: NotificationData) =>
  show({ title: t('notifications__error'), ...data, type: 'error' });

type ShowParams = {
  id?: string;
  title?: TranslatedString;
  message?: TranslatedString;
  type?: string;
  code?: number;
  dismissable?: boolean;
  duration?: number;
  link?: {
    href: string;
    label: string;
  };
};
export const show =
  ({
    id = uuidv4(),
    title,
    message,
    type,
    code,
    dismissable = true,
    duration = 10000,
    link,
  }: ShowParams = {}) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: 'NOTIFICATION_SHOW',
      data: {
        id,
        title,
        message,
        type,
        code,
        dismissable,
        link,
      },
    });

    if (duration !== Infinity) {
      setTimeout(() => dispatch(remove(id)), duration);
    }
  };

export const remove = (id: string) => ({
  type: 'NOTIFICATION_HIDE',

  data: {
    id,
  },
});
