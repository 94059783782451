import { combineReducers } from '@reduxjs/toolkit';

import access from './access';
import account from './account';
import actions from './actions';
import attributeEditor from './attributeEditor';
import attributes from './attributes';
import branding from './branding';
import categories from './categories';
import categoryGroups from './categoryGroups';
import comments from './comments';
import company from './company';
import contexts from './contexts';
import course from './course';
import courseProgress from './courseProgress';
import customActions from './customActions';
import customResources from './customResources';
import employeeAttributes from './employeeAttributes';
import employeeEditor from './employeeEditor';
import employeeKioskExport from './employeeKioskExport';
import employeeRounds from './employeeRounds';
import employees, { employeeQueries, employeePagination } from './employees';
import employeesImportResult from './employeesImportResult';
import employeesNotInOpen from './employeesNotInOpen';
import engagement from './engagement';
import externalMetrics from './externalMetrics';
import features from './features';
import filterColumns from './filterColumns';
import filterOptions from './filterOptions';
import filters from './filters';
import flows from './flows';
import group from './group';
import groupQuestions from './groupQuestions';
import heatmap from './heatmap';
import improveResources from './improveResources';
import loaders from './loaders';
import loginFlow from './loginFlow';
import managingSegments from './managingSegments';
import notifications from './notifications';
import pagination from './pagination';
import priorities from './priorities';
import schedule from './schedule';
import { scheduleAttributes } from './scheduleAttributes';
import scores from './scores';
import segmentEditor from './segmentEditor';
import segments from './segments';
import selectedEmployees from './selectedEmployees';
import session from './session';
import settings from './settings';
import topics from './topics';

const appReducer = combineReducers({
  access,
  account,
  actions,
  attributeEditor,
  attributes,
  branding,
  categories,
  categoryGroups,
  comments,
  company,
  contexts,
  course,
  courseProgress,
  customActions,
  customResources,
  employeeAttributes,
  employeeEditor,
  employeeKioskExport,
  employeePagination,
  employeeQueries,
  employeeRounds,
  employees,
  employeesImportResult,
  employeesNotInOpen,
  engagement,
  externalMetrics,
  features,
  filterColumns,
  filterOptions,
  filters,
  flows,
  group,
  groupQuestions,
  heatmap,
  improveResources,
  loaders,
  loginFlow,
  managingSegments,
  notifications,
  pagination,
  priorities,
  scheduleAttributes,
  scores,
  schedule,
  segments,
  segmentEditor,
  selectedEmployees,
  session,
  settings,
  topics,
});

const rootReducer = (state: $TSFixMe, action: $TSFixMe) => {
  if (action.type === 'USER_LOGOUT') {
    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    state = undefined;
  }

  return appReducer(state, action);
};

// eslint-disable-next-line import/no-default-export
export default rootReducer;
