export const getScoreRange = (score: number, scoreMode: 'nps' | 'mean') => {
  if (scoreMode === 'nps') {
    if (score <= -60) {
      return 1;
    } else if (score <= -20) {
      return 2;
    } else if (score <= 19) {
      return 3;
    } else if (score <= 59) {
      return 4;
    } else if (score <= 100) {
      return 5;
    }
  }

  if (score <= 2.9) {
    return 1;
  } else if (score <= 4.9) {
    return 2;
  } else if (score <= 6.9) {
    return 3;
  } else if (score <= 8.9) {
    return 4;
  } else if (score <= 10) {
    return 5;
  }
};
