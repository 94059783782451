import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import hasRight from '@peakon/shared/utils/hasRight';

import { categoriesGroupSelector } from './CategoryGroupSelectors';
import { RootState } from '../types/redux';

export type SettingsKeys = 'closesAt' | 'test' | 'scoreMode';

const companySelector = (state: RootState) => state.company;
export const contextSelector = (state: RootState) => state.contexts;
const sessionSelector = (state: RootState) => state.session;
const categoryGroupSelector = (_discard: RootState, group: string) => group;
const settingsSelector = (state: RootState) => state.settings;

export const currentContext = createSelector([contextSelector], (contexts) => {
  const params = new URLSearchParams(window.location.search);
  const contextId = params.get('contextId');

  if (contextId) {
    const contextFromUrl = contexts.find(
      (context) => context?.id === contextId,
    );
    if (contextFromUrl) {
      return contextFromUrl;
    }
  }
  return contexts.first();
});

export const getCompanyContext = createSelector([contextSelector], (contexts) =>
  contexts.find((context) => context?.level === 'company'),
);

export const getCommentRights = createSelector(
  [currentContext, companySelector, sessionSelector],
  (context, company, session) => ({
    admin: session.hasRight([
      'comment:driver:read:all',
      'comment:text:read:all',
      'comment:value:read:all',
    ]),
    commentRank: company.hasAddOn('comment_rank'),
    commentSegmentsExport:
      company.hasAddOn('comment_segments_export') &&
      context.hasRight('comment:segments:export'),
    read: context.hasRight('comment:driver:read'),
    compare: context.hasRight('comment:compare'),
    input: context.hasRight('comment:text:read'),
    sensitive: context.hasRight('comment:sensitive:read'),
    values:
      company.hasAddOn('values') && context.hasRight('comment:value:read'),
  }),
);

const getGroupCommentRights = createSelector(
  [
    currentContext,
    companySelector,
    sessionSelector,
    categoryGroupSelector,
    categoriesGroupSelector,
  ],
  (context, company, session, group, groups) => {
    const categoryGroup = groups.get(group);

    const groupAccess =
      categoryGroup && company.hasAddOn('access_by_question_set')
        ? categoryGroup.hasComments()
        : true;

    return {
      admin:
        groupAccess &&
        session.hasRight([
          'comment:driver:read:all',
          'comment:text:read:all',
          'comment:value:read:all',
        ]),
      commentRank: company.hasAddOn('comment_rank'),
      commentSegmentsExport:
        groupAccess &&
        company.hasAddOn('comment_segments_export') &&
        context.hasRight('comment:segments:export'),
      read: groupAccess && context.hasRight('comment:driver:read'),
      compare: groupAccess && context.hasRight('comment:compare'),
      input: groupAccess && context.hasRight('comment:text:read'),
      sensitive: groupAccess && context.hasRight('comment:sensitive:read'),
      values:
        groupAccess &&
        company.hasAddOn('values') &&
        context.hasRight('comment:value:read'),
    };
  },
);

export const getEmployeeRights = createSelector(
  [currentContext],
  (dashboardContext) => {
    const rights = dashboardContext ? dashboardContext.rights?.toArray() : [];

    return {
      admin: hasRight(rights, ['employee:admin']),
      write: hasRight(rights, ['employee:write']),
    };
  },
);

export const getGroupOverviewRights = createSelector(
  [
    currentContext,
    getGroupCommentRights,
    companySelector,
    sessionSelector,
    settingsSelector,
    categoriesGroupSelector,
  ],
  (dashboardContext, commentRights, company, session, settings, group) => ({
    comments: commentRights.read || commentRights.input || commentRights.values,
    compare: dashboardContext.hasRight('dataset:compare'),
    priority: dashboardContext.hasRight('priority:read') && !settings.closesAt,

    share:
      !settings.closesAt &&
      company.hasAddOn('collaboration_shareable') &&
      dashboardContext.hasRight('share:admin'),

    values:
      company.hasAddOn('values') &&
      session.hasRight('value:read') &&
      // @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
      group.some((item) => item.id === 'values'),
  }),
);

export const getCategoryGroupIds = createSelector(
  [currentContext],
  (dashboardContext) => {
    return (
      dashboardContext?.categoryGroups.map((group) => group?.id).toArray() || []
    );
  },
);

export const getQuestionSets = createSelector([contextSelector], (contexts) => {
  return uniq(
    flatten(
      contexts
        .map((context) => context.categoryGroups.map(({ id }) => id).toArray())
        .toArray(),
    ),
  );
});

export const getCommentTypes = createSelector(
  [getCommentRights, getCategoryGroupIds],
  (rights, categoryGroups) => {
    let commentTypes: Array<string> = [];

    if (rights.read) {
      commentTypes = categoryGroups.filter((group) => {
        return !['text', 'values'].includes(group);
      });
    }

    if (rights.input && categoryGroups.includes('text')) {
      commentTypes.push('text');
    }

    if (rights.values && categoryGroups.includes('values')) {
      commentTypes.push('values');
    }

    return commentTypes;
  },
);

export const getCommentsIntroRights = createSelector(
  [currentContext],
  (dashboardContext) => ({
    acknowledge: dashboardContext.hasRight('comment:driver:acknowledge'),
    conversations: dashboardContext.hasRight('conversation:read'),
  }),
);

const filteredSettingsSelector = (
  state: RootState,
  omitKeys?: SettingsKeys[],
) => {
  if (!omitKeys) {
    return Object.values(state.settings);
  }

  return Object.values(omit(state.settings, omitKeys));
};

export const getDashboardDependencies = createSelector(
  [currentContext, filteredSettingsSelector],
  (dashboardContext, settings) => {
    return [dashboardContext.id, ...settings];
  },
);
