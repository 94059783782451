import { List } from 'immutable';

import Segment from '@peakon/records/SegmentRecord';
import { SegmentResponse } from '@peakon/shared/schemas/api/segments';

type Action = {
  type: 'SEGMENT_READ_SUCCESS';
  data: {
    data: SegmentResponse;
  };
};

const segments = (state = List<Segment>(), action: Action) => {
  switch (action.type) {
    case 'SEGMENT_READ_SUCCESS': {
      return state.push(Segment.createFromApi(action.data.data));
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default segments;
