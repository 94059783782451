import { useCallback, useRef } from 'react';

import api from '@peakon/shared/utils/api';
import RealtimeChannel from '@peakon/shared/utils/realtime/RealtimeChannel';

export const useBackgroundTaskChannel = ({
  onComplete,
  onError,
  onProgress,
}: {
  onComplete: (data: unknown) => void;
  onError: (data: unknown) => void;
  onProgress: (data: unknown) => void;
}) => {
  const channelRef = useRef<RealtimeChannel | null>(null);

  const leave = useCallback(() => {
    channelRef.current?.leave(true);
  }, []);

  const initialize = useCallback(
    (accountId?: string) => {
      if (accountId) {
        channelRef.current = new RealtimeChannel(
          `background-tasks/${accountId}`,
          {
            getToken: () => api.auth.restoreToken(),
          },
        );
      } else {
        leave();
      }
    },
    [leave],
  );

  const connect = useCallback(async () => {
    if (!channelRef.current) {
      throw new Error('Realtime channel not initialized');
    }

    if (!channelRef.current?.isConnected()) {
      await channelRef.current.join();
    }

    channelRef.current.on('complete', onComplete);

    channelRef.current.on('taskError', onError);

    channelRef.current.on('progress', onProgress);
  }, [onComplete, onError, onProgress]);

  return {
    initialize,
    leave,
    connect,
  };
};
