import React, { Fragment } from 'react';

import { t } from '@peakon/shared/features/i18next/t';

import { useDashboardContext } from '../../hooks/useDashboardContext';

export const DriversStepTitle = () => {
  const dashboardContext = useDashboardContext();
  const hasPrioritiesAdminRight = dashboardContext.hasRight('priority:admin');

  return (
    <Fragment>
      {hasPrioritiesAdminRight
        ? t('manager_onboarding__drivers__title_admin')
        : t('manager_onboarding__drivers__title')}
    </Fragment>
  );
};

type DriversStepTextProps = {
  paragraph: 1 | 2;
};

export const DriversStepText = ({ paragraph }: DriversStepTextProps) => {
  const dashboardContext = useDashboardContext();
  const hasPrioritiesAdminRight = dashboardContext.hasRight('priority:admin');

  const [firstParagraph, secondParagraph] = hasPrioritiesAdminRight
    ? [
        t('manager_onboarding__drivers__text_admin'),
        t('manager_onboarding__drivers__text_admin_2'),
      ]
    : [
        t('manager_onboarding__drivers__text'),
        t('manager_onboarding__drivers__text_2'),
      ];

  return (
    <Fragment>{paragraph === 1 ? firstParagraph : secondParagraph}</Fragment>
  );
};
