import jsonapiparser from '@peakon/jsonapiparser';

function flatten({ id, type, attributes, relationships }: $TSFixMe) {
  const parsedRelationships = {};

  for (const key in relationships) {
    if (attributes && attributes[key]) {
      // handle special case of named attributes on employee records
      // where they have the same attribute/relationship key
      if (
        type === 'employees' &&
        relationships[key].type === 'attribute_options'
      ) {
        // @ts-expect-error Property '_attributeOptions' does not exist on type '{}'.ts(2339)
        if (!parsedRelationships._attributeOptions) {
          // @ts-expect-error Property '_attributeOptions' does not exist on type '{}'.ts(2339)
          parsedRelationships._attributeOptions = {};
        }
        // @ts-expect-error Property '_attributeOptions' does not exist on type '{}'.ts(2339)
        parsedRelationships._attributeOptions[key] = flatten(
          relationships[key],
        );
      } else {
        throw new Error(
          `Relationship key ${key} already exists as an attribute`,
        );
      }
    } else if (relationships[key]) {
      if (Array.isArray(relationships[key])) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        parsedRelationships[key] = relationships[key].map(flatten);
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        parsedRelationships[key] = flatten(relationships[key]);
      }
    }
  }

  return {
    id,
    _type: type,
    ...attributes,
    ...parsedRelationships,
  };
}

/** @deprecated Please use `@peakon/jsonapiparser` instead */
// eslint-disable-next-line import/no-default-export
export default function parser(data: $TSFixMe, { isParsed }: $TSFixMe = {}) {
  if (!data) {
    // FIXME: throw error?
    return { data: {} };
  }

  const parsed = isParsed ? data : jsonapiparser(data);

  let flattened;
  if (Array.isArray(parsed.data)) {
    flattened = parsed.data.map(flatten);
  } else {
    flattened = flatten(parsed.data);
  }

  return {
    ...parsed,
    rawData: parsed.data,
    data: flattened,
  };
}
