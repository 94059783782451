import React, { Component } from 'react';

import classnames from 'classnames';

import { Button } from '@peakon/bedrock/react/button';
import { Checkbox } from '@peakon/bedrock/react/form';
import { Modal } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

type Props = {
  asyncConfirm?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  disableConfirm?: boolean;
  isOpen?: boolean;
  modalClassName?: string;
  onAnswer?: (response: boolean) => void;
  onCancel: () => void;
  onClose?: () => void;
  onConfirm?: ({ optOut }: { optOut?: boolean }) => void;
  optOut?: boolean;
  scrollDisabled?: boolean;
  title?: string;
  type?: 'positive' | 'negative';
  width?: number;
  children?: React.ReactNode;
};

type State = {
  optOutChecked: boolean;
  isLoading: boolean;
};

class ConfirmModal extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    scrollDisabled: true,
    type: 'positive',
  };

  state = {
    optOutChecked: false,
    isLoading: false,
  };

  onAnswer = (response: boolean, optOutChecked?: boolean) => {
    const { onAnswer, onConfirm, onCancel } = this.props;

    this.setState({ isLoading: response });

    onAnswer?.(response);
    return response ? onConfirm?.({ optOut: optOutChecked }) : onCancel();
  };

  handleClose = () => {
    const { onClose, onCancel } = this.props;

    this.setState({ isLoading: false });
    if (onClose) {
      onClose();
    } else {
      onCancel();
    }
  };

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      asyncConfirm,
      cancelLabel,
      children,
      confirmLabel,
      disableConfirm,
      isOpen,
      modalClassName,
      optOut,
      scrollDisabled,
      title,
      type,
      width,
    } = this.props;

    const { optOutChecked } = this.state;

    return (
      <Modal
        title={title}
        isOpen={isOpen}
        modalClassName={classnames(styles.container, modalClassName)}
        onClose={this.handleClose}
        scrollDisabled={scrollDisabled}
        style={{
          width,
        }}
        testId="confirm-modal"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.CloseButton
            closeLabel={t('common__close')}
            onClick={this.handleClose}
          />
        </Modal.Header>
        {children && (
          <Modal.Content className={styles.content}>{children}</Modal.Content>
        )}
        <Modal.Footer className={styles.footer}>
          {optOut && (
            <div className={styles.optOut}>
              <Checkbox
                checked={optOutChecked}
                id="confirm-modal-dont-show"
                name="confirm_modal_dont_show"
                onChange={this.onOptOut}
                label={t('dont_show_this_again')}
              />
            </div>
          )}
          <Button
            variant="secondary"
            onClick={() => this.onAnswer(false)}
            data-test-id="confirm-modal-cancel"
          >
            {cancelLabel || t('cancel')}
          </Button>
          <Button
            busy={asyncConfirm && this.state.isLoading}
            disabled={disableConfirm}
            onClick={() => this.onAnswer(true, optOutChecked)}
            variant={type === 'positive' ? 'primary' : 'danger'}
            data-test-id="confirm-modal-confirm"
          >
            {confirmLabel ||
              (type === 'positive'
                ? t('question__confirm')
                : t('question__delete'))}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  onOptOut = () => {
    const { optOutChecked } = this.state;

    this.setState({
      optOutChecked: !optOutChecked,
    });
  };
}

// eslint-disable-next-line import/no-default-export
export default ConfirmModal;
