import { OrderedMap } from 'immutable';

import QuestionScoresRecord from '@peakon/records/QuestionScoresRecord';

const DEFAULT_STATE = {
  isLoading: true,
  group: null,
  engagementQuestions: OrderedMap<string, QuestionScoresRecord>(),
};

const questions = (
  state = DEFAULT_STATE,
  action: $TSFixMe,
): typeof DEFAULT_STATE => {
  switch (action.type) {
    case 'ENGAGEMENT/QUESTIONS_LIST_LOADING': {
      return { ...state, group: action.data.group, isLoading: true };
    }

    case 'ENGAGEMENT/QUESTIONS_LIST_SUCCESS': {
      const { data, group } = action.data;

      // discard any questions that are not for the last group
      // to avoid race conditions
      if (group !== state.group) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        engagementQuestions: OrderedMap(
          // @ts-expect-error TS(7006): Parameter 'q' implicitly has an 'any' type.
          data.map((q) => [q.id, QuestionScoresRecord.createFromApi(q)]),
        ),
      };
    }

    case 'ENGAGEMENT/QUESTIONS_LIST_FAILED': {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default questions;
