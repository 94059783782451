import ErrorReporter from '@peakon/error-reporter';

/**
 * NOTE: Need to use ENV from global here scope to avoid circular dependency
 */
export const errorReporter = new ErrorReporter({
  // eslint-disable-next-line no-restricted-globals
  accessToken: ENV.rollbar ? ENV.rollbar.client : '',
  // eslint-disable-next-line no-restricted-globals
  environment: ENV.clusterEnv,
  // eslint-disable-next-line no-restricted-globals
  version: ENV.version,
});

export type ErrorReporterType = typeof errorReporter;
