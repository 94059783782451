import z from 'zod';

import { ALL_QUESTION_SETS } from '@peakon/records/constants/questionSets';

import { valueSchema } from './values';
import { translatedStringSchema } from '../../features/i18next/t';

const baseCategorySchema = z.object({
  id: z.string(),
  type: z.literal('categories'),
  attributes: z.object({
    description: z.string().nullish(),
    descriptionTranslated: translatedStringSchema.nullish(),
    descriptionTranslations: z.record(z.string(), z.string()).optional(),
    group: z.enum([...ALL_QUESTION_SETS, 'values']),
    legacy: z.boolean().optional(),
    legacyDriver: z.string().optional(),
    legacySubdriver: z.string().optional(),
    main: z.boolean(),
    name: z.string().optional(),
    nameTranslated: translatedStringSchema.optional(),
    nameTranslations: z.record(z.string(), z.string()).optional(),
    standard: z.string().nullish(),
    theory: z.string().optional(),
  }),
});

export const categorySchema = baseCategorySchema.extend({
  relationships: z
    .object({
      parentCategory: baseCategorySchema.nullable().optional(),
      value: valueSchema.nullable().optional(),
    })
    .optional(),
});

export type CategoryResponse = z.infer<typeof categorySchema>;
