import { combineReducers } from '@reduxjs/toolkit';

import filters from './filters';
import form from './form';
import groups from './groups';
import pagination from './pagination';
import suggested from './suggested';
import teams from './teams';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  filters,
  form,
  groups,
  pagination,
  suggested,
  teams,
});
