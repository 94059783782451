import { jwtDecode } from 'jwt-decode';

import { Right } from '@peakon/shared/utils/rights/types';

type ImpersonationToken = {
  employeeId: string;
  accountId: string;
  companyId: string;
  scopes: Right[];
  exp: number;
};

export const decodeToken = (token: string | null) => {
  if (!token) {
    return null;
  }

  try {
    return jwtDecode<ImpersonationToken>(token);
  } catch {
    return null;
  }
};

export const hasProxyViewRight = (token: string | null) => {
  return decodeToken(token)?.scopes.includes('proxy:view');
};
