import { useMutation, useQueryClient } from '@tanstack/react-query';

import { analytics } from '@peakon/shared/features/analytics/analytics';
import api from '@peakon/shared/utils/api';

import { standaloneTeamSuggestionsQueryKeys } from './useTeamSuggestionsQuery';
import { catchHandler } from '../../../actions/NotificationActions';
import { useAppDispatch } from '../../../utils/reduxHooks';

type Args = {
  priorityId: string;
  text: string;
};

function addTeamSuggestion({ priorityId, text }: Args) {
  return api.post(`/team_suggestions/priority/${priorityId}`, {}, { text });
}

export function useAddTeamSuggestion() {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: addTeamSuggestion,
    onSuccess: () => {
      analytics.track('team_suggestion__added');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Automatically disabled here to enable the rule globally
      queryClient.invalidateQueries(standaloneTeamSuggestionsQueryKeys.all);
    },
    onError: (error) => {
      dispatch(catchHandler(error));
    },
  });
}
