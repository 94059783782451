import formatYearless from '@peakon/shared/utils/formatYearless';

type State =
  | {
      unread: number;
      unreadSince: null;
      unreadSinceFormatted: null;
    }
  | {
      unread: number;
      unreadSince: string;
      unreadSinceFormatted: string;
    };

const INITIAL_STATE = {
  unread: 0,
  unreadSince: null,
  unreadSinceFormatted: null,
};

const unreadState = (state: State = INITIAL_STATE, action: $TSFixMe): State => {
  switch (action.type) {
    case 'COMMENTS_LIST_BY_CONTEXT_SUCCESS': {
      const {
        meta: { unread, unreadSince },
      } = action.data;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return {
        unread,
        unreadSince,
        unreadSinceFormatted: formatYearless(unreadSince, 'LLS'),
      } as State;
    }

    case 'COMMENTS_MARK_AS_READ_SUCCESS':
    case 'COMMENTS_FLUSH': {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default unreadState;
