import React, { ReactNode, CSSProperties } from 'react';

import classnames from 'classnames';

import styles from './flex.css';

type ResponsiveProp<TValues> =
  | { s: TValues; m?: TValues; l?: TValues }
  | { s?: TValues; m: TValues; l?: TValues }
  | { s?: TValues; m?: TValues; l: TValues };

type Value<TValues> = TValues | ResponsiveProp<TValues>;

type SupportedProperties =
  | 'gap'
  | 'flexBasis'
  | 'flexShrink'
  | 'flexGrow'
  | 'flexWrap'
  | 'flexDirection'
  | 'alignItems'
  | 'alignSelf'
  | 'justifyContent'
  | 'justifySelf';

type SupportedPropertyValuesRecord = Record<
  SupportedProperties,
  Value<unknown> | undefined
>;

/**
 *
 * NOTE: Don't use `$TSFixMe`
 *
 * We can try to fix this with generics,
 * but right now it isn't worth it since
 * this is not exposed to consumers of the
 * component in any way.
 *
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Mapper = (value: any) => any;

export type Props = {
  children: ReactNode;
  className?: never;

  gap?: Value<0 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40 | 48 | 64>;
  flexBasis?: Value<CSSProperties['flexBasis']>;
  flexShrink?: Value<CSSProperties['flexShrink']>;
  flexGrow?: Value<CSSProperties['flexGrow']>;
  flexDirection?: Value<CSSProperties['flexDirection']>;
  alignItems?: Value<CSSProperties['alignItems']>;
  alignSelf?: Value<CSSProperties['alignSelf']>;
  justifyContent?: Value<CSSProperties['justifyContent']>;
  justifySelf?: Value<CSSProperties['justifySelf']>;
  flexWrap?: Value<CSSProperties['flexWrap']>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-restricted-syntax
function isResponsiveProperty(value: unknown): value is ResponsiveProp<any> {
  return Boolean(value && typeof value === 'object');
}

function prefix(value: number): string {
  return value < 10 ? `0${value}` : value.toString();
}

const propertyValueMaps: Partial<Record<SupportedProperties, Mapper>> = {
  gap(value: number) {
    return `var(--spacing-${prefix(value)})`;
  },
};

function applyMapper<TValue>(value: TValue, mapper?: Mapper): TValue {
  if (!mapper) {
    return value;
  }

  if (value === undefined || value === null) {
    return value;
  }

  return mapper(value);
}

function mapPropsToCustomProperties(
  props: Partial<SupportedPropertyValuesRecord>,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const properties = Object.keys(props) as SupportedProperties[];

  return properties.reduce(
    (customProperties, property) => {
      const value = props[property];

      if (!value) {
        return customProperties;
      }

      const mapper = propertyValueMaps[property];

      if (!isResponsiveProperty(value)) {
        // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
        customProperties[`--flex__s-${property}`] = applyMapper(value, mapper);
        return customProperties;
      }

      const { s, m, l } = value;

      return {
        ...customProperties,
        [`--flex__s-${property}`]: applyMapper(s, mapper),
        [`--flex__m-${property}`]: applyMapper(m, mapper),
        [`--flex__l-${property}`]: applyMapper(l, mapper),
      };
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as Record<string, unknown>,
  );
}

function mapPropsToClasses(props: Partial<SupportedPropertyValuesRecord>) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const properties = Object.keys(props) as SupportedProperties[];

  return properties.reduce(
    (classNames, property) => {
      const value = props[property];

      if (!value) {
        return classNames;
      }

      if (!isResponsiveProperty(value)) {
        // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
        classNames[styles[`s_${property}`]] = true;

        return classNames;
      }

      const { s, m, l } = value;

      const sc = styles[`s_${property}`];
      const mc = styles[`m_${property}`];
      const lc = styles[`l_${property}`];

      return {
        ...classNames,
        [sc]: s,
        [mc]: m,
        [lc]: l,
      };
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as Record<string, boolean>,
  );
}

export function Flex({
  children,
  /**
   *
   * NOTE: Ignoring `className`
   *
   * Since we explicitly DON'T want to support `className` and we don't want
   * it to be passed to the `values` we destructure it here and ignore it.
   *
   */
  className: _className,
  ...values
}: Props) {
  return (
    <div
      className={classnames(styles.flex, mapPropsToClasses(values))}
      style={mapPropsToCustomProperties(values)}
    >
      {children}
    </div>
  );
}
