import React from 'react';

import { EmptyStateGeneric } from '@peakon/bedrock/illustrations/react/empty-state';
import { LinkButton } from '@peakon/bedrock/react/button';
import { Heading2 } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export const NotFoundScreen = () => (
  <div className={styles.container}>
    <EmptyStateGeneric />
    <div className={styles.titleWrapper}>
      <Heading2 level={1} data-test-id="page-not-found-error-message">
        {t('not_found__title')}
      </Heading2>
    </div>
    <div className={styles.buttonWrapper}>
      <LinkButton variant="primary" href="/">
        {t('not_found__go_to_dashboard')}
      </LinkButton>

      <LinkButton
        variant="tertiary"
        href="https://doc.workday.com/en-us/peakon.html"
      >
        {t('not_found__visit_help_center')}
      </LinkButton>
    </div>
  </div>
);
