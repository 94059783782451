import { useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

// eslint-disable-next-line import/no-default-export
export default function useResize(
  callback: $TSFixMe,
  { wait = 0, triggerOnMount }: $TSFixMe = {},
) {
  const handleResize = useMemo(
    () =>
      wait !== 0
        ? debounce((event) => callback(event), wait)
        : (event: $TSFixMe) => callback(event),
    [wait, callback],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    if (triggerOnMount) {
      callback();
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line
}
