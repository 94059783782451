import { List } from 'immutable';

import { SystemNotification } from '@peakon/records';

type Action = {
  type: 'NOTIFICATION_SHOW' | 'NOTIFICATION_HIDE';
  data: SystemNotification;
};

const notifications = (
  state = List<SystemNotification>(),
  action: Action,
): List<SystemNotification> => {
  switch (action.type) {
    case 'NOTIFICATION_SHOW': {
      const notification = action.data;

      return state.findIndex((n) => n?.id === notification.id) === -1
        ? state.push(new SystemNotification(action.data))
        : state;
    }
    case 'NOTIFICATION_HIDE': {
      const { id } = action.data;

      return state.filter((n) => n?.id !== id);
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default notifications;
