import React from 'react';

import classnames from 'classnames';

import styles from './styles.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
};

export const PanelSectionHeader = ({ children, className, title }: Props) => (
  <div className={classnames(styles.root, className)}>
    <div className={styles.title}>{title}</div>
    {children && <div className={styles.content}>{children}</div>}
  </div>
);
